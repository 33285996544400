<app-user-detail
  *ngIf="isAdmin || isEmployee"
  [userUuid]="userToBook"
></app-user-detail>

<div class="container">
  <span *ngIf="type === 'flight'" class="component-info heading-5">{{
    "flight.custom.lets-request" | translate
  }}</span>
  <span *ngIf="type === 'train'" class="component-info heading-5">{{
    "train.custom.lets-request" | translate
  }}</span>

  <div class="bg-card">
    <div class="service-card-container">
      <div class="selector" *ngIf="type === 'flight'">
        <span
          class="selector-item"
          *ngIf="canBookHotel || isAdmin"
          (click)="navigate('hotel')"
          >{{ "common.hotels" | translate }}</span
        >
        <span
          class="selector-item-selected"
          *ngIf="canRequestCustomFlight || isAdmin"
          >{{ "common.flights" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestCustomTrain || isAdmin"
          (click)="navigate('train')"
          >{{ "common.trains" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestCustomCar || isAdmin"
          (click)="navigate('car')"
          >{{ "common.cars" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestAirbnb || isAdmin"
          (click)="navigate('airbnb')"
          >{{ "airbnb.custom.request.option" | translate }}</span
        >
      </div>

      <div class="selector" *ngIf="type === 'train'">
        <span
          class="selector-item"
          *ngIf="canBookHotel || isAdmin"
          (click)="navigate('hotel')"
          >{{ "common.hotels" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestCustomFlight || isAdmin"
          (click)="navigate('flight')"
          >{{ "common.flights" | translate }}</span
        >
        <span
          class="selector-item-selected"
          *ngIf="canRequestCustomTrain || isAdmin"
          >{{ "common.trains" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestCustomCar || isAdmin"
          (click)="navigate('car')"
          >{{ "common.cars" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestAirbnb || isAdmin"
          (click)="navigate('airbnb')"
          >{{ "airbnb.custom.request.option" | translate }}</span
        >
      </div>

      <form
        [formGroup]="form"
        [ngClass]="{ 'loading-background': loading }"
        (ngSubmit)="form.valid && !loading ? onSubmit() : (isInvalid = true)"
      >
        <div class="row" *ngIf="isModal && !userUuid">
          <div class="col-12">
            <div class="form-group">
              <mat-label
                >{{
                  type + ".create-" + type + ".choose-employee" | translate
                }}*</mat-label
              >
              <select
                class="form-control rounded-1 w-25"
                formControlName="userUuid"
              >
                <option
                  *ngFor="
                    let user of users;
                    trackBy: utilsService.getUuidTracking
                  "
                  [ngValue]="user"
                >
                  {{ user.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="travel-option" aria-label="Select an option">
          <span class="travel-option-span">{{
            "my-bookeds.flight.return-trip" | translate
          }}</span>
          <mat-radio-button
            [checked]="checkedReturn"
            (click)="returnTrip('return')"
            checked="true"
          >
          </mat-radio-button>
          <span class="travel-option-span">{{
            "my-bookeds.flight.one-way" | translate
          }}</span>
          <mat-radio-button
            [checked]="checkedOneWay"
            name="checkedOneWay"
            (click)="returnTrip('oneWay')"
            checked="false"
          ></mat-radio-button>
        </div>
        <div class="form-row">
          <input
            formControlName="origin"
            type="text"
            id="origin"
            [ngClass]="
              type == 'flight'
                ? form.controls['origin'].invalid && isInvalid
                  ? 'origin-ubication-flight-input form-control form-control--no-border error'
                  : 'origin-ubication-flight-input form-control form-control--no-border'
                : form.controls['origin'].invalid && isInvalid
                ? 'ubication-train-input form-control form-control--no-border error'
                : 'ubication-train-input form-control form-control--no-border'
            "
            matInput
            [matAutocomplete]="fromM"
            (input)="getCities($event.target.value)"
            placeholder="{{ 'train.form-train.origin' | translate }}"
          />
          <mat-autocomplete
            #fromM="matAutocomplete"
            panelWidth="20%"
            (optionSelected)="cleanCitiesList()"
          >
            <mat-option *ngFor="let option of citiesList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
          <div
            mat-stroked-button
            class="ubication-switch"
            (click)="exchangeUbications()"
          ></div>
          <input
            formControlName="destination"
            type="text"
            id="destination"
            [ngClass]="
              type == 'flight'
                ? form.controls['destination'].invalid && isInvalid
                  ? 'origin-ubication-flight-input form-control form-control--no-border error'
                  : 'origin-ubication-flight-input form-control form-control--no-border'
                : form.controls['destination'].invalid && isInvalid
                ? 'ubication-train-input form-control form-control--no-border error'
                : 'ubication-train-input form-control form-control--no-border'
            "
            matInput
            [matAutocomplete]="toM"
            (input)="getCities($event.target.value)"
            placeholder="{{ 'train.form-train.destination' | translate }}"
          />
          <mat-autocomplete
            #toM="matAutocomplete"
            panelWidth="20%"
            (optionSelected)="cleanCitiesList()"
          >
            <mat-option *ngFor="let option of citiesList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="form-row-date">
          <div
            *ngIf="checkedReturn"
            class="travel-date-field form-control form-control--no-border"
            [formGroup]="form"
          >
            <input
              #bookedRange
              readonly
              class="date-input"
              (dateSelect)="onDateSelection($event)"
              placeholder="{{ 'hotel.custom.select-date' | translate }}"
              #dp
              ngbDatepicker
              [dayTemplate]="t1"
              [autoClose]="'outside'"
              [displayMonths]="1"
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              [minDate]="minDateNgStruct"
              [maxDate]="maxDateNgStruct"
              [ngClass]="{
                error:
                  (form.controls['dateDeparture'].invalid && isInvalid) ||
                  (form.controls['dateReturn'].invalid && isInvalid)
              }"
              (closed)="onDatepickerClosed()"
            />
            <ng-template #t1 let-date let-focused="focused">
              <span
                class="custom-day"
                [ngClass]="{
                  activeFrom: form.controls['dateDeparture'].value === date,
                  activeTo: form.controls['dateReturn'].value === date
                }"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>
          <div
            *ngIf="checkedOneWay"
            class="travel-date-field form-control form-control--no-border"
            [formGroup]="form"
            [ngClass]="{oneWay: checkedOneWay}"
          >
            <input
              id="date_form"
              readonly
              class="date-picker-input"
              placeholder="{{ 'hotel.custom.select-date' | translate }}"
              name="hotelPicker"
              formControlName="dateDeparture"
              ngbDatepicker
              #hotelPicker="ngbDatepicker"
              (click)="hotelPicker.toggle()"
              [minDate]="minDateNgStruct"
              [maxDate]="maxDateNgStruct"
              [ngClass]="{
                error: form.controls['dateDeparture'].invalid && isInvalid
              }"
            />
          </div>
          <input
            type="text"
            readonly
            id="DateIn"
            name="DateIn"
            class="hours-field form-control form-control--no-border"
            [value]="
              form.value.timeDeparture
                ? form.value.timeDeparture.hour +
                  ' : ' +
                  form.value.timeDeparture.minute
                : null
            "
            placeholder="{{
              'custom-service.train-flight.departure' | translate
            }}"
            [ngClass]="{
              'input-oneWay': checkedOneWay,
              error: form.controls['timeDeparture'].invalid && isInvalid
            }"
            (click)="
              showHoursDeparture = !showHoursDeparture; showHoursArrival = false
            "
          />

          <ngb-timepicker
            formControlName="timeDeparture"
            id="departureHours"
            [meridian]="false"
            *ngIf="showHoursDeparture"
            class="hour-departure-modal"
            tabindex="0"
            (blur)="showHoursDeparture = false"
          >
          </ngb-timepicker>

          <input
            *ngIf="checkedReturn"
            type="text"
            readonly
            id="DateOut"
            name="DateOut"
            class="hours-field form-control form-control--no-border"
            [value]="
              form.value.timeReturn
                ? form.value.timeReturn.hour +
                  ' : ' +
                  form.value.timeReturn.minute
                : null
            "
            [ngClass]="{
              error: form.controls['timeReturn'].invalid && isInvalid
            }"
            placeholder="{{ 'custom-service.train-flight.return' | translate }}"
            (click)="
              showHoursArrival = !showHoursArrival; showHoursDeparture = false
            "
          />

          <ngb-timepicker
            formControlName="timeReturn"
            id="returnHours"
            [meridian]="false"
            *ngIf="showHoursArrival"
            class="hour-return-modal"
            tabindex="0"
            (blur)="showHoursArrival = false"
          ></ngb-timepicker>
        </div>

        <div class="form-row">
          <div class="annotations">
            <mat-label class="annotations-label">{{
              "hotel.custom.annotations" | translate
            }}</mat-label>
            <textarea
              class="annotation-textarea form-control form-control--no-border"
              formControlName="notes"
              placeholder="{{
                'hotel.custom.annotations-placeholder' | translate
              }}"
            ></textarea>
          </div>
        </div>

        <div class="button-row">
          <button
            [ngStyle]="{
              cursor: !form.valid || loading ? 'not-allowed' : 'pointer'
            }"
            [ngClass]="{
              'custom-btn disabled-button': !form.valid || loading, 'custom-btn': form.valid || !loading
            }"
          >
            {{ "common.send-request" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
