<form *ngIf="formSearchTrain" [formGroup]="formSearchTrain" class="search-card">
  <div class="trip-length-container">
    <div class="trip-length-selector-container">
      <div id="roundtrip" class="cursor-pointer" [ngClass]="{ active: checkedOneway === false }"
        (click)="setOneWaySearch(false)">
        {{ "train.form-train.roundTripYes" | translate }}
      </div>
      <div id="oneWay" class="cursor-pointer" [ngClass]="{ active: checkedOneway }" (click)="setOneWaySearch(true)">
        {{ "train.form-train.roundTripNo" | translate }}
      </div>
    </div>
  </div>
  <div class="origin-destination-container">
    <div class="input-containers col-5">
      <label class="origin-container train-station-container input-icon border-radius-20">
        <img src="assets/img/svg/train-orange.svg" />
        <input placeholder="{{ 'train.form-train.location' | translate }}" id="origin" type="text"
          [matAutocomplete]="from" [value]="searchConfig.originText" (input)="getTrainStations($event.target.value)" />
        <mat-autocomplete #from="matAutocomplete" panelWidth="30%"
          (optionSelected)="optionSelected('origin', $event.option.value)">
          <mat-option *ngFor="let option of trainStationList" [value]="option.title">
            {{ option.title }}
          </mat-option>
        </mat-autocomplete>
      </label>
    </div>
    <button class="button-secondary col-1" (click)="switchOriginDestination()">
      <img src="assets/img/svg/train/switch.svg" alt="switch" />
    </button>
    <div class="input-containers col-5">
      <label class="destination-container train-station-container input-icon border-radius-20">
        <img src="assets/img/svg/train-orange.svg" />
        <input placeholder="{{ 'train.form-train.location' | translate }}" id="destination" type="text"
          [value]="searchConfig.destinationText" [matAutocomplete]="to" (input)="getTrainStations($event.target.value)" />
        <mat-autocomplete #to="matAutocomplete" panelWidth="30%"
          (optionSelected)="optionSelected('destination', $event.option.value)">
          <mat-option *ngFor="let option of trainStationList" [value]="option.title">
            {{ option.title }}
          </mat-option>
        </mat-autocomplete>
      </label>
    </div>
  </div>
  <div class="other">
    <div class="date-container" [ngClass]="{oneWay: checkedOneway}">
      <div class="outward">
        <label for="">{{ "flights.form.dateIn" | translate }}</label>
        <label class="input-icon border-radius-20">
          <img src="assets/img/svg/calendar-orange.svg" />
          <input placeholder="{{ 'train.form-train.departure-date' | translate }}" [minDate]="getInMinDate()"
            [maxDate]="formSearchTrain.get('returnDate').value" (ngModelChange)="onDateChange()" (click)="dInput.toggle()" ngbDatepicker
            #dInput="ngbDatepicker" formControlName="departDate" />
        </label>
      </div>
      <div class="return" *ngIf="!checkedOneway">
        <label for="">{{ "flights.form.dateOut" | translate }}</label>
        <label class="input-icon border-radius-20">
          <img src="assets/img/svg/calendar-orange.svg" />
          <input placeholder="{{ 'train.form-train.return-date' | translate }}" [minDate]="getOutMinDate()" [maxDate]="maxDateNgStruct" (ngModelChange)="onDateChange()" (click)="dInput.toggle()" ngbDatepicker #dInput="ngbDatepicker"
            formControlName="returnDate" />
        </label>
      </div>
      <div class="traveler-select-container">
        <label for="">{{
          "train.form-train.traveler-selection" | translate
          }}</label>
        <div class="border-radius-20" (click)="openTravelerSelector()">
          <label class="input-icon border-radius-20 cursor-pointer">
            <span>
              {{
              searchConfig?.travelers != null
              ? searchConfig?.travelers.length
              : "0"
              }}
              {{ "train.form-train.travelers" | translate | lowercase }}
            </span>
            <span>+</span>
          </label>
        </div>
      </div>
      <div class="card-select-container" ngbDropdown>
        <label for="">
          {{ "train.trenymas.title" | translate }}
        </label>
        <div *ngIf="trainCards.length > 0" ngbDropdownToggle class="dropdown-toggle border-radius-20 cursor-pointer">
          {{
          formSearchTrain.get("travelCard").value != null
          ? formSearchTrain.get("travelCard").value.viewValue
          : ("train.trenymas.none" | translate)
          }}
        </div>
        <div ngbDropdownMenu class="dropdown-menu border-radius-20" aria-labelledby="dropdownBasic1">
          <p ngbDropdownItem (click)="selectCard(null)" [ngClass]="{
              selected: formSearchTrain.get('travelCard').value === null
            }">
            {{ "train.trenymas.none" | translate }}
          </p>
          <p ngbDropdownItem *ngFor="let trainCard of trainCards; let i = index" (click)="selectCard(trainCard)"
            [ngClass]="{
              selected: trainCard === formSearchTrain.get('travelCard').value,
              last: trainCards.length - 1 === i
            }">
            {{ trainCard.viewValue }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <mat-accordion #timeFilterAccordion="matAccordion">
    <mat-expansion-panel [expanded]="
        searchConfig?.filters?.arrivalTime != null ||
        searchConfig?.filters?.departureTime != null ||
        searchConfig?.filters?.returnArrivalTime != null ||
        searchConfig?.filters?.returnDepartureTime != null
      " class="custom-expansion-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <label>{{ "train.form-train.time-filters-title" | translate }}</label>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="time-filters">
        <div class="custom-slider">
          <label>
            {{ "train.form-train.outward-time-filters" | translate }}
          </label>
          <ngx-slider (userChangeEnd)="getSliderValue(1, $event)" [(value)]="minTimeDeparture"
            [(highValue)]="maxTimeDeparture" [options]="optionsTimeDeparture"></ngx-slider>
        </div>
        <div class="custom-slider" *ngIf="checkedOneway === false">
          <label>
            {{ "train.form-train.return-time-filters" | translate }}
          </label>
          <div class="custom-slider">
            <ngx-slider (userChangeEnd)="getSliderValue(2, $event)" [(value)]="minReturnTimeDeparture"
              [(highValue)]="maxReturnTimeDeparture" [options]="optionsTimeDeparture"></ngx-slider>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="container-fluid mt-2">
    <div>
      <label>{{'my-bookeds.flight.travellers' | translate}}</label>
    </div>
    <div class="row">
      <div class="col-4">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>{{ ("common.traveller" | translate) + ' ' + 1 }}</mat-label>
          <input matInput [value]="defaultUser?.name + ' ' + defaultUser?.lastname" readonly>
        </mat-form-field>
      </div>
      <div *ngFor="let traveler of travelers; let i = index; trackBy: trackByIndex" class="col-4 traveler-dropdown">
        <mat-form-field appearance="fill" class="full-width">
          <mat-label>
            {{ travelers[i] ? ('common.traveller' | translate) + ' ' + (i + 2) : ('common.add-passenger' | translate) }}
          </mat-label>
          <mat-select #select id="traveler-{{ i }}" [(value)]="travelers[i]" (closed)="onPanelClose()" (openedChange)="onOpenedChange($event, input)">
            <mat-option>
              <input #input matInput (input)="applyFilter($event.target.value)" (click)="$event.stopPropagation()" placeholder="{{ 'common.search-user' | translate }}" />
            </mat-option>
            <mat-option *ngFor="let user of filteredUsers" [value]="user.uuid">
              {{ user.name }} {{ user.lastname }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="options-travel">
    <label class="project-label">{{'project.project-assign' | translate}}</label>
    <div class="d-flex align-items-center row">
      <div class="input-group col-4" [ngClass]="{ 'disabled-input': loadingProjects || !formSearchTrain.get('departDate').value || (!formSearchTrain.get('returnDate').value && !checkedOneway) }">
        <select
          name=""
          id=""
          class="form-control form-control--squared select-input custom-select"
          formControlName="selectedProject"
          [disabled]="loadingProjects || !formSearchTrain.get('departDate').value || (!formSearchTrain.get('returnDate').value && !checkedOneway)"
        >
          <option [ngValue]="null" disabled selected>{{'project.project-select' | translate}}</option>

          <option *ngFor="let project of projects" [ngValue]="project">
            {{ project.title }}
          </option>
        </select>
      </div>
  
      <button *ngIf="!loadingProjects" class="btn no-focus-border" (click)="openTravelDialog()"
        [disabled]="loadingProjects || !formSearchTrain.get('departDate').value || (!formSearchTrain.get('returnDate').value && !checkedOneway)">
        <img src="assets/img/svg/add-booking-circle.svg" alt="Add booking" />
      </button>
      <i style="margin-left: 0.5rem; font-size: 1.5rem;" class="ft-loader" *ngIf="loadingProjects"></i>
    </div>
  </div>

  <!--   DISABLED UNTIL TRAINLINE INTEGRATION
  <button
    (click)="submit()"
    [disabled]="formSearchTrain.valid === false"
    class="button-primary"
  > -->
  <button (click)="submit()" class="button-primary custom-btn"
  [disabled]="
    !formSearchTrain.valid ||
    (!checkedOneway &&
      (!formSearchTrain.get('returnDate').value) || !allTravelersSelected())
  ">
  {{ "train.form-train.search-trains" | translate }}
</button>
  <div *ngIf="searchHasFailed" class="search-has-failed-container">
    <p>
      {{ "common.search-fail-custom-service.title" | translate }}
    </p>
    <button class="button-secondary" (click)="
        groupFilters.requestCustomTrain
          ? goToCreateCustomTrain()
          : utilsService.openChat()
      ">
      {{
      (groupFilters.requestCustomTrain
      ? "common.search-fail-custom-service.button"
      : "common.open-chat"
      ) | translate
      }}
    </button>
  </div>
</form>