<router-outlet></router-outlet>
<div
  *ngIf="utilsService.loadingFlights"
  class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
>
  <div class="page-loading" style="opacity: 1; z-index: 98">
    <app-loader-plane></app-loader-plane>
    <p *ngIf="utilsService.reloadingFlights" class="loading-message">
      {{ "common.updating-results-flight" | translate }}  <span class="dots"><span>.</span><span>.</span><span>.</span></span>
    </p>
  </div>
</div>
<div
  *ngIf="utilsService.loadingTrains"
  class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
>
  <div class="page-loading" style="opacity: 1; z-index: 98">
    <app-loader-train></app-loader-train>
  </div>
</div>
<div
  *ngIf="utilsService.loadingHotels"
  class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
>
  <div class="page-loading" style="opacity: 1; z-index: 98">
    <app-loader-hotel></app-loader-hotel>
  </div>
</div>
