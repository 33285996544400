import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { TravelService } from "@core/services/travel.service";
import { UtilsService } from "@core/services/utils.service";
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";

@Component({
  selector: "app-dialog-assign-project",
  templateUrl: "./dialog-assign-project.component.html",
  styleUrls: ["./dialog-assign-project.component.scss"],
})
export class DialogAssignProjectComponent implements OnInit, AfterViewInit {
  travelData: {
    type: string;
    initDate: string;
    beginDate: string;
    endDate: string;
    uuid: string;
    userUuid: string;
    bookingUuid: any;
    data: any;
  };
  isUser: boolean;
  isCreate = false;
  isEdit = false;
  projects: any[];
  isOnlyCreate = false;
  assignProjectResources = {
    title: "project.assign-project",
  };
  createProjectResources = {
    title: "project.create",
  };
  title = this.assignProjectResources.title;
  buttonTitle = this.createProjectResources.title;
  createProjectForm: UntypedFormGroup;
  hoveredDate: NgbDateStruct;
  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear() - 100,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 50,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  @ViewChild("bookedRange") bookedRange: ElementRef;
  selectedProject: any;
  ogProject: any;
  companyUuid: string;
  isLoading = true;
  userUuid: string;

  constructor(
    private travelService: TravelService,
    private dialogRef: MatDialogRef<DialogAssignProjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private ngbDateParser: NgbDateParserFormatter,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
  ) {
    this.createProjectForm = this.fb.group({
      title: [
        data.editTravelData?.title ? data.editTravelData.title : '',
        Validators.required,
      ],
      initDate: [
        data.editTravelData?.initDate ? data.editTravelData.initDate : undefined,
      ],
      endDate: [
        data.editTravelData?.endDate ? data.editTravelData.endDate : undefined,
      ],
      description: [
        data.editTravelData?.description ? data.editTravelData.description : "",
      ],
      // userUuid: [null],
      // bookUuid: [null],
      type: ["new"],
      // companyUuid: [null],
    });
    if (data != null && Object.keys(data).length > 0) {
      this.travelData = data.bookingData;
      this.isUser = data.isUser;
      this.isCreate = data.isOnlyCreate ? data.isOnlyCreate : false;
      this.isOnlyCreate = this.isCreate;
      let userUuid = this.travelData?.userUuid;
      if (userUuid == null) {
        userUuid =
          this.travelData?.userUuid != null
            ? this.travelData.userUuid
            : this.route.snapshot.paramMap.get("uuid") != null
              ? this.route.snapshot.paramMap.get("uuid")
              : data.userUuid != null
                ? data.userUuid
                : this.utilsService.getLocalStorageItem("userUuid") != null
                  ? this.utilsService.getLocalStorageItem("userUuid").value
                  : null;
      }
      this.userUuid = userUuid;
      this.companyUuid =
        data.companyUuid != null
          ? data.companyUuid
          : this.utilsService.getLocalStorageItem("companyUuid") != null
            ? this.utilsService.getLocalStorageItem("companyUuid").value
            : this.utilsService.getLocalStorageItem("company") != null
              ? this.utilsService.getLocalStorageItem("company").value?.uuid
              : JSON.parse(localStorage.getItem("company"))?.uuid;
      if (this.isOnlyCreate === false) {
        this.travelService
          .getTravelsWithParams({ companyUuid: this.companyUuid })
          .subscribe(
            (res) => {
              this.projects = [];
              if (data.isNewBooking == null || data.isNewBooking === false) {
                this.projects.push({
                  title: "--------No-------- ",
                  uuid: "none",
                  initDate: new Date(
                    this.date.getFullYear(),
                    1,
                    1
                  ).toISOString(),
                  endDate: new Date(
                    this.date.getFullYear() + 10,
                    1,
                    1
                  ).toISOString(),
                });
              }
              this.projects = this.projects.concat(res.docs);
              this.projects = this.projects.filter((project) => {
                if (project.initDate && project.endDate) {
                  const projectDatesAreCorrect = this.checkDatesAreCorrect(
                    this.travelData.initDate != null
                      ? this.travelData.initDate
                      : this.travelData.beginDate,
                    this.travelData.endDate,
                    moment(project.initDate),
                    moment(project.endDate)
                  );
                  return projectDatesAreCorrect;
                } else {
                  return true;
                }
              });
              const travelUuid =
                data.travelToEdit?.uuid != null
                  ? data.travelToEdit.uuid
                  : this.travelData.uuid;
              if (travelUuid != null) {
                this.selectedProject = this.projects.find(
                  (project) => project.uuid === travelUuid
                );
                if (!this.selectedProject)
                  this.selectedProject = this.projects.find(
                    (project) => project.uuid === "none"
                  );
                if (
                  (data.isNewBooking == null || data.isNewBooking === false) &&
                  this.selectedProject
                ) {
                  this.ogProject = JSON.parse(
                    JSON.stringify(this.selectedProject)
                  );
                }
              }
              this.isLoading = false;
            },
            (err) => {
              console.log(err);
              this.isLoading = false;
            }
          );
      } else {
        this.title = this.createProjectResources.title;
        this.isLoading = false;
      }
    } else {
      this.dialogRef.close();
    }
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    if (this.createProjectForm.value.title != null && this.createProjectForm.value.title != '') {
      this.isEdit = true;
      this.title = 'project.edit-project';
      const formVal = JSON.parse(JSON.stringify(this.createProjectForm.value));
      if (formVal.initDate && formVal.endDate) {
        const initDate = moment(formVal.initDate);
        const endDate = moment(formVal.endDate);
        formVal.initDate = initDate.format("DD/MM/YYYY");
        formVal.endDate = endDate.format("DD/MM/YYYY");
        this.bookedRange.nativeElement.value =
          formVal.initDate + " - " + formVal.endDate;
        this.createProjectForm.patchValue({
          initDate: {
            day: initDate.date(),
            month: initDate.month() + 1,
            year: initDate.year(),
          },
        });
        this.createProjectForm.patchValue({
          endDate: {
            day: endDate.date(),
            month: endDate.month() + 1,
            year: endDate.year(),
          },
        });
      }
      this.createProjectForm.updateValueAndValidity();
    }
  }

  changeTab() {
    this.isCreate = !this.isCreate;
    if (this.isCreate === true) {
      this.title = this.createProjectResources.title;
      this.buttonTitle = this.assignProjectResources.title;
    } else {
      this.title = this.assignProjectResources.title;
      this.buttonTitle = this.createProjectResources.title;
    }
  }

  checkDatesAreCorrect(
    bookingInitDate,
    bookingEndDate,
    projectInitDate,
    projectEndDate
  ) {
    let projectDatesAreCorrect = true;
    if (bookingInitDate != null) {
      const momentInitDate = moment(bookingInitDate);
      projectDatesAreCorrect = moment(projectInitDate).isSameOrBefore(
        momentInitDate,
        "day"
      );
      if (bookingEndDate != null && projectDatesAreCorrect) {
        const momentEndDate = moment(bookingEndDate);
        projectDatesAreCorrect = moment(projectEndDate).isSameOrAfter(
          momentEndDate,
          "day"
        );
      } else {
        if (bookingEndDate == null && projectDatesAreCorrect) {
          projectDatesAreCorrect = moment(projectEndDate).isSameOrAfter(
            momentInitDate,
            "day"
          );
        }
      }
    }
    return projectDatesAreCorrect;
  }

  close(isFinal: boolean) {
    if (isFinal === false) {
      this.dialogRef.close();
    } else {
      if (this.isCreate) {
        const formVal = JSON.parse(
          JSON.stringify(this.createProjectForm.value)
        );
        if (!this.checkDisable()) {
          if (this.bookedRange.nativeElement.value === "") {
            delete formVal.initDate;
            delete formVal.endDate;
          }
          let initDate;
          let endDate;
          if (formVal.initDate && formVal.endDate) {
            initDate = moment(
              new Date(
                formVal.initDate.year,
                formVal.initDate.month - 1,
                formVal.initDate.day
              )
            );
            endDate = moment(
              new Date(
                formVal.endDate.year,
                formVal.endDate.month - 1,
                formVal.endDate.day
              )
            );
          }
          let projectDatesAreCorrect = true;
          if (this.travelData != null && initDate && endDate) {
            projectDatesAreCorrect = this.checkDatesAreCorrect(
              this.travelData.initDate != null
                ? this.travelData.initDate
                : this.travelData.beginDate,
              this.travelData.endDate,
              initDate,
              endDate
            );
          }
          if (projectDatesAreCorrect && formVal.initDate && formVal.endDate) {
            formVal.initDate = initDate.format("MM/DD/YYYY");
            formVal.endDate = endDate.format("MM/DD/YYYY");
          }
          if (this.isEdit) {
            delete formVal.type;
          }
          formVal["companyUuid"] = this.companyUuid;
          if (this.userUuid != null && !(this.data?.fromAdmin)) {
            formVal["userUuid"] = this.userUuid;
          }
          const body: any = {
            travelData: formVal,
            isCreate: true,
          };
          if (this.travelData != null) {
            const addBody = {
              item: {
                type: this.travelData.type,
                uuid: this.travelData.bookingUuid,
              },
            };
            body.addBody = addBody;
          }
          this.dialogRef.close(body);
        }
      } else {
        const addBody = {
          item: {
            type: this.travelData.type,
            uuid: this.travelData.bookingUuid,
          },
        };

        if (this.selectedProject.uuid === "none") {
          if (this.ogProject != null) {
            this.travelData.type = this.selectedProject.uuid;
            this.travelData.uuid = this.ogProject.uuid;
            const body = {
              travelData: this.travelData,
              addBody,
              isCreate: false,
              projectUuid: this.selectedProject.uuid,
              project: this.selectedProject
            };
            this.dialogRef.close(body);
          } else {
            this.dialogRef.close();
          }
        } else {
          const body = {
            travelData: this.travelData,
            addBody,
            isCreate: false,
            projectUuid: this.selectedProject.uuid,
            project: this.selectedProject
          };
          this.dialogRef.close(body);
        }
      }
    }
  }

  // Date range picker methods
  onDateSelection(date: NgbDate) {
    if (
      !this.createProjectForm.controls["initDate"].value &&
      !this.createProjectForm.controls["endDate"].value
    ) {
      this.createProjectForm.controls["initDate"].setValue(date);
    } else if (
      this.createProjectForm.controls["initDate"].value &&
      !this.createProjectForm.controls["endDate"].value &&
      date &&
      date.after(this.createProjectForm.controls["initDate"].value)
    ) {
      this.createProjectForm.controls["endDate"].setValue(date);
    } else {
      this.createProjectForm.controls["endDate"].setValue(null);
      this.createProjectForm.controls["initDate"].setValue(date);
    }
    this.bookedRange.nativeElement.value =
      this.ngbDateParser.format(
        this.createProjectForm.controls["initDate"].value
      ) +
      " - " +
      this.ngbDateParser.format(
        this.createProjectForm.controls["endDate"].value
      );
  }

  isHovered(date: NgbDate) {
    return (
      this.createProjectForm.controls["initDate"].value &&
      !this.createProjectForm.controls["endDate"].value &&
      this.hoveredDate &&
      date.after(this.createProjectForm.controls["initDate"].value) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      date.after(this.createProjectForm.controls["initDate"].value) &&
      date.before(this.createProjectForm.controls["endDate"].value)
    );
  }

  checkDisable() {
    let initDate = this.createProjectForm.controls["initDate"].value;
    let endDate = this.createProjectForm.controls["endDate"].value;
    return this.createProjectForm.controls["title"].value === null ||
      this.createProjectForm.controls["title"].value === '' || (this.travelData &&
        !this.checkDatesAreCorrect(
          this.travelData.initDate != null
            ? moment(this.travelData.initDate).format('YYYY/MM/DD')
            : moment(this.travelData.beginDate).format('YYYY/MM/DD'),
          moment(this.travelData.endDate).format('YYYY/MM/DD'),
          initDate ? moment([initDate.year, initDate.month - 1, initDate.day]).format('YYYY-MM-DD') : null,
          endDate ? moment([endDate.year, endDate.month - 1, endDate.day]).format('YYYY-MM-DD') : null
        ))
      ? true
      : false;
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.createProjectForm.controls["initDate"].value) ||
      date.equals(this.createProjectForm.controls["endDate"].value)
    );
  }
}
