import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChildren,
} from "@angular/core";
import { FormControl, UntypedFormArray, UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Hotel } from "@core/models";
import { SearchConfig } from "@interfaces";
import {
  NgbDateParserFormatter,
  NgbDropdown,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CompanyService, NgxToastrService, TravelService, UserService, UtilsService } from "@services";
import { CustomDateParserFormatter } from "@shared/component/date-parser";
import { DialogAssignProjectComponent } from "@shared/component/dialog-assign-project/dialog-assign-project.component";
import moment from "moment";
import { Subscription } from "rxjs";

// @ts-ignore
@Component({
  selector: "app-search-form",
  templateUrl: "./search-form.component.html",
  styleUrls: ["./search-form.component.scss"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class SearchFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() openModal = false;
  @Input() isOnDetails = false;
  @Input() userToBook: any;
  @Input() companyUuid: string;
  @Input() firstSearch = true;
  @Input() hotelsMapCopy: Hotel[];
  @Input() hotelsListCopy: Hotel[];
  @Input() uuidUser: any;
  @Input() travelersSelected: any;
  @Input() projectDataSaved: any;
  @Input() isAdmin: any;
  @Input() groupFilters: any;
  @Input() searchConfig: SearchConfig = {
    uuid: "",
    locationText: "",
    latitude: "",
    longitude: "",
    checkIn: null,
    minElements: "0",
    checkOut: null,
    occupancies: [
      {
        rooms: 1,
        adults: 1,
        paxes: [],
      },
    ],
  };

  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  formRoom: any;
  rooms = [];

  guests: any[] = [];
  guestsNumber: number = 0;
  opened: boolean[][] = [[false], [false], [false]];
  @ViewChildren(NgbDropdown) dropdown: NgbDropdown;

  @Output() emitData = new EventEmitter<any>();
  @Output() emitSelectedUsers = new EventEmitter<any>();
  @Output() emitProject = new EventEmitter<any>();

  defaultUser: any;
  travelers: string[] = [];
  companyUsers: { name: string; lastname: string; uuid: string }[] = [];
  selectedUsers: any;
  public filterControl = new FormControl();
  public filteredUsers: { name: string; lastname: string; uuid: string }[];
  projects: any[];
  selectedProject: any = null;
  loadingProjects: boolean = false; 
  subcriptions: Subscription[] = [];

  constructor(
    private ngxToastrService: NgxToastrService,
    public translate: TranslateService,
    private formBuilder: UntypedFormBuilder,
    public utilsService: UtilsService,
    private companyService: CompanyService,
    private userService: UserService,
    private travelService: TravelService,
    private dialog: MatDialog
  ) {
    this.formRoom = this.formBuilder.group({
      room: this.formBuilder.array([
        {
          idHab: 1,
          numAdults: 1,
        },
      ]),
    });
  }

  ngOnInit() {
    this.getCompanyUsers();
    this.utilsService.clearProcessedUuids();
    this.utilsService.clearProjectData();

    if(this.projectDataSaved){
      this.projects = this.projectDataSaved.allProjects;
      this.selectedProject = this.projectDataSaved.selectedProject;
    }

    if (this.travelersSelected && this.travelersSelected.length > 0) {
      const travelersSelectedCopy = [...this.travelersSelected];
      travelersSelectedCopy.shift();
      this.travelers = travelersSelectedCopy.map((user) => user.uuid);
    }
    const array = [];
    if (this.searchConfig && this.searchConfig.occupancies) {
      let i = 1;
      for (const room of this.searchConfig.occupancies) {
        const newRoom = {
          idHab: i,
          numAdults: room.adults,
        };
        i++;
        array.push(newRoom);
      }
      this.formRoom = this.formBuilder.group({
        room: this.formBuilder.array(array),
      });
      this.rooms = array;
    }
    if (!this.travelersSelected) {
      this.updateTravelers();
    }
  }

  ngAfterViewInit() {}

  getInMaxDate() {
    return this.utilsService.getInDate(
      this.maxDateNgStruct,
      this.searchConfig.checkOut
    );
  }

  getOutMinDate() {
    const minDateWithAdvance = this.getInMinDate();
    return this.utilsService.getOutDate(
      minDateWithAdvance,
      this.searchConfig.checkIn
    );
  }
 
  getInMinDate() {
    const daysInAdvance = this.isAdmin ? 0 : (this.groupFilters?.hotelDaysInAdvance || 0);
    const minDate = moment().add(daysInAdvance, 'days');
    return {
      year: minDate.year(),
      month: minDate.month() + 1,
      day: minDate.date(),
    };
  }

  getAddress(place: object) {
    if (place["geometry"]) {
      this.searchConfig.locationText = place["formatted_address"];
      this.searchConfig.latitude = place["geometry"]["location"]
        .lat()
        .toString();
      this.searchConfig.longitude = place["geometry"]["location"]
        .lng()
        .toString();
    } else {
      this.searchConfig.latitude = "";
      this.searchConfig.longitude = "";
      this.translate
        .get(["common.attention", "search.search-homepage.pick-valid-location"])
        .subscribe((result) => {
          this.ngxToastrService.typeInfo(
            result["common.attention"],
            result["search.search-homepage.pick-valid-location"]
          );
        });
    }
    this.unFocusInput();
  }

  unFocusInput() {
    if (document.getElementById("address-input") as HTMLElement) {
      (document.getElementById("address-input") as HTMLElement).focus();
    }
    if (this.searchConfig.checkIn && this.searchConfig.checkOut) {
      document.getElementById("date_form").focus();
    } else {
      document.getElementById("date_form").focus();
    }
  }

  getRooms() {
    return this.formRoom.get("room") as UntypedFormArray;
  }

  addRoom(number: number) {
    const arrayRooms = [];
    for (let i = 0; i < number; i++) {
      arrayRooms.push({
        idHab: i + 1,
        numAdults: 1,
      });
      this.formRoom = this.formBuilder.group({
        room: this.formBuilder.array(arrayRooms),
      });
      this.rooms = arrayRooms;
    }

    this.updateTravelers();
  }

  checkDropdown(dropdown: any, number: number, item: number) {
    this.opened[number][item] = dropdown;
  }

  sendData() {
    this.searchConfig.occupancies = [];
    if (this.rooms.length < 1) {
      this.searchConfig.occupancies = [
        {
          rooms: 1,
          adults: 1,
        },
      ];
    } else {
      for (let i = 0; i < this.rooms.length; i++) {
        const obj = {
          rooms: 1,
          adults: this.rooms[i].numAdults,
          paxes: [],
        };

        this.searchConfig.occupancies.push(obj);
      }
    }
    let selectedUuids = this.travelers.slice();
    if (this.uuidUser) {
      selectedUuids.unshift(this.uuidUser);
    }
    const selectedUsers = selectedUuids.map((uuid) =>
      this.companyUsers.find((user) => user.uuid === uuid)
    );
    this.selectedUsers = selectedUsers;
    const projectData = {
      selectedProject: this.selectedProject,
      allProjects: this.projects
    };

    this.emitData.emit(this.searchConfig);
    this.emitSelectedUsers.emit(this.selectedUsers);
    this.emitProject.emit(projectData);
  }

  updateTravelers() {
    const user = this.companyUsers.find((user) => user.uuid === this.uuidUser);

    if (user) {
      this.defaultUser = user;
    }
    for (let i = 0; i < this.rooms.length - 1; i++) {
      if (!this.travelers[i]) {
        this.travelers[i] = "";
      }
    }
    if (this.travelers.length > this.rooms.length - 1) {
      this.travelers = this.travelers.slice(0, this.rooms.length - 1);
    }
    setTimeout(() => {
      this.travelers = [...this.travelers];
    });
  }

  getCompanyUsers() {
    if (!this.companyUuid) {
      this.userService.getUser(this.uuidUser).subscribe(
        (res) => {
          this.companyUuid = res.companyUuid;
          this.fetchCompanyUsers();
        },
        (err) => {}
      );
    } else {
      this.fetchCompanyUsers();
    }
  }

  fetchCompanyUsers() {
    if (this.companyUuid) {
      this.companyService.getCompanyUsers(this.companyUuid).subscribe(
        (res) => {
          this.companyUsers = res.map((user) => ({
            name: user.name,
            lastname: user.lastname,
            uuid: user.uuid,
          }));
          
          this.filteredUsers = this.companyUsers;
          this.setInitialTraveler();
        },
        (err) => {}
      );
    }
  }

  setInitialTraveler() {
    const user = this.companyUsers.find((user) => user.uuid === this.uuidUser);
    if (user) {
      this.defaultUser = user;
    }
    if (!this.travelersSelected) {
      this.updateTravelers();
    }
  }

  onPanelClose() {
    this.filterControl.setValue('');
    this.filteredUsers = this.companyUsers;
  }

  applyFilter(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredUsers = this.companyUsers.filter(user =>
      user.name.toLowerCase().includes(filterValue) || 
      user.lastname.toLowerCase().includes(filterValue)
    );
  }

  onOpenedChange(isOpened: boolean, input: HTMLInputElement) {
    if (isOpened) {
      setTimeout(() => input.focus(), 0);
    }
  }

  allTravelersSelected(): boolean {
    return this.travelers.every(traveler => traveler !== null && traveler !== undefined && traveler !== '');
  }


  trackByIndex(index: number, item: any): any {
    return index;
  }

  onDateChange() {
    if (this.searchConfig.checkIn && this.searchConfig.checkOut) {
      const checkInParam = this.transformDateToISO(this.searchConfig.checkIn);
      const checkOutParam = this.transformDateToISO(this.searchConfig.checkOut);
      this.loadProjects(checkInParam, checkOutParam);
    }
  }

  loadProjects(checkIn: any, checkOut: any){
    this.loadingProjects = true;

    this.travelService.getTravelsWithParams({ companyUuid: this.companyUuid })
      .subscribe(resp => {
        this.projects = [];
        const defaultProject = {
          title: "--------No-------- ",
          uuid: "none",
        };
        this.projects.push(defaultProject);

        this.projects = this.projects.concat(resp.docs);
        this.projects = this.projects.filter((project) => {
          if (project.initDate && project.endDate) {
            const projectDatesAreCorrect = this.checkDatesAreCorrect(
              checkIn,
              checkOut,
              moment(project.initDate),
              moment(project.endDate)
            );
            return projectDatesAreCorrect;
          } else {
            return true;
          }
        });
        this.selectedProject = defaultProject;
        this.loadingProjects = false;
      }, error => {
        this.loadingProjects = false;
      });
  }

  checkDatesAreCorrect(
    bookingInitDate,
    bookingEndDate,
    projectInitDate,
    projectEndDate
  ) {
    let projectDatesAreCorrect = true;
    if (bookingInitDate != null) {
      const momentInitDate = moment(bookingInitDate);
      projectDatesAreCorrect = moment(projectInitDate).isSameOrBefore(
        momentInitDate,
        "day"
      );
      if (bookingEndDate != null && projectDatesAreCorrect) {
        const momentEndDate = moment(bookingEndDate);
        projectDatesAreCorrect = moment(projectEndDate).isSameOrAfter(
          momentEndDate,
          "day"
        );
      } else {
        if (bookingEndDate == null && projectDatesAreCorrect) {
          projectDatesAreCorrect = moment(projectEndDate).isSameOrAfter(
            momentInitDate,
            "day"
          );
        }
      }
    }
    return projectDatesAreCorrect;
  }

  transformDateToISO(date: { year: number, month: number, day: number }): string {
    const { year, month, day } = date;
    const jsDate = new Date(Date.UTC(year, month - 1, day));
    const datePart = jsDate.toISOString().split('T')[0];
    return `${datePart}T00:00:00.000+00:00`;
  }

  openTravelDialog(){
    const modalRef = this.dialog.open(DialogAssignProjectComponent, {
      data: {
        create: true,
        isOnlyCreate: true,
        userUuid: this.uuidUser,
        companyUuid: this.companyUuid,
      },
      panelClass: "new-dialog",
    });

    modalRef.afterClosed().subscribe((result) => {
      console.log("result: ", result)
      if (result != null) {
        if (this.uuidUser !== null) {
          result.travelData["userUuid"] = this.uuidUser;
        }

        if (this.companyUuid !== null) {
          result.travelData["companyUuid"] = this.companyUuid;
        }
        if (
          result.travelData["endDate"] === "" &&
          result.travelData["initDate"] === ""
        ) {
          delete result.travelData["endDate"];
          delete result.travelData["initDate"];
        }
        this.subcriptions.push(
          this.travelService.postTravel(result.travelData).subscribe(
            (res) => {
              const checkInFormat = moment({
                year: this.searchConfig.checkIn.year,
                month: this.searchConfig.checkIn.month - 1,
                day: this.searchConfig.checkIn.day
              });
              
              const checkOutFormat = moment({
                year: this.searchConfig.checkOut.year,
                month: this.searchConfig.checkOut.month - 1,
                day: this.searchConfig.checkOut.day
              });
              const projectInitDate = moment(res.initDate);
              const projectEndDate = moment(res.endDate);

              const isWithinRange = checkInFormat.isBetween(projectInitDate, projectEndDate, null, '[]')
              && checkOutFormat.isBetween(projectInitDate, projectEndDate, null, '[]');

              if (isWithinRange) {
              this.projects.push(res);
              this.selectedProject = res;
              }

              this.subcriptions.push(
                this.translate
                  .get(`project.my-projects.created`)
                  .subscribe((resp) => {
                    this.ngxToastrService.typeSuccess(null, resp);
                    setTimeout(() => {
                    }, 3000);
                  })
              );
            },
            (err) => {
              this.subcriptions.push(
                this.translate.get("common.error").subscribe((resp) => {
                  this.ngxToastrService.typeInfo(resp, err.error.message);
                })
              );
            }
          )
        );
      }
    });
  }

  ngOnDestroy() {
    if (this.subcriptions) {
      this.subcriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

}
