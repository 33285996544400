import { UntypedFormGroup } from "@angular/forms";

export function ValidatePrice(formGroup: UntypedFormGroup) {
  const f = formGroup.controls;
  
  const priceMinValid = !isNaN(Number(f.priceMin?.value)) && 
                        !isNaN(Number(f.priceMax?.value)) && 
                        Number(f.priceMin.value) <= Number(f.priceMax.value);

  const priceMinFlightValid = !isNaN(Number(f.priceMinFlight?.value)) &&
                              !isNaN(Number(f.priceMaxFlight?.value)) &&
                              Number(f.priceMinFlight.value) <= Number(f.priceMaxFlight.value);

  if (priceMinValid && priceMinFlightValid) {
    return null;
  }
  return { validatePrice: true };
}
