import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup, NgModel, UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { ActivatedRoute } from "@angular/router";
import { User } from "@core/models";
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  BookService,
  BookedService,
  CompanyService,
  ExcelService,
  MediaService,
  NgxToastrService,
  TravelService,
  UserService,
  UtilsService,
} from "@services";
import { DialogAssignProjectComponent } from "@shared/component/dialog-assign-project/dialog-assign-project.component";
import { AppState } from "app/app.reducer";
import { ViewPdfModalComponent } from "app/modules/businessEntities/user/user-detail/view-pdf-modal/view-pdf-modal.component";
import * as moment from "moment";
import { forkJoin, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, take } from "rxjs/operators";
import swal from "sweetalert2";
import { TravelAddUserComponent } from "./travel-add-user/travel-add-user.component";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { Location } from "@angular/common";
import { TravelBookingsModalComponent } from "./travel-bookings-modal/travel-bookings-modal.component";

@Component({
  selector: "app-travel-container",
  templateUrl: "./travel-container.component.html",
  styleUrls: ["./travel-container.component.scss"],
})
export class TravelContainerComponent implements OnInit, OnDestroy {
  @Input() travels: any[];
  @Input() pagination: any;
  @Input() form: UntypedFormGroup;
  @Input() uuid?: string;
  @Input() companyUuid?: string;
  @Input() isEmployee?: string;
  @Input() isLoading?: boolean;
  @Input() isCompanyBookeds?: boolean;
  @Input() isLoadingTravel?: boolean;
  subject: Subject<any> = new Subject<any>();
  params: any = {};
  uuidUser: string;
  hasCleared = false;
  types = [
    {
      name: "Hotel",
      value: "hotel",
    },
    {
      name: "Vuelo",
      value: "flight",
    },
    {
      name: "Tren",
      value: "train",
    },
    {
      name: "Coche de alquiler",
      value: "car",
    },
    {
      name: "Transfer",
      value: "transfer",
    },
    {
      name: "Taxi",
      value: "taxi",
    },
    {
      name: "Otro",
      value: "other",
    },
  ];
  isAdmin = false;
  subscriptions: Subscription[] = [];
  user: User;
  employeeUuid: string;
  lowValue = 0;
  highValue = 6;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild("paginator") paginator: MatPaginator;
  @ViewChild("createdAtRange") createdAtRange: ElementRef;
  createdFromDate: any;
  createdToDate: any;
  @ViewChild("d2") createdInput: NgbInputDatepicker;
  hoveredDate: NgbDateStruct;
  travelPagination = {};
  companyImage = "";
  companyUuidSaved: string;
  
  constructor(
    private travelService: TravelService,
    private translateService: TranslateService,
    private ngbDateParser: NgbDateParserFormatter,
    private bookingService: BookedService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private ngxToastrService: NgxToastrService,
    private userService: UserService,
    public utilsService: UtilsService,
    private excelService: ExcelService,
    private dialog: MatDialog,
    private bookService: BookService,
    private formBuilder: UntypedFormBuilder,
    private _location: Location,
    private mediaService: MediaService,
    private companyService: CompanyService,
  ) {
    this.form = this.formBuilder.group({
      title: "",
      total: 0,
    });
    this.isCompanyBookeds =
      this.route.snapshot.routeConfig.path === "projects" ||
      this.route.snapshot.routeConfig.path === ":id/projects";
  }

  ngOnInit() {
    this.companyUuidSaved = this.companyUuid;
    this.subscriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user != null) {
          if (s.user.type?.toUpperCase() !== "USER") {
            this.isAdmin = true;
            if (s.user.type?.toUpperCase() === "COMPANY") {
              this.uuid = s.user.uuid;
              this.companyUuid = this.route.snapshot.paramMap.get("uuid")
                ? s.user.uuid
                : s.user.companyUuid;
              this.uuidUser = this.route.snapshot.paramMap.get("uuid")
                ? this.route.snapshot.paramMap.get("uuid")
                : s.user.uuid;
            } else {
              const companyId = this.route.snapshot.paramMap.get("id") || s.user.companyUuid;
              if (companyId) {
                this.companyUuid = companyId;
              } 
              this.uuidUser = this.route.snapshot.paramMap.get("uuid")
                ? this.route.snapshot.paramMap.get("uuid")
                : s.user.uuid;
            }
          } else {
            this.uuidUser = s.user.uuid;
          }
          if (this.uuidUser) {
            this.userService.getUser(this.uuidUser).subscribe((u) => {
              if(u){
                this.user = u;
              }
              if(u && u.companyUuid){
                this.companyUuid = u.companyUuid;
              }
              if(this.companyUuid){
                this.loadCompanyImg(this.companyUuid, this.uuidUser)
              }
            }, (error) => {})
          }
          if(s.user.accessType.toLowerCase() === "user"){
            this.bookService.clearCache();
            this.loadTravels();
          }else{
            const cachedBookeds = this.bookService.getData('travels');
            if(cachedBookeds && Object.keys(cachedBookeds).length > 0){
              this.travels = cachedBookeds;
            }else{
              this.loadTravels();
            }
          }
        }
      })
    );
   
    if (this.companyUuid) {
      const userUuid = this.uuidUser || this.uuid;
      this.loadCompanyImg(this.companyUuid, userUuid);
    }
   
    this.subject
      .pipe(debounceTime(800), distinctUntilChanged())
      .subscribe((s) => {
        // llamada a método que actualiza el objeto params
        this.updateParams(s.param, s.value);
        this.loadTravels();
      });
  }
  get f() {
    return this.form.controls;
  }
 
  loadCompanyImg(companyUuid?: string, userUuid?: string){
    this.companyService
      .getCompanyImage(companyUuid, userUuid)
      .subscribe((img: string) => {
        this.mediaService.getMedia(img).subscribe((s) => {
          const reader = new FileReader();
          reader.readAsDataURL(s);
          let base64data;
          reader.onloadend = () => {
            base64data = reader.result;
            this.companyImage = base64data;
          };
        });
      });
  }

  /**
   * Method triggered when a filter field changes. If it is an INPUT field, it calls our subject.
   * If it is not, it calls directly to our updateParams method and to the API caller method
   * @param param field name
   * @param event field value
   * @see updateParams()
   * @see getTravels()
   */
  filterParam(param: string, event?) {
    this.hasCleared = false;
    if (param === "title") {
      this.subject.next({ param: param, value: event });
    } else {
      this.updateParams(param, event);
      this.loadTravels();
    }
    this.paginator.firstPage();
  }

  /**
   * Update the params object. If it is the status case, the value will be the status variable above.
   * @param param field name
   * @param value field value
   */
  updateParams(param: string, value?) {
    if (this.uuidUser) {
      this.params["userUuid"] = this.uuidUser;
    } else if (this.companyUuid) {
      this.params["companyUuid"] = this.companyUuid;
    }
    switch (param) {
      case "title":
        value === ""
          ? delete this.params["title"]
          : (this.params["title"] = value);
        break;
      case "createDate":
        this.params["createDateQuery"] = moment(
          this.ngbDateParser.format(value),
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
        break;
      case "page":
        this.params["page"] = value;
        break;
      default:
        break;
    }
  }

  /**
   * Clears the params object and call the API service with no params
   */
  clearFilters() {
    this.f.title.setValue("");
    this.createdFromDate = null;
    this.createdToDate = null;
    this.createdAtRange.nativeElement.value = "";
    this.hasCleared = true;
  }

  /**
   * Method triggered when pagination change event is fired
   * @param event pagination change
   */
  onPageChange(event) {
    this.updateParams("page", event);
    this.loadTravels();
  }

  getFilters() {
    return [this.f.title.value, this.f.total.value];
  }

  transformTextType(type: string) {
    let ty = type;
    this.types.forEach((t) => {
      if (t.value === type) {
        ty = t.name;
      }
    });
    return ty;
  }

  transformDate(date: Date) {
    return this.translate.currentLang === "es"
      ? moment(date).format("DD/MM/YYYY")
      : moment(date).format("MM/DD/YYYY");
  }

  onEdit(travel: any) {
    const data: any = {
      create: true,
      isOnlyCreate: true,
      editTravelData: travel,
      previousBooking: true,
    };

    if (this.uuidUser) {
      data.userUuid = this.uuidUser;
    }
    if (this.companyUuid) {
      data.companyUuid = this.companyUuid;
    }
    const modalRef = this.dialog.open(DialogAssignProjectComponent, {
      data,
      panelClass: "new-dialog",
    });
    modalRef.afterClosed().subscribe((result) => {
      if (result != null) {
        if (this.companyUuid) {
          result.travelData["companyUuid"] = this.companyUuid;
        }
        if (result.travelData["endDate"] === "" && result.travelData["initDate"] === "") {
          delete result.travelData["endDate"];
          delete result.travelData["initDate"];
        }
        this.subscriptions.push(
          this.travelService
            .putTravel(result.travelData, travel.uuid)
            .subscribe(
              () => {
                this.subscriptions.push(
                  this.translate
                    .get(`project.my-projects.edited`)
                    .subscribe((resp) => {
                      this.ngxToastrService.typeSuccess(null, resp);
                      this.loadTravels();
                    })
                );
              },
              (err) => {
                this.subscriptions.push(
                  this.translateService
                    .get("common.error")
                    .subscribe((resp) => {
                      this.ngxToastrService.typeInfo(resp, err.error.message);
                    })
                );
              }
            )
        );
      }
    });
  }

  onGetPDF(booked: any, type: any) {
    const downloadRequests: any[] = [];
  
    if (type === "hotel" || type === "bookingApiReference") {
      if (Array.isArray(booked.bonoFileUrl)) {
        for (let fileUrl of booked.bonoFileUrl) {
          downloadRequests.push(this.bookingService.getBookedsPdf(fileUrl).pipe(take(1)));
        }
      }
  
    } else if (type === "custom-booking") {
      if (Array.isArray(booked.voucherFile)) {
        for (let file of booked.voucherFile) {
          const customUrl = `/media/${file.uuid}/custom`;
          downloadRequests.push(this.bookingService.getCustomBookingPdf(customUrl).pipe(take(1)));
        }
      }
    } else {
      if (Array.isArray(booked.voucherFileUrl)) {
        for (let fileUrl of booked.voucherFileUrl) {
          downloadRequests.push(this.bookingService.getBookedsPdf(fileUrl).pipe(take(1)));
        }
      }
    }
    if (downloadRequests.length > 0) {
      forkJoin(downloadRequests).subscribe(
        (responses) => {
          responses.forEach((res, index) => {
            const modalRef = this.modalService.open(ViewPdfModalComponent, {
              size: "lg",
              centered: true,
            });
            modalRef.componentInstance.blobUrl = URL.createObjectURL(res);
            if (type === "hotel" || type === "bookingApiReference") {
              modalRef.componentInstance.fileName = booked.apiReference || booked.bonoFileUrl[index];
            } else if (type === "custom-booking") {
              modalRef.componentInstance.fileName = booked.voucherFile[index]?.fileName || booked.voucherFile[index]?.uuid;
            } else {
              modalRef.componentInstance.fileName = booked.voucherFileUrl[index] || booked.voucherFile[index]?.fileName || booked.voucherFile[index]?.uuid;
            }
          });
        },
        () => {
          this.translateService
            .get(["common.error-pdf"])
            .pipe(take(1))
            .subscribe(() => {
              this.advicePdf();
            });
        }
      );
    }
  }

  advicePdf() {
    this.subscriptions.push(
      this.translateService
        .get(["common.generating-pdf", "common.generating-pdf-body"])
        .subscribe((result) => {
          swal.fire({
            title: result["common.generating-pdf"],
            text: result["common.generating-pdf-body"],
            imageUrl: "assets/img/svg/timePdf.svg",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
            backdrop: true,
          });
        })
    );
  }

  hasPdf(expense: any) {
    if (
      expense.voucherFileUrl &&
      expense.voucherFileUrl !== "" &&
      expense.voucherFileUrl !== "/media/expense/undefined"
    ) {
      return true;
    } else {
      return false;
    }
  }

  missingCompanyUuid() {
    this.translateService
      .get(["common.error", "my-bookeds.user.no-company-uuid"])
      .subscribe((resp) => {
        this.ngxToastrService.typeInfo(
          resp["common.error"],
          resp["my-bookeds.user.no-company-uuid"]
        );
      });
  }

  addUser(travel: any) {
    const modalRef = this.modalService.open(TravelAddUserComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.uuid = this.companyUuid;
    modalRef.componentInstance.uuidUser = this.uuidUser;
    modalRef.componentInstance.travel = travel;
    this.subscriptions.push(
      modalRef.componentInstance.sendInfo.subscribe(
        (dataModal) => {
          this.subscriptions.push(
            this.travelService.addItemTravel(dataModal, travel.uuid).subscribe(
              () => {
                this.subscriptions.push(
                  this.translate
                    .get(`project.my-projects.add-user.done`)
                    .subscribe((resp) => {
                      this.ngxToastrService.typeSuccess(null, resp);
                      setTimeout(() => {
                        this.loadTravels();
                      }, 3000);
                    })
                );
              },
              (err) => {
                this.subscriptions.push(
                  this.translate
                    .get(`project.my-projects.delete.error-cancel`)
                    .subscribe((resp) => {
                      this.ngxToastrService.typeInfo(resp, err.error.message);
                    })
                );
              }
            )
          );
        },
        (err) => {
          this.subscriptions.push(
            this.translate
              .get(`project.my-projects.delete.error-cancel`)
              .subscribe((resp) => {
                this.ngxToastrService.typeInfo(resp, err.error.message);
              })
          );
        }
      )
    );
  }

  onDelete(userUuid: string, travelUuid: string) {
    const body = {
      item: {
        type: "user",
        uuid: userUuid,
      },
      userUuid: userUuid,
    };
    this.subscriptions.push(
      this.travelService.removeItemTravel(body, travelUuid).subscribe(
        (s) => {
          this.subscriptions.push(
            this.translate
              .get(`project.my-projects.delete-user.done`)
              .subscribe((resp) => {
                this.ngxToastrService.typeSuccess(null, resp);
                setTimeout(() => {
                  this.loadTravels();
                }, 3000);
              })
          );
        },
        (err) => {
          this.subscriptions.push(
            this.translate
              .get(`project.my-projects.delete.error-cancel`)
              .subscribe((resp) => {
                this.ngxToastrService.typeInfo(resp, err.error.message);
              })
          );
        }
      )
    );
  }

  onCancel(uuid: string) {
    this.translate
      .get([
        `project.my-projects.delete.title`,
        "project.my-projects.delete.question",
        "common.confirm",
        "common.cancel",
      ])
      .subscribe((result) => {
        swal
          .fire({
            title: result[`project.my-projects.delete.title`],
            text: result["project.my-projects.delete.question"],
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0CC27E",
            cancelButtonColor: "#FF586B",
            confirmButtonText: result["common.confirm"],
            cancelButtonText: result["common.cancel"],
            customClass: {
              confirmButton: "btn btn-success btn-raised",
              cancelButton: "btn btn-danger btn-raised mr-5",
            },
            buttonsStyling: false,
          })
          .then((action) => {
            if (action.value) {
              this.subscriptions.push(
                this.travelService.deleteTravel(uuid).subscribe(
                  () => {
                    this.translate
                      .get(`project.my-projects.delete.canceled`)
                      .subscribe((resp) => {
                        this.loadTravels();
                        this.ngxToastrService.typeSuccess(null, resp);
                      });
                  },
                  (err) => {
                    if (
                      err.error.message ===
                      "There are some travel items attached to this travel"
                    ) {
                      this.translate
                        .get([`project.my-projects.delete.cannot-delete`])
                        .subscribe((resp) => {
                          this.ngxToastrService.typeInfo(
                            resp["project.my-projects.delete.cannot-delete"],
                            ""
                          );
                        });
                    } else {
                      this.translate
                        .get([`project.my-projects.delete.error-cancel`])
                        .subscribe((resp) => {
                          this.ngxToastrService.typeInfo(
                            resp["project.my-projects.delete.error-cancel"],
                            ""
                          );
                        });
                    }
                  }
                )
              );
            }
          });
      });
  }

  exportExcel(travel?) {
    this.subscriptions.push(
      this.translate
        .get(["excel.project", "excel.all-projects"])
        .subscribe((resp) => {
          travel
            ? this.excelService.createProjectsExcel(
                resp["excel.project"] +
                  travel.title +
                  (this.isAdmin
                    ? ""
                    : " (" + this.user.name + " " + this.user.lastname + ")"),
                [travel],
                this.isAdmin && this.isCompanyBookeds,
                this.user? this.user.uuid: null
              )
            : this.excelService.createProjectsExcel(
                resp["excel.all-projects"] +
                  (this.isAdmin
                    ? ""
                    : this.user.name + " " + this.user.lastname),
                this.travels,
                !this.uuidUser,
                this.user? this.user.uuid: null
              );
        },(error => {
          this.translate
              .get(["project.export-excel-error", "project.excel-standar-error"])
              .pipe(take(1))
              .subscribe((result) => {
                this.ngxToastrService.typeInfo(result["project.export-excel-error"], result["project.excel-standar-error"]);
              });
        }))
    );
  }

  canExportBonus(travel) {
    const bookings = this.isAdmin
      ? travel.bookings
      : travel.bookings.filter((b) => b.userUuid === this.uuidUser);
    const customBookings = this.isAdmin
      ? travel.customBookings
      : travel.customBookings.filter((b) => b.userUuid === this.uuidUser);
    const expenses = this.isAdmin
      ? travel.expenses
      : travel.expenses.filter((b) => b.userUuid === this.uuidUser);
    const cars = this.isAdmin
      ? travel.cars
      : travel.cars.filter((b) => b.userUuid === this.uuidUser);
    const flights = this.isAdmin
      ? travel.flights
      : travel.flights.filter((b) => b.userUuid === this.uuidUser);
    const trains = this.isAdmin
      ? travel.trains
      : travel.trains.filter((b) => b.userUuid === this.uuidUser);
    if (
      bookings.length <= 0 &&
      customBookings.length <= 0 &&
      cars.length <= 0 &&
      flights.length <= 0 &&
      trains.length <= 0 &&
      expenses.length <= 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  async exportBonus(travel) {
    this.travelService.getTravelBonus(travel.uuid).subscribe(async (res) => {
      const zip = new JSZip();
      for (let i = 0; i < res.length; i++) {
        const arr = new Uint8Array(res[i].data);
        const data: Blob = new Blob([arr], { type: 'application/pdf' })
        zip.file(`bono-${i + 1}.pdf`, data);
        if (i === res.length - 1) {
          await zip
            .generateAsync({ type: "blob" })
            .then(async function (content) {
              await saveAs(content, `bonos-${travel.title}.zip`);
            });
        }
      }
      if(res.length == 0){
        this.translate
          .get("flight-train.no-proyect-bono")
          .pipe(take(1))
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, '');
          });
      }
    });
  }

  getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  onDateSelection(date: NgbDate) {
    if (!this.createdFromDate && !this.createdToDate) {
      this.createdFromDate = date;
    } else if (
      this.createdFromDate &&
      !this.createdToDate &&
      date.after(this.createdFromDate)
    ) {
      this.createdToDate = date;
      this.createdInput.close();
      this.createdAtRange.nativeElement.value =
        this.ngbDateParser.format(this.createdFromDate) +
        " a " +
        this.ngbDateParser.format(this.createdToDate);
    } else {
      this.createdToDate = null;
      this.createdFromDate = date;
    }
  }

  setPage() {
    this.paginator.firstPage();
  }

  isHovered(date: NgbDate, type: string) {
    return (
      this.createdFromDate &&
      !this.createdToDate &&
      this.hoveredDate &&
      date.after(this.createdFromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      date.after(this.createdFromDate) && date.before(this.createdToDate)
    );
  }

  isRange(date: NgbDate, type: string) {

      return (
        date.equals(this.createdFromDate) ||
        date.equals(this.createdToDate) ||
        this.isInside(date) ||
        this.isHovered(date, type)
      );
  }

  projectTotal(project: any) {
    let total = 0.0;
    if (project.bookingFromModule?.length > 0) {
      project.bookingFromModule.filter((hotel) => !hotel.deleted).map((hotel) => {
        total += parseFloat(hotel["price"]);
      })
    } else if (project.bookings?.length > 0) {
      project.bookings.filter((hotel) => !hotel.deleted).map((hotel) => {
        total += parseFloat(hotel["price"]);
      })
    }
    if (project.flights?.length > 0) {
      project.flights.filter((flight) => !flight.deleted).map((flight) => {
        total += parseFloat(flight["price"]);
      })
    }
    if (project.trains?.length > 0) {
      project.trains.filter((train) => !train.deleted).map((train) => {
        total += parseFloat(train["price"]);
      })
    }
    if (project.expenses?.length > 0) {
      project.expenses.filter((element) => !element.deleted).map((element) => {
        total += parseFloat(element["price"]);
      })
    }
    if (project.cars?.length > 0) {
      project.cars.filter((car) => !car.deleted).map((car) => {
        total += parseFloat(car["price"]);
      })
    }
    if (project.customBookings?.length > 0) {
      project.customBookings.filter((custom) => !custom.deleted).map((custom) => {
        total += parseFloat(custom["price"]);
      })
    }
    return total;
  }

  loadTravels() {   
    this.isLoadingTravel = true;
    let params = {
      ...(this.companyUuidSaved ? { companyUuid: this.companyUuidSaved } : this.companyUuid ? { companyUuid: this.companyUuid } : {}),
      ...(this.uuidUser && !this.isCompanyBookeds) && {userUuid: this.uuidUser},
    }
    this.travelService
      .getTravelsWithParams(params)
      .subscribe(
        (travels: any) => {
          this.isLoading = false;
          this.isLoadingTravel = false;
          this.travels = travels.docs;
          this.travelPagination = travels;
          this.bookService.setData('travels', this.travels);
          delete this.travelPagination["docs"];
        },
        (err) => {
          this.isLoading = false;
          this.isLoadingTravel = false;
          this.translate
            .get("company.booked.error-get-booked")
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.error);
            });
        }
      );
  }

  goBack() {
    this._location.back();
  }

  seeBookings(travel): void {
    this.isLoadingTravel = true;

    travel.bookings.forEach((booking: any) => {
      const relatedBooking = travel.bookingFromModule.find((bfm: any) => bfm.uuid === booking.bookedApiUuid);
      if (relatedBooking) {
        booking.checkIn = relatedBooking.checkIn;
        booking.checkOut = relatedBooking.checkOut;
        if(relatedBooking.status) {
          booking.status = relatedBooking.status;
        }
      }
    });

    const allReservations = [
      ...travel.bookings.map((b: any) => ({ ...b, type: 'Hotel' })),
      ...travel.flights.map((f: any) => ({ ...f, type: 'Flight' })),
      ...travel.cars.map((c: any) => ({ ...c, type: 'Car' })),
      ...travel.expenses.map((e: any) => ({ ...e, type: e.type  })),
      ...travel.customBookings.map((cb: any) => ({ ...cb, type: 'CustomBooking' })),
      ...travel.trains.map((t: any) => ({ ...t, type: 'Train' }))
    ];

    const userRequests: Observable<any>[] = allReservations.map(reservation =>
      this.userService.getUserByUuid(reservation.userUuid).pipe(
        map((user: User) => ({ reservation, userName: `${user.name} ${user.lastname}` }))
      )
    );

    forkJoin(userRequests).subscribe(results => {
      results.forEach(result => {
        result.reservation.author = result.userName;
      });
      this.isLoadingTravel = false;
      const dialogRef = this.dialog.open(TravelBookingsModalComponent, {
        width: '80%',
        data: {
          allReservations: allReservations,
          travelTitle: travel.title
        }
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    });
  }

  canSeeBookings(travel) {
    const allBookings = [
      ...travel.bookings,
      ...travel.flights,
      ...travel.cars,
      ...travel.expenses,
      ...travel.customBookings,
      ...travel.trains
    ];

    return allBookings.length > 0;
  }

  addTravel(){
    const data = {
      create: true,
      isOnlyCreate: true,
      companyUuid: this.companyUuid,
      fromAdmin: true
    };
    const modalRef = this.dialog.open(DialogAssignProjectComponent, {
      data: data,
      panelClass: "new-dialog",
    });
    modalRef.afterClosed().subscribe((result) => {
      if (result != null) {
        if (this.companyUuid !== null) {
          result.travelData["companyUuid"] = this.companyUuid;
        }
        if (
          result.travelData["endDate"] === "" &&
          result.travelData["initDate"] === ""
        ) {
          delete result.travelData["endDate"];
          delete result.travelData["initDate"];
        }
        this.subscriptions.push(
          this.travelService.postTravel(result.travelData).subscribe(
            () => {
              this.subscriptions.push(
                this.translate
                  .get(`project.my-projects.created`)
                  .subscribe((resp) => {
                    this.ngxToastrService.typeSuccess(null, resp);
                    setTimeout(() => {
                        this.loadTravels();
                    }, 3000);
                  })
              );
            },
            (err) => {
              this.subscriptions.push(
                this.translate.get("common.error").subscribe((resp) => {
                  this.ngxToastrService.typeInfo(resp, err.error.message);
                })
              );
            }
          )
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.bookService.clearCache();
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
