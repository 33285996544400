import { Location } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  NgModel,
  Validators,
} from "@angular/forms";
import { NavigationExtras, Router } from "@angular/router";
import { User } from "@core/models";
import { NgxToastrService, UserService, UtilsService } from "@core/services";
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "app/app.reducer";
import cities from "assets/utils/cities.json";
import hotels from "assets/utils/hotels.json";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { BookedService } from "../../../../core/services/booked.service";
@Component({
  selector: "app-request-custom-hotel",
  templateUrl: "./request-custom-hotel.component.html",
  styleUrls: ["./request-custom-hotel.component.scss"],
})
export class RequestCustomHotelComponent implements OnInit, OnDestroy {
  public get ngbDateParser(): NgbDateParserFormatter {
    return this._ngbDateParser;
  }
  public set ngbDateParser(value: NgbDateParserFormatter) {
    this._ngbDateParser = value;
  }
  @Output() fromDateEmit = new EventEmitter();
  @Output() toDateEmit = new EventEmitter();
  hoveredDate: NgbDateStruct;
  @ViewChild("d1") bookedInput: NgbInputDatepicker;
  @ViewChild("bookedRange") bookedRange: ElementRef;
  @ViewChild(NgModel) datePick: NgModel;
  @Input() isModal?;
  @Input() userUuid?;
  @Input() companyUuid?;
  @Output() closeEvent? = new EventEmitter();
  subscriptions: Subscription[] = [];
  canBookHotel: boolean;
  canRequestCustomFlight: boolean;
  canRequestCustomTrain: boolean;
  canRequestCustomCar: boolean;
  canRequestAirbnb: boolean;
  authUserUuid = "";
  userImageBase64: string;
  loading = false;
  form: UntypedFormGroup;
  date = new Date();
  bookedUserAccessType: string;
  minDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  hotelsList = [];
  citiesList = [];
  city = "";
  user: User;
  userType: string;
  userToBook: string;
  isAdmin: boolean = false;
  isInvalid: boolean = false;

  constructor(
    private bookedService: BookedService,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    private _ngbDateParser: NgbDateParserFormatter,
    private utilsService: UtilsService,
    private userService: UserService,
    private router: Router,
    private modalService: NgbModal,
    private store: Store<AppState>,
    private location: Location
  ) {
    this.subscriptions.push(
      this.store.select("auth").subscribe((s) => {
        if (s.user.type?.toUpperCase() === "USER") {
          this.userUuid = s.user.uuid;
          this.authUserUuid = s.user.uuid;
        }
        if (s.user && s.user.type?.toUpperCase() !== "USER") {
          this.isAdmin = true;
        }
        this.userToBook = localStorage.getItem("userToBook")
          ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
          : null;
        this.userType = s.user.type;
      })
    );
  }

  ngOnInit() {
    if (
      window.history.state.canBookHotel !== null &&
      window.history.state.canRequestCustomCar !== null &&
      window.history.state.canRequestCustomFlight !== null &&
      window.history.state.canRequestCustomTrain !== null &&
      window.history.state.canRequestAirbnb !== null
    ) {
      this.canBookHotel = window.history.state.canBookHotel;
      this.canRequestCustomCar = window.history.state.canRequestCustomCar;
      this.canRequestCustomFlight = window.history.state.canRequestCustomFlight;
      this.canRequestCustomTrain = window.history.state.canRequestCustomTrain;
      this.canRequestAirbnb = window.history.state.canRequestAirbnb;
      this.userUuid = window.history.state.userUuid;
      this.companyUuid = window.history.state.companyUuid;
      this.userImageBase64 = window.history.state.userImageBase64;
    } else {
      this.router.navigate(["/custom-services"]);
    }

    if (
      this.userUuid &&
      (this.userUuid !== null || this.userUuid !== undefined)
    ) {
      this.userService
        .getUser(this.userUuid)
        .pipe(take(1))
        .subscribe((user: User) => {
          this.bookedUserAccessType = user.type;
          this.user = user;
        });
    }

    this.form = this.formBuilder.group({
      hotel: [null, Validators.required],
      city: [null, [Validators.required]],
      checkIn: [null, [Validators.required]],
      checkOut: [null, [Validators.required]],
      comments: [""],
      rooms: [1],
      people: [1],
      userUuid: this.userUuid,
      companyUuid: this.companyUuid,
    });
  }

  onSubmit() {
    this.isInvalid = false;
    this.loading = true;
    const body = this.form.getRawValue();
    const formatDate = (ngbDate: NgbDate): string =>
      `${ngbDate.year}-${String(ngbDate.month).padStart(2, '0')}-${String(ngbDate.day).padStart(2, '0')}`;
  
      body["checkIn"] = moment(formatDate(body["checkIn"]))
        .format("DD-MM-YYYY");
  
      body["checkOut"] = moment(formatDate(body["checkOut"]))
        .format("DD-MM-YYYY");
    body[
      "comments"
    ] = `(comments): ${body["comments"]} + (rooms): ${body["rooms"]} + (people): ${body["people"]}`;

    if (this.user) {
      body["holder"] = { name: this.user.name, surname: this.user.lastname };
    }

    // delete body.rooms;
    // delete body.people;

    this.subscriptions.push(
      this.bookedService.sendCustomHotelRequest(body).subscribe(
        (s) => {
          this.subscriptions.push(
            this.translate
              .get(["common.congrat", "hotel.custom.request.success"])
              .subscribe((result) => {
                this.loading = false;
                this.ngxToastrService.typeSuccess(
                  result["common.congrat"],
                  result["hotel.custom.request.success"]
                );
                if (this.isModal) {
                  this.closeEvent.emit(s);
                } else {
                  const route = this.utilsService.getNavigationRoute(this.user);
                  if (route) {
                    this.router.navigate([route]);
                  }
                }
              })
          );
        },
        (err) => {
          this.translate.get("hotel.custom.request..error").subscribe((result) => {
            this.loading = false;
            this.ngxToastrService.typeInfo(result, err.error.message);
          });
        }
      )
    );
  }

  get f() {
    return this.form.controls;
  }

  goBack() {
    this.location.back();
  }

  emitDates() {
    this.fromDateEmit.emit(this.form.controls["checkIn"].value);
    this.toDateEmit.emit(this.form.controls["checkOut"].value);
  }

  onDateSelection(date: NgbDate) {
    const checkIn = this.form.controls["checkIn"].value;
    const checkOut = this.form.controls["checkOut"].value;
  
    if (!checkIn) {
      this.form.controls["checkIn"].setValue(date);
    } else if (date && !checkOut && date.after(checkIn)) {
      this.form.controls["checkOut"].setValue(date);
    } else {
      this.form.controls["checkOut"].setValue(null);
      this.form.controls["checkIn"].setValue(date);
    }
    this.updateDateInput();
  }
  
  onDatepickerClosed() {
    const checkIn = this.form.controls["checkIn"].value;
    const checkOut = this.form.controls["checkOut"].value;
  
    if (checkIn && !checkOut) {
      this.form.controls["checkOut"].setValue(checkIn);
    }
    this.updateDateInput();
  }
  
  updateDateInput() {
    this.bookedRange.nativeElement.value =
      this.ngbDateParser.format(this.form.controls["checkIn"].value) +
      " - " +
      this.ngbDateParser.format(this.form.controls["checkOut"].value);
  
    this.emitDates();
  }

  isHovered(date: NgbDate) {
    return (
      this.form.controls["checkIn"].value &&
      !this.form.controls["checkOut"].value &&
      this.hoveredDate &&
      date.after(this.form.controls["checkIn"].value) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      date.after(this.form.controls["checkIn"].value) &&
      date.before(this.form.controls["checkOut"].value)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.form.controls["checkIn"].value) ||
      date.equals(this.form.controls["checkOut"].value)
    );
  }

  closeModalCross() {
    this.modalService.dismissAll();
  }

  navigate(route: string) {
    const navigationExtra: NavigationExtras = {
      state: {
        canBookHotel: this.canBookHotel,
        canRequestCustomFlight: this.canRequestCustomFlight,
        canRequestCustomTrain: this.canRequestCustomTrain,
        canRequestCustomCar: this.canRequestCustomCar,
        canRequestAirbnb: this.canRequestAirbnb,
        userUuid: this.userUuid ? this.userUuid : this.authUserUuid,
        companyUuid: this.companyUuid,
        userImageBase64: this.userImageBase64,
      },
      queryParams: {
        userId: this.userUuid ? this.userUuid : this.authUserUuid,
        companyUuid: this.companyUuid,
      },
      replaceUrl: true
    };

    this.router.navigate(["custom-services/" + route], navigationExtra);
  }

  // Buscador de ciudades
  getCities(value: string) {
    this.citiesList = [];
    if (value !== "" && value.length > 2) {
      cities.Table1.forEach((v) => {
        if (
          v["name"]?.toLowerCase().includes(value?.toLowerCase()) &&
          !this.citiesList.includes(v["name"])
        ) {
          this.citiesList.push(v["name"]);
        }
      });
    } else {
      this.citiesList = [];
    }
  }
  getHotels(value: string) {
    if (value !== "" && this.city !== "") {
      hotels.hotels.forEach((h) => {
        {
          if (
            h["city"]?.toLowerCase().includes(this.city?.toLowerCase()) &&
            !this.hotelsList.includes(h.name)
          ) {
            this.hotelsList.push(h.name);
          }
        }
      });
    } else {
      this.hotelsList = [];
    }
  }

  getOutMinDateManual() {
    return this.utilsService.getOutDate(
      this.minDateNgStruct,
      this.form.controls["checkIn"].value
    );
  }

  cleanCitiesList(option) {
    this.city = option;
    this.citiesList = [];
  }
  cleanHotelsList() {
    this.hotelsList = [];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
