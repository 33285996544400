import { EventEmitter, Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { CreateCarComponent } from "app/modules/components/cars/custom/create-car/create-car.component";
import { CreateFlightTrainComponent } from "app/modules/components/flight-train/create-flight-train/create-flight-train.component";
import { CreateCustomHotelComponent } from "app/modules/components/hotel/create-custom-hotel/create-custom-hotel.component";
import { BookedService } from "./booked.service";
import { CarService } from "./car.service";
import { FlightService } from "./flight.service";
import { NgxToastrService } from "./ngx.toastr.service";
import { TrainService } from "./train.service";
import { CreateAirbnbComponent } from "app/modules/components/airbnb/custom/create-airbnb/create-airbnb.component";
import { AirbnbService } from "./airbnb.service";

@Injectable({
  providedIn: "root",
})
export class RequestUtilsService {
  sendInfo: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private flightService: FlightService,
    private trainService: TrainService,
    private bookedService: BookedService,
    private carService: CarService,
    private airbnbService: AirbnbService,
    private translateService: TranslateService,
    private ngxToastrService: NgxToastrService
  ) {}

  createCustomFlightTrain(request: any, type: string, hasPrivileges: boolean) {
    const modalRef = this.modalService.open(CreateFlightTrainComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.isAdmin = hasPrivileges;
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.companyUuid = request.companyUuid;
    modalRef.componentInstance.request = JSON.parse(JSON.stringify(request));
    if (request.userUuid) {
      modalRef.componentInstance.userId = request.userUuid;
    }
    modalRef.componentInstance.sendInfo.subscribe((info) => {
      modalRef.componentInstance.loading = true;
      const formData = new FormData();
      if(info.file){
        if (info.file && Array.isArray(info.file)) {
          info.file.forEach((file: any, index: number) => {
            if (file.blob && file.name) {
              const newFile = new File([file.blob], file.name, { type: file.blob.type });
              formData.append("file", newFile, newFile.name);
            } 
            else if (file instanceof File) {
              formData.append("file", file, file.name);
            } 
            else if (file instanceof Blob) {
              const newFile = new File([file], `file_${index}.pdf`, { type: file.type });
              formData.append("file", newFile, newFile.name);
            } 
          });
        } 
      }
      formData.append("companyUuid", request.companyUuid);
      formData.append("userUuid", request.userUuid);
      formData.append("from", info.origin);
      formData.append("to", info.destination);
      formData.append("departDate", info.departure);
      formData.append("observations", info.annotations);
      formData.append("price", info.price);
      formData.append("oneWay", info.oneWay);
      formData.append("freeTaxes", info.freeTaxes);
      if (!info.oneWay) {
        formData.append("returnDate", info.return);
      }

      if(type === 'flight') {
        formData.append("newCustom", "true");
      }

      if(info.traveller) {
        formData.append("traveller", JSON.stringify(info.traveller));
      }
      if(info.paymentCard){
        formData.append("paymentCard", JSON.stringify(info.paymentCard));
      }
      // const fte = {
      //   departDate: info.departure,
      //   returnDate: info.return,
      //   oneWay: false,
      // };
      const postCreate =
        type === "train"
          ? this.trainService.postCreateTrain(formData, request.uuid)
          : this.flightService.postCreateFlight(formData, request.uuid);
      postCreate.subscribe(
        (result) => {
          modalRef.componentInstance.loading = false;
          modalRef.dismiss("Send Info");
          this.translateService
            .get(["common.congrat", `${type}.create-${type}.created-${type}`])
            .subscribe((resp) => {
              this.ngxToastrService.typeSuccess(
                resp["common.congrat"],
                resp[`${type}.create-${type}.created-${type}`]
              );
            });
          this.sendInfo.emit(true);
        },
        (err) => {
          modalRef.componentInstance.loading = false;
          this.translateService
            .get([
              `${type}.create-${type}.error-create-${type}`,
              "common.transaction-error",
            ])
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(
                result[`${type}.create-${type}.error-create-${type}`],
                result["common.transaction-error"]
              );
            });
        }
      );
    });
  }

  createCustomHotel(request: any, hasPrivileges: boolean) {
    const modalHotel = this.modalService.open(CreateCustomHotelComponent, {
      size: "lg",
      centered: true,
    });
    modalHotel.componentInstance.userId = request.userUuid;
    modalHotel.componentInstance.isAdmin = hasPrivileges;
    modalHotel.componentInstance.companyUuid = request.companyUuid;
    modalHotel.componentInstance.request = JSON.parse(JSON.stringify(request));

    modalHotel.componentInstance.sendInfo.subscribe((info) => {
      modalHotel.componentInstance.loading = true;
      const formData = new FormData();
      if(info.file){
        if (info.file && Array.isArray(info.file)) {
          info.file.forEach((file: any, index: number) => {
            if (file.blob && file.name) {
              const newFile = new File([file.blob], file.name, { type: file.blob.type });
              formData.append("file", newFile, newFile.name);
            } 
            else if (file instanceof File) {
              formData.append("file", file, file.name);
            } 
            else if (file instanceof Blob) {
              const newFile = new File([file], `file_${index}.pdf`, { type: file.type });
              formData.append("file", newFile, newFile.name);
            } 
          });
        } 
      }
      formData.append("userUuid", request.userUuid);
      formData.append("checkIn", info.checkIn);
      formData.append("checkOut", info.checkOut);
      formData.append("price", info.price);
      formData.append("destinationName", info.city);
      formData.append("rooms", info.rooms);
      formData.append("people", info.people);
      formData.append("holder", JSON.stringify(info.holder))
      formData.append("hotelName", info.hotel);
      formData.append("freeTaxes", info.freeTaxes);
      formData.append("customCode", info.customCode);
      formData.append("costCenter", info.costCenter);
      if (info.comments) {
        formData.append("comments", info.comments);
      }
      if(info.paymentCard){
        formData.append("paymentCard", JSON.stringify(info.paymentCard));
      }
      formData.append('newCustom', 'true');
      formData.append('type', 'hotel');
      // const fte = {
      //   departDate: info.checkIn,
      //   returnDate: info.checkOut,
      //   oneWay: false,
      // };
      this.bookedService.createCustomBooking(formData, request.uuid).subscribe(
        (s) => {
          this.translateService
            .get(["common.congrat", "hotel.custom.created"])
            .subscribe((resp) => {
              modalHotel.componentInstance.loading = false;
              this.ngxToastrService.typeSuccess(
                resp["common.congrat"],
                resp["hotel.create-custom.success"]
              );
              modalHotel.close();
              this.sendInfo.emit(true);
            });
        },
        (err) => {
          modalHotel.componentInstance.loading = false;
          this.translateService.get("common.error").subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.message);
          });
        }
      );
    });
  }

  createCustomCar(request: any, hasPrivileges: boolean) {
    const modalRef = this.modalService.open(CreateCarComponent, {
      size: "lg",
      centered: true,
    });

    modalRef.componentInstance.isAdmin = hasPrivileges;
    modalRef.componentInstance.loading = false;
    modalRef.componentInstance.request = request;
    if (request.userUuid) {
      modalRef.componentInstance.userId = request.userUuid;
    }
    modalRef.componentInstance.sendInfo.subscribe((info) => {
      modalRef.componentInstance.loading = true;
      const formData = new FormData();
      if(info.file){
        if (info.file && Array.isArray(info.file)) {
          info.file.forEach((file: any, index: number) => {
            if (file.blob && file.name) {
              const newFile = new File([file.blob], file.name, { type: file.blob.type });
              formData.append("file", newFile, newFile.name);
            } 
            else if (file instanceof File) {
              formData.append("file", file, file.name);
            } 
            else if (file instanceof Blob) {
              const newFile = new File([file], `file_${index}.pdf`, { type: file.type });
              formData.append("file", newFile, newFile.name);
            } 
          });
        } 
      }
      formData.append("userUuid", request.userUuid);
      formData.append("from", info.from);
      formData.append("to", info.to);
      formData.append("pickupPlaceFrom", info.pickupPlaceFrom);
      formData.append("pickupPlaceTo", info.pickupPlaceTo);
      formData.append("price", info.price);
      formData.append("age", info.age);
      formData.append("freeTaxes", info.freeTaxes);
      formData.append("departDate", info.departDate);
      formData.append("returnDate", info.returnDate);
      formData.append("provider", info.provider);
      formData.append("carType", info.carType);
      formData.append("insuranceWithExcess", info.insuranceWithExcess);
      formData.append("comments", info.comments);
      if(info.traveller) {
        formData.append("traveller", JSON.stringify(info.traveller));
      }
      if(info.paymentCard){
        formData.append("paymentCard", JSON.stringify(info.paymentCard));
      }
      // const fte = {
      //   departDate: info.departDate,
      //   returnDate: info.returnDate,
      //   oneWay: false,
      // };
      this.carService.createCustomCar(formData, request.uuid).subscribe(
        (car) => {
          modalRef.componentInstance.loading = false;
          modalRef.dismiss("Send Info");
          this.translateService
            .get(["car.create-car.created-car"])
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess(
                "",
                result["car.create-car.created-car"]
              );
              modalRef.dismiss();
              this.sendInfo.emit(true);
            });
        },
        (error) => {
          this.translateService
            .get(["car.create-car.error-create-car"])
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(
                "",
                result["car.create-car.error-create-car"]
              );
              modalRef.dismiss();
            });
        }
      );
    });
  }

  createCustomAirbnb(request: any, hasPrivileges: boolean) {
    const modalAirbnb = this.modalService.open(CreateAirbnbComponent, {
      size: "lg",
      centered: true,
    });
    modalAirbnb.componentInstance.userId = request.userUuid;
    modalAirbnb.componentInstance.isAdmin = hasPrivileges;
    modalAirbnb.componentInstance.companyUuid = request.companyUuid;
    modalAirbnb.componentInstance.request = JSON.parse(JSON.stringify(request));

    modalAirbnb.componentInstance.sendInfo.subscribe((info) => {
      modalAirbnb.componentInstance.loading = true;
      const formData = new FormData();
      if(info.file){
        formData.append("file", info.file, info.file.name);
      }
      formData.append("userUuid", request.userUuid);
      formData.append("checkIn", info.checkIn);
      formData.append("checkOut", info.checkOut);
      formData.append("price", info.price);
      formData.append("destinationName", info.city);
      formData.append("people", info.travelers);
      formData.append("hotelName", info.link || 'Airbnb');
      formData.append("customCode", info.customCode);
      formData.append("costCenter", info.costCenter);
      if (info.comments) {
        formData.append("comments", info.comments);
      }
      if(info.paymentCard){
        formData.append("paymentCard", JSON.stringify(info.paymentCard));
      }

      formData.append('newCustom', 'true');
      formData.append('type', 'airbnb');
    
      this.airbnbService.createCustomAirbnb(formData, request.uuid).subscribe(
        (s) => {
          this.translateService
            .get(["common.congrat", "hotel.custom.created"])
            .subscribe((resp) => {
              modalAirbnb.componentInstance.loading = false;
              this.ngxToastrService.typeSuccess(
                resp["common.congrat"],
                resp["hotel.create-custom.success"]
              );
              modalAirbnb.close();
              this.sendInfo.emit(true);
            });
        },
        (err) => {
          modalAirbnb.componentInstance.loading = false;
          this.translateService.get("common.error").subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.message);
          });
        }
      );
    });
  }
}
