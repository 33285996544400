<div class="card mt-2 mb-3 border-card cardBooked"
[ngClass]="(booked?.travelTitle && 
          ((booked?.customCode && booked?.customCode !== '' && booked?.customCode !== 'null') || 
          (booked?.costCenter && booked?.costCenter !== '' && booked?.costCenter !== 'null'))) 
          ? 'card-expanded' : ''">
  <div id="cardContent" class="row mx-0 card-content">
    <!-- HOTEL-->
    <div
      id="hotelImage"
      class="card-img carousel-img"
      *ngIf="
        type === 'custom-booking' || type === 'bookingApiReference';
        else elseBlock1
      "
    >
      <div [attr.id]="'hotelImages' + index" class="carousel slide w-100" data-ride="carousel" wrap="true" data-interval="false" *ngIf="booked.hotel; else elseBlock2">
        <ol class="carousel-indicators">
          <li *ngFor="let image of booked.hotel.images; let i = index" data-target="#hotelImages" [attr.data-slide-to]="i" [class.active]="i === 0"></li>
        </ol>
        <div class="carousel-inner">
          <div *ngFor="let image of booked.hotel.images; let i = index" class="carousel-item" [class.active]="i === 0">
            <img [src]="image" class="d-block w-100" alt={{booked.hotel.name}}>
          </div>
        </div>
        <a class="carousel-control-prev" [attr.href]="'#hotelImages' + index" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" [attr.href]="'#hotelImages' + index" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <ng-template #elseBlock2>
        <img
          class="img-fluid imgBookedCard mb-auto"
          src="assets/img/gallery/image_not_available.png"
          alt="Hotel image"
        />
      </ng-template>
    </div>
    <ng-template #elseBlock1>
      <div id="serviceImage" class="card-img">
        <img
          class="img-fluid imgBookedCard mb-auto"
          [ngClass]="(booked?.travelTitle && 
                    ((booked?.customCode && booked?.customCode !== '' && booked?.customCode !== 'null') || 
                    (booked?.costCenter && booked?.costCenter !== '' && booked?.costCenter !== 'null'))) 
                    ? 'image-expanded' : ''"
          src="{{ defaultImage }}"
          alt="Service image"
        />

        <div *ngIf="type === 'flight' && booked.airlines && booked.airlines.length > 0" class="airline-logo">
          <img src="{{getLogo(booked.airlines[0])}}" alt="">
        </div>

        <div *ngIf="type === 'train' && booked.renfeLocalizador !== undefined" class="airline-logo train-logo">
          <img src="assets/img/trains/renfe.svg" alt="">
        </div>
      </div>
    </ng-template>
  </div>
  <div class="card-header">
    <div class="first-container">
      <div class="header mr-1">
        <!-- HOTEL-->
        <h4
          *ngIf="type === 'bookingApiReference'"
          class="card-title card-title--hotel heading-6"
        >
          {{ booked.hotel.name }}
        </h4>
        <h4
          *ngIf="type === 'custom-booking' || type === 'hotel'"
          class="card-title card-title--hotel heading-6"
        >
          {{ booked.hotelName }}
        </h4>
        <p *ngIf="type === 'bookingApiReference'" class="light-text address">
          {{ booked.hotel.address }}<span *ngIf="booked.hotel.destinationName"> &nbsp;/&nbsp; {{ booked.hotel.destinationName }}</span>
        </p>
        <p
          *ngIf="type === 'custom-booking' || type === 'hotel'"
          class="light-text"
        >
          {{ booked.destinationName }}
        </p>
        <!-- FLIGHT TRAIN CAR-->
        <h4
          *ngIf="booked.from"
          class="card-title heading-6 mb-2"
        >
          {{ booked.from }}{{ booked.to ? " - " + booked.to : "" }}
        </h4>
        <!-- EXPENSE-->
        <h4
          *ngIf="type === 'expense'"
          class="card-title heading-6"
        >
          {{ booked.type }}
        </h4>
        <p *ngIf="(booked.customCode && booked.customCode !== null && booked.customCode !== 'null')
                  || (booked.costCenter && booked.costCenter !== null && booked.costCenter !== 'null')" class="light-text">
          <span *ngIf="booked.customCode && booked.customCode !== null && booked.customCode !== 'null'">{{ booked.customCode }}</span> -
          <span *ngIf="booked.costCenter && booked.costCenter !== null && booked.costCenter !== 'null'">{{ booked.costCenter }}</span>
        </p>
      </div>
      <div ngbDropdown>
        <img
          ngbDropdownToggle
          src="assets/img/svg/black-kebab.svg"
          style="cursor: pointer"
        />
        <div
          ngbDropdownMenu
          class="dropdown-menu"
          aria-labelledby="dropdownBasic1"
        >
          <p *ngIf="booked.deliveryNote" (click)="onDeliveryNote(booked)">
            <img ngbDropdownToggle src="assets/img/svg/bill-black.svg" />
            {{ "project.delivery-note" | translate }}
          </p>
          <p (click)="onChangeTravel()">
            <img ngbDropdownToggle src="assets/img/svg/sidebar/briefcase.svg" />
            {{ "project.assign-project" | translate }}
          </p>
          <p (click)="onView()">
            <img ngbDropdownToggle src="assets/img/svg/clipboard.svg" />
            {{ "common.see-summary" | translate }}
          </p>
          <p
            (click)="hasPdf() ? onEditGetPDF(booked) : onEdit(booked)"
          >
            <img src="assets/img/svg/pen-black.svg" alt="edit" />
            {{ "common.edit" | translate }}
          </p>
          <p *ngIf="canCancel()" (click)="onCancel(booked)">
            <img ngbDropdownToggle src="assets/img/svg/trash-black.svg" />
            {{ "common.cancel" | translate }}
          </p>
          <p *ngIf="!isUser && isEmployee && !booked.refund && !booked.payment" (click)="deletePermanently(booked)">
            <img ngbDropdownToggle src="assets/img/svg/trash-black.svg" />
            {{ "common.delete-book" | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="data">
      <div class="w-100">
        <!-- HOTEL-->
        <p *ngIf="type === 'custom-booking' || type === 'bookingApiReference'">
          <span class="icon-bcg">
            <img src="assets/img/svg/calendar-black.svg" />
          </span>
          {{ "common.entry" | translate }} -
          {{ "common.departure" | translate }}:
          <span class="light-text"
            >{{ booked.checkIn | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY" }} -
            {{
              booked.checkOut | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY"
            }}</span
          >
        </p>
        <!-- EXPENSE-->
        <p *ngIf="type === 'expense'">
          <span class="icon-bcg">
            <img src="assets/img/svg/coin.svg" />
          </span>
          {{ "project.my-projects.total" | translate }}:
          <span class="light-text">{{
            booked.price || booked.amount | price
          }}</span>
        </p>
        <!-- FLIGHT TRAIN CAR EXPENSE-->
        <p *ngIf="type !== 'custom-booking' && type !== 'bookingApiReference'" class="flight-text">
          <span class="icon-bcg">
            <img src="assets/img/svg/calendar-black.svg" />
          </span>
          {{ "flights.form.dateIn" | translate }}:
          <span class="light-text" *ngIf="type === 'flight'">{{
            booked.checkIn
                  ? (booked.checkIn | dateMoment : "YYYY-MM-DD")
                  : booked.departDate
                  ? getDate(booked.departDate)
                  : booked.beginDate
                  ? getDate(booked.beginDate)
                  : ""
          }} &nbsp;&nbsp;  ({{ "flight.result-flight.flight-timezone" | translate }})</span>
          <span class="light-text" *ngIf="type !== 'flight'">{{
            booked.checkIn
                  ? (booked.checkIn | dateMoment : "YYYY-MM-DD")
                  : booked.departDate
                  ? getDate(booked.departDate)
                  : booked.beginDate
                  ? getDate(booked.beginDate)
                  : ""
          }}</span>
        </p>
        <!-- FLIGHT TRAIN CAR EXPENSE-->
        <p
          *ngIf="
            (booked.returnDate || booked.endDate) &&
            type !== 'custom-booking' &&
            type !== 'bookingApiReference'
          "
          class="flight-text"
        >
          <span class="icon-bcg">
            <img src="assets/img/svg/calendar-black.svg" />
          </span>
          {{ "flights.form.dateOut" | translate }}:
          <span class="light-text" *ngIf="type?.toLowerCase() === 'flight' ">{{
            booked.checkOut
            ? (booked.checkOut | dateMoment : "YYYY-MM-DD")
            : booked.returnDate
            ? getDate(booked.returnDate)
            : booked.endDate
            ? getDate(booked.endDate)
            : ""
          }}  &nbsp;&nbsp;  ({{ "flight.result-flight.flight-timezone" | translate }})</span>
          <span class="light-text" *ngIf="type?.toLowerCase()!== 'flight'">{{
            booked.checkOut
            ? (booked.checkOut | dateMoment : "YYYY-MM-DD")
            : booked.returnDate
            ? getDate(booked.returnDate)
            : booked.endDate
            ? getDate(booked.endDate)
            : ""
          }}</span>
        </p>
        <!-- EXPENSE-->
        <p *ngIf="type === 'expense'">
          <span class="icon-bcg">
            <img src="assets/img/svg/calendar-black.svg" />
          </span>
          {{ "common.creation-date" | translate }}:
          <span class="light-text">{{
            booked.createdAt | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY"
          }}</span>
        </p>
        <!-- FLIGHT TRAIN -->
        <p *ngIf="type === 'flight' || type === 'train'" class="flight-text">
          <span class="icon-bcg">
            <img src="{{ icon }}" />
          </span>
          {{ "company.transaction.filters.type" | translate }}:
          <span class="light-text">{{
            (booked.returnDate
              ? "car.form-car.roundTripYes"
              : "car.form-car.roundTripNo"
            ) | translate
          }}</span>
        </p>
        <!-- HOTEL-->
        <p *ngIf="type === 'bookingApiReference'">
          <span class="icon-bcg">
            <img src="assets/img/svg/sidebar/profile.svg" />
          </span> 
          {{ "my-bookeds.view.bookedMadePerson" | translate }}:
          <span class="light-text"
            >{{ booked.author }} ({{
              booked.createdAt | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY"
            }})</span
          >
        </p>
        <p *ngIf="booked.travelTitle && booked.travelTitle !== ''">
          <span class="icon-bcg">
            <img style="width: 1rem;" src="assets/img/svg/project.svg" />
          </span>
          {{ "excel.related-project" | translate }}:
          <span class="light-text">{{ booked.travelTitle }}</span>
        </p>
        <p *ngIf="booked.comments && booked.comments !== ''">
          <img src="assets/img/svg/black-edit.svg" />
          {{ "my-bookeds.view.comments" | translate }}:
          <span class="light-text">{{ reduceComments(booked.comments) }}</span>
        </p>
        <div class="d-flex justify-content-between status-button">
          <p class="mb-0 mr-2">
            <img src="assets/img/svg/layer1.svg" />
            {{ "common.state" | translate }}
            <span
              *ngIf="
                booked.status === 'cancelled'
              "
              class="cancelled"
              >{{ "common.canceled" | translate }}</span
            >
            <span
              *ngIf="
                !booked.status || (booked.status && booked.status === 'active' || booked.status?.toLowerCase() === 'confirmed') || (type === 'car' || type === 'expense'
                  ? !booked.status || (booked.status && booked.status === 'active' || booked.status?.toLowerCase() === 'confirmed')
                  : false)
              "
              class="success"
              >{{ "common.confirmed" | translate }}</span
            >
            <span *ngIf="booked.status === 'pending'" class="info">{{
              "common.pending" | translate
            }}</span>
          </p>
          <div class="status-container">
            <button
              *ngIf="hasPdf() && booked.status !== 'cancelled'"
              (click)="onGetPDF()"
            >
              {{ "my-bookeds.view.see-bonus" | translate }}
            </button>
            <button
              *ngIf="!hasPdf() && booked.status !== 'cancelled'"
              (click)="advicePdf()"
            >
              {{ "my-bookeds.view.see-bonus" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
