export class Filters {
  stars: number;
  priceMin: number;
  priceMax: number;
  priceMinFlight: number;
  priceMaxFlight: number;
  lodgingType: "room" | "apartment" | "all";
  breakfast: boolean;
  requestFlight: boolean;
  requestTrain: boolean;
  requestCustomFlight?: boolean;
  requestCustomTrain?: boolean;
  requestCar?: boolean;
  onlyAuthorization?: boolean;
  onlyFlightAuthorization?: boolean;
  onlyTrainAuthorization?: boolean;
  requestCancelBooked: boolean;
  requestCancelFlight: boolean;
  requestBooked: boolean;
  sitType?: "ECONOMY" | "PREMIUM_ECONOMY" | "BUSINESS" | "CLASS_FIRST";
  renfeCard?: "PLUS50" | "GOLDENCARD" | "ALL";
  allFlightsAllowed?: boolean;
  allowCheckedBag?: boolean;
  priceMaxBag?: number;
  chooseTrainSeat?: boolean;
  weight?: number;
  hotelDaysInAdvance?: number;
  flightDaysInAdvance?: number;
  trainDaysInAdvance?: number;
}
