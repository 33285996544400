<div>
  <div *ngIf="loadingFlight" class="col-12">
    <app-loading [userType]="'user'"></app-loading>
  </div>
  <div class="row">
    <div class="col-8" id="flights-col">
      <ng-container *ngFor="let segmentArr of selectedFlights; let i = index">
        <div class="card-container" id="card-{{ i }}">
          <div class="card-head">
            <div class="header-title">
              <img [src]="getLogo(segmentArr[0].object.marketing_company)" alt="airlineLogo" />
              <div class="hangover-button" *ngIf="segmentArr.length > 1" (click)="toggleHanover(i)">
                <p>{{ "flight.detail-flight.see-stops" | translate }}</p>
                <img src="assets/img/svg/flight/down-arrow.svg" alt="down-arrow" />
              </div>
            </div>
          </div>
          <div class="card-content">
            <div class="row" [ngClass]="{ show: seeHangover[i] === true }">
              <div class="col-2">
                <h6>
                  {{
                  (i === 0
                  ? "flight.form-flight.outward-trip"
                  : "flight.form-flight.return-trip"
                  ) | translate
                  }}
                </h6>
              </div>
              <div class="col-3">
                <span class="departure-duration">{{
                  segmentArr[0].object.departure_date | date: "HH:mm"
                  }}</span>
                <span class="departure">
                  {{ segmentArr[0].object.departure }}
                </span>
                <span class="departure-date">{{
                  segmentArr[0].object.departure_date | customDateFormat: "date"
                  }}</span>
              </div>
              <div class="col-4">
                <div class="mainInfoContainer">
                  <div class="stopsContainer">
                    <span>{{ durations[i] }}</span>
                    <div class="LegInfo_stopLine__MzUxZ">
                      <span *ngFor="let i of getStops(segmentArr)" class="LegInfo_stopDot__ZTAyN"></span><svg
                        id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px" y="0px" viewBox="0 0 12 12" enable-background="new 0 0 12 12" xml:space="preserve"
                        class="LegInfo_planeEnd__ZDkxM">
                        <path fill="#898294"
                          d="M3.922,12h0.499c0.181,0,0.349-0.093,0.444-0.247L7.949,6.8l3.233-0.019C11.625,6.791,11.989,6.44,12,6 c-0.012-0.44-0.375-0.792-0.818-0.781L7.949,5.2L4.866,0.246C4.77,0.093,4.602,0,4.421,0L3.922,0c-0.367,0-0.62,0.367-0.489,0.71 L5.149,5.2l-2.853,0L1.632,3.87c-0.084-0.167-0.25-0.277-0.436-0.288L0,3.509L1.097,6L0,8.491l1.196-0.073 C1.382,8.407,1.548,8.297,1.632,8.13L2.296,6.8h2.853l-1.716,4.49C3.302,11.633,3.555,12,3.922,12">
                        </path>
                      </svg>
                    </div>
                    <div class="stopsLabelContainer" *ngIf="segmentArr.length - 1 > 0">
                      {{ segmentArr.length - 1 }}
                      {{ "flight.detail-flight.stops" | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <span class="arrival-duration">{{
                  segmentArr[segmentArr.length - 1].object.arrival_date
                  | date: "HH:mm"
                  }}</span>
                <span class="arrival">{{
                  segmentArr[segmentArr.length - 1].object.arrival
                  }}</span>
                <span class="arrival-date">{{
                  segmentArr[segmentArr.length - 1].object.arrival_date
                  | customDateFormat: "date"
                  }}</span>
              </div>
            </div>

            <div class="row hangovers" *ngIf="segmentArr.length > 1 && seeHangover[i] === true">
              <ng-container *ngFor="let hangover of segmentArr | slice: 1:segmentArr.length">
                <div class="col-2">
                  <img [src]="getLogo(hangover.object.marketing_company)" alt="airlineLogo" />
                </div>
                <div class="col-3">
                  <span class="departure">
                    {{ this.departureName }}{{ hangover.object.departure }}
                  </span>
                  <span class="departure-date">{{
                    hangover.object.departure_date | customDateFormat: "date"
                    }}</span>
                  <span class="departure-time">{{
                    hangover.object.departure_date | date: "HH:mm"
                    }}</span>
                </div>
                <div class="col-4">
                  <div class="mainInfoContainer">
                    <div class="stopsContainer">
                      <div class="stopsLabelContainer"></div>
                      <div class="LegInfo_stopLine__MzUxZ"></div>
                      <div class="stopsLabelContainer"></div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <span class="arrival">{{ hangover.object.arrival }}</span>
                  <span class="departure-date">{{
                    hangover.object.arrival_date | customDateFormat: "date"
                    }}</span>
                  <span class="departure-time">{{
                    hangover.object.arrival_date | date: "HH:mm"
                    }}</span>
                </div>
              </ng-container>
            </div>

            <div class="card-foot" [ngClass]="{ double: canBaggage }">
              <div ngbDropdown class="dropdown-container my-dropdown select-input form-control"
                *ngIf="canBaggage && baggagesInfo && baggagesInfo.length > 0">
                <div ngbDropdownToggle class="dropdown-toggle cursor-pointer">
                  {{
                  !selectedBags ||
                  !selectedBags.length > 0 ||
                  !selectedBags[i]
                  ? ("flight.detail-flight.baggageSelection" | translate)
                  : selectedBags[i].short_name
                  ? selectedBags[i].short_name
                  : ("train.trenymas.none" | translate)
                  }}
                </div>
                <div ngbDropdownMenu class="dropdown-menu border-radius-20" aria-labelledby="dropdownBasic1">
                  <p ngbDropdownItem (click)="selectBaggage(i, -1)">
                    -- {{ "train.trenymas.none" | translate }} --
                  </p>
                  <ng-container *ngFor="
                      let bag of getBaggagesInfo(segmentArr);
                      let index = index
                    ">
                    <p ngbDropdownItem *ngIf="
                        bag.seg_references[0] === segmentArr[i]
                          ? segmentArr[i].id
                          : segmentArr[0].id &&
                            bag.category?.toLowerCase() !== 'seatmap'
                      " (click)="selectBaggage(i, bag)" [ngClass]="{
                        last: baggagesInfo.length - 1 === i
                      }">
                      {{ bag.short_name }}: +{{ bag.total }}€
                    </p>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="col-4" id="price-col">
      <div id="details-container">
        <span *ngIf="canUseResident" class="resident-applied">{{ 'flight.resident-applied' | translate }}</span>
        <div class="details-buy-text" [ngClass]="{ 'no-fare': fareSelected == null }">
          <ng-container *ngIf="fareSelected && farePricing; else elseBlock">
            <div class="buy-text">
              <div>
                <div *ngIf="!isPriceWithinRange(getPrice()) || groupFilters.onlyFlightAuthorization" style="display: flex; justify-content: center;">
                  <span class="admin-permission-msg">{{ "authorization.permissions-required" | translate }}</span>
                </div>
                <div class="buy-price" *ngIf="selectedBags != 0">
                  <div class="baggage-extra">
                    <div id="baggage-text">
                      <ng-container *ngFor="let bag of selectedBags">
                        <p *ngIf="isNumber(bag) && bag.total > 0">
                          {{
                          "flight.detail-flight.baggageSelection" | translate
                          }}
                        </p>
                      </ng-container>
                    </div>
                    <div id="baggage-price">
                      <ng-container *ngIf="
                          selectedBags[0] != null || selectedBags[1] != null
                        ">
                        <ng-container *ngFor="let bag of selectedBags">
                          <p *ngIf="isNumber(bag) && bag.total > 0">
                            {{ bag.total }} €
                          </p>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="total-price">
                <p>{{ "flight.result-flight.total-price" | translate }}:</p>
                <p>{{ getPrice() | price }} €</p>
              </div>
              <span class="change-fare" (click)="emitChangeFare()">{{
                "flight.result-flight.change-fare" | translate
                }}</span>
            </div>
          </ng-container>
          <ng-template #elseBlock>
            <div class="baggage-extra" *ngIf="selectedBags > 0">
              <p>{{ "flight.result-flight.seat-selection" | translate }}</p>
              <p *ngFor="let bags of selectedBags">{{ bags.total }} €</p>
            </div>
            <p>{{ "flight.result-flight.total-price" | translate }}:</p>
            <p *ngIf="totalPrice > 0">{{ totalPrice | price }} €</p>
          </ng-template>
        </div>
        <button class="custom-btn" (click)="goNextStep()">
          {{ "flight.result-flight.continue" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>