import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import {
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { BillsComponent } from "@shared/component/bookeds/billing/bills/bills.component";
import { TransactionComponent } from "@shared/component/bookeds/billing/transaction.component";
import { BookedComponent } from "@shared/component/bookeds/booked/booked.component";
import { InfoComponent } from "@shared/component/bookeds/info/info.component";
import { CreateTravelModalComponent } from "app/modules/components/travels/create-travel-modal/create-travel-modal.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ConfigComponent } from "../components/config/config.component";
import { BarOptmComponent } from "../components/dashboard/charts/bar-optm/bar-optm.component";
import { PieOptmComponent } from "../components/dashboard/charts/pie-optm/pie-optm.component";
import { DashboardComponent } from "../components/dashboard/dashboard.component";
import { GraphComponent } from "../components/graph/graph/graph.component";
import { InsuranceComponent } from "../components/insurance/insurance.component";
import { BookCardComponent } from "../components/my-bookeds/user-my-bookeds/book-card/book-card.component";
import { BookedContainerComponent } from "../components/my-bookeds/user-my-bookeds/booked-container/booked-container.component";
import { BookedSearchComponent } from "../components/my-bookeds/user-my-bookeds/booked-search/booked-search.component";
import { TravelContainerComponent } from "../components/my-bookeds/user-my-bookeds/travel-container/travel-container.component";
import { UploadBill } from "./component/bookeds/billing/bills/uploadBill/uploadBill.component";
import { BookedsViewComponent } from "./component/bookeds/bookeds-view/bookeds-view.component";
import { CustomConfirmDialogComponent } from "./component/custom-confirm-dialog/custom-confirm-dialog.component";
import { LoaderHotelComponent } from "./component/custom-loaders/loader-hotel/loader-hotel.component";
import { LoaderPlaneComponent } from "./component/custom-loaders/loader-plane/loader-plane.component";
import { LoaderTrainComponent } from "./component/custom-loaders/loader-train/loader-train.component";
import { DialogAssignProjectComponent } from "./component/dialog-assign-project/dialog-assign-project.component";
import { DialogUserFilesComponent } from "./component/dialog-user-files/dialog-user-files.component";
import { LoadingComponent } from "./component/loading/loading.component";
import { CreatePasswordComponent } from "./component/reset-password/create-password/create-password.component";
import { RequestPasswordResetComponent } from "./component/reset-password/request-password-reset/request-password-reset.component";
import { ResetPasswordCompleteComponent } from "./component/reset-password/reset-password-complete/reset-password-complete.component";
import { ResetPasswordSentComponent } from "./component/reset-password/reset-password-sent/reset-password-sent.component";
import { ResetPasswordComponent } from "./component/reset-password/reset-password/reset-password.component";
import { NgbDateESParserFormatter } from "./date/ngb-date-es-parser-formatter";
import { NgbDatepickerES } from "./date/ngb-datepicker-es";
import { SidebarDirective } from "./directives/sidebar.directive";
import { SidebarAnchorToggleDirective } from "./directives/sidebaranchortoggle.directive";
import { SidebarLinkDirective } from "./directives/sidebarlink.directive";
import { SidebarListDirective } from "./directives/sidebarlist.directive";
import { SidebarToggleDirective } from "./directives/sidebartoggle.directive";
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { NavbarComponent } from "./navbar/navbar.component";
import { BookedTypePipe } from "./pipes/booked-type.pipe";
import { DateHourMomentPipe } from "./pipes/date-hour-moment.pipe";
import { DateMomentPipe } from "./pipes/date-moment.pipe";
import { DescriptionPipe } from "./pipes/description.pipe";
import { FilterBookedPipe } from "./pipes/filter-booked.pipe";
import { FilterTRMPipe } from "./pipes/filter-trm.pipe";
import { FilterPipe } from "./pipes/filter.pipe";
import { GenderPipe } from "./pipes/gender.pipe";
import { PricePipe } from "./pipes/prices.pipe";
import { RequestsPipe } from "./pipes/requests.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { SearchPipe } from "./pipes/search.pipe";
import { TranslatorPipe } from "./pipes/translator.pipe";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { BookedDeliveryNoteComponent } from "./component/booked-delivery-note/booked-delivery-note.component";
import { UserCardModalComponent } from './component/user-card-modal/user-card-modal.component';

@NgModule({
    exports: [
        CommonModule,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        NgbModule,
        TranslateModule,
        SearchPipe,
        LoadingComponent,
        DescriptionPipe,
        PricePipe,
        FilterPipe,
        FilterBookedPipe,
        FilterTRMPipe,
        RequestsPipe,
        BookedTypePipe,
        SafePipe,
        DateMomentPipe,
        GenderPipe,
        DateHourMomentPipe,
        CreateTravelModalComponent,
        DashboardComponent,
        PieOptmComponent,
        BarOptmComponent,
        InfoComponent,
        BookedComponent,
        BookedContainerComponent,
        TravelContainerComponent,
        BookedSearchComponent,
        BillsComponent,
        TransactionComponent,
        MatExpansionModule,
        MatDatepickerModule,
        MatGridListModule,
        LoaderPlaneComponent,
        LoaderTrainComponent,
        LoaderHotelComponent,
        TranslatorPipe,
        BookCardComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatButtonModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatPaginatorModule,
    ],
    declarations: [
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        SearchPipe,
        LoadingComponent,
        DescriptionPipe,
        PricePipe,
        FilterPipe,
        FilterBookedPipe,
        FilterTRMPipe,
        BookedTypePipe,
        GenderPipe,
        SafePipe,
        DateMomentPipe,
        DateHourMomentPipe,
        TranslatorPipe,
        BookedsViewComponent,
        UploadBill,
        GraphComponent,
        RequestPasswordResetComponent,
        ResetPasswordSentComponent,
        ResetPasswordCompleteComponent,
        ResetPasswordComponent,
        CreatePasswordComponent,
        ConfigComponent,
        CreateTravelModalComponent,
        DashboardComponent,
        PieOptmComponent,
        BarOptmComponent,
        InfoComponent,
        BookedComponent,
        BookedContainerComponent,
        BookCardComponent,
        TravelContainerComponent,
        BookedSearchComponent,
        BillsComponent,
        TransactionComponent,
        // AgencyFormComponent,
        LoaderPlaneComponent,
        LoaderTrainComponent,
        LoaderHotelComponent,
        DialogUserFilesComponent,
        InsuranceComponent,
        CustomConfirmDialogComponent,
        DialogAssignProjectComponent,
        RequestsPipe,
        BookedDeliveryNoteComponent,
        UserCardModalComponent,
      ],
    providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateESParserFormatter },
        { provide: NgbDatepickerI18n, useClass: NgbDatepickerES },
    ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: NgbDateParserFormatter, useClass: NgbDateESParserFormatter },
        { provide: NgbDatepickerI18n, useClass: NgbDatepickerES },
      ],
    };
  }
}
