import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-pdf-modal',
  templateUrl: './view-pdf-modal.component.html',
  styleUrls: ['./view-pdf-modal.component.scss'],
})
export class ViewPdfModalComponent implements OnInit {
  @Input() blobUrl: string | null = null;
  @Input() blobUrls: string[] = [];
  @Input() fileName: string | null = null;
  @Input() fileNames: string[] = [];
  currentIndex: number = 0;
  tempBlobUrl: string | null = null;
  isViewerVisible: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.tempBlobUrl = this.currentBlobUrl;
  }

  get currentBlobUrl(): string {
    return this.blobUrls.length > 0
      ? this.blobUrls[this.currentIndex]
      : this.blobUrl!;
  }

  get currentFileName(): string {
    return this.fileNames.length > 0
      ? this.fileNames[this.currentIndex]
      : this.fileName || '';
  }

  next() {
    if (this.currentIndex < this.blobUrls.length - 1) {
      this.currentIndex++;
      this.refreshPdfViewer();
    }
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.refreshPdfViewer();
    }
  }

  refreshPdfViewer() {
    this.isViewerVisible = false;
    this.cdr.detectChanges();

    this.tempBlobUrl = this.currentBlobUrl;
    this.isViewerVisible = true;
    this.cdr.detectChanges();
  }

  downloadPDFBooked() {
    window.open(this.currentBlobUrl, '_blank');
  }

  closeModal() {
    this.activeModal.dismiss('Cross click');
  }
}
