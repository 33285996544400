<div class="row mt-2">
  <div class="col-12">
    <app-user-detail *ngIf="(isAdmin && canUseTRM) || isEmployee" [userUuid]="userToBook">
    </app-user-detail>

    <div class="ph-item" *ngIf="hotel == null">
      <div class="ph-col-12">
        <div class="ph-row">
          <div class="ph-col-4 empty"></div>
          <div class="ph-col-4 big"></div>
        </div>
        <div class="ph-row">
          <div class="ph-col-5 empty"></div>
          <div class="ph-col-2"></div>
          <div class="ph-col-4 empty"></div>
        </div>
      </div>
      <div class="ph-col-6">
        <div class="ph-picture"></div>
      </div>
      <div class="ph-row">
        <div class="ph-row"></div>
        <div class="ph-row"></div>
        <div class="ph-row"></div>
        <div class="ph-row"></div>
        <div class="ph-row"></div>
        <div class="ph-row"></div>
        <div class="ph-row"></div>
      </div>
    </div>

    <div class="row" *ngIf="hotel">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 bookeds-style">
        <div class="card border-search">
          <div class="card-body bg-card" style="padding-top: 0px !important;">
            <img
              src="assets/img/svg/arrow-back.svg"
              title="{{ 'common.return' | translate }}"
              alt="emptySearch"
              class="back-cursor cursor-pointer"
              (click)="goBack()"
            />

            <div class="flex justify-content-center">
              <div class="col-xl-12 col-lg-12 my-2 d-flex justify-content-center" *ngIf="hotel && hotel.images">
                <div id="hotelImages" class="carousel slide" data-ride="carousel" wrap="true">
                  <ol class="carousel-indicators">
                    <li *ngFor="let image of hotel.images; let i = index" data-target="#hotelImages"
                      [attr.data-slide-to]="i" [class.active]="i === 0"></li>
                  </ol>
                  <div class="carousel-inner">
                    <div *ngFor="let image of hotel.images; let i = index" class="carousel-item"
                      [class.active]="i === 0">
                      <img [src]="image.path || image" class="d-block w-100 hotel-image" alt={{hotel.name}}>
                    </div>
                  </div>
                  <a class="carousel-control-prev" href="#hotelImages" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#hotelImages" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-xl-7 col-md-8 col-xs-12 d-flex justify-content-center pb-4">
                <div class="row">
                  <div class="col-12 d-flex flex-row justify-content-left mt-3">
                    <b>
                      <h1 class="heading-4 m-0">{{ hotel.name }}</h1>
                    </b>
                    <span class="stars-style">
                      <i *ngFor="let i of countStars" class="fa fa-star yellow darken-2"></i>
                    </span>
                  </div>

                  <div class="col-12 d-flex justify-content-left mt-2">
                    <!-- Enlace "Ver dirección" -->
                    <a href="javascript:void(0);" (click)="scrollToBottom()" style="
                        text-decoration: underline;
                        color: #F85F1F;
                        cursor: pointer;
                        font-weight: bold;
                      ">{{ hotel.address }}</a>
                  </div>

                  <div class="img-style col-xl-12 col-lg-12" *ngIf="hotel && !hotel.images">
                    <img src="assets/img/gallery/no-image.jpg" alt="No hay imagen" />
                  </div>
                  <mat-accordion [ngClass]="getDescriptionPanelClass()" *ngIf="hotel.description">
                    <mat-expansion-panel [expanded]="false" (opened)="onPanelOpened()" (closed)="onPanelClosed()">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{ "common.description" | translate }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="content" [ngClass]="getDescriptionPanelClass()">
                        <p *ngFor="let d of hotel.description" [innerHTML]="d"></p>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                  <mat-accordion [ngClass]="getDetailsPanelClass()" *ngIf="hotel.additionalDetails && (hotel.additionalDetails.checkIn || hotel.additionalDetails.checkOut || hotel.additionalDetails.fees || hotel.additionalDetails.policies)" class="additional-details">
                    <mat-expansion-panel [expanded]="false" (opened)="onDetailsOpened()" (closed)="onDetailsClosed()">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{ "hotel.additional-details" | translate }}
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div class="content">
                        <p *ngIf="hotel.additionalDetails.checkIn && hotel.additionalDetails.checkIn[0]">Checkin: {{
                          hotel.additionalDetails.checkIn[0].startTime }} - {{
                          hotel.additionalDetails.checkIn[0].endTime }}</p>
                        <p *ngIf="hotel.additionalDetails.checkOut">Checkout: {{ hotel.additionalDetails.checkOut }}</p>
                        <ng-container *ngIf="hotel.additionalDetails.checkIn && hotel.additionalDetails.checkIn[0] && hotel.additionalDetails.checkIn[0].instructions">
                          <p>{{"common.instructions" | translate }}</p>
                          <div class="instructions" [innerHTML]="hotel.additionalDetails.checkIn[0].instructions">
                          </div>
                        </ng-container>
                        <ng-container *ngIf="hotel.additionalDetails.checkIn && hotel.additionalDetails.checkIn[0] && hotel.additionalDetails.checkIn[0].specialInstructions">
                          <p>{{"common.special-instructions" | translate }}</p>
                          <div class="instructions"
                            [innerHTML]="hotel.additionalDetails.checkIn[0].specialInstructions">
                          </div>
                        </ng-container>
                        <ng-container *ngIf="hotel.additionalDetails.fees">
                          <p>{{ "flights.form.fees" | translate }}</p>
                          <div class="instructions">
                            <div *ngFor="let fee of hotel.additionalDetails.fees" [innerHTML]="fee.content"></div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="hotel.additionalDetails.policies">
                          <p>{{ "flight.multifamily.policies" | translate }}</p>
                          <div class="instructions">
                            <div *ngFor="let policie of hotel.additionalDetails.policies" [innerHTML]="policie.content">
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </div>
            </div>
            <div class="row justify-content-center container-info" *ngIf="hotel.facility.length > 0">
              <div class="row col-xl-7 col-md-12 col-xs-12 facilities-container">
                <div class="facilities">
                  <div *ngIf="hotel.facility.includes('GREENHOTEL')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/palm.svg" />
                    </div>
                    <p>{{
                      "search.card.green-hotel" | translate
                    }}
                    </p>
                  </div>
                  <div *ngIf="hotel.facility.includes('24HRECEPTION')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/cloche.svg" />
                    </div>
                    <p>{{
                      "search.card.24h-reception" | translate
                    }}
                    </p>
                  </div>
                  <div *ngIf="hotel.facility.includes('PARKING')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/park.svg" />
                    </div>
                    <p>{{
                      "search.card.parking" | translate
                    }}</p>  
                  </div>
                  <div *ngIf="hotel.facility.includes('AIRCONDITIONING')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/snowflake.svg" />
                    </div>
                    <p>{{
                      "search.card.air-conditioning" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('TV')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/television.svg" />
                    </div>
                    <p>{{
                      "search.card.tv" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('BATHROOM')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/toilet.svg" />
                    </div>
                    <p>{{
                      "search.card.bathroom" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('POOL')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/swimming-pool.svg" />
                    </div>
                    <p>{{
                      "search.card.pool" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('WHEELCHAIRACCESS')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/wheelchair.svg" />
                    </div>
                    <p>{{
                      "search.card.mobility" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('GYM')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/gym.svg" />
                    </div>
                    <p>{{
                      "search.card.gym" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('WIFI')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/wifi.svg" />
                    </div>
                    <p>{{
                      "search.card.wifi" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('SPA')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/spa.svg" />
                    </div>
                    <p>{{
                      "search.card.spa" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('PETS')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/paw.svg" />
                    </div>
                    <p>{{
                      "search.card.pets" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('TERRACE')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/terrace.svg" />
                    </div>
                    <p>{{
                      "search.card.terrace" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('BAR')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/cocktail.svg" />
                    </div>
                    <p>{{
                      "search.card.bar" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('BREAKFAST')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/tea.svg" />
                    </div>
                    <p>{{
                      "common.breakfast" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('AIRPORTSHUTTLE')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/car-to-airport.svg" />
                    </div>
                    <p>{{
                      "search.card.airport-shuttle" | translate
                    }}</p>
                  </div>
                  <div *ngIf="hotel.facility.includes('ROOMSERVICE')">
                    <div class="icon-bcg">
                      <img src="assets/img/svg/room-service.svg" />
                    </div>
                    <p>{{
                      "search.card.room-service" | translate
                    }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center" #scrollAfterChangeRoom>
              <div class="row pl-2 col-xl-7 col-md-12 col-xs-6 detailHotelCheckInOut">
                <div class="col-12 pl-1 d-flex justify-content-left mt-3 title-date">
                  <h5>
                    <b>{{ "search.filters.num_rooms" | translate }}</b>
                  </h5>
                </div>
                <div class="col-12 pl-1 d-flex flex-row justify-content-left title-date">
                  <span>
                    <b>{{ "hotel.custom.rooms" | translate }}: </b>
                  </span>
                  <p>
                    {{ searchConfig.occupancies.length }}
                  </p>
                  <span>
                    <b>{{ "agency.search.filters.adults" | translate }}: </b>
                  </span>
                  <p>
                    {{ totalAdults }}
                  </p>
                </div>
                <div class="col-12 pl-1 d-flex flex-row justify-content-left title-date">
                  <span><b>{{ "search.search-booked.date-entry" | translate }}:</b></span>
                  <p>
                    {{
                    searchConfig.checkIn.year +
                    "-" +
                    searchConfig.checkIn.month +
                    "-" +
                    searchConfig.checkIn.day
                    | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY"
                    }}
                  </p>
                  <span><b>{{
                      "search.search-booked.date-departure" | translate
                      }}:</b></span>
                  <p>
                    {{
                    searchConfig.checkOut.year +
                    "-" +
                    searchConfig.checkOut.month +
                    "-" +
                    searchConfig.checkOut.day
                    | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY"
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex flex-row justify-content-start" style="margin-left: 5%;">
            <div>
              <span class="span-options">{{ 'search.filters.title' | translate }}: </span>
            </div>
            <div class="px-sm-3 px-xs-5 custom-radio">
              <input
                type="checkbox"
                class="custom-control-input"
                name="filtersHotel"
                [checked]="isFilterChecked('breakfast')"
                id="breakfast"
                (change)="applyFilters($event)"
              />
              <label class="custom-control-label custom-color-label" for="breakfast">
                {{ 'search.search-booked.breakfast-included' | translate }}
              </label>
            </div>
            <div class="px-sm-3 px-xs-5 custom-radio">
              <input
                type="checkbox"
                class="custom-control-input"
                name="filtersHotel"
                [checked]="isFilterChecked('cancellation')"
                id="cancellation"
                (change)="applyFilters($event)"
              />
              <label class="custom-control-label custom-color-label" for="cancellation">
                {{ 'search.search-booked.free-cancel' | translate }}
              </label>
            </div>
          </div>
          
          <div class="card-body bg-card d-flex flex-column px-4">
            <!-- TABLA DE ROOMS -->
            <!-- <div class="row p-0 col-12 justify-content-center"> -->
            <div class="room-header">
              <h4>
                <b>{{ "hotel.room-selection" | translate }}
                  {{ getCurrentOccupancyIndex() + 1 }}</b>
              </h4>
              <span *ngIf="selectedOccupancy.adults > 0">
                {{ "agency.search.filters.adults" | translate }}:
                <img src="" alt="" /> x{{ selectedOccupancy.adults }}</span>
            </div>
            <div class="d-flex rooms-container" [ngStyle]="{'max-width.px': selectedRoom ? 1500 : 1000}">
              <div class="rooms-choice-container" [ngClass]="{
                  'no-room-selected': !selectedRoom && !orderSummary.length
                }">
                <div class="row col-12 px-0 justify-content-center">
                  <div *ngIf="filteredRooms.length > 0; else noRoomsAvailable" style="width: 100% !important;">
                    <ng-container *ngIf="hotel.rooms && hotel.rooms.length">
                      <div class="col-12 container-rooms px-3 py-3 mb-3" *ngFor="let room of filteredRooms; let j = index">
                        <div class="col-12 p-0 room-info justify-content-left" style="position: relative;">
                          <div class="row col-12 header-department actions" (click)="toggleRoomRatesVisibility(room.code)">
                            <p class="col-10">
                              <span style="padding-right: 0.6rem; font-weight: bold; font-size: large;">{{ room.name }}</span>
                            </p>
                            <div class="col-2 text-right">
                              <img src="assets/img/svg/arrow-down.svg" [style.transform]="roomRatesVisibility[room.code] ? '' : 'rotate(180deg)'">
                            </div>
                          </div>
                          <div class="row col-12" *ngIf="!roomRatesVisibility[room.code]">
                            <p class="col-12">
                              <span class="min-price">{{'common.availability' | translate}} {{ getMinPrice(room.rates) | currency:'EUR' }}</span>
                            </p>
                          </div>
                          <div *ngIf="roomRatesVisibility[room.code]" style="margin-top: 1rem;">
                            <div class="bed-selection-container d-flex p-3">
                              <label>{{'hotel.bed-selection' | translate}}</label>
                              <mat-form-field appearance="fill" style="width: 100%;">
                                <mat-select [(ngModel)]="selectedBedTypes[room.code]" (selectionChange)="onBedTypeChange(room.code, $event)">
                                  <mat-option *ngFor="let bedType of getUniqueBedTypes(room.rates)" [value]="bedType">{{ bedType }}</mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                            <ng-container *ngFor="let rate of filteredRates[room.code]; let i = index">
                              <div class="border-room my-0 mx-auto"></div>
                              <div class="d-flex justify-content-center select-room p-3 cursor-pointer"
                                (click)="selectRoom(room, rate)" [ngClass]="{
                                  active: isChecked(room, rate),
                                  'request-room-hover': !canRequest || getUserBookingOption(rate) === 2
                                }" *ngIf="rate.adults >= selectedOccupancy.adults">
                                <div *ngIf="!canRequest || getUserBookingOption(rate) === 2" class="room-pre-authorized">
                                  {{ "search.search-booked.must-be-approved" | translate }}
                                </div>
                                <div class="row d-flex justify-content-between align-items-center">
                                  <div>
                                    <div (mouseover)="hoverCancel(i, j)" (mouseout)="hoverCancel(null, null)"
                                      *ngIf="rate.cancellationPolicies && rate.cancellationPolicies.length > 0"
                                      class="room-info justify-content-right">
                                      <div>
                                        <p class="mb-1" [ngClass]="
                                          isSameOrAfterToday(rate.cancellationPolicies[0].from)
                                            ? 'color-info'
                                            : 'color-grey'
                                        ">
                                          <ng-container *ngIf="isSameOrAfterToday(rate.cancellationPolicies[0].from); else noCancelTemplate">
                                            {{ 'search.search-booked.free-cancel' | translate }} 
                                            <span>
                                              {{ 'hotel.until' | translate }} {{ formatCancellationDate(rate.cancellationPolicies[0].from) }}
                                            </span>
                                          </ng-container>
                                          <ng-template #noCancelTemplate>
                                            {{ 'search.search-booked.no-cancel' | translate }}
                                          </ng-template>
                                          <i class="fa fa-question-circle" [ngClass]="
                                            isSameOrAfterToday(rate.cancellationPolicies[0].from)
                                              ? 'color-info'
                                              : 'color-grey'
                                          "></i>
                                        </p>
                                        <div class="cancellation-policies" *ngIf="showPolicies && actualRoom === i && actualGroupRoom === j">
                                          <p *ngFor="let policie of rate.cancellationPolicies; let i = index" class="badge">
                                            {{ policie.text | translator }}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="room-info justify-content-left">
                                      <p *ngIf="rate.boardCode === 'HB' || rate.boardCode === 'AI' || rate.boardCode === 'BB'" class="color-info m-0">
                                        <i class="fa fa-coffee color-info"></i>
                                        {{ "agency.search.search-booked.breakfast-included" | translate }}
                                      </p>
                                      <p *ngIf="rate.boardCode === 'RO' || rate.boardCode === 'FB'" class="color-grey m-0">
                                        {{ "agency.search.search-booked.breakfast-not-included" | translate }}
                                      </p>
                                    </div>
                                  </div>
                                  <div class="d-flex">
                                    <div class="room-info justify-content-center">
                                      <p class="price m-0 mr-2">
                                        <span class="text-bold-600">{{ rate.totalWithPercentage | price }}€<sup class="offer" *ngIf="rate.offers != null && rate.offers[0] != null">{{ getOffersPercentage(rate) }}%</sup></span>
                                      </p>
                                    </div>
                                    <div class="room-info">
                                      <img class="check-input" [src]="isChecked(room, rate) ? 'assets/img/svg/radio-button-checked.svg' : 'assets/img/svg/radio-button-empty.svg'" alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>

                    </ng-container>
                  </div>
                  <ng-template #noRoomsAvailable>
                    <div class="no-rooms-container">
                      <div class="no-rooms-content">
                        <img src="assets/img/svg/empty-results.svg" alt="No rooms available" class="no-rooms-icon"/>
                        <h5>{{ "authorization.status.no-vacancy" | translate }}</h5>
                        <p>{{ "common.no-options" | translate }}</p>
                      </div>
                    </div>
                  </ng-template>
                  
                </div>
              </div>
              <!-- Order Summary -->
              <div class="summary-order-container" *ngIf="selectedRooms.length > 0" style="flex-direction: column;">
                <div class="main-container">
                  <div class="travellers-names" (click)="addTravellersNames()" [ngStyle]="{color: !guests || guests.length !== totalAdults ? '#fff' : '#FA7B46', background: !guests || guests.length !== totalAdults ? '#f46f69' : 'none' }">
                    <img src="{{ !guests || guests.length !== totalAdults ? 'assets/img/svg/radio-button-empty.svg' : 'assets/img/svg/radio-button-checked.svg' }}" />
                    {{ "flight.passengers-data" | translate }}
                  </div>
                  <h1>{{ "search.search-booked.price-details" | translate }}</h1>
                  <div *ngIf="selectedRooms && selectedRooms.length > 0">
                    <div *ngFor="let sRoom of selectedRooms">
                      <div>
                        <p class="room-type m-0">{{ sRoom.name | uppercase }}</p>
                        <span class="price-policy" [ngClass]="isSameOrAfterToday(sRoom.rates[0].cancellationPolicies[0].from) ? 'color-info' : 'color-grey'" *ngIf="sRoom.rates[0].cancellationPolicies && sRoom.rates[0].cancellationPolicies.length > 0">
                          {{ (isSameOrAfterToday(sRoom.rates[0].cancellationPolicies[0].from) ? "search.search-booked.free-cancel" : "search.search-booked.no-cancel") | translate | uppercase }}
                        </span>
                      </div>
                      <div class="row">
                        <div class="col p-0">
                          <p class="mb-3">
                            {{ searchConfig.checkIn.year + "-" + searchConfig.checkIn.month + "-" + searchConfig.checkIn.day | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY" }}
                            -
                            {{ searchConfig.checkOut.year + "-" + searchConfig.checkOut.month + "-" + searchConfig.checkOut.day | dateMoment : "YYYY-MM-DD" : "DD/MM/YYYY" }}
                          </p>
                        </div>
                        <p class="col-auto p-0">{{ sRoom.rates[0].totalWithPercentage | price }}€</p>
                      </div>
                    </div>
                  </div>
                  <div class="buttons-container mt-3">
                    <div class="total-price-container">
                      <b>{{ "csv.total" | translate }} {{ totalPrice | price }}€</b>
                    </div>
                    <div class="inner-container">
                      <button class="btn-search btn-secondary button-style btn-cancel" *ngIf="isFirstMiddleOrLast() >= 0 && this.searchConfig.occupancies.length > 1" (click)="changeNextRoom(false)">
                        {{ "hotel.previous-room" | translate }}
                      </button>
                      <button class="btn-search btn-secondary button-style" *ngIf="isFirstMiddleOrLast() <= 0 && this.searchConfig.occupancies.length > 1" (click)="changeNextRoom(true)" [disabled]="selectedRoom == null">
                        {{ "hotel.next-room" | translate }}
                      </button>
                      <button class="btn-search btn-secondary button-style" *ngIf="isFirstMiddleOrLast() === 1" (click)="doBooking()" [disabled]="selectedRoom == null || selectedRooms.length !== searchConfig.occupancies.length">
                        {{ ((canRequest === false || canOnlyRequestRooms) && !isAdmin ? "hotel.commit-request" : "hotel.commit-booking") | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- MAPA -->
            </div>
            <div #bottom class="row col-xl-7 col-md-8 col-xs-12 justify-content-center align-self-center mt-4">
              <div class="row map map-container mx-auto" style="height: 400px; width: 100%" *ngIf="hotel && googleMaps.waitForMapsToLoad() | async">
                <app-google-maps [listHotels]="[hotel]" [hotelsMapCopy]="hotelMapsCopy"
                  [hotelsListCopy]="hotelListCopy" [singleSearch]="true" [firstSearch]="firstSearch"
                  [mapHasBeenDragged]="mapHasBeenDragged" [searchConfig]="searchConfig" [fromCard]="true"
                  style="height: 100%; width: 100%"></app-google-maps>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>