import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { MediaService } from "@core/services/media.service";
import { Company, Config, User } from "@models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { RequestService } from "@core/services/request.service";

import {
  AgencyService,
  AuthService,
  BookedService,
  CompanyService,
  ConfigService,
  ExcelService,
  NgxToastrService,
  UserService,
  UtilsService,
} from "@services";
import { GraphComponent } from "app/modules/components/graph/graph/graph.component";
import * as fromCompanyActions from "app/store/actions/company.actions";
import { CompanyState } from "app/store/reducers/company.reducer";
import { Subscription, forkJoin } from "rxjs";
import { AppState } from "../company.reducer";
import { MatDialog } from "@angular/material/dialog";
import { DialogUserFilesComponent } from "@shared/component/dialog-user-files/dialog-user-files.component";
import * as lodash from 'lodash-es';
import * as momentTz from 'moment-timezone';
import swal from 'sweetalert2';
@Component({
  selector: "app-company-homepage",
  templateUrl: "./company-homepage.component.html",
  styleUrls: ["./company-homepage.component.scss"],
})
export class CompanyHomepageComponent implements OnInit, OnDestroy {
  companies: Company[] = [];
  configHotels: Config;
  configFlights: Config;
  search = "";
  getConfigsubscription: Subscription[] = [];
  maxHotelPercentage;
  maxFlightPercentage;
  subscriptions: Subscription[] = [];
  userType: string;
  whoAmI = "agency";
  author: any;
  image: any;
  fullCompaniesPage = false;
  haveRequest: Boolean = false;
  authorizations = [];
  agencyUuid: string;
  agency;
  totalRequests = 0;
  requests: any;
  totalAuthorizations = 0;
  totalPendingFlights = 0;
  agencyImage: any;
  usersAgency: any;
  showContent = false;
  isAdmin = false;
  isSuperAdmin = false;
  showAgencyAdmins = false;
  constructor(
    private companyService: CompanyService,
    private agencyService: AgencyService,
    private userService: UserService,
    public authService: AuthService,
    private store: Store<AppState>,
    private configService: ConfigService,
    private modalService: NgbModal,
    private ngxToastrService: NgxToastrService,
    private mediaService: MediaService,
    private utilsService: UtilsService,
    private bookedService: BookedService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private requestService: RequestService,
    private excelService: ExcelService,
    private dialog: MatDialog,
  ) {
    this.subscriptions.push(
      this.store.select("auth").subscribe((s) => {

        if (s.user != null) {
          this.userType = s.user.type
            ? s.user.type
            : s.user["user"]
              ? s.user["user"]["accessType"]
              : "";
          if (s.user["user"] && s.user["user"]["accessType"] === "agency") {
            this.agencyUuid = s.user["user"]["agencyUuid"];
          } else if (s.user["accessType"] === "agency") {
            this.agencyUuid = s.user["agencyUuid"];
          }
          this.author = s.user;
          if (
            s.user.type
              ? s.user.type === "EMPLOYEE" ||
                s.user.type === "HUB" ||
                s.user.type === "AGENCY"
              : s.user.accessType === "agency" ||
                s.user.accessType === "hub" ||
                s.user.accessType === "employee"
          ) {
            this.isAdmin = true;
          }
          if (
            s.user.type
              ? s.user.type === "EMPLOYEE" 
              : s.user.accessType === "employee"
          ) {
            this.isSuperAdmin = true;
          }
        }
      })
    );
    const agencyUuid: string =
      !this.agencyUuid ? this.route.snapshot.paramMap.get("agencyUuid") : this.agencyUuid;

    if(agencyUuid){
      this.showAgencyAdmins = true;
      this.subscriptions.push(
    this.agencyService.getAgency(agencyUuid).subscribe((agency) => {
          this.agency = agency;
        })
      );
    }else{
      this.showAgencyAdmins = false;
    }
  }

  ngOnInit() {
    this.loadCompanies();
    this.getPercentages();
    this.store.select("company").subscribe((companyState: CompanyState) => {
      this.search = companyState.search;
    });
    if (
      this.userType === "EMPLOYEE" &&
      !this.route.snapshot.paramMap.has("agencyUuid")
    ) {
      this.whoAmI = "all";
    }
    if (
      this.userType === "EMPLOYEE" || this.userType === "HUB" &&
      this.route.snapshot.paramMap.has("agencyUuid")
      ) {
      this.fetchCompanyImage(this.route.snapshot.paramMap.get("agencyUuid"));
    }

    if (this.userType === "AGENCY" && this.author.agencyUuid || this.author.user?.agencyUuid) {
      this.fetchCompanyImage(this.author.agencyUuid || this.author.user?.agencyUuid);
    }

    if (!this.route.snapshot.paramMap.has("agencyUuid")) {
      this.fullCompaniesPage = true;
    }
    if (this.userType === "EMPLOYEE") {
      this.getAuthorizationList();
      this.getRequestsList();
      // this.getPendingFlightsCount();
    }
  }

  private fetchCompanyImage(agencyUuid: string) {
    this.agencyService.getAgency(agencyUuid).subscribe((agency) => {
      this.agencyImage = agency?.image;
      
      if (this.agencyImage) {
        this.subscriptions.push(
          this.mediaService.getMedia(this.agencyImage).subscribe((s) => {
            const reader = new FileReader();
            reader.readAsDataURL(s);
            reader.onloadend = () => {
              this.image = reader.result as string;
            };
          })
        );
      }
    });
  }
  loadUsersAgency(){
    const agencyUuid: string =
      !this.agencyUuid ? this.route.snapshot.paramMap.get("agencyUuid") : this.agencyUuid;
    if (agencyUuid){

      const combinedRequests = forkJoin({
        agency: this.agencyService.getAgency(agencyUuid),
        users: this.agencyService.getUsersByAccessType(agencyUuid)
      });

      this.subscriptions.push(
        combinedRequests.subscribe(resp => {
          this.agency = resp.agency;
          this.usersAgency = resp.users;
        })
      )
    }
  }

  loadCompanies() {
    this.loadUsersAgency();
    const params = {};
    
    if (
      (this.userType === "HUB" ||
      this.userType === "hub" ||
      this.userType === "EMPLOYEE") &&
      this.route.snapshot.paramMap.has("agencyUuid")
      ) {
        params["agencyUuid"] = this.route.snapshot.paramMap.get("agencyUuid");
      }
      if (this.userType?.toUpperCase() === "AGENCY") {
        params["agencyUuid"] = this.agencyUuid;
      }
      
      if (
        (this.userType === "HUB" || this.userType === "hub") &&
        (this.author.hubUuid || this.author.uuid)
        ) {
          params["hubUuid"] = this.author.hubUuid || this.author.uuid;
    }
    this.companyService.getCompanies(params).subscribe(
      (data: Company[]) => {
        const res: any = data;
        this.companies = res.filter((company) => company.deleted === false);
      },
      (error) => { }
    );
  }
  onNewUser() {
    const navigationUser: NavigationExtras = {
      state: {
        loading: false,
        agency: this.route.snapshot.paramMap.has("agencyUuid")
          ? this.route.snapshot.paramMap.get("agencyUuid")
          : this.author.agencyUuid,
        fromCompanyHomepage: true,
        externalUser: this.author,
      },
    };

    this.router.navigate(["user/create-user"], navigationUser);
  }
  dispatchSearchAction(term: string) {
    const action = new fromCompanyActions.CompanySearchAction(term);
    this.store.dispatch(action);
  }
  openGraphModal(name, type, graphic) {
    const modalRef = this.modalService.open(GraphComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.name = name;
    modalRef.componentInstance.type = "agency";
    if (
      (this.userType === "EMPLOYEE" || this.userType === "HUB") &&
      this.route.snapshot.paramMap.has("agencyUuid")
    ) {
      modalRef.componentInstance.uuid =
        this.route.snapshot.paramMap.get("agencyUuid");
    } else if (
      this.userType === "EMPLOYEE" &&
      !this.route.snapshot.paramMap.has("agencyUuid")
    ) {
      modalRef.componentInstance.type = "all";
    } else {
      modalRef.componentInstance.uuid = this.agency.uuid;
    }
    modalRef.componentInstance.graphic = graphic;
  }

  onNew() {
    let agencyUuid = "";
    if (
      (this.userType === "HUB" || this.userType === "EMPLOYEE") &&
      this.route.snapshot.paramMap.has("agencyUuid")
    ) {
      agencyUuid = this.route.snapshot.paramMap.get("agencyUuid");
    } else {
      agencyUuid = this.author.uuid;
    }

    const extras: NavigationExtras = {
      state: {
        loading: false,
        maxHotelPercentage: 100,
        maxFlightPercentage: this.utilsService.maxFlightPercentage,
        isCreate: true,
        agencyUuid: agencyUuid,
      },
    };

    this.router.navigate(["companies/new"], extras);
  }

  getAuthorizationList() {
    const params = { status: "request" };
    switch (this.userType) {
      case "HUB":
        params["hubUuid"] = this.author.uuid;
        break;
      case "agency":
        params["agencyUuid"] = this.author.uuid;
        break;
      case "EMPLOYEE":
        this.route.params.subscribe((routeParams) => {
          if (routeParams.agencyUuid != null) {
            params["agencyUuid"] = routeParams.agencyUuid;
          }
          if (routeParams.hubUuid != null) {
            params["hubUuid"] = routeParams.hubUuid;
          }
        });
        break;
      default:
        break;
    }
    this.subscriptions.push(
      this.bookedService.getAuthorization(params).subscribe((result) => {
        this.totalAuthorizations = result.length;
      })
    );
  }

  getRequestsList() {
    const params = { status: "active" };
    switch (this.userType) {
      case "HUB":
        params["hubUuid"] = this.author.uuid;
        break;
      case "agency":
        params["agencyUuid"] = this.author.uuid;
        break;
      case "EMPLOYEE":
        this.route.params.subscribe((routeParams) => {
          if (routeParams.agencyUuid != null) {
            params["agencyUuid"] = routeParams.agencyUuid;
          }
          if (routeParams.hubUuid != null) {
            params["hubUuid"] = routeParams.hubUuid;
          }
        });
        break;
      default:
        break;
    }
    this.subscriptions.push(
      this.requestService.getRequests(params).subscribe(async (result: any) => {
        this.requests = await result;
        this.totalRequests = this.requests.filter(
          (doc) => doc.status == "active"
        ).length;
      })
    );
  }
  onEditAgency() {
    const navigationExtra: NavigationExtras = {
      state: {
        agency: this.agency,
        maxHotelPercentage: this.maxHotelPercentage,
        maxFlightPercentage: this.maxFlightPercentage,
      },
    };

    this.router.navigate(
      [`companies/${this.agency.uuid}/edit`],
      navigationExtra
    );
  }
  goPendings() {
    this.router.navigateByUrl("flight/pending");
  }

  // getPendingFlightsCount() {
  //   this.subscriptions.push(
  //     this.flightService.getFlightPendingListCount().subscribe((flights) => {
  //       this.totalPendingFlights = flights.departFlights.length + flights.endFlights.length;
  //     })
  //   );
  // }

  goToDashboard() {
    const dataDashboard = {
      isSuperAdmin: true,
    };
    this.router.navigateByUrl("companies/dashboard", {
      state: { dataDashboard },
    });
  }

  getPercentages() {
    this.getConfigsubscription.push(
      this.configService.getPercentageVyoo("percentageCompany").subscribe(
        (data: Config) => {
          this.configHotels = data;
          if (!this.configHotels.value) {
            this.configHotels.value = [17];
          }
          this.maxHotelPercentage = this.configHotels.value
            ? this.configHotels.value[0]
            : 17;
        },
        (err) => {
          this.ngxToastrService.typeInfo(
            "Error",
            this.translate.instant("common.some-error")
          );
        }
      ),
      this.configService.getPercentageVyoo("flightCommissions").subscribe(
        (data: Config) => {
          this.configFlights = data;
          if (!this.configFlights.value) {
            this.configFlights.value = [
              {
                local_emission: 5,
                eu_emission: 7,
                world_emission: 9,
                percentage: 5,
              },
            ];
          }

          this.maxFlightPercentage = this.configFlights.value[0].percentage;
        },
        (err) => {
          this.ngxToastrService.typeInfo(
            "Error",
            this.translate.instant("common.some-error")
          );
        }
      )
    );
  }
  goToAuthorizations() {
    if (this.userType === "EMPLOYEE" && this.fullCompaniesPage) {
      this.router.navigateByUrl("companies/authorizations");
    } else {
      if (this.userType === "HUB") {
        this.router.navigateByUrl(
          `agencies/${this.route.snapshot.paramMap.get(
            "agencyUuid"
          )}/authorizations`
        );
      } else {
        this.router.navigateByUrl(
          `agencies/${this.author.agencyUuid}/authorizations`
        );
      }
    }
  }
  goToRequests() {
    if (this.userType === "EMPLOYEE" && this.fullCompaniesPage) {
      this.router.navigateByUrl("companies/requests");
    }
  }

  orderBy(type: string) {
    switch (type) {
      case "alphabetic":
        this.companies = this.utilsService.sortArrayByAlphabet(this.companies);
        break;
      case "creation-asc":
        this.companies = this.utilsService
          .sortArrayByDate(this.companies)
          .reverse();
        break;
      case "creation-desc":
        this.companies = this.utilsService.sortArrayByDate(this.companies);
        break;
      default:
        break;
    }
  }

  exportExcel() {
    this.companyService.getAllCompaniesBookeds().subscribe((res) => {
      this.excelService.createBookingsExcel("Todas las empresas", res);
    });
  }

  toggleContent() {
    this.showContent = !this.showContent;
  }

  changeActive(user: User) {
    if (this.isAdmin) {
      this.userService
        .putUser(user.uuid, { dni: user.dni, active: !user.active })
        .subscribe(() => {
          this.loadCompanies();
        });
    }
  }

  openNewGraphModal(name, type, graphic, uuid) {
    const modalRef = this.modalService.open(GraphComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.name = name;
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.graphic = graphic;
    if (uuid) {
      modalRef.componentInstance.uuid = uuid;
    }
  }

  openUserStorage(user) {
    this.userService.getUser(user.uuid).subscribe(
      (userRes) => {
        /* const dialogRef =  */ this.dialog.open(DialogUserFilesComponent, {
          height: "90vh",
          width: "90vw",
          data: { user: userRes },
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }

  onEditUser(user: User) {
    const navigationUser: NavigationExtras = {
      state: {
        loading: false,
        agency : this.agency.uuid,
        user: user,
        fromCompanyDetails: true,
      },
    };
    this.router.navigate(["user/edit-user/", user.uuid], navigationUser);

    if (lodash.get(user, "birthdate")) {
      user.birthdate = momentTz(user.birthdate)
        .tz("Europe/Madrid")
        .format("YYYY-MM-DD");
    }
    if (lodash.get(user, "passportExpires")) {
      user.passportExpires = momentTz(user.passportExpires)
        .tz("Europe/Madrid")
        .format("YYYY-MM-DD");
    }
  }

  onDeleteUser(user: User) {
    this.translate
      .get([
        "company.company-detail.remove-user",
        "company.company-detail.user-data-lost",
        "common.confirm",
        "common.cancel",
        "company.company-detail.user-removed",
        "company.company-detail.error-user-removed",
      ])
      .subscribe((result) => {
        swal
          .fire({
            title: result["company.company-detail.remove-user"],
            text: result["company.company-detail.user-data-lost"],
            showCancelButton: true,
            confirmButtonColor: "#0CC27E",
            cancelButtonColor: "#FF586B",
            confirmButtonText: result["common.confirm"],
            cancelButtonText: result["common.cancel"],
            customClass: {
              confirmButton: "swal-button-squared swal-button-confirm-green",
              cancelButton: "swal-button-squared swal-button-dismiss-red",
            },
            buttonsStyling: false,
          })
          .then((action) => {
            if (action.value) {
              this.userService.deleteUser(user.uuid).subscribe(
                () => {
                  this.ngxToastrService.typeSuccess(
                    null,
                    result["company.company-detail.user-removed"]
                  );
                  this.loadCompanies();
                },
                (err) => {
                  this.ngxToastrService.typeInfo(
                    result["company.company-detail.error-user-removed"],
                    err.error.message
                  );
                }
              );
            }
          });
      });
  }

  ngOnDestroy() {
    this.getConfigsubscription.forEach((s) => s.unsubscribe());
  }
}
