<div class="modal-body p-3">
  <div class="row">
    <div style="width: 100%; height: 800px">
      <ng-container *ngIf="isViewerVisible">
        <ng2-pdfjs-viewer
          [pdfSrc]="tempBlobUrl"
          zoom="page-width"
          [downloadFileName]="currentFileName"
        ></ng2-pdfjs-viewer>
      </ng-container>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <div class="btn-container">
        <div class="pagination-container" *ngIf="blobUrls.length > 1">
          <button
            class="icon-btn"
            (click)="previous()"
            [disabled]="currentIndex === 0"
            aria-label="Previous"
          >
            «
          </button>
          <span class="page-indicator">
            {{ currentIndex + 1 }} / {{ blobUrls.length }}
          </span>
          <button
            class="icon-btn"
            (click)="next()"
            [disabled]="currentIndex === blobUrls.length - 1"
            aria-label="Next"
          >
            »
          </button>
        </div>
        <div class="flex-spacer"></div>
        <div class="action-btn-container">
          <button class="custom-btn download-btn" (click)="downloadPDFBooked()">
            {{ 'common.download' | translate }}
          </button>
          <button
            class="custom-btn custom-btn--close"
            (click)="closeModal()"
          >
            {{ 'common.close' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
