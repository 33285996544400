<div class="modal-header">
  <h4 class="modal-title heading-5" id="modal-basic-title">
    {{
      (editing
        ? type + ".edit-" + type + ".edit-" + type
        : type + ".create-" + type + ".create-" + type
      ) | translate
    }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!--<div class="row" *ngIf="isAdmin">
    <div class="col-12">
      <app-create-travel-modal [customBooking]="isAdmin ? true : false" [userUuid]="isAdmin ? userId : null" (sendInfo)="handleTravel($event)"></app-create-travel-modal>
    </div>
  </div>
  <hr />-->
  <form
    [formGroup]="form"
    class="card-body"
    [ngClass]="{ 'loading-background': loading }"
    (ngSubmit)="form.valid && !loading ? onSubmit() : (isInvalid = true)"
  >
    <div class="row" *ngIf="!userId">
      <div class="col-12">
        <div class="form-group">
          <label
            >{{
              type + ".create-" + type + ".choose-employee" | translate
            }}*</label
          >
          <select
            class="form-control w-25"
            formControlName="userId"
          >
            <option
              *ngFor="let user of users; trackBy: utilsService.getUuidTracking"
              [ngValue]="user"
            >
              {{ user.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row mt-2" *ngIf="editing && (userType?.toLowerCase() === 'employee' || userType?.toLowerCase() === 'company')">
      <div class="col-12">
        <div class="form-group">
          <label class="pt-0" for="passenger">{{ "common.traveller" | translate }}</label>
          <select
            formControlName="passenger"
            id="passenger"
            class="form-control custom-select"
          >
          <option *ngFor="let user of companyUsers" [value]="user.uuid">
            {{ user.name }} {{ user.lastname }}
          </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-6">
        <div class="form-group">
          <label
            >{{ type + ".create-" + type + ".booked-file" | translate }}*</label
          >
          <div>
            <input
              formControlName="file"
              type="file"
              id="file"
              class="inputFile"
              (change)="onFileChange($event)"
              accept="application/pdf"
              multiple
            />
            <label for="file" class="labelFile" [class.disabled]="userType !== 'EMPLOYEE'">{{
              type + ".create-" + type + ".choose-file" | translate
            }}</label>
            <div class="file-tags" *ngIf="(files?.length > 0 || existingFiles?.length > 0); else showFileBack">
              <span *ngFor="let f of existingFiles; let i = index" class="file-tag">
                {{ f.name }}
                <button type="button" class="remove-btn" (click)="removeFile(i)">x</button>
              </span>
              <span *ngFor="let f of files; let i = index" class="file-tag">
                {{ f.name }}
                <button type="button" class="remove-btn" (click)="removeFile(i)">x</button>
              </span>
            </div>
            <ng-template #showFileBack>
              <label class="pl-3" *ngIf="fileBack">
                {{ fileBack.name }}
              </label>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-6 pd" *ngIf="isAdmin">
        <div class="form-group">
          <mat-checkbox class="example-margin" formControlName="freeTaxes">
            <small>{{ "expenses.freeTaxes" | translate }}</small>
          </mat-checkbox>
          <div>
            <input
              formControlName="price"
              type="text"
              placeholder="{{ type + '.form-' + type + '.price' | translate }}"
              id="price"
              class="form-control"
              [disabled]="data?.refund || data?.payment"
              [ngClass]="{
                'input-error': !form.controls.price.valid && isInvalid && !form.controls.price.disabled
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row mb-2" *ngIf="userType === 'EMPLOYEE'">
      <div class="px-sm-3 px-xs-5 custom-radio">
        <input
          [disabled]="userType != 'EMPLOYEE'"
          type="radio"
          id="returnTrip"
          [checked]="!checkedOneWay"
          name="checkedReturn"
          class="custom-control-input"
          (click)="returnTrip('return')"
        />
        <label class="custom-control-label pt-0" for="returnTrip">{{
          "my-bookeds.flight.return-trip" | translate
        }}</label>
      </div>
      <div class="px-sm-3 px-xs-5 custom-radio">
        <input
          [disabled]="userType != 'EMPLOYEE'"
          type="radio"
          id="checkedOneWay"
          [checked]="checkedOneWay"
          name="checkedOneWay"
          class="custom-control-input"
          (click)="returnTrip('oneWay')"
        />
        <label class="custom-control-label pt-0" for="checkedOneWay">{{
          "my-bookeds.flight.one-way" | translate
        }}</label>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <input
            formControlName="origin"
            type="text"
            id="origin"
            class="form-control-user form-control"
            matInput
            [matAutocomplete]="from"
            (input)="getCities($event.target.value)"
            placeholder="{{ type + '.form-' + type + '.origin' | translate }}"
            [ngClass]="{
              'input-error': !form.controls.origin.valid && isInvalid
            }"
          />
          <mat-autocomplete
            #from="matAutocomplete"
            panelWidth="20%"
            (optionSelected)="cleanCitiesList()"
          >
            <mat-option *ngFor="let option of citiesList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <input
            formControlName="destination"
            type="text"
            id="destination"
            class="form-control-user form-control"
            matInput
            [matAutocomplete]="to"
            (input)="getCities($event.target.value)"
            placeholder="{{
              type + '.form-' + type + '.destination' | translate
            }}"
            [ngClass]="{
              'input-error': !form.controls.destination.valid && isInvalid
            }"
          />
          <mat-autocomplete
            #to="matAutocomplete"
            panelWidth="20%"
            (optionSelected)="cleanCitiesList()"
          >
            <mat-option *ngFor="let option of citiesList" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-6">
        <div class="form-group">
          <input
            class="form-control filter-dates-placeholder"
            [disabled]="userType != 'EMPLOYEE'"
            readonly
            placeholder="{{
              type + '.form-' + type + '.date-departure' | translate
            }}"
            name="dateDeparture"
            id="dateDeparture"
            formControlName="dateDeparture"
            ngbDatepicker
            #dDeparture="ngbDatepicker"
            (click)="dDeparture.toggle()"
            [minDate]="minDateNgStruct"
            [maxDate]="maxDateNgStruct"
            [ngClass]="{
              'input-error': !form.controls.dateDeparture.valid && isInvalid
            }"
          />
          <div class="mt-2">
            <ngb-timepicker
              formControlName="timeDeparture"
              [spinners]="false"
            ></ngb-timepicker>
            <div *ngIf="!form.controls.timeDeparture.valid && isInvalid">
              {{"common.required" | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-6" *ngIf="isAdmin">
        <div class="form-group">
          <input
            readonly
            [disabled]="f.dateDeparture.hasError('required') || checkedOneWay || userType != 'EMPLOYEE'"
            [ngClass]="{
              dates: !f.dateDeparture.hasError('required') && !checkedOneWay
            }"
            [ngClass]="{
              'input-error': !form.controls.dateDeparture.valid && isInvalid
            }"
            class="form-control filter-dates-placeholder"
            placeholder="{{
              type + '.form-' + type + '.date-return' | translate
            }}"
            name="dateReturn"
            id="dateReturn"
            formControlName="dateReturn"
            ngbDatepicker
            #dReturn="ngbDatepicker"
            (click)="dReturn.toggle()"
            [minDate]="getOutDate()"
            [maxDate]="maxDateNgStruct"
            [ngClass]="{
              'input-error': !form.controls.dateReturn.valid && isInvalid
            }"
          />
          <div class="mt-2">
            <ngb-timepicker
              [readonlyInputs]="
                f.dateDeparture.hasError('required') || checkedOneWay
              "
              formControlName="timeReturn"
              [spinners]="false"
            ></ngb-timepicker>
            <div *ngIf="!form.controls.timeReturn.valid && isInvalid">
              {{"common.required" | translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <input
            formControlName="customCode"
            placeholder="{{ 'common.custom-code' | translate }}"
            type="text"
            id="customCode"
            class="form-control-user form-control"
          />
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-12">
        <div class="form-group">
          <input
            formControlName="costCenter"
            placeholder="{{ 'common.cost-center' | translate }}"
            type="text"
            id="costCenter"
            class="form-control-user form-control"
          />
        </div>
      </div>
    </div>
    <div class="row" *ngIf="userType === 'EMPLOYEE'">
      <div class="col-12">
        <div class="form-group">
          <div class="form-group">
            <textarea
              formControlName="notes"
              id="notes"
              class="textarea form-control"
              placeholder="{{
                type + '.form-' + type + '.annotations-request' | translate
              }}"
              rows="4"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end">
          <button
            class="custom-btn mb-0"
            ngbAutofocus
            [ngStyle]="{
              cursor: !form.valid || loading ? 'not-allowed' : 'pointer'
            }"
            [ngClass]="{
              'disabled-button': !form.valid || loading
            }"
          >
            {{ "common.send" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="row" *ngIf="loading">
    <app-loading [userType]="'employee'"></app-loading>
  </div>
</div>
