<app-user-detail
  *ngIf="isAdmin || isEmployee"
  [userUuid]="userToBook"
></app-user-detail>
<div class="container">
  <!-- TITULO -->
  <span class="component-info heading-5">{{
    "car.custom.request.title-request" | translate
  }}</span>

  <!-- FORMULARIO -->
  <div class="bg-card">
    <div class="service-card-container">
      <div class="selector">
        <span
          class="selector-item"
          *ngIf="canBookHotel || isAdmin"
          (click)="navigate('hotel')"
          >{{ "common.hotels" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestCustomFlight || isAdmin"
          (click)="navigate('flight')"
          >{{ "common.flights" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestCustomTrain || isAdmin"
          (click)="navigate('train')"
          >{{ "common.trains" | translate }}</span
        >
        <span
          class="selector-item-selected"
          *ngIf="canRequestCustomCar || isAdmin"
          >{{ "common.cars" | translate }}</span
        >
        <span
          class="selector-item"
          *ngIf="canRequestAirbnb || isAdmin"
          (click)="navigate('airbnb')"
          >{{ "airbnb.custom.request.option" | translate }}</span
        >
      </div>

      <form
        [formGroup]="form"
        [ngClass]="{ 'loading-background': loading }"
        (ngSubmit)="form.valid && !loading ? onSubmit() : (isInvalid = true)"
      >
        <div class="row" *ngIf="isModal && !userUuid">
          <div class="col-12">
            <div class="form-group">
              <mat-label>{{
                type + ".create-" + type + ".choose-employee" | translate
              }}</mat-label>
              <select
                class="form-control rounded-1 w-25"
                formControlName="userUuid"
              >
                <option
                  *ngFor="
                    let user of users;
                    trackBy: utilsService.getUuidTracking
                  "
                  [ngValue]="user"
                >
                  {{ user.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="car-field">
            <label for="origin">{{
              "car.custom.request.from" | translate
            }}</label>
            <input
              formControlName="from"
              type="text"
              id="origin"
              name="origin"
              class="city-input form-control form-control--no-border"
              matInput
              [matAutocomplete]="fromM"
              (input)="getCities($event.target.value)"
              [ngClass]="{ error: form.controls['from'].invalid && isInvalid }"
              placeholder="{{ 'car.custom.request.ubication' | translate }}"
            />
            <mat-autocomplete
              #fromM="matAutocomplete"
              panelWidth="20%"
              (optionSelected)="cleanCitiesList($event.option.value)"
            >
              <mat-option *ngFor="let option of citiesList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </div>
          <div
            mat-stroked-button
            class="ubication-switch"
            (click)="exchangeUbications()"
          ></div>
          <div class="car-field">
            <label for="destination">{{
              "car.custom.request.to" | translate
            }}</label>
            <input
              formControlName="to"
              type="text"
              id="destination"
              class="city-input form-control form-control--no-border"
              matInput
              [matAutocomplete]="toM"
              name="destination"
              [ngClass]="{ error: form.controls['to'].invalid && isInvalid }"
              (input)="getCities($event.target.value)"
              placeholder="{{ 'car.custom.request.ubication' | translate }}"
            />
            <mat-autocomplete
              #toM="matAutocomplete"
              panelWidth="20%"
              (optionSelected)="cleanCitiesList($event.target.value)"
            >
              <mat-option *ngFor="let option of citiesList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </div>
        </div>
        <div class="form-row">
          <div class="car-field">
            <label for="pickup">{{
              "car.custom.request.pickup.titleFrom" | translate
            }}</label>
            <mat-select
              name="pickup"
              class="ubication-input form-control form-control--no-border"
              placeholder="{{
                'car.custom.request.pickup.titleFrom-holder' | translate
              }}"
              [ngClass]="{
                error: form.controls['pickupPlaceFrom'].invalid && isInvalid
              }"
              formControlName="pickupPlaceFrom"
            >
              <mat-option
                *ngFor="let place of pickupOptions"
                [value]="place.value"
              >
                {{ place.viewValue }}
              </mat-option>
            </mat-select>
          </div>
          <div class="car-field">
            <label for="pickup">{{
              "car.custom.request.pickup.titleTo" | translate
            }}</label>
            <mat-select
              name="pickup"
              class="ubication-input form-control form-control--no-border"
              placeholder="{{
                'car.custom.request.pickup.titleTo-holder' | translate
              }}"
              [ngClass]="{
                error: form.controls['pickupPlaceTo'].invalid && isInvalid
              }"
              formControlName="pickupPlaceTo"
            >
              <mat-option
                *ngFor="let place of pickupOptions"
                [value]="place.value"
              >
                {{ place.viewValue }}
              </mat-option>
            </mat-select>
          </div>
        </div>

        <div class="form-row">
          <div class="time-row-in">
            <div class="travel-date-field" [formGroup]="form">
              <label for="date-picker-in">{{
                "car.custom.request.departDate" | translate
              }}</label>
              <input
                id="date_form"
                class="date-picker-input form-control form-control--no-border"
                formControlName="departDate"
                placeholder="{{ 'hotel.custom.select-date' | translate }}"
                name="date-picker-in"
                ngbDatepicker
                #datePickerIn="ngbDatepicker"
                (click)="datePickerIn.toggle()"
                [minDate]="minDateNgStruct"
                [maxDate]="maxDateNgStruct"
                [ngClass]="{
                  error: form.controls['departDate'].invalid && isInvalid
                }"
              />
            </div>
            <div class="travel-date-field" [formGroup]="form">
              <label for="hour-picker-in">{{
                "custom-service.train-flight.departure" | translate
              }}</label>
              <input
                type="text"
                readonly
                id="DateIn"
                name="hour-picker-in"
                formControlName="timeDepart"
                class="hours-field form-control form-control--no-border"
                [value]="
                  form.value.timeDepart
                    ? form.value.timeDepart.hour +
                      ' : ' +
                      form.value.timeDepart.minute
                    : null
                "
                placeholder="{{
                  'custom-service.train-flight.departure' | translate
                }}"
                (click)="
                  showHoursDeparture = !showHoursDeparture;
                  showHoursReturn = false
                "
                [ngClass]="{
                  error: form.controls['timeDepart'].invalid && isInvalid
                }"
              />
              <ngb-timepicker
                formControlName="timeDepart"
                id="returnHours"
                [meridian]="false"
                *ngIf="showHoursDeparture"
                class="hour-departure-modal"
                tabindex="0"
                (blur)="showHoursDeparture = false"
              ></ngb-timepicker>
            </div>
          </div>
          <div class="time-row-out">
            <div class="travel-date-field" [formGroup]="form">
              <label for="date-picker-out">{{
                "car.custom.request.returnDate" | translate
              }}</label>
              <input
                id="date_form"
                class="date-picker-input form-control form-control--no-border"
                placeholder="{{ 'hotel.custom.select-date' | translate }}"
                name="date-picker-out"
                formControlName="returnDate"
                ngbDatepicker
                #datePickerOut="ngbDatepicker"
                (click)="datePickerOut.toggle()"
                [min]="minDateNgStruct"
                [max]="maxDateNgStruct"
                [minDate]="getOutMinDate()"
                [maxDate]="maxDateNgStruct"
                [ngClass]="{
                  error: form.controls['returnDate'].invalid && isInvalid
                }"
              />
            </div>
            <div class="travel-date-field" [formGroup]="form">
              <label for="hour-picker-in">{{
                "custom-service.train-flight.return" | translate
              }}</label>
              <input
                type="text"
                readonly
                id="DateOut"
                name="hour-picker-out"
                formControlName="timeReturn"
                class="hours-field form-control form-control--no-border"
                [value]="
                  form.value.timeReturn
                    ? form.value.timeReturn.hour +
                      ' : ' +
                      form.value.timeReturn.minute
                    : null
                "
                placeholder="{{
                  'custom-service.train-flight.return' | translate
                }}"
                (click)="
                  showHoursReturn = !showHoursReturn; showHoursDeparture = false
                "
                [ngClass]="{
                  error: form.controls['timeReturn'].invalid && isInvalid
                }"
              />
              <ngb-timepicker
                formControlName="timeReturn"
                id="returnHours"
                [meridian]="false"
                *ngIf="showHoursReturn"
                class="hour-return-modal"
                tabindex="0"
                (blur)="showHoursReturn = false"
              ></ngb-timepicker>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="age-field">
            <label for="age">{{ "car.custom.request.age" | translate }}</label>
            <input
              class="age-field-input form-control form-control--no-border"
              formControlName="age"
              type="number"
              min="0"
              max="100"
              id="age"
              placeholder="{{
                'car.custom.request.age-placeholder' | translate
              }}"
              [ngClass]="{ error: form.controls['age'].invalid && isInvalid }"
            />
            <div *ngIf="form.get('age').touched && !form.controls.age.valid && isInvalid">
              {{"car.form.custom.request.ageError" | translate}}
            </div>
          </div>
          <div class="car-type-field">
            <label for="carType">{{
              "car.custom.request.carType.title" | translate
            }}</label>
            <mat-select
              class="car-type-input form-control form-control--no-border"
              placeholder="{{
                'car.custom.request.carType.holder' | translate
              }}"
              formControlName="carType"
              [ngClass]="{
                error: form.controls['carType'].invalid && isInvalid
              }"
            >
              <mat-option *ngFor="let type of carType" [value]="type.value">
                {{ type.viewValue }}
              </mat-option>
            </mat-select>
          </div>
          <div class="insurance-field">
            <label for="insurance-field">{{
              "car.insuranceWithExcess" | translate
            }}</label>
            <mat-select
              class="insurance-field-input form-control form-control--no-border"
              placeholder="{{
                'car.custom.request.insurance.holder' | translate
              }}"
              formControlName="insuranceWithExcess"
              [ngClass]="{
                error: form.controls['insuranceWithExcess'].invalid && isInvalid
              }"
            >
              <mat-option value="true">
                {{ "common.yes" | translate }}
              </mat-option>
              <mat-option value="false">
                {{ "common.no" | translate }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="form-row">
          <div class="annotations">
            <mat-label class="annotations-label">{{
              "hotel.custom.annotations" | translate
            }}</mat-label>
            <textarea
              matInput
              class="annotation-textarea form-control form-control--no-border"
              formControlName="comments"
              placeholder="{{
                'hotel.custom.annotations-placeholder' | translate
              }}"
            ></textarea>
          </div>
        </div>
        <div class="button-row">
          <button
            class="custom-btn"
            [ngStyle]="{
              cursor: !form.valid || loading ? 'not-allowed' : 'pointer'
            }"
            [ngClass]="{
              'custom-btn disabled-button': !form.valid || loading, 'custom-btn': form.valid || !loading
            }"
          >
            {{ "common.send-request" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
