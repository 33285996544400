import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { NavigationExtras, Router } from "@angular/router";
import {
  Journey,
  JourneysReferences,
  MultifamilyRS,
  Segment,
  Solution,
} from "@core/models";
import { FlightLleegoService, StateService, UtilsService } from "@services";
import * as moment from "moment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-flights-card-result",
  templateUrl: "./flights-card-result.component.html",
  styleUrls: ["./flights-card-result.component.scss"],
})
export class FlightsCardResultComponent implements OnInit, OnDestroy, OnChanges {
  @Input() userUuid: string;
  @Input() groupFilters?: any;
  @Input() outwardList: JourneysReferences[];
  oneWay: any;
  @Input() travelersAge: number[];
  @Input() isUser: boolean;
  @Input() selectedUsers: any;
  @Input() projectData: any;
  selectedFlights: Array<Segment[]> = [];
  arrivalTime: string[] = [];
  airlineLogos: string[] = [];
  private subcriptions: Subscription[] = [];
  showContent = false;
  flightDuration: string[] = [];
  pageEvent: PageEvent;
  lowValue = 0;
  highValue = 10;
  multiFamilyRes: MultifamilyRS;
  selectedDurations: string[] = [];
  constructor(
    private router: Router,
    public stateService: StateService,
    private flightLleegoService: FlightLleegoService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.oneWay = !this.stateService.flightSearchForm.get("roundtrip").value;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedUsers) {
      this.selectedUsers = changes.selectedUsers.currentValue;
    }
    if (changes.projectData) {
      this.projectData = changes.projectData.currentValue;
    }
  }

  getDuration(segment: Segment) {
    const departureDate = moment(segment.object.departure_date);
    const arrivalDate = moment(segment.object.arrival_date);
    const duration = moment.duration(departureDate.diff(arrivalDate));
    return duration.toISOString().split("PT")[1];
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  getDetailsOrNextFlight(solution: Solution) {
    if (this.oneWay) {
      const segmentsIdArray = this.getAllSegmentRef(
        solution.data.associations[0].journey_references[0]
      );
      this.selectedFlights.push(this.idArrayToSegmentArray(segmentsIdArray));
      this.goDetails(solution);
    } else {
      const segmentsIdArrayGoing = this.getAllSegmentRef(
        solution.data.associations[0].journey_references[0]
      );
      this.selectedFlights[0] =
        this.idArrayToSegmentArray(segmentsIdArrayGoing);
      const segmentsIdArrayReturn = this.getAllSegmentRef(
        solution.data.associations[1].journey_references[0]
      );
      this.selectedFlights[1] = this.idArrayToSegmentArray(
        segmentsIdArrayReturn
      );
      this.goDetails(solution);
    }
  }

  getAllSegmentRef(journeyId: string) {
    const segmentsRef: string[] = [];
    if (journeyId.includes("@")) {
      const segments = journeyId.split("@");
      segments.forEach((segment: string) => {
        segmentsRef.push(segment);
      });
    } else {
      segmentsRef.push(journeyId);
    }
    return segmentsRef;
  }

  goDetails(solution: Solution) {
    this.utilsService.loadingFlights = false;
    const lleegoToken = this.stateService.flightSelectedState.token;
    const navigationExtra: NavigationExtras = {
      state: {
        solutionId: solution.id,
        selectedFlights: this.selectedFlights,
        selectedUsers: this.selectedUsers,
        projectData: this.projectData,
        journeysAssociations: solution.data.associations,
        userUuid: this.userUuid,
        lleegoToken: lleegoToken,
        travelersAge:
          this.stateService.flightTravelers.length &&
          this.travelersAge.length < this.stateService.flightTravelers.length
            ? this.stateService.flightTravelers
            : this.travelersAge,
        isUser: this.isUser,
        multiFamilyRes: this.multiFamilyRes,
        duration: this.selectedDurations,
        isPriceWithinRange: this.isPriceWithinRange(solution.total_price.total)
      },
    };
    this.selectedFlights = [];
    this.utilsService.setBackNavigation(false);
    this.router.navigateByUrl("flight/order", navigationExtra);
  }

  formatDate(value) {
    return moment(value).format("DD/MM/YYYY");
  }

  getTime(value: string) {
    const res = value.split("T")[1].slice(0, 5);
    return res;
  }

  idArrayToSegmentArray(idArray: string[]) {
    const segments: Segment[] = [];
    idArray.forEach((segmentId: string) => {
      segments.push(this.getSegment(segmentId));
    });
    return segments;
  }

  getSegment(id: string): Segment {
    if (id.includes("@")) {
      const splittedIds = id.split("@");
      id = splittedIds[0];
    }
    const segmentFind: Segment =
      this.stateService.flightSelectedState.segments.find(
        (segment: Segment) => segment.id === id
      );
    return segmentFind;
  }

  getLastSegment(id: string): Segment {
    if (id.includes("@")) {
      const splittedIds = id.split("@");
      id = splittedIds[splittedIds.length - 1];
    }
    const segmentFind: Segment =
      this.stateService.flightSelectedState.segments.find(
        (segment: Segment) => segment.id === id
      );
    return segmentFind;
  }

  calcTotalSegmentTime(id: string): string {
    const journey: Journey =
      this.stateService.flightSelectedState.journeys.find(
        (jr: Journey) => jr.id === id
      );
    if (journey) {
      let duration = journey.object.duration;
      const hours = Math.floor(journey.object.duration / 3600);
      duration %= 3600;
      const minutes = Math.floor(duration / 60);
      return `${hours}h ${minutes}m`;
    } else {
      return "";
    }
    /* const segmentArr = this.getSegments(journeyId);
    if (segmentArr.length === 0) {
      return this.getDuration(this.getSegment(journeyId));
    } else {
      let totalTime: string;
      const departDatetime = moment(
        segmentArr[0].object.departure_date,
        moment.ISO_8601
      );
      const returnDatetime = moment(
        segmentArr[segmentArr.length - 1].object.arrival_date,
        moment.ISO_8601
      );
      const duration = moment.duration(returnDatetime.diff(departDatetime));
      const days = duration.days();
      const hours = duration.hours();
      const hoursFormatted = `${hours}h `;
      const minutes = duration.minutes();
      const minutesFormatted = `${minutes}m`;
      totalTime = `${hoursFormatted}${minutesFormatted}`;
      if (days > 0) {
        const daysFormatted = `${days}d `;
        totalTime = `${daysFormatted}${totalTime}`;
      }
      return totalTime;
    } */
  }

  getSegments(journeyId: string) {
    let stops = [];
    if (journeyId.includes("@")) {
      stops = [...journeyId.split("@")];
    }
    const stopSegments: Segment[] = [];
    stops.forEach((journey) => {
      stopSegments.push(this.getSegment(journey));
    });
    return stopSegments;
  }

  getStops(journeyId: string) {
    let stops = [];
    if (journeyId.includes("@")) {
      stops = [...journeyId.split("@")];
      if (stops.length > 1) {
        stops.splice(stops.length - 1, 1);
      }
    }
    const stopSegments: Segment[] = [];
    stops.forEach((journey) => {
      stopSegments.push(this.getSegment(journey));
    });
    const stopSegmentsNames = stopSegments.map(
      (segment) => segment.object.arrival
    );
    return stopSegmentsNames;
  }

  getLogo(value: string) {
    return `assets/img/airlines/small/${value}.png`;
  }

  getMultifamily(solution: Solution) {
    this.stateService.residentCandidates = solution.resident_candidates;
    this.utilsService.loadingFlights = true;
    const multifamilyReq = {
      token: this.stateService.flightSelectedState.token,
      travelID: solution.id,
      journey0: solution.data.associations[0].journey_references[0],
    };
    this.selectedDurations.push(
      this.calcTotalSegmentTime(
        solution.data.associations[0].journey_references[0]
      )
    );
    if (solution.data.associations.length > 1) {
      multifamilyReq["journey1"] =
        solution.data.associations[1].journey_references[0];
      this.selectedDurations.push(
        this.calcTotalSegmentTime(
          solution.data.associations[1].journey_references[0]
        )
      );
    }
    multifamilyReq["userUuid"] = this.userUuid;
    this.subcriptions.push(
      this.flightLleegoService.getMultifamily(multifamilyReq).subscribe(
        (res) => {
          this.multiFamilyRes = res;
          this.getDetailsOrNextFlight(solution);
        },
        (error) => {
          this.getDetailsOrNextFlight(solution);
        }
      )
    );
  }

  isPriceWithinRange(total: string): boolean {
    const totalPrice = parseFloat(total);
  
    if (
      !this.groupFilters ||
      this.groupFilters?.priceMinFlight == null ||
      this.groupFilters?.priceMaxFlight == null
    ) {
      return true;
    }
  
    return (
      totalPrice >= this.groupFilters.priceMinFlight &&
      totalPrice <= this.groupFilters.priceMaxFlight
    );
  }

  ngOnDestroy() {
    this.subcriptions.forEach((s) => s.unsubscribe());
  }
}
