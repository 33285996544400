import { Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NavigationExtras, Router } from "@angular/router";
import {
  AvailabilityPriceRequest,
  CabinType,
  FlightAvailReq,
  FlightAvailRes,
  JourneyRequest,
  JourneysReferences,
  PaxesDistribution,
  Rule,
  SearchCriteria,
  Solution,
  TransportRequest,
} from "@core/models";
import {
  CompanyService,
  FlightLleegoService,
  NgxToastrService,
  StateService,
  TravelService,
  UserService,
  UtilsService,
} from "@core/services";
import {
  NgbActiveModal,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { CustomDateParserFormatter } from "@shared/component/date-parser";
import newAirports from "assets/utils/airports.json";
import airports from "assets/utils/amadeus/airports.json";
import moment from "moment";
import { Subscription } from "rxjs";
import { AppState } from "../../search/search.reducer";
import { DialogTravelersComponent } from "../../trains/dialog-travelers/dialog-travelers.component";
import { default as swal } from "sweetalert2";
import municipalitiesJson from "../../../../../assets/utils/resident-municipalities.json";
import { DialogAssignProjectComponent } from "@shared/component/dialog-assign-project/dialog-assign-project.component";

@Component({
  selector: "app-flight-form",
  templateUrl: "./flight-form.component.html",
  styleUrls: ["./flight-form.component.scss"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class FlightFormComponent implements OnInit {
  modal = false;
  formSearchFlight: UntypedFormGroup;
  oneWay = false;
  isAdmin = false;
  isEmployee = false;
  canUseTRM: boolean;
  userToBook: string;
  flightListResult;
  originSelected: string;
  destinationSelected: string;
  flightAvailRes: FlightAvailRes;
  outwardList: JourneysReferences[] = [];
  returnList: JourneysReferences[] = [];
  transportRequest: TransportRequest;
  companyUuid: string;
  userUuid: string;
  airportList: any[] = [];
  seats = [
    {
      value: "Y",
      viewValue: "Economy",
    },
    {
      value: "W",
      viewValue: "Economic Premium",
    },
    {
      value: "M",
      viewValue: "Economic Standard",
    },
    {
      value: "C",
      viewValue: "Business",
    },
    {
      value: "F",
      viewValue: "First",
    },
  ];

  searchHasFailed = false;
  @Output() firstSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitSelectedTravelers: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitProject = new EventEmitter<any>();
  loadingFlights = false;
  flightAvailReq: FlightAvailReq;

  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };

  user;
  destinationRadius;
  originRadius;
  private subcriptions: Subscription[] = [];
  groupFilters: any;
  munipalitiesList = municipalitiesJson;

  companyUsers: { name: string; lastname: string; uuid: string }[] = [];
  defaultUser: any;
  travelers: any[] = [];
  @Input() travelersSelected: any;
  @Input() projectDataSaved: any;
  public filterControl = new FormControl();
  public filteredUsers: { name: string; lastname: string; uuid: string }[];
  projects: any[];
  selectedProject: any = null;
  loadingProjects: boolean = false; 

  constructor(
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    public utilsService: UtilsService,
    public activeModal: NgbActiveModal,
    public stateService: StateService,
    private store: Store<AppState>,
    private userService: UserService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<FlightFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private flightLleegoService: FlightLleegoService, // @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private companyService: CompanyService,
    private travelService: TravelService
  ) {
    this.subcriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() !== "USER") {
          this.isAdmin = true;
          this.user = s.user;
          if (s.user.type?.toUpperCase() === "EMPLOYEE") {
            this.isEmployee = true;
          }
          this.userToBook = localStorage.getItem("userToBook")
            ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
            : null;
          if (this.userToBook != null) {
            this.userUuid = this.userToBook;
            this.userService.getUserByUuid(this.userUuid).subscribe((user) => {
              if (!this.stateService.flightTravelers || this.stateService.flightTravelers.length <= 0) {
                this.stateService.flightTravelers = [ moment().diff(user.birthdate, "years")];
              }
              this.user = user;
            });
          } else {
            this.userUuid = s.user.uuid;
            if (!this.stateService.flightTravelers || this.stateService.flightTravelers.length <= 0) {
              this.stateService.flightTravelers = [moment().diff(s.user.birthdate, "years")];
            }
          }
          this.canUseTRM = s.user.type
            ? s.user.plan && s.user.plan.permissions.canUseTRM
            : s.user.user.plan && s.user.user.plan.permissions.canUseTRM;
        } else {
          this.userUuid = s.user.uuid;
          this.user = s.user;
          if (!this.stateService.flightTravelers || this.stateService.flightTravelers.length <= 0) {
            this.stateService.flightTravelers = [moment().diff(s.user.birthdate, "years")];
          }
          this.companyUuid = s.user.companyUuid;
        }
      })
    );
    this.modal = data.modal ? data.modal : false;
  }

  ngOnInit(): void {
    this.getCompanyUsers();
    this.utilsService.clearProcessedUuids();

    if(this.projectDataSaved){
      this.projects = this.projectDataSaved.allProjects;
      this.selectedProject = this.projectDataSaved.selectedProject;
    }

    if (this.travelersSelected && this.travelersSelected.length > 0) {
      const travelersSelectedCopy = [...this.travelersSelected];
      travelersSelectedCopy.shift();

      this.travelers = travelersSelectedCopy.map((user) => user.uuid);
      if (!this.stateService.flightTravelers || this.stateService.flightTravelers.length <= 0) {
        this.stateService.flightTravelers = [moment().diff(this.travelersSelected[0].birthdate, "years") , ...travelersSelectedCopy.map(user => ( moment().diff(user.birthdate, "years") ))];
      }
    }

    if (this.stateService.flightSelectedState != null) {
      this.flightListResult = this.stateService.flightSelectedState;
    }
    if (this.stateService.transportRequest != null) {
      this.transportRequest = this.stateService.transportRequest;
    }
    if (this.stateService.destinationSelected != null) {
      this.destinationSelected = this.stateService.destinationSelected;
    }
    if (this.stateService.originSelected != null) {
      this.originSelected = this.stateService.originSelected;
    }
    if (this.stateService.flightSearchForm != null) {
      this.formSearchFlight = this.stateService.flightSearchForm;
      if (this.stateService.flightSearchForm.get("roundtrip").value) {
        this.oneWay = false;
      } else {
        this.oneWay = true;
      }
    } else {
      this.formSearchFlight = this.formBuilder.group({
        origin: [null, [Validators.required]],
        originNearAirports: [false],
        dateIn: [null, [Validators.required]],
        dateOut: [null],
        destination: [null, [Validators.required]],
        destinationNearAirports: [false],
        onlyDirect: [false],
        roundtrip: [
          this.stateService.flightSearchForm
            ? this.stateService.flightSearchForm.get("roundtrip").value
            : !this.oneWay,
          [Validators.required],
        ],
        type: ["Y", [Validators.required]],
        //travelers: [1, [Validators.required]],
       
        travelers: [
          this.stateService.flightTravelers.length > 0? this.stateService.flightTravelers.length : 1,
          [Validators.required],
        ],
        
        sources: [1, [Validators.required]],
        allowBaggage: [false],
        allowCancelation: [false],  
        arrivalSameDay: [false],
      });
    }
    if (!this.travelersSelected) {
      this.updateTravelers();
    }
    this.getSeatFilters();
  }

  swipeDestination() {
    const destination = this.formSearchFlight.get("destination").value;
    const origin = this.formSearchFlight.get("origin").value;
    const originSel = this.originSelected;
    const destinationSel = this.destinationSelected;
    this.originSelected = destinationSel;
    this.destinationSelected = originSel;
    this.stateService.originSelected = this.originSelected;
    this.stateService.destinationSelected = this.destinationSelected;
    // this.getAirports(origin);
    // this.optionSelected('origin', origin);
    // this.getAirports(destination);
    // this.optionSelected('destination', destination);
    this.formSearchFlight.controls.destination.setValue(origin);
    this.formSearchFlight.controls.origin.setValue(destination);
  }

  returnTrip(value: boolean) {
    this.oneWay = value;
    const validator = this.oneWay ? null : [Validators.required];
    this.formSearchFlight.get("roundtrip").setValue(!this.oneWay);
    this.formSearchFlight.get("dateOut").setValidators(validator);
    this.formSearchFlight.get("dateOut").updateValueAndValidity();
  }

  getAirports(value: string) {
    this.airportList = [];
    if (value !== "" && value.length > 2) {
      newAirports["Table1"].forEach((e) => {
        if (
          (e.name &&
            (e.name.includes(value) ||
              e.name
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    ?.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ))) ||
          (e.IATA &&
            (e.IATA.includes(value) ||
              e.IATA?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    ?.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ))) ||
          (e.country &&
            (e.country.includes(value) ||
              e.country
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    ?.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ))) ||
          (e.city &&
            (e.city.includes(value) ||
              e.city
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .includes(
                  value
                    ?.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                )))
        ) {
          this.airportList.push({
            title:
              e.city.trim() +
              "/" +
              e.name.trim() +
              " " +
              "(" +
              e.IATA.trim() +
              ")",
            iata: e.IATA.trim(),
            coordinates: {
              latitude: e.lat.trim(),
              longitude: e.lon.trim(),
            },
          });
        }
      });
      airports.Table1.forEach((e) => {
        if (
          e.AirportName.includes(value) ||
          e.AirportName?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              value
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) ||
          e.IATA.includes(value) ||
          e.IATA?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              value
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) ||
          e.Country.includes(value) ||
          e.Country?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              value
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
        ) {
          // comprobamos que el aeropuerto no se repita en la lista primigenia
          if (
            !this.airportList.some((f) =>
              f.title?.toLowerCase().includes(`${value?.toLowerCase()}`)
            )
          ) {
            this.airportList.push({
              title: e.AirportName + " " + "(" + e.IATA + ")",
              iata: e.IATA.trim(),
              coordinates: {
                latitude: e.Latitude,
                longitude: e.Longitude,
              },
            });
          }
        }
      });
    } else {
      this.airportList = [];
    }
  }

  optionSelected(field: string, value: string) {
    if (this.airportList.length > 0) {
      if (field === "origin") {
        this.originSelected = this.airportList.find(
          (a) => a.title === value
        ).iata;
        this.stateService.originSelected = this.originSelected;
        this.airportList = [];
      }
      if (field === "destination") {
        this.destinationSelected = this.airportList.find(
          (a) => a.title === value
        ).iata;
        this.stateService.destinationSelected = this.destinationSelected;
        this.airportList = [];
      }
    }
  }

  getSeatFilters() {
    this.subcriptions.push(
      this.userService.getGroupUser(this.userUuid).subscribe((g) => {
        this.groupFilters = g.filters;
        if (g.filters.sitType) {
          this.pushSeatType(g.filters.sitType);
        }
      })
    );
  }

  getTravelerPlaceholder(): string {
    let placeholder = "";
    this.translate
      .get(["flight.form-flight.passengers", "flight.form-flight.passenger"])
      .subscribe((result) => {
        if (this.stateService.flightTravelers.length > 0) {
          placeholder =
            this.stateService.flightTravelers.length.toString() +
            " " +
            (this.stateService.flightTravelers.length > 1
              ? result["flight.form-flight.passengers"]
              : result["flight.form-flight.passenger"]);
        }
      });
    return placeholder;
  }

  private pushSeatType(type) {
    this.seats = [];
    switch (type) {
      case "ECONOMY":
        this.seats.push({
          value: "Y",
          viewValue: "Economy",
        });
        break;
      case "PREMIUM_ECONOMY":
        this.seats.push(
          {
            value: "Y",
            viewValue: "Economy",
          },
          {
            value: "M",
            viewValue: "Economic Standard",
          },
          {
            value: "W",
            viewValue: "Economic Premium",
          }
        );
        break;
      case "BUSINESS":
        this.seats.push(
          {
            value: "Y",
            viewValue: "Economy",
          },
          {
            value: "M",
            viewValue: "Economic Standard",
          },
          {
            value: "W",
            viewValue: "Economic Premium",
          },
          {
            value: "C",
            viewValue: "Business",
          }
        );
        break;
      case "FIRST":
        this.seats.push(
          {
            value: "Y",
            viewValue: "Economy",
          },
          {
            value: "W",
            viewValue: "Economic Premium",
          },
          {
            value: "M",
            viewValue: "Economic Standard",
          },
          {
            value: "C",
            viewValue: "Business",
          },
          {
            value: "F",
            viewValue: "First",
          }
        );
        break;
      default:
        this.seats.push(
          {
            value: "Y",
            viewValue: "Economy",
          },
          {
            value: "W",
            viewValue: "Economic Premium",
          },
          {
            value: "M",
            viewValue: "Economic Standard",
          },
          {
            value: "C",
            viewValue: "Business",
          },
          {
            value: "F",
            viewValue: "First",
          }
        );
        break;
    }
  }

  toggleOneWay() {
    this.oneWay = !this.oneWay;
  }

  getOutMinDate() {
    const minDateWithAdvance = this.getInMinDate();
    return this.utilsService.getOutDateSame(
      minDateWithAdvance,
      this.formSearchFlight.controls["dateIn"].value
    );
  }

  getInMinDate() {
    const daysInAdvance = this.isAdmin ? 0 : (this.groupFilters?.flightDaysInAdvance || 0);
    const minDate = moment().add(daysInAdvance, 'days');
    return {
      year: minDate.year(),
      month: minDate.month() + 1,
      day: minDate.date(),
    };
  }

  getSeatSelected() {
    const seat = this.seats.find(
      (e) => e.value === this.formSearchFlight.controls["type"].value
    );
    return seat.viewValue;
  }

  selectSeat(seat: { viewValue: string; value: string }) {
    this.formSearchFlight.controls["type"].setValue(seat.value);
  }

  openTravelersModal() {
    const travelerList = JSON.parse(
      JSON.stringify(
        this.stateService.flightTravelers
      )
    );
    const dialogTravelers = this.dialog.open(DialogTravelersComponent, {
      panelClass: "new-dialog",
      data: {
        travelerList: travelerList,
      },
    });
    dialogTravelers.afterClosed().subscribe((result) => {
      if (result != null) {
        this.stateService.flightTravelers = result;
        this.formSearchFlight.controls["travelers"].setValue(result);
      }
      this.updateTravelers();
    });
  }

  changeTravellers(operation) {
    if (operation === 'add') {
      this.travelers.push('')
      this.stateService.flightTravelers.push('')
      this.formSearchFlight.controls["travelers"].setValue(this.stateService.flightTravelers)
    } else if (operation === 'remove') {
      this.stateService.flightTravelers.pop()
      this.travelers.pop()
      this.formSearchFlight.controls["travelers"].setValue(this.stateService.flightTravelers)
    }
  }

  searchFlights() {
    // this.travelUtilsService.removeTravelLocalStorage();
    // this.openTravelModal();

    this.stateService.flightSearchForm = this.formSearchFlight;
    this.flightAvailRes = null;
    this.outwardList = [];
    this.returnList = [];
    this.searchHasFailed = false;
    this.stateService.flightsGoing = this.outwardList;
    this.stateService.flightsReturn = this.returnList;
    this.stateService.flightSelectedState = null;
    this.loadingFlights = true;
    this.utilsService.loadingFlights = true;
    const journeys: JourneyRequest[] = [];
    const auxUsers: any[] = this.travelers.map((t) => this.companyUsers.find((u) => u.uuid === t))
    const auxAges = auxUsers.map(t =>  moment().diff(t.birthdate, "years"))
    auxAges.push(moment().diff(this.defaultUser.birthdate, "years"));
    this.stateService.flightTravelers = auxAges;
    const paxes_distribution: PaxesDistribution = {
      passengers_ages: auxAges,
    };
    const formResult = this.formSearchFlight.getRawValue();
    const departDateMonth =
      formResult.dateIn.month < 10
        ? `0${formResult.dateIn.month}`
        : formResult.dateIn.month;
    const departDateDay =
      formResult.dateIn.day < 10
        ? `0${formResult.dateIn.day}`
        : formResult.dateIn.day;
    const departDate = `${formResult.dateIn.year}-${departDateMonth}-${departDateDay}`;
    this.stateService.setFlightSearch(this.formSearchFlight);
    const dateDepartureAux = departDate;
    try {
      const origin = this.originSelected;
      const destination = this.destinationSelected;
      let goSchedule = [];
      if (
        this.stateService.minTimeDeparture !== 0 ||
        this.stateService.maxTimeDeparture !== 24
      ) {
        goSchedule = [
          this.stateService.minTimeDeparture.toString() + ":00",
          this.stateService.maxTimeDeparture.toString() + ":00",
        ];
        if (this.stateService.maxTimeDeparture === 24) {
          goSchedule[1] = "23:59";
        }
      }
      const goFourney: JourneyRequest = {
        date: dateDepartureAux,
        origin: origin,
        destination: destination,
        schedules: goSchedule,
        arrival_same_day:
          this.formSearchFlight.controls["arrivalSameDay"].value,
      };
      if (this.formSearchFlight.value.originNearAirports === true) {
        goFourney.origin_radius = this.originRadius;
      }
      if (this.formSearchFlight.value.destinationNearAirports === true) {
        goFourney.destination_radius = this.destinationRadius;
      }
      journeys.push(goFourney);

      if (
        this.formSearchFlight.controls["roundtrip"].value === true &&
        this.formSearchFlight.controls["dateOut"].value
      ) {
        const returnDateMonth =
          formResult.dateOut.month < 10
            ? `0${formResult.dateOut.month}`
            : formResult.dateOut.month;
        const returnDateDay =
          formResult.dateOut.day < 10
            ? `0${formResult.dateOut.day}`
            : formResult.dateOut.day;
        const returnDate = `${formResult.dateOut.year}-${returnDateMonth}-${returnDateDay}`;
        const dateReturnAux = returnDate;
        let returnSchedule = [];
        if (
          this.stateService.minTimeReturnDeparture !== 0 ||
          this.stateService.maxTimeReturnDeparture !== 24
        ) {
          returnSchedule = [
            this.stateService.minTimeReturnDeparture.toString() + ":00",
            this.stateService.maxTimeReturnDeparture.toString() + ":00",
          ];
          if (this.stateService.maxTimeReturnDeparture === 24) {
            returnSchedule[1] = "23:59";
          }
        }
        const retJourney: JourneyRequest = {
          date: dateReturnAux,
          origin: destination,
          destination: origin,
          schedules: returnSchedule,
          arrival_same_day:
            this.formSearchFlight.controls["arrivalSameDay"].value,
        };
        if (this.formSearchFlight.value.originNearAirports === true) {
          retJourney.origin_radius = this.originRadius;
        }
        if (this.formSearchFlight.value.destinationNearAirports === true) {
          retJourney.destination_radius = this.destinationRadius;
        }
        journeys.push(retJourney);
      }

      const cabinType: CabinType = this.formSearchFlight.controls["type"].value;

      this.transportRequest = {
        paxes_distribution: paxes_distribution,
        journeys: journeys,
        direct: this.formSearchFlight.controls["onlyDirect"].value,
        cabin: cabinType,
        refundable: this.formSearchFlight.controls["allowCancelation"].value,
      };

      this.stateService.transportRequest = this.transportRequest;

      const rule: Rule = {
        combined: true,
        duplicated: false,
        show_data: true,
        show_partial: true,
      };
      const searchCriteria: SearchCriteria = {
        travel: this.stateService.transportRequest,
        rule: rule,
        // connectors: ["BA"],
      };
      const availabilityPriceRequest: AvailabilityPriceRequest = {
        criterias: [searchCriteria],
      };
      if (this.userUuid) {
        this.flightAvailReq = {
          query: availabilityPriceRequest,
          userUuid: this.userUuid,
        };
      } else {
        this.flightAvailReq = { query: availabilityPriceRequest };
      }
      this.stateService.oneWay = this.oneWay;
      this.subcriptions.push(
        this.flightLleegoService.postFlightAvail(this.flightAvailReq).subscribe(
          (res) => {
            this.flightAvailRes = res;
            this.stateService.flightSelectedState = this.flightAvailRes;
            if (
              this.flightAvailRes &&
              this.flightAvailRes.solutions.length > 0 &&
              res !== null
            ) {
              this.stateService.orderByLleego = this.flightAvailRes.solutions;
              this.flightAvailRes.solutions.forEach((solution: Solution) => {
                this.getFlights(solution, 0);
              });
              if (journeys.length === 2) {
                this.flightAvailRes.solutions.forEach((solution: Solution) => {
                  this.getFlights(solution, 1);
                });
              }

              this.loadingFlights = false;
              this.utilsService.loadingFlights = false;

              let selectedUuids = this.travelers.slice();
              if (this.userUuid) {
                selectedUuids.unshift(this.userUuid);
              }
              const projectData = {
                selectedProject: this.selectedProject,
                allProjects: this.projects
              };

              this.firstSearch.emit();
              this.emitSelectedTravelers.emit(selectedUuids);
              this.emitProject.emit(projectData);
            } else {
              this.searchHasFailed = true;
              this.subcriptions.push(
                this.translate
                  .get(["flight.form-flight.not-found"])
                  .subscribe((result) => {
                    this.presentToast(
                      "Error",
                      result["flight.form-flight.not-found"]
                    );

                    this.loadingFlights = false;
                    this.utilsService.loadingFlights = false;
                  })
              );
            }
            if (this.modal) {
              this.dialogRef.close({ result: this.flightAvailRes });
            }
          },
          (err) => {
            this.searchHasFailed = true;
            this.loadingFlights = false;
            this.utilsService.loadingFlights = false;
            this.subcriptions.push(
              this.translate
                .get(["flight.form-flight.internal-error"])
                .subscribe((result) => {
                  this.presentToast(
                    "Error",
                    result["flight.form-flight.internal-error"]
                  );
                  this.loadingFlights = false;
                  this.utilsService.loadingFlights = false;
                })
            );
            this.loadingFlights = false;
            this.utilsService.loadingFlights = false;
          }
        )
      );
    } catch (error) {
      this.subcriptions.push(
        this.translate
          .get(["flight.form-flight.search-criteria-error"])
          .subscribe((result) => {
            this.presentToast(
              "Error",
              result["flight.form-flight.search-criteria-error"]
            );
            this.loadingFlights = false;
            this.utilsService.loadingFlights = false;
          })
      );

      if (this.modal) {
        this.dialog.closeAll();
      }
      this.loadingFlights = false;
      this.utilsService.loadingFlights = false;
    }
  }

  getFlights(solution: Solution, index: number) {
    let segmentsRef: string[] = [];
    const solutionId = solution.id;

    solution.data.associations[index].journey_references.forEach(
      (journeyId: string) => {
        segmentsRef = this.getSegmentRef(journeyId);
        if (index === 0) {
          this.stateService.flightsGoing.push({
            solution_id: solutionId,
            segments_ref: segmentsRef,
            price: solution.total_price.total,
          });
        } else if (
          index === 1
          // &&
          // this.fS["roundtrip"].value === true
        ) {
          this.stateService.flightsReturn.push({
            solution_id: solution.id,
            segments_ref: segmentsRef,
            price: solution.total_price.total,
          });
        }
        segmentsRef = [];
      }
    );
  }

  getSegmentRef(journeyId: string) {
    const segmentsRef: string[] = [];
    if (journeyId.includes("@")) {
      const segments = journeyId.split("@");
      segments.forEach((segment: string) => {
        segmentsRef.push(segment);
      });
    } else {
      segmentsRef.push(journeyId);
    }
    return segmentsRef;
  }

  goToCreateCustomFlight() {
    this.formSearchFlight.value["oneWay"] = this.oneWay;
    const navigationExtras: NavigationExtras = {
      state: {
        canBookHotel: this.groupFilters.requestBooked,
        canRequestCustomFlight: this.groupFilters.requestCustomFlight,
        canRequestCustomTrain: this.groupFilters.requestCustomTrain,
        canRequestCustomCar: this.groupFilters.requestCar,
        canRequestAirbnb: this.groupFilters.lodgingType === "all" || "room",
        userUuid: this.userToBook ? this.userToBook : this.userUuid,
        companyUuid: this.companyUuid,
        userImageBase64: this.user.image,
        isFromSearchFail: true,
        searchFailData: this.formSearchFlight.value,
      },
    };
    this.router.navigate(["/custom-services/flight"], navigationExtras);
  }

  async presentToast(header: string, msg: string) {
    this.ngxToastrService.typeInfo(header, msg);
  }

  checkIfResident() {
    let text = "";
    if (this.user.resident && this.user.resident.toString() !== "none") {
      if (
        this.munipalitiesList.find((m) => m.municipality === this.user.city && this.user.resident === m.community)
      ) {
        text = "flight.resident-gonna-applied";
      } else {
        text = "flight.resident-city-warning";
      }
    } else {
      text = "flight.resident-warning";
    }
    this.translate.get([text, "common.go-profile"]).subscribe((result) => {
      swal.fire({
        icon: "warning",
        title: "",
        text: result[text],
        showCancelButton: false,
        showConfirmButton: text !== "flight.resident-gonna-applied",
        showCloseButton: true,
        backdrop: true,
        confirmButtonText: result["common.go-profile"]
      }).then((action) => {
        if (action.value) {
          this.router.navigate([this.isAdmin ? "/user/edit-user/" + this.user.uuid : "/user/user-profile"]);
        }
      });
    });
  }

  getCompanyUsers() {
    if (!this.companyUuid) {
      this.userService.getUser(this.userUuid).subscribe(
        (res) => {
          this.companyUuid = res.companyUuid;
          this.fetchCompanyUsers();
        },
        (err) => {}
      );
    } else {
      this.fetchCompanyUsers();
    }
  }

  fetchCompanyUsers() {
    if (this.companyUuid) {
      this.companyService.getCompanyUsers(this.companyUuid).subscribe(
        (res) => {
          this.companyUsers = res;
          
          this.filteredUsers = this.companyUsers;
          this.setInitialTraveler();
        },
        (err) => {}
      );
    }
  }

  setInitialTraveler() {
    const user = this.companyUsers.find((user) => user.uuid === this.userUuid);
    if (user) {
      this.defaultUser = user;
    }
    if (!this.travelersSelected) {
      this.updateTravelers();
    }
  }


  updateTravelers() {
    const user = this.companyUsers.find((user) => user.uuid === this.userUuid);
    if (user) {
      this.defaultUser = user;
    }
    for (let i = this.travelers.length; i < this.stateService.flightTravelers.length - 1; i++) {
      if (!this.travelers[i]) {
        this.travelers[i] = "";
      }
    }
    if (this.travelers.length > this.stateService.flightTravelers.length - 1) {
      this.travelers = this.travelers.slice(0, this.stateService.flightTravelers.length - 1);
    }
    setTimeout(() => {
      this.travelers = [...this.travelers];
    });
  }
  
  

  applyFilter(filterValue: string) {
    this.filteredUsers = this.companyUsers.filter(user =>
      `${user.name} ${user.lastname}`.toLowerCase().includes(filterValue.toLowerCase())
    );
  }
  
  onPanelClose() {
    this.filterControl.setValue('');
    this.filteredUsers = this.companyUsers;
  }
  
  onOpenedChange(event: boolean, input: HTMLInputElement) {
    if (event) {
      input.focus();
    }
  }

  allTravelersSelected(): boolean {
    return this.travelers.every(traveler => traveler !== null && traveler !== undefined && traveler !== '');
  }
  
  trackByIndex(index: number) {
    return index;
  }

  onDateChange() {
    if(!this.oneWay){
      if (this.formSearchFlight.controls['dateIn'].value && this.formSearchFlight.controls['dateOut'].value) {
        const checkInParam = this.transformDateToISO(this.formSearchFlight.controls['dateIn'].value);
        const checkOutParam = this.transformDateToISO(this.formSearchFlight.controls['dateOut'].value);
        this.loadProjects(checkInParam, checkOutParam);
      }
    }else{
      if (this.formSearchFlight.controls['dateIn'].value){
        const checkInParam = this.transformDateToISO(this.formSearchFlight.controls['dateIn'].value);
        this.loadProjects(checkInParam);
      }
    }
  }

  transformDateToISO(date: { year: number, month: number, day: number }): string {
    const { year, month, day } = date;
    const jsDate = new Date(Date.UTC(year, month - 1, day));
    const datePart = jsDate.toISOString().split('T')[0];
    return `${datePart}T00:00:00.000+00:00`;
  }


  loadProjects(checkIn: any, checkOut?: any){
    this.loadingProjects = true;
  
    this.travelService.getTravelsWithParams({ companyUuid: this.companyUuid })
      .subscribe(resp => {
        this.projects = [];
        const defaultProject = {
          title: "--------No-------- ",
          uuid: "none",
        };
  
        this.projects.push(defaultProject);
        this.projects = this.projects.concat(resp.docs);
        
        this.projects = this.projects.filter((project) => {
          if (project.initDate && project.endDate) {
            const projectDatesAreCorrect = this.checkDatesAreCorrect(
              checkIn,
              checkOut,
              moment(project.initDate),
              moment(project.endDate)
            );
            return projectDatesAreCorrect;
          } else {
            return true;
          }
        });
        
        this.selectedProject = this.projects.find(project => project.uuid === 'none');
        this.loadingProjects = false;
      }, error => {
        this.loadingProjects = false;
      });
  }

  checkDatesAreCorrect(
    bookingInitDate,
    bookingEndDate,
    projectInitDate,
    projectEndDate
  ) {
    let projectDatesAreCorrect = true;
    if (bookingInitDate != null) {
      const momentInitDate = moment(bookingInitDate);
      projectDatesAreCorrect = moment(projectInitDate).isSameOrBefore(
        momentInitDate,
        "day"
      );
      if (bookingEndDate != null && projectDatesAreCorrect) {
        const momentEndDate = moment(bookingEndDate);
        projectDatesAreCorrect = moment(projectEndDate).isSameOrAfter(
          momentEndDate,
          "day"
        );
      } else {
        if (bookingEndDate == null && projectDatesAreCorrect) {
          projectDatesAreCorrect = moment(projectEndDate).isSameOrAfter(
            momentInitDate,
            "day"
          );
        }
      }
    }
    return projectDatesAreCorrect;
  }

  openTravelDialog(){
    const modalRef = this.dialog.open(DialogAssignProjectComponent, {
      data: {
        create: true,
        isOnlyCreate: true,
        userUuid: this.userUuid,
        companyUuid: this.companyUuid,
      },
      panelClass: "new-dialog",
    });

    modalRef.afterClosed().subscribe((result) => {
      if (result != null) {
        if (this.userUuid !== null) {
          result.travelData["userUuid"] = this.userUuid;
        }

        if (this.companyUuid !== null) {
          result.travelData["companyUuid"] = this.companyUuid;
        }
        if (
          result.travelData["endDate"] === "" &&
          result.travelData["initDate"] === ""
        ) {
          delete result.travelData["endDate"];
          delete result.travelData["initDate"];
        }
        this.subcriptions.push(
          this.travelService.postTravel(result.travelData).subscribe(
            (res) => {

              let dateIn = this.formSearchFlight.controls['dateIn'].value;
              let dateOut = this.formSearchFlight.controls['dateOut'].value || null;

              const checkInFormat = moment({
                year: dateIn.year,
                month: dateIn.month - 1,
                day: dateIn.day
              });

              let checkOutFormat = null;
              if (dateOut) {
                checkOutFormat = moment({
                  year: dateOut.year,
                  month: dateOut.month - 1,
                  day: dateOut.day
                });
              }
              
              const projectInitDate = moment(res.initDate);
              const projectEndDate = moment(res.endDate);

              if (!checkOutFormat) {
                const isWithinRange = checkInFormat.isBetween(projectInitDate, projectEndDate, null, '[]');
                if (isWithinRange) {
                  this.projects.push(res);
                  this.selectedProject = res;
                }
              } else {
                const isWithinRange = checkInFormat.isBetween(projectInitDate, projectEndDate, null, '[]') &&
                                      checkOutFormat.isBetween(projectInitDate, projectEndDate, null, '[]');
                if (isWithinRange) {
                  this.projects.push(res);
                  this.selectedProject = res;
                }
              }

              this.subcriptions.push(
                this.translate
                  .get(`project.my-projects.created`)
                  .subscribe((resp) => {
                    this.ngxToastrService.typeSuccess(null, resp);
                    setTimeout(() => {
                    }, 3000);
                  })
              );
            },
            (err) => {
              this.subcriptions.push(
                this.translate.get("common.error").subscribe((resp) => {
                  this.ngxToastrService.typeInfo(resp, err.error.message);
                })
              );
            }
          )
        );
      }
    });
  }

}
