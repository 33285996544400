

<!-- EMPTY STATE -->
<div class="text-center" *ngIf="bills.length <= 0">
  <img class="max-size-custom" src="assets/img/gallery/flights-not-found.svg" alt="No hay archivos" />
  <h3 class="mt-4">
    <b>{{ "company.bills.no-bills" | translate }}</b>
  </h3>
</div>
<div *ngIf="bills.length > 0">
  <form [formGroup]="form">
    <div class="container-customize">
      <div class="row">
        <div class="filters">
          <!-- FILTER -->
          <div>
            <input (input)="setPage()" id="reference" formControlName="reference" class="form-control form-control--squared form-control--small search-input"
              placeholder="{{ 'company.bills.filters.reference' | translate }}" />
          </div>
          <div>
            <input class="form-control form-control--squared form-control--small filter-dates" readonly
              placeholder="{{ 'project.my-projects.initDate' | translate }}" name="date" id="date" ngbDatepicker
              [ngClass]="!date.value ? 'filter-dates-placeholder' : ''"
              #date="ngbDatepicker" #dateInput (dateSelect)="onDateSelection($event)" (click)="date.toggle()" />
          </div>
          <div class="text-center align-middle" id="filters-buttons">
            <button type="button" [ngClass]="canClean() ? 'clear-btn canClean' : 'clear-btn'"
              (click)="cleanFilters()">
              {{ "common.clean" | translate }}
              <img src="{{
                !canClean() ? 'assets/img/svg/grey-cross.svg' : 'assets/img/svg/red-cross.svg'
              }}" />
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-3" *ngFor="
            let bill of bills
              | filter
                : ['date', 'reference']
                : [f.date.value, f.reference.value]
                : ['dateStart', 'rg']
              | slice: lowValue:highValue;
            let i = index
          ">
          <div class="bill-wrapper">
            <div class="bill-container">
              <div class="img-container">
                <img src="assets/img/svg/bill-2.svg" alt="bill-default-image" />
              </div>
              <div class="text-container">
                <h4>
                  {{ "company.bills.filters.reference" | translate }}:
                  {{ bill.reference }}
                </h4>
                <h6>
                  {{ "company.bills.filters.date" | translate }}:
                  {{ transformDate(bill.date) }}
                </h6>
              </div>
              <div class="kebab-container">
                <div ngbDropdown>
                  <img ngbDropdownToggle src="assets/img/svg/black-kebab.svg" style="cursor: pointer" />
                  <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownBasic1">
                    <p class="text-vyoo-primary" (click)="downloadBill(bill)">
                      <img width="30px" src="assets/img/svg/bill-black.svg"
                        title="{{ 'company.bills.see-bills' | translate }}" alt="seeBill"
                        class="back-cursor cursor-pointer"
                        style="width: 1.5rem;" />
                      {{ "company.bills.see-bills" | translate }}
                    </p>
                    <p class="text-vyoo-primary" *ngIf="businessEntityType === 'EMPLOYEE'" (click)="removeBill(bill)">
                      <img width="30px" src="assets/img/svg/trash-black.svg"
                        title="{{ 'company.bills.delete-bill' | translate }}" alt="deleteBill"
                        class="back-cursor cursor-pointer"
                        style="width: 1.5rem;" />
                      {{ "company.bills.delete-bill" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 my-5 d-flex justify-content-center" *ngIf="
          (
            bills
            | filter
              : ['date', 'reference']
              : [f.date.value, f.reference.value]
              : ['dateStart', 'rg']
          ).length === 0
        ">
        <div class="icon-bcg icon-bcg--large">
          <img src="assets/img/svg/empty-search.svg" alt="emptySearch" class="empty-search" />
        </div>
        <h2 class="ml-3 my-auto">{{ "search.not-data" | translate }}</h2>
      </div>

      <div *ngIf="
          (
            bills
            | filter
              : ['date', 'reference']
              : [f.date.value, f.reference.value]
              : ['dateStart', 'rg']
          ).length > 0
        " class="d-flex justify-content-center mt-2 mb-2 col-12">
        <mat-paginator #paginator [length]="
            (
              bills
              | filter
                : ['date', 'reference']
                : [f.date.value, f.reference.value]
                : ['dateStart', 'rg']
            ).length
          " [pageSize]="8" (page)="getPaginatorData($event)" showFirstLastButtons [hidePageSize]="true"
          style="border-radius: 15px">
        </mat-paginator>
      </div>
    </div>
  </form>
</div>