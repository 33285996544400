<div class="contenListFlights">
  <ng-container
    *ngIf="
      this.stateService.flightSelectedState.solutions &&
      this.stateService.flightSelectedState.solutions.length > 0
    "
  >
    <div
      class="card-content"
      *ngFor="
        let solution of this.stateService.flightSelectedState.solutions
          | slice: lowValue:highValue;
        let i = index
      "
    >
      <div class="associations" [ngClass]="{ 'associations-oneWay': oneWay }">
        <div
          *ngFor="let association of solution.data.associations; let j = index"
          class="association-container"
        >
          <div class="left">
            <div class="rowh">
              <div class="cardContentContainer">
                <div class="journey">
                  <img
                    class="airlineLogo"
                    [src]="
                      getLogo(
                        getSegment(association.journey_references[0]).object
                          .marketing_company
                      )
                    "
                    alt="logo"
                    width="72px"
                  />
                  <div class="origin">
                    <span class="colored">{{
                      getSegment(association.journey_references[0]).object
                        .departure
                    }}</span>
                    <span class="bold"
                      >{{
                        getTime(
                          getSegment(association.journey_references[0]).object
                            .departure_date
                        )
                      }}
                    </span>
                    <span>{{
                      formatDate(
                        getSegment(association.journey_references[0]).object
                          .departure_date
                      )
                    }}</span>
                  </div>
                  <div class="iconFlight">
                    <img src="assets/img/svg/flight-takeoff.svg" alt="" />
                  </div>
                  <div class="linewrap">
                    <p
                      *ngIf="
                        getStops(association.journey_references[0]).length > 0;
                        else elseBlock
                      "
                    >
                      {{ getStops(association.journey_references[0]).length }}
                      {{
                        (getStops(association.journey_references[0]).length > 1
                          ? "flight.detail-flight.stops"
                          : "flight.detail-flight.stop"
                        ) | translate
                      }}
                      {{
                        getStops(association.journey_references[0]).join(", ")
                      }}
                    </p>
                    <ng-template #elseBlock>
                      <p>
                        {{ "flight.result-flight.direct-flight" | translate }}
                      </p>
                    </ng-template>
                    <div class="linesingle"></div>
                    <span>
                      {{
                        calcTotalSegmentTime(association.journey_references[0])
                      }}
                    </span>
                  </div>
                  <div class="iconFlight">
                    <img src="assets/img/svg/flight-landing.svg" alt="" />
                  </div>
                  <div class="destination">
                    <span class="colored">{{
                      getLastSegment(association.journey_references[0]).object
                        .arrival
                    }}</span>
                    <span class="bold"
                      >{{
                        getTime(
                          getLastSegment(association.journey_references[0])
                            .object.arrival_date
                        )
                      }}
                    </span>
                    <span>{{
                      formatDate(
                        getLastSegment(association.journey_references[0]).object
                          .arrival_date
                      )
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- precio y boton-->
        <div class="colPrice">
          <div>
            <div>
              <h6>
                {{
                  (oneWay
                    ? "flight.result-flight.oneway-price"
                    : "flight.result-flight.return-price"
                  ) | translate
                }}
              </h6>
              <div style="margin: 0.5rem 0 !important;" *ngIf="!isPriceWithinRange(solution.total_price.total) || groupFilters.onlyFlightAuthorization">
                <span class="authorization-required">
                  {{ "authorization.required-auth" | translate }}
                </span>
              </div>
              <h6 class="my-auto price">
                {{ solution.total_price.total | price }}€
              </h6>
            </div>
            <div id="right-button-container">
              <button (click)="getMultifamily(solution)" class="custom-btn">
                {{ ((!isPriceWithinRange(solution.total_price.total) || groupFilters.onlyFlightAuthorization) && isUser) ? ("flight.form-flight.request-flight" | translate) : ("flight.result-flight.book-flight" | translate) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mat-paginator
      class="d-flex justify-content-center"
      [length]="this.stateService.flightSelectedState.solutions.length"
      [pageSize]="10"
      (page)="getPaginatorData($event)"
      [pageSizeOptions]="[5, 10, 25, 100]"
      showFirstLastButtons
      [hidePageSize]="true"
      style="border-radius: 15px"
    >
    </mat-paginator>
  </ng-container>
</div>
