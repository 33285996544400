<div class="modal-header-user">
  <div class="top-buttons">
    <button
      type="button"
      class="close"
      aria-label="Close"
      *ngIf="!firstUser"
      (click)="goBack()"
    >
      <img src="assets/img/svg/arrow-back.svg" alt="">
    </button>
    <div class="more-btn-position" ngbDropdown style="cursor: pointer">
      <button
        type="button"
        class="btn-more"
        ngbDropdownToggle
        aria-labelledby="dropdownBasic1"
      >
        {{ "user.form.choose-file" | translate }}
        <img src="../../../../../assets/img/svg/dropdown.svg" />
      </button>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <label
          ngbDropdownItem
          for="file-upload"
          class="btn-files-upload dashed label-image-change"
        >
          <p class="profile-edit-logo">
            {{ "user.form.change-image" | translate }}
            <input
              type="file"
              (change)="onImageChange($event)"
              id="file-upload"
              class="input-edit-photo"
            />
          </p>
        </label>
        <button (click)="removeImage()" ngbDropdownItem>
          {{ "user.form.delete-image" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="profile">
    <div class="photo-profile">
      <div
        class="fileInputProfileWrap"
        *ngIf="form"
        [ngClass]="{
          'invalid-image': form.controls['image'].hasError('required')
        }"
      >
        <div class="img-space">
          <ng-container *ngIf="isImageSaved; else elseTemplate">
            <div class="user-image">
              <label
                ngbDropdownItem
                for="file-upload"
                class="btn-files-upload dashed label-image-change-general"
              >
                <input
                  type="file"
                  (change)="onImageChange($event)"
                  id="file-upload"
                  class="input-edit-photo"
                />
                <img [src]="cardImageBase64" />
              </label>
            </div>
          </ng-container>
          <ng-template #elseTemplate>
            <div class="user-image">
              <div class="profile-initials text-white" *ngIf="user">
                {{ user?.name[0] | uppercase }}{{ user?.lastname[0] | uppercase }}
              </div>
              <div class="profile-initials text-white" *ngIf="!user">VY</div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="name-departament">
      <p class="profile-tittle text-bold-500">
        {{ user?.name }} {{ user?.lastname }}
      </p>
      <p class="profile-departament small">{{ group?.name | titlecase }}</p>
    </div>
  </div>
</div>

<div *ngIf="form" class="modal-body-user p-3">
  <form
    *ngIf="form"
    [formGroup]="form"
    [ngClass]="{ 'loading-background': loading }"
    (ngSubmit)="form.valid && !loading ? onSubmit() : (isInvalid = true)"
  >
    <div class="row-user" *ngIf="groups.length > 0">
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <mat-slide-toggle
            class="section"
            (change)="changeManager($event)"
            formControlName="isManager"
            id="isManager"
            *ngIf="fromCompanyDetails && !isUser"
          >
            {{ "user.form.is-manager" | translate }}
          </mat-slide-toggle>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <mat-slide-toggle
            class="section"
            formControlName="isGroupAdmin"
            id="isGroupAdmin"
            *ngIf="(isAdmin || canGroupAdmin) && !isUser && !isManager"
            (change)="changeGroupAdmin($event)"
          >
            {{ "user.form.is-group-manager" | translate }}
          </mat-slide-toggle>
        </div>
      </div>
    </div>
    <h5>{{ "user.notifications.title" | translate }}</h5>
    <form formGroupName="notifications" class="row-user">
      <div class="col-lg-4 col-md-4 col-xs-12" *ngIf="isManager">
        <div class="form-group-user">
          <mat-slide-toggle
            class="section"
            formControlName="company"
            id="company"
            (change)="changeNotifications($event)"
          >
            {{ "user.notifications.company" | translate }}
          </mat-slide-toggle>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12" *ngIf="f.isGroupAdmin.value">
        <div class="form-group-user">
          <mat-slide-toggle
            class="section"
            formControlName="group"
            id="group"
            (change)="changeNotifications($event)"
          >
            {{ "user.notifications.group" | translate }}
          </mat-slide-toggle>
        </div>
      </div>
      <div
        class="col-lg-4 col-md-4 col-xs-12"
        *ngIf="
          (!isManager && !f.isGroupAdmin.value) ||
          (isManager && !f.notifications.controls.company.value) ||
          (f.isGroupAdmin.value && !f.notifications.controls.group.value)
        "
      >
        <div class="form-group-user">
          <mat-slide-toggle
            class="section"
            formControlName="user"
            id="user"
            (change)="changeNotifications($event)"
          >
            {{ "user.notifications.user" | translate }}
          </mat-slide-toggle>
        </div>
      </div>
    </form>
    <h5>{{ "common.required-field" | translate }}</h5>

    <div class="row-user">
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user" *ngIf="groups.length > 0 && !isUser">
          <label class="tittle-form-control-user" for="group"
            >{{ "user.form.group" | translate }}*</label
          >
          <select
            [ngClass]="{
              'input-error': !form.controls.group.valid && isInvalid
            }"
            formControlName="group"
            [compareWith]="compareFn"
            type="text"
            id="group"
            class="form-control-user form-control select-input ng-pristine ng-touched"
          >
            <option
              *ngFor="let g of groups; trackBy: utilsService.getUuidTracking"
              [value]="g.uuid"
            >
              {{ g.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="name"
            >{{ "common.name" | translate }}*</label
          >
          <input
            [ngClass]="{
              'input-error': !form.controls.name.valid && isInvalid
            }"
            [readOnly]="loading"
            formControlName="name"
            type="text"
            id="name"
            class="form-control-user form-control"
          />
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="lastname"
            >{{ "user.form.surname" | translate }}*</label
          >
          <input
            [ngClass]="{
              'input-error': !form.controls.lastname.valid && isInvalid
            }"
            [readOnly]="loading"
            formControlName="lastname"
            type="text"
            id="lastname"
            class="form-control-user form-control"
          />
        </div>
      </div>
    </div>
    <div class="row-user">
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="birthdate"
            >{{ "user.form.birthdate" | translate }}*</label
          >
          <div class="input-icons">
            <input
              [ngClass]="{
                'input-error': !form.controls.birthdate.valid && isInvalid
              }"
              mat-button
              [disabled]="loading"
              readonly
              formControlName="birthdate"
              type="text"
              id="birthdate"
              class="form-control-user birthdate filter-dates-placeholder form-control"
              ngbDatepicker
              #datePicker="ngbDatepicker"
              (click)="datePicker.toggle()"
              [minDate]="minDateNgStruct"
              [maxDate]="maxDateNgStruct"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="gender"
            >{{ "user.form.gender" | translate }}*</label
          >
          <select
            [ngClass]="{
              'input-error': !form.controls.gender.valid && isInvalid
            }"
            [attr.disabled]="loading ? '' : null"
            formControlName="gender"
            id="gender"
            class="form-control-user select-input form-control"
          >
            <option
              *ngFor="let genderOption of genderOptions"
              [ngValue]="genderOption.value"
            >
              {{ genderOption.text | translate }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="phone"
            >{{ "user.form.phone" | translate }}*</label
          >
          <input
            [ngClass]="{
              'input-error': !form.controls.phone.valid && isInvalid
            }"
            [readOnly]="loading"
            formControlName="phone"
            type="text"
            id="phone"
            class="form-control-user form-control"
          />
          <div *ngIf="form.get('phone').touched && !form.controls.phone.valid && isInvalid">
            {{"user.form.error.phone" | translate}}
          </div>
        </div>
      </div>
    </div>
    <div class="row-user">
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="email"
            >{{ "common.email" | translate }}*</label
          >
          <input
            [ngClass]="{
              'input-error': !form.controls.email.valid && isInvalid
            }"
            [readOnly]="loading"
            formControlName="email"
            type="email"
            id="email"
            class="form-control-user form-control"
          />
          <div *ngIf="form.get('email').touched && !form.controls.email.valid && isInvalid">
            {{"user.form.error.email" | translate}}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="identificationType">{{ "user.form.documentType" | translate }}*</label>
          <select
            [ngClass]="{
              'input-error': !form.controls.gender.valid && isInvalid
            }"
            [attr.disabled]="loading ? '' : null"
            formControlName="identificationType"
            id="identificationType"
            class="form-control-user select-input form-control"
            (change)="onIdentificationTypeChange()"
          >
            <option value="" disabled selected hidden>{{ "user.form.documentType" | translate }}</option>
            <option value="DNI">{{ "user.form.dni" | translate }}</option>
            <option value="NIE">{{ "user.form.nie" | translate }}</option>
          </select>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12" *ngIf="form.controls.identificationType.value === 'DNI'">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="dni"
            >{{ "user.form.dni" | translate }}*</label
          >
          <input
            [ngClass]="{ 'input-error': !form.controls.dni.valid && isInvalid }"
            [readOnly]="loading"
            formControlName="dni"
            type="text"
            id="dni"
            class="form-control-user form-control"
          />
          <div *ngIf="form.get('dni').touched && !form.controls.dni.valid && isInvalid">
            {{"user.form.error.dni" | translate}}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12" *ngIf="form.controls.identificationType.value === 'NIE'">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="nie">{{ "user.form.nie" | translate }}*</label>
          <input
            [ngClass]="{ 'input-error': !form.controls.nie.valid && isInvalid }"
            [readOnly]="loading"
            formControlName="nie"
            type="text"
            id="nie"
            class="form-control-user form-control"
          />
          <div *ngIf="form.get('nie').touched && !form.controls.nie.valid && isInvalid">
            {{ "user.form.error.nie" | translate }}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="nationality"
            >{{ "user.form.nationality" | translate }}*</label
          >
          <select
            [ngClass]="{
              'input-error': !form.controls.nationality.valid && isInvalid
            }"
            formControlName="nationality"
            type="text"
            id="nationality"
            class="form-control-user form-control select-input"
          >
            <option disabled selected hidden></option>
            <option *ngFor="let c of countriesList" [ngValue]="c.name">
              {{ c.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <h5 id="optional">
      {{ "common.optional-field" | translate }}
      <img src="assets/img/svg/information.svg" alt="info-icon" [matTooltip]="'user.form.optional-fields' | translate"/>
    </h5>
    <div class="row-user">
      <div class="col-lg-4 col-md-4 col-xs-12" *ngIf="form.controls.identificationType.value === 'DNI'">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="dniIssuanceDate">{{
            "user.form.dniIssuanceDate" | translate
          }}</label>
          <input
            mat-button
            [disabled]="loading"
            readonly
            formControlName="dniIssuanceDate"
            type="text"
            id="dniIssuanceDate"
            class="form-control-user birthdate filter-dates-placeholder form-control"
            ngbDatepicker
            #dniIssuanceDate="ngbDatepicker"
            (click)="dniIssuanceDate.toggle()"
            [minDate]="minDateNgStruct"
            [maxDate]="maxDateNgStruct"
          />
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12" *ngIf="form.controls.identificationType.value === 'NIE'">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="nieIssuanceDate">{{ "user.form.nieIssuanceDate" | translate }}</label>
          <input
            mat-button
            [disabled]="loading"
            readonly
            formControlName="nieIssuanceDate"
            type="text"
            id="nieIssuanceDate"
            class="form-control-user birthdate filter-dates-placeholder form-control"
            ngbDatepicker
            #nieIssuanceDate="ngbDatepicker"
            (click)="nieIssuanceDate.toggle()"
            [minDate]="minDateNgStruct"
            [maxDate]="maxDateNgStruct"
          />
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12" *ngIf="form.controls.identificationType.value === 'DNI'">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="dniExpirationDate">{{
            "user.form.dniExpirationDate" | translate
          }}</label>
          <input
            mat-button
            [disabled]="loading"
            readonly
            formControlName="dniExpirationDate"
            type="text"
            id="dniExpirationDate"
            class="form-control-user birthdate filter-dates-placeholder form-control"
            ngbDatepicker
            #dniExpirationDate="ngbDatepicker"
            (click)="dniExpirationDate.toggle()"
            [minDate]="maxDateNgStruct"
          />
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12" *ngIf="form.controls.identificationType.value === 'NIE'">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="nieExpirationDate">{{
            "user.form.nieExpirationDate" | translate
          }}</label>
          <input
            mat-button
            [disabled]="loading"
            readonly
            formControlName="nieExpirationDate"
            type="text"
            id="nieExpirationDate"
            class="form-control-user birthdate filter-dates-placeholder form-control"
            ngbDatepicker
            #nieExpirationDate="ngbDatepicker"
            (click)="nieExpirationDate.toggle()"
            [minDate]="maxDateNgStruct"
          />
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="passport">{{
            "user.form.passport" | translate
          }}</label>
          <input
            [readOnly]="loading"
            formControlName="passport"
            type="text"
            id="passport"
            class="form-control-user form-control"
          />
        </div>
      </div>
    </div>

    <div class="row-user">
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="passportIssuanceDate">{{
            "user.form.passportIssuanceDate" | translate
          }}</label>
          <input
            mat-button
            [disabled]="loading"
            readonly
            formControlName="passportIssuanceDate"
            type="text"
            id="passportIssuanceDate"
            class="form-control-user birthdate filter-dates-placeholder form-control"
            ngbDatepicker
            #passportIssuanceDate="ngbDatepicker"
            (click)="passportIssuanceDate.toggle()"
            [minDate]="minDateNgStruct"
            [maxDate]="maxDateNgStruct"
          />
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="passportExpires">{{
            "user.form.passportExpires" | translate
          }}</label>
          <input
            mat-button
            [disabled]="loading"
            readonly
            formControlName="passportExpires"
            type="text"
            id="passportExpires"
            class="form-control-user birthdate filter-dates-placeholder form-control"
            ngbDatepicker
            #passportExpires="ngbDatepicker"
            (click)="passportExpires.toggle()"
            [minDate]="maxDateNgStruct"
          />
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label
            class="tittle-form-control-user"
            for="passportCountryEmitter"
            >{{ "user.form.passportCountryEmitter" | translate }}</label
          >
          <select
            [readOnly]="loading"
            formControlName="passportCountryEmitter"
            type="text"
            id="passportCountryEmitter"
            class="form-control-user form-control"
          >
          <option disabled selected hidden></option>
          <option *ngFor="let c of countriesList" [ngValue]="c.name">
            {{ c.name }}
          </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row-user">
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="postalCode">{{
            "common.postal-code" | translate
          }}</label>
          <input
            [readOnly]="loading"
            formControlName="postalCode"
            type="text"
            id="postalCode"
            class="form-control-user form-control"
          />
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="city">{{
            "common.city" | translate
          }}</label>
          <input
            [readOnly]="loading"
            formControlName="city"
            type="text"
            id="city"
            class="form-control-user form-control"
            *ngIf="!form.controls.resident.value || form.controls.resident.value === 'none'"
          />
          <select
            *ngIf="form.controls.resident.value && form.controls.resident.value !== 'none'"
            [attr.disabled]="loading ? '' : null"
            formControlName="city"
            id="city"
            class="form-control-user select-input form-control"
          >
            <option
              *ngFor="let municipality of munipalitiesList"
              [ngValue]="municipality.municipality"
            >
              {{ municipality.municipality | translate }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="address">{{
            "common.province" | translate
          }}</label>
          <input
            [readOnly]="loading"
            formControlName="province"
            type="text"
            id="province"
            class="form-control-user form-control"
          />
        </div>
      </div>
    </div>

    <div class="row-user">
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="employeeNumber">{{
            "user.form.employeeNumber" | translate
          }}</label>
          <input
            [readOnly]="loading"
            formControlName="employeeNumber"
            type="text"
            id="employeeNumber"
            class="form-control-user form-control"
          />
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="resident">{{
            "user.form.resident" | translate
          }}</label>
          <select
            [attr.disabled]="loading ? '' : null"
            formControlName="resident"
            id="resident"
            class="form-control-user select-input form-control"
          >
            <option
              *ngFor="let residentOption of residentOptions"
              [ngValue]="residentOption.value"
            >
              {{ residentOption.text | translate }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="address">{{
            "common.address" | translate
          }}</label>
          <input
            [readOnly]="loading"
            formControlName="address"
            type="text"
            id="address"
            class="form-control-user form-control"
          />
        </div>
      </div>
    </div>

    <div class="row-user">
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="country">{{
            "common.country" | translate
          }}</label>
          <select
            formControlName="country"
            type="text"
            id="country"
            class="form-control-user select-input form-control"
          >
            <option disabled selected hidden></option>
            <option *ngFor="let c of countriesList" [ngValue]="c.name">
              {{ c.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="currentCountry">{{
            "user.form.currentCountry" | translate
          }}</label>
          <select
            formControlName="currentCountry"
            type="text"
            id="currentCountry"
            class="form-control-user select-input form-control"
          >
            <option disabled selected hidden></option>
            <option *ngFor="let c of countriesList" [ngValue]="c.name">
              {{ c.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row-user">
      <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="renfeCard">{{
            "train.confirm-stepper-container.passenger-form.renfe-card"
              | translate
          }}</label>
          <input
            formControlName="renfeCard"
            type="text"
            id="renfeCard"
            class="form-control-user form-control"
          />
        </div>
      </div>
      <div class="form-group col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label for="ffCard">{{ "my-bookeds.train-flight.ff-card" | translate }}</label>
          <span
            (click)="openFfCardModal('ffCard')"
            class="icon-edit ml-1"
            style="cursor: pointer; padding-left: 0.5rem;"
          >
            <i class="ft-edit"></i>
          </span>
          <div *ngIf="ffCards?.controls?.length > 0">
            <input readonly [value]="getCardsInput(ffCards.controls)" class="form-control" style="width: 90%;" />
          </div>
          <div *ngIf="ffCards?.controls?.length === 0">
            <select class="form-control select-input" style="width: 90%;" disabled>
              <option>{{ "my-bookeds.train-flight.no-cards-available" | translate }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row-user">
      <div class="col-lg-4 col-md-4 col-xs-12" *ngIf="!isCreateUser">
        <div class="form-group-user">
          <label for="paymentCard">{{ "company.card.payment-cards-company" | translate }}</label>
          <span
            *ngIf="canEditPaymentCards"
            (click)="openFfCardModal('paymentCard')"
            class="icon-edit ml-1"
            style="cursor: pointer; padding-left: 0.5rem;"
          >
            <i class="ft-edit"></i>
          </span>
          <div *ngIf="paymentCards?.controls?.length > 0">
            <input readonly [value]="getCardsInput(paymentCards.controls)" class="form-control" style="width: 90%;" />
          </div>
          <div *ngIf="paymentCards?.controls?.length === 0">
            <select class="form-control select-input" style="width: 90%;" disabled>
              <option>{{ "my-bookeds.train-flight.no-cards-available" | translate }}</option>
            </select>
          </div>
        </div>
      </div>

    <!-- TARJETAS PERSONALES: comentado hasta aclarar su funcionamiento -->
    <!-- <div class="col-lg-4 col-md-4 col-xs-12">
        <div class="form-group-user">
          <label for="personalPaymentCard">{{ "company.card.payment-cards-user" | translate }}</label>
          <span
            *ngIf="!isCreateUser"
            (click)="openFfCardModal('personalPaymentCard')"
            class="icon-edit ml-1"
            style="cursor: pointer; padding-left: 0.5rem;"
          >
            <i class="ft-edit"></i>
          </span>
          <div *ngIf="personalPaymentCards?.controls?.length > 0">
            <input readonly [value]="getCardsInput(personalPaymentCards.controls)" class="form-control" style="width: 90%;" />
          </div>
          <div *ngIf="personalPaymentCards?.controls?.length === 0">
            <select class="form-control select-input" style="width: 90%;" disabled>
              <option>{{ "my-bookeds.train-flight.no-cards-available" | translate }}</option>
            </select>
          </div>
        </div>
      </div> -->
    </div>

    <div class="row-user" *ngIf="isSuperAdmin">
      <div class="col-12">
        <div class="form-group-user">
          <label class="tittle-form-control-user" for="annotations">{{
            "hotel.custom.annotations" | translate
          }}</label>
          <textarea
            formControlName="annotations"
            type="text"
            id="annotations"
            class="form-control-user form-control"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row-button">
      <div class="text-rigth mt-3">
        <button
          type="button"
          class="custom-btn custom-btn--close"
          aria-label="Close"
          *ngIf="!firstUser"
          (click)="goBack()"
        >
          {{ "common.back" | translate }}
        </button>
      </div>
      <div class="text-right mt-3">
        <button
          class="custom-btn"
          ngbAutofocus
          mat-button
          [ngStyle]="{
            cursor: !form.valid || loading ? 'not-allowed' : 'pointer'
          }"
          matTooltip="{{
            (!form.valid || loading ? 'user.form.invalid-form' : '') | translate
          }}"
        >
          {{ "common.save-changes" | translate }}
        </button>
      </div>
    </div>
  </form>
  <div *ngIf="loading" class="row">
    <app-loading [userType]="'employee'"></app-loading>
  </div>
</div>
