import { Country } from "@angular-material-extensions/select-country";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { LetItGoType } from "@core/models/letitgo-type";
import {
  Association,
  BookingFlightReq,
  BookingQuery,
  Company,
  Contact,
  Document,
  DocumentType,
  Fares,
  FlightSelectDto,
  GroupRestriction,
  MultifamilyRS,
  Penalty,
  PreOrderDto,
  Segment,
  SolutionStandAloneCatalogue,
  TitleType,
  TransportSupplement,
  Traveller,
  TravellerType,
} from "@models";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AllowService,
  AuthService,
  BookedService,
  CompanyService,
  FlightLleegoService,
  FlightService,
  NgxToastrService,
  StateService,
  TravelUtilsService,
  UserService,
  UtilsService,
} from "@services";
import { DialogAssignProjectComponent } from "@shared/component/dialog-assign-project/dialog-assign-project.component";
import { SelectFlightResponse } from "@shared/interfaces/amadeus/select/select-flight-response";
import { AppState } from "app/app.reducer";
import { ViewPdfModalComponent } from "app/modules/businessEntities/user/user-detail/view-pdf-modal/view-pdf-modal.component";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import swal from "sweetalert2";
import { DialogPoliciesComponent } from "./dialog-policies/dialog-policies.component";
import municipalitiesJson from "../../../../../assets/utils/resident-municipalities.json";
import { UserCardModalComponent } from "@shared/component/user-card-modal/user-card-modal.component";

@Component({
  selector: "app-select-flight",
  templateUrl: "./select-flight.component.html",
  styleUrls: ["./select-flight.component.scss"],
})
export class SelectFlightComponent implements OnInit, OnDestroy {
  fullFlight: any = {};
  loading = false;
  travelers: any = {};
  isLinear = true;
  detailsRead = false;
  confirmed = false;
  flightIds: number[] = [];
  requestId = "";
  priceTotal = "";
  departureTime: string[] = [];
  arrivalTime: string[] = [];
  segments: any[] = [];
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  isEditable = true;
  flightSelectDto: FlightSelectDto;
  loadingFlight = false;
  flightDetails: SelectFlightResponse = {};
  duration: string[] = [];
  imgAirlines: string[] = [];
  totalDuration = "";
  iconAirlines: string[] = [];
  private subcriptions: Subscription[] = [];
  submitDone = false;
  plusBag = false;
  amount = 0;
  arrayTravelers = [];
  arrayDataTravelers = [];
  arrayBagsIncluded = [];
  genderOptions = [
    { value: "MALE", text: "Hombre" },
    { value: "FEMALE", text: "Mujer" },
  ];
  documentTypeOptions = [
    { value: "PASSPORT", text: "Pasaporte" },
    { value: "IDENTITY_CARD", text: "DNI/NIF" },
    { value: "NIE", text: "NIE" },
  ];
  deviceTypeOptions = [
    { value: "MOBILE", text: "Teléfono móvil" },
    { value: "LANDLINE", text: "Teléfono fijo" },
    { value: "FAX", text: "Fax" },
  ];
  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear() - 100,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  expiryMinnDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate() + 1,
  };
  expiryMaxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  flightPreOrdered = false;
  flightPreOrderedResponse: any = {};
  readyToConfirm = false;
  issuanceCountry = "";
  birthCountry = "";
  nationality = "";
  userUuid = "";
  showSecondSelector = false;
  showThirdSelector = false;
  showFourthSelector = false;
  user: any = {};
  company: Company;
  data: any = {};
  groupFilters: any = {};
  travelData: any = {};
  isUser = true;
  groupRestriction: GroupRestriction;
  selectedFlights: Array<Segment[]>;
  solutionId: string;
  basePrice: number;
  totalPrice = 0;
  journeysAssociations: Association[];
  isPriceWithinRange: boolean;
  lleegoToken: string;
  policiesSolutionPenalties: Penalty[] = [];
  baggagesInfo: SolutionStandAloneCatalogue;
  travelersAge: number[];
  selectedBaggages: TransportSupplement[];
  needAddress = false;
  needDocApis = false;
  emitOnBookingDetail: any;
  availablePayments: any;
  baggagePrice = 0;
  isAdmin = false;
  canUseTRM: boolean;
  userToBook: string;
  multiFamily: MultifamilyRS;
  optimusMultiFamily: MultifamilyRS;
  @ViewChild("stepper") stepper: MatStepper;
  fareSelected: Fares;
  fareName: string;
  canBaggage = false;
  requestData: any;
  durations: string[] = [];
  selectBaggage = [];
  navigationExtras: NavigationExtras = {
    state: {
      module: "flights",
      fromReservation: true
    },
  };
  extraInfoForm: UntypedFormGroup;
  canUseResident: any;
  residentOptions = [
    { value: "none", text: "Ninguno" },
    { value: "CN", text: "Canarias" },
    { value: "CE", text: "Ceuta" },
    { value: "BL", text: "Baleares" },
    { value: "ML", text: "Melilla" },
  ];
  didSearch: boolean = true;
  munipalitiesList = municipalitiesJson;
  selectedUsers: any;
  projectData: any;
  externalUser: any;

  constructor(
    private flightService: FlightService,
    public translate: TranslateService,
    private router: Router,
    private ngxToastrService: NgxToastrService,
    private _formBuilder: UntypedFormBuilder,
    private travelUtilsService: TravelUtilsService,
    private authService: AuthService,
    private userService: UserService,
    public flightLleegoService: FlightLleegoService,
    private dialog: MatDialog,
    private stateService: StateService,
    public utilsService: UtilsService,
    public store: Store<AppState>,
    private modalService: NgbModal,
    private bookedService: BookedService,
    public allowService: AllowService,
    private companyService: CompanyService,
    private activatedRoute: ActivatedRoute
  ) {
    this.subcriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        this.externalUser = s?.user;
        if (s.user && s.user.type?.toUpperCase() !== "USER" && (s.user.type?.toUpperCase() === "COMPANY" || s.user.type?.toUpperCase() === "EMPLOYEE" ||
            s.user.type?.toUpperCase() === "AGENCY" || s.user.type?.toUpperCase() === "HUB")) {
          this.isAdmin = true;
          this.userToBook = localStorage.getItem("userToBook")
            ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
            : null;
          this.canUseTRM = s.user.type?.toUpperCase() === "EMPLOYEE" ? true : (s.user.type
            ? s.user.plan && s.user.plan.permissions.canUseTRM
            : s.user.user.plan && s.user.user.plan.permissions.canUseTRM);
            if(!this.canUseTRM){
              this.canUseTRM = this.authService.userCompanyPlan?.permissions.canUseTRM;
            }
        }
      })
    );
    if (this.utilsService.isBackNavigation()) {
      this.utilsService.setBackNavigation(false);
      this.router.navigate(['/flight']);
    } else {
      const navigation = this.router.getCurrentNavigation();
      const savedStateString = localStorage.getItem('stateFlightRedirect');
      if (navigation && navigation.extras.state) {
        this.userUuid = navigation.extras.state.userUuid;
        this.isUser = navigation.extras.state.isUser;
        this.selectedFlights = navigation.extras.state.selectedFlights;
        this.selectedUsers = navigation.extras.state.selectedUsers;
        this.projectData = navigation.extras.state.projectData;
        this.solutionId = navigation.extras.state.solutionId;
        this.journeysAssociations = navigation.extras.state.journeysAssociations;
        this.lleegoToken = navigation.extras.state.lleegoToken;
        this.travelersAge = navigation.extras.state.travelersAge;
        this.multiFamily = navigation.extras.state.multiFamilyRes;
        this.optimusMultiFamily = this.multiFamily;
        this.durations = navigation.extras.state.duration;
        this.travelersAge = navigation.extras.state.travelersAge;
        this.isPriceWithinRange = navigation.extras.state.isPriceWithinRange;
      } else if(savedStateString){
        const savedState = JSON.parse(savedStateString);
        this.userUuid = savedState.userUuid;
        this.isUser = savedState.isUser;
        this.selectedFlights = savedState.selectedFlights;
        this.selectedUsers = savedState.selectedUsers;
        this.projectData = savedState.projectData;
        this.solutionId = savedState.solutionId;
        this.journeysAssociations = savedState.journeysAssociations;
        this.lleegoToken = savedState.lleegoToken;
        this.travelersAge = savedState.travelersAge;
        this.multiFamily = savedState.multiFamilyRes;
        this.optimusMultiFamily = savedState.multiFamily;
        this.durations = savedState.durations;
        this.isPriceWithinRange = savedState.isPriceWithinRange;
  
        const restoredForm = this._formBuilder.group(savedState.flightSearchFormData);
        this.stateService.setFlightSearch(restoredForm);
        this.stateService.totalPrice = savedState.stateTotalPrice;
        this.stateService.flightTravelers = savedState.stateFlightTravelers;
        this.stateService.transportId = savedState.stateTransportId;

        localStorage.removeItem('stateFlightRedirect');
      }else {
        this.router.navigate(['/flight']);
      }
    }
  }

  ngOnInit() {
    this.stateService.didFirstSearch = true;
    this.loadingFlight = true;
    this.extraInfoForm = this._formBuilder.group({
      customCode: [null],
      costCenter: [null],
    });
    this.requestData = {
      token: this.lleegoToken,
      solutionID0: this.solutionId,
      journey00: this.journeysAssociations[0]?.journey_references[0],
      journey01: this.journeysAssociations[1]?.journey_references[0],
      userUuid: this.userUuid,
      passengers: Array.isArray(
        this.stateService.flightSearchForm.value.travelers
      )
        ? this.stateService.flightSearchForm.value.travelers.length
        : this.stateService.flightSearchForm.value.travelers,
    };
    this.firstFormGroup = this._formBuilder.group({});
    this.secondFormGroup = this._formBuilder.group({
      dateOfBirth: ["", [Validators.required]],
      gender: [""],
      firstName: ["", [Validators.required, Validators.maxLength(90)]],
      lastName: ["", [Validators.required, Validators.maxLength(90)]],
      documentType: [""],
      documentNumber: ["", [Validators.required]],
      issuanceDate: [""],
      expiryDate: [""],
      issuanceLocation: [""],
      birthPlace: [""],
      deviceType: [""],
      phoneNumber: ["", [Validators.minLength(9), Validators.required]],
      companyName: ["", [Validators.required, Validators.maxLength(20)]],
      emailAddress: ["", [Validators.required, Validators.email]],
    });
    this.thirdFormGroup = this._formBuilder.group({});

    if (this.isUser) {
      this.subcriptions.push(
        this.authService.getProfile().subscribe((u) => {
          this.user = u;
          this.canUseResident = this.user.resident && this.user.resident.toString() !== "none" && this.stateService.residentCandidates && this.stateService.residentCandidates.find(r => r === this.user.resident);
          this.userUuid = u.uuid;
          this.subcriptions.push(
            this.userService
              .getGroupUser(this.user["uuid"])
              .subscribe((group) => {
                this.groupRestriction = group;
                this.groupFilters = group.filters;
              })
          );
          if (this.user?.companyUuid) {
            this.companyService.getCompany(this.user.companyUuid).subscribe(
              (company: Company) => {
                this.company = company;
              },
              (error) => {}
            );
          }
        })
      );
    } else {
      this.subcriptions.push(
        this.userService.getUser(this.userUuid).subscribe((u) => {
          this.user = u;
          this.canUseResident = this.user.resident && this.user.resident.toString() !== "none" && this.stateService.residentCandidates && this.stateService.residentCandidates.find(r => r === this.user.resident);
          this.subcriptions.push(
            this.userService
              .getGroupUser(this.user["uuid"])
              .subscribe((group) => {
                this.groupRestriction = group;
                this.groupFilters = group.filters;
              })
          );
          if (this.user?.companyUuid) {
            this.companyService.getCompany(this.user.companyUuid).subscribe(
              (company: Company) => {
                this.company = company;
              },
              error => {}
            );
          }
        })
      );
    }
    this.isAdmin = this.isUser === true ? false : true;

    const params = { ...this.activatedRoute.snapshot.queryParams };
    const Ds_SignatureVersion = params['Ds_SignatureVersion'];
    const Ds_MerchantParameters = params['Ds_MerchantParameters'];
    const Ds_Signature = params['Ds_Signature'];
    
    if (Ds_SignatureVersion && Ds_MerchantParameters && Ds_Signature) {
      const redsysData = {
        Ds_SignatureVersion,
        Ds_MerchantParameters,
        Ds_Signature,
      };

      const cardName = localStorage.getItem('cardName');
      if (cardName) {
        redsysData['cardName'] = cardName;
        localStorage.removeItem('cardName');
      }

        const uuidToBook = this.userToBook ? this.userToBook : this.userUuid;
        this.userService
        .postUserNewTokenRedsys(uuidToBook, redsysData)
        .subscribe(
          (result) => {
            this.user = result;
            this.translate.get("company.card.card-added").subscribe((resp) => {
              this.ngxToastrService.typeSuccess(null, resp);
            });
          },
          (err) => {
            this.translate.get("company.card.error").subscribe((resp) => {
              this.ngxToastrService.typeInfo(null, resp);
            });
          }
        );
    }
  }

  // get campos del formulario
  get phoneNumber() {
    return this.secondFormGroup.get("phoneNumber");
  }

  get firstName() {
    return this.secondFormGroup.get("firstName");
  }
  get lastName() {
    return this.secondFormGroup.get("lastName");
  }
  get email() {
    return this.secondFormGroup.get("emailAddress");
  }
  get documentNumber() {
    return this.secondFormGroup.get("documentNumber");
  }
  get dateOfBirth() {
    return this.secondFormGroup.get("dateOfBirth");
  }
  get issuanceDate() {
    return this.secondFormGroup.get("issuanceDate");
  }
  get expiryDate() {
    return this.secondFormGroup.get("expiryDate");
  }
  get gender() {
    return this.secondFormGroup.get("gender");
  }
  get companyName() {
    return this.secondFormGroup.get("companyName");
  }
  // fin get campos del formulario

  getLogo(value) {
    return `assets/img/airlines/small/${value}.png`;
  }

  getTime(value: string) {
    const res = value.split("T")[1].slice(0, 5);
    return res;
  }

  getDuration(segment: Segment) {
    const departureDate = moment(segment.object.departure_date);
    const arrivalDate = moment(segment.object.arrival_date);
    const duration = moment.duration(departureDate.diff(arrivalDate));
    return duration.toISOString().split("PT")[1];
  }

  fillArrayTravelers(id: string, i, option, none?) {
    this.arrayTravelers.forEach((t) => {
      if (t.id === id) {
        if (none) {
          delete t.withExtraBag;
        } else {
          t.withExtraBag = {
            bagTypeId: option.id,
            count: i + 1,
          };
        }
      }
    });
  }
  fillArrayIncludedBagsInit(included: any) {
    if (this.groupFilters.allowCheckedBag) {
      Object.keys(included.bags).map((k) => {
        if (included.bags[k].price.amount <= this.groupFilters.priceMaxBag) {
          this.arrayBagsIncluded.push({ id: k, data: included.bags[k] });
        }
      });
    }
  }

  loadIdentificationData(event) {
    let passportExpires = "";
    let passportIssuanceDate = "";
    let dniIssuanceDate = "";
    let dniExpirationDate = "";
    if (this.arrayDataTravelers.length === 0) {
      if (event === "0: PASSPORT") {
        if (
          this.user &&
          this.user["passportExpires"] &&
          this.user["passportExpires"] !== "" &&
          this.user["passportExpires"] !== null
        ) {
          passportExpires = moment(
            this.user["passportExpires"].split("T")[0]
          ).format("YYYY-MM-DD");
          this.secondFormGroup.controls["expiryDate"].setValue(
            this.user
              ? this.user["passportExpires"]
                ? {
                  year: parseInt(passportExpires.split("-")[0], 10),
                  month: parseInt(passportExpires.split("-")[1], 10),
                  day: parseInt(passportExpires.split("-")[2], 10),
                }
                : null
              : null
          );
        }
        if (
          this.user &&
          this.user["passportIssuanceDate"] &&
          this.user["passportIssuanceDate"] !== "" &&
          this.user["passportIssuanceDate"] !== null
        ) {
          passportIssuanceDate = moment(
            this.user["passportIssuanceDate"].split("T")[0]
          ).format("YYYY-MM-DD");
          this.secondFormGroup.controls["issuanceDate"].setValue(
            this.user
              ? this.user["passportIssuanceDate"]
                ? {
                  year: parseInt(passportIssuanceDate.split("-")[0], 10),
                  month: parseInt(passportIssuanceDate.split("-")[1], 10),
                  day: parseInt(passportIssuanceDate.split("-")[2], 10),
                }
                : null
              : null
          );
        }
      } else {
        if (
          this.user &&
          this.user["dniIssuanceDate"] &&
          this.user["dniIssuanceDate"] !== "" &&
          this.user["dniIssuanceDate"] !== null
        ) {
          dniIssuanceDate = moment(
            this.user["dniIssuanceDate"].split("T")[0]
          ).format("YYYY-MM-DD");
          this.secondFormGroup.controls["issuanceDate"].setValue(
            this.user
              ? this.user["dniIssuanceDate"]
                ? {
                  year: parseInt(dniIssuanceDate.split("-")[0], 10),
                  month: parseInt(dniIssuanceDate.split("-")[1], 10),
                  day: parseInt(dniIssuanceDate.split("-")[2], 10),
                }
                : null
              : null
          );
        }
        if (
          this.user &&
          this.user["dniExpirationDate"] &&
          this.user["dniExpirationDate"] !== "" &&
          this.user["dniExpirationDate"] !== null
        ) {
          dniExpirationDate = moment(
            this.user["dniExpirationDate"].split("T")[0]
          ).format("YYYY-MM-DD");
          this.secondFormGroup.controls["expiryDate"].setValue(
            this.user
              ? this.user["dniExpirationDate"]
                ? {
                  year: parseInt(dniExpirationDate.split("-")[0], 10),
                  month: parseInt(dniExpirationDate.split("-")[1], 10),
                  day: parseInt(dniExpirationDate.split("-")[2], 10),
                }
                : null
              : null
          );
        }
      }
    }
  }

  preOrderFlight() {
    this.loadingFlight = true;
    const arrayIds = [];
    this.flightIds.forEach((e) => {
      arrayIds.push(e.toString());
    });
    const dto: PreOrderDto = {
      requestId: this.requestId,
      flights: arrayIds,
      travelers: this.arrayTravelers,
    };
    if (this.userUuid !== "") {
      dto.userUuid = this.userUuid;
    }
    let birthdate = "";
    let dniIssuanceDate = "";
    let dniExpirationDate = "";
    if (
      this.user &&
      this.user["birthdate"] &&
      this.user["birthdate"] !== "" &&
      this.user["birthdate"] !== null
    ) {
      birthdate = moment(this.user["birthdate"].split("T")[0]).format(
        "YYYY-MM-DD"
      );
    }
    this.subcriptions.push(
      this.flightService.preOrderFlight(dto).subscribe(
        (result) => {
          this.flightPreOrderedResponse = result;
          this.loadingFlight = false;
          this.flightPreOrdered = true;
          this.secondFormGroup.controls["firstName"].setValue(
            this.user["name"]
          );
          this.secondFormGroup.controls["lastName"].setValue(
            this.user["lastname"]
          );
          this.secondFormGroup.controls["documentType"].setValue(
            "IDENTITY_CARD"
          );
          this.secondFormGroup.controls["documentNumber"].setValue(
            this.user["dni"]
          );
          this.secondFormGroup.controls["dateOfBirth"].setValue(
            this.user
              ? this.user["birthdate"]
                ? {
                  year: parseInt(birthdate.split("-")[0], 10),
                  month: parseInt(birthdate.split("-")[1], 10),
                  day: parseInt(birthdate.split("-")[2], 10),
                }
                : null
              : null
          );
          this.secondFormGroup.controls["gender"].setValue(
            this.user["gender"].toString()?.toUpperCase()
          );
          this.secondFormGroup.controls["emailAddress"].setValue(
            this.user["email"]
          );
          if (
            this.user &&
            this.user["dniIssuanceDate"] &&
            this.user["dniIssuanceDate"] !== "" &&
            this.user["dniIssuanceDate"] !== null
          ) {
            dniIssuanceDate = moment(
              this.user["dniIssuanceDate"].split("T")[0]
            ).format("YYYY-MM-DD");
            this.secondFormGroup.controls["issuanceDate"].setValue(
              this.user
                ? this.user["dniIssuanceDate"]
                  ? {
                    year: parseInt(dniIssuanceDate.split("-")[0], 10),
                    month: parseInt(dniIssuanceDate.split("-")[1], 10),
                    day: parseInt(dniIssuanceDate.split("-")[2], 10),
                  }
                  : null
                : null
            );
          }
          if (
            this.user &&
            this.user["dniExpirationDate"] &&
            this.user["dniExpirationDate"] !== "" &&
            this.user["dniExpirationDate"] !== null
          ) {
            dniExpirationDate = moment(
              this.user["dniExpirationDate"].split("T")[0]
            ).format("YYYY-MM-DD");
            this.secondFormGroup.controls["expiryDate"].setValue(
              this.user
                ? this.user["dniExpirationDate"]
                  ? {
                    year: parseInt(dniExpirationDate.split("-")[0], 10),
                    month: parseInt(dniExpirationDate.split("-")[1], 10),
                    day: parseInt(dniExpirationDate.split("-")[2], 10),
                  }
                  : null
                : null
            );
          }
        },
        () => {
          this.loadingFlight = false;
          this.travelUtilsService.removeTravelLocalStorage();
          swal.close();
          this.translate.get("flight.form-flight.error").subscribe((resp) => {
            this.ngxToastrService.typeInfo(resp, "");
            this.router.navigateByUrl("flight");
          });
        }
      )
    );
  }

  handleCountry(field: string, event: Country) {
    if (field === "issuanceCountry") {
      this.issuanceCountry = event.alpha2Code;
      this.showSecondSelector = true;
    } else if (field === "nationality") {
      this.nationality = event.alpha2Code;
      this.showThirdSelector = true;
    } else {
      this.birthCountry = event.alpha2Code;
      this.showFourthSelector = true;
    }
  }

  addToList() {
    const idT = this.arrayDataTravelers.length + 1;
    const expiryDate = this.secondFormGroup.controls["expiryDate"].value;
    const issuanceDate = this.secondFormGroup.controls["issuanceDate"].value;
    const dateOfBirth = this.secondFormGroup.controls["dateOfBirth"].value;
    const firstName = this.secondFormGroup.controls["firstName"].value;
    const lastName = this.secondFormGroup.controls["lastName"].value;
    const phone: string = this.secondFormGroup.value.phoneNumber;
    this.arrayDataTravelers.push({
      id: idT.toString(),
      dateOfBirth:
        JSON.stringify(dateOfBirth.month).length === 1
          ? new Date(
            dateOfBirth.year +
            "-" +
            "0" +
            dateOfBirth.month +
            "-" +
            dateOfBirth.day
          )
            .toISOString()
            .split("T")[0]
          : new Date(
            dateOfBirth.year + "-" + dateOfBirth.month + "-" + dateOfBirth.day
          )
            .toISOString()
            .split("T")[0],
      gender: this.secondFormGroup.controls["gender"].value,
      name: {
        firstName: firstName.split(" ")[0],
        middleName: firstName.split(" ")[1] ? firstName.split(" ")[1] : "",
        lastName: lastName.split(" ")[0],
        secondLastName: lastName.split(" ")[1] ? lastName.split(" ")[1] : "",
      },
      documents: [
        {
          documentType: this.secondFormGroup.controls["documentType"].value,
          number: this.secondFormGroup.controls["documentNumber"].value,
          issuanceDate:
            JSON.stringify(issuanceDate.month).length === 1
              ? new Date(
                issuanceDate.year +
                "-" +
                "0" +
                issuanceDate.month +
                "-" +
                issuanceDate.day
              )
                .toISOString()
                .split("T")[0]
              : new Date(
                issuanceDate.year +
                "-" +
                issuanceDate.month +
                "-" +
                issuanceDate.day
              )
                .toISOString()
                .split("T")[0],
          expiryDate:
            JSON.stringify(expiryDate.month).length === 1
              ? new Date(
                expiryDate.year +
                "-" +
                "0" +
                expiryDate.month +
                "-" +
                expiryDate.day
              )
                .toISOString()
                .split("T")[0]
              : new Date(
                expiryDate.year +
                "-" +
                expiryDate.month +
                "-" +
                expiryDate.day
              )
                .toISOString()
                .split("T")[0],
          issuanceCountry: this.issuanceCountry,
          // issuanceLocation: this.secondFormGroup.controls["issuanceLocation"]
          //   .value,
          nationality: this.nationality,
          birthPlace: this.secondFormGroup.controls["birthPlace"].value,
          birthCountry: this.birthCountry,
          holder: true,
        },
      ],
      contact: {
        phones: [
          {
            deviceType: this.secondFormGroup.controls["deviceType"].value
              ? this.secondFormGroup.controls["deviceType"].value
              : "",
            countryCallingCode: phone
              ? phone.length <= 10
                ? phone.substr(0, 2).split("+")[1]
                : phone.substr(0, 3).split("+")[1]
              : "",
            number: phone
              ? phone.length <= 10
                ? phone.substr(2, phone.length)
                : phone.substr(3, phone.length)
              : "",
          },
        ],
        companyName: this.secondFormGroup.controls["companyName"].value,
        emailAddress: this.secondFormGroup.controls["emailAddress"].value,
      },
    });
    if (this.arrayDataTravelers.length > 0) {
      this.secondFormGroup.controls["deviceType"].clearValidators();
      this.secondFormGroup.controls["companyName"].clearValidators();
      this.secondFormGroup.controls["phoneNumber"].clearValidators();
      if (this.arrayDataTravelers.length > 1) {
        const contact = this.arrayDataTravelers[0]["contact"];
        this.arrayDataTravelers.forEach((t) => {
          t["contact"] = contact;
        });
      }
    } else {
      this.secondFormGroup.controls["deviceType"].setValidators(
        Validators.required
      );
      this.secondFormGroup.controls["companyName"].setValidators(
        Validators.required
      );
      this.secondFormGroup.controls["phoneNumber"].setValidators(
        Validators.required
      );
    }
    this.secondFormGroup.reset();
  }

  changeConfirm(value: string) {
    if (value === "Y") {
      this.readyToConfirm = true;
    } else {
      this.readyToConfirm = false;
    }
  }

  createHolderContact(travelerForm): Contact {
    /* const holderContactPhone = {
      country_pref: "34",
      number: "648200516",
    }; */

    const contact: Contact = {
      type: "Passenger",
      /* phones: [holderContactPhone], */
      country: travelerForm.nationality.code,
      address: this.user.address ? this.user.address : '',
    };
    if (travelerForm.city && travelerForm.city.trim() !== '') {
      contact.city = travelerForm.city;
      contact.postal_code = this.munipalitiesList.find((c) => c.municipality === travelerForm.city)?.code;
    }
    return contact;
  }

  getDocumentType(formType: string): DocumentType {
    switch (formType) {
      case "IDENTITY_CARD":
        return DocumentType.NI;
      case "PASSPORT":
        return DocumentType.PP;
      case "NIE":
        return DocumentType.NIE;
      default:
        break;
    }
  }

  getTravellerType(birthDate: any): TravellerType {
    const travellerAge = moment().diff(
      moment(
        `${birthDate.year}-${birthDate.month}-${birthDate.day}`,
        "YYYY-MM-DD"
      ),
      "years"
    );
    if (travellerAge >= 12) {
      return TravellerType.ADT;
    } else if (travellerAge < 12 && travellerAge > 2) {
      return TravellerType.CHD;
    } else {
      return TravellerType.INF;
    }
  }

  createTravellersData(): Traveller[] {
    // const responsibles: PersonInCharge = { name: 'Vyootrip', address: 'C/ Leonardo da Vinci, 18', phone: '648200516' }
    const travellerData: Traveller[] = [];
    this.flightLleegoService.travelerForm.value.forEach((travelerForm) => {
      const contact: Contact = this.createHolderContact(travelerForm);
      const travelerDocumentsArr = [];

      const selectedCardIndex = travelerForm.documents?.selectedFfCard;
      if (selectedCardIndex != null && travelerForm.documents.ffCard[selectedCardIndex]) {
        const selectedFfCard = travelerForm.documents.ffCard[selectedCardIndex];
        
        travelerDocumentsArr.push({
          type: travelerForm.documents.documentType,
          number: selectedFfCard.cardNumber,
        });
      }

      const travellerDocument: Document = {
        type: this.getDocumentType(travelerForm.documentType),
        number: travelerForm.documentNumber,
        nationality: travelerForm.nationality.code,
        country: travelerForm.nationality.code,
      };
      if (travellerDocument.type?.toUpperCase() === "NI" && travelerForm.city) {
        travellerDocument['city'] = travelerForm.city;
      }
      if (travelerForm.expiryDate != null && travelerForm.expiryDate !== "") {
        travellerDocument.expiry_date = moment([
          travelerForm.expiryDate.year,
          travelerForm.expiryDate.month - 1,
          travelerForm.expiryDate.day,
        ]).format("YYYY-MM-DD");
      }
      if (
        travelerForm.issuanceDate != null &&
        travelerForm.issuanceDate !== ""
      ) {
        travellerDocument.issue_date = moment([
          travelerForm.issuanceDate.year,
          travelerForm.issuanceDate.month - 1,
          travelerForm.issuanceDate.day,
        ]).format("YYYY-MM-DD");
      }
      travelerDocumentsArr.push(travellerDocument);
      const traveller: Traveller = {
        uuid: travelerForm.uuid,
        type: this.getTravellerType(travelerForm.birthDate),
        title:
          travelerForm.gender?.toLowerCase() === "male"
            ? TitleType.Mr
            : TitleType.Ms,
        name: travelerForm.name,
        documents: travelerDocumentsArr,
        contact: contact,
        surnames: [travelerForm.surname],
      };
      if (travelerForm.lastSurname != null && travelerForm.lastSurname !== "") {
        traveller.surnames.push(travelerForm.lastSurname);
      }
      traveller.birth_date = `${travelerForm.birthDate.year}-${travelerForm.birthDate.month}-${travelerForm.birthDate.day}`;
      travellerData.push(traveller);
    });
    return travellerData;
  }

  getVyooPrice() {
    let total = 0;
    if (this.fareSelected) {
      total = parseFloat(this.fareSelected.total_price);
    } else {
      total = this.totalPrice;
    }
    if (this.canUseResident && this.stateService.totalPrice) {
      total = this.stateService.totalPrice;
    }
    if (this.baggagePrice > 0) {
      total += this.baggagePrice;
    }
    return total;
  }
  confirmFlight() {
    const vyooPrice = this.getVyooPrice();
    const travellerData: Traveller[] = this.createTravellersData();
    this.showPaymentCardSelector().then((selectedCard) => {
      if (!selectedCard && !this.company.bookedWithoutCard) {
        return;
      }

    this.utilsService.loadingFlights = true;
    const airlines = [];
    const bookingQuery: BookingQuery = {
      token: this.lleegoToken,
      solutions: [
        {
          id: this.stateService.transportId ? this.stateService.transportId : (this.fareSelected ? this.fareSelected.id : this.solutionId),
          journeys: this.fareSelected
            ? this.fareSelected.journeys_ids
            : [this.journeysAssociations[0]?.journey_references[0]],
        },
      ],
      holder: {
        name: "VYOOTRIP",
        surnames: ["S.L"],
        contact: {
          type: "Emergency",
          mails: ["reservas@vyootrip.com"],
          phones: [{
            country_pref: "34",
            number: "648200516",
          }],
          address: "C. Egido, 2",
          city: "Los Villares",
          postal_code: "23160",
          country: "ES",
        }
      },
      travellers: travellerData,
      vyooPrice: vyooPrice,
    };
    const travel = this.travelUtilsService.getTravelLocalStorage();
    if (this.journeysAssociations[1]?.journey_references[0] != null) {
      bookingQuery.solutions[0].journeys.push(
        this.journeysAssociations[1]?.journey_references[0]
      );
    }
    if (this.selectedBaggages != null && this.selectedBaggages.length > 0) {
      bookingQuery.ancillaries = this.selectedBaggages.filter(
        (selectedBaggage) => selectedBaggage.id != null
      );
    }
    const confirmDto: BookingFlightReq = {
      query: bookingQuery,
      emitOnBookingDetail: this.emitOnBookingDetail,
      available_payments: this.availablePayments
    };
    if (this.userUuid !== "") {
      confirmDto.userUuid = this.userUuid;
    }
    if (travel && travel.data.title) {
      confirmDto["travelName"] = travel.data.title;
    }
    confirmDto["from"] = this.stateService.flightSearchForm.controls[
      "origin"
    ].value
      .split("/")[1]
      .split(" (")[0];
    confirmDto["to"] = this.stateService.flightSearchForm.controls[
      "destination"
    ].value
      .split("/")[1]
      .split(" (")[0];
    const flights: any = this.selectedFlights;
    confirmDto["departureDate"] = flights[0][0].object.departure_date;
    if (flights[1]) {
      confirmDto["returnDate"] = flights[1][0]?.object.departure_date;
    }
    confirmDto["customCode"] = this.extraInfoForm.value.customCode;
    confirmDto["costCenter"] = this.extraInfoForm.value.costCenter;

    if (this.fareName && this.fareSelected) {
      bookingQuery["fares"] = { fareName: this.fareName, fareConditions: this.fareSelected.services ?? [], price: this.fareSelected.total_price }
    }

    if (this.selectedFlights.length > 0) {
      const outwardAirline = this.selectedFlights[0][0].object.marketing_company;
      airlines.push(outwardAirline);
    }

    if (this.selectedFlights.length > 1) {
      const returnAirline = this.selectedFlights[1][0].object.marketing_company;
      airlines.push(returnAirline);
    }

    bookingQuery["airlines"] = airlines;

    if(!this.company.bookedWithoutCard){
      confirmDto["paymentCard"] = {
        name: selectedCard.name,
        token: selectedCard.token
      };
    }

    this.subcriptions.push(
      this.flightLleegoService.postFlightBooking(confirmDto).subscribe(
        (flightResult) => {
          this.translate
            .get(["common.congrat", "flight.success"])
            .subscribe((response) => {
              this.utilsService.loadingFlights = false;
              swal
                .fire({
                  position: "center",
                  icon: "success",
                  title: response["common.congrat"],
                  text: response["flight.success"],
                  showConfirmButton: false,
                  timer: 3000,
                })
                .then(() => {
                  if(this.projectData && this.projectData.selectedProject && this.projectData.selectedProject.uuid !== "none"){
                    if (flightResult.createResponse) {
                      this.addToTravel(flightResult.createResponse);
                    }
                    if (flightResult.additionalBookings && Array.isArray(flightResult.additionalBookings)) {
                      flightResult.additionalBookings.forEach((booking: any) => {
                        this.addToTravel(booking);
                      });
                    }
                  }
                  this.cleanData();
                  this.emitFlight(
                    flightResult.createResponse.lleegoReservationUuid,
                    flightResult.createResponse.pnr,
                    flightResult.createResponse.lleegoPayment,
                    flightResult.createResponse.price,
                    bookingQuery.ancillaries
                  );

                  // this.handleTravelFlow(flightResult).subscribe(
                  //   (res) => {
                  //     if (res != null) {
                  //       if (res.isCreate) {
                  //         this.travelUtilsService.createTravel(
                  //           res.travelData,
                  //           res.addBody,
                  //           res.travelData.type
                  //         );
                  //       } else {
                  //         this.travelUtilsService.addItemToTravel(
                  //           res.addBody,
                  //           res.travelData.uuid
                  //         );
                  //       }
                  //     }
                  //     this.cleanData();
                  //     this.emitFlight(
                  //       flightResult.lleegoReservationUuid,
                  //       flightResult.pnr,
                  //       flightResult.lleegoPayment,
                  //       flightResult.price,
                  //       bookingQuery.ancillaries
                  //     );
                  //   },
                  //   (err) => {
                  //     this.cleanData();
                  //   }
                  // );
                });
            });
        },
        (err) => {
          if (err.status === 817) {
            if(this.projectData && this.projectData.selectedProject && this.projectData.selectedProject.uuid !== "none"){
              if (err.error.letItGoBooks && Array.isArray(err.error.letItGoBooks)) {
                err.error.letItGoBooks.forEach((letItGoBook) => {
                  if (letItGoBook && letItGoBook.uuid) {
                    this.addToTravel(letItGoBook);
                  }
                });
              }
            }
            this.cleanData();

            this.utilsService.loadingFlights = false;
            this.translate
              .get(["common.timeout.title", "common.timeout.message"])
              .subscribe((response) => {
                swal
                  .fire({
                    position: "center",
                    icon: "warning",
                    title: response["common.timeout.title"],
                    text: response["common.timeout.message"],
                    showConfirmButton: true,
                    showCancelButton: true,
                  })
                  .then((action) => {
                    if (action.isConfirmed) {
                      this.navigationExtras.state["isOutdated"] = true;
                      this.redirectToInit();
                    }
                  });
              });
          } else if (err.status === 403 && err.error && err.error.message === "Su empresa no dispone de una tarjeta válida, contacte con su administrador") {
            this.utilsService.loadingFlights = false;

            this.translate.get(["common.card-permission-error"])
              .pipe(take(1))
              .subscribe((value) =>
                this.ngxToastrService.typeInfo(null, value["common.card-permission-error"])
              );
          } else {
            if(this.projectData && this.projectData.selectedProject && this.projectData.selectedProject.uuid !== "none"){
              if (err.error.letItGoBooks && Array.isArray(err.error.letItGoBooks)) {
                err.error.letItGoBooks.forEach((letItGoBook) => {
                  if (letItGoBook && letItGoBook.uuid) {
                    this.addToTravel(letItGoBook);
                  }
                });
              }
            }
            this.cleanData();

            this.utilsService.loadingFlights = false;
            swal.close();
            this.translate
              .get(["common.congrat", "common.operations"])
              .subscribe((resultOperations) => {
                swal
                  .fire({
                    position: "center",
                    imageUrl: "../../../../../assets/img/svg/check-circle.svg",
                    title: resultOperations["common.congrat"],
                    text: resultOperations["common.operations"],
                    showConfirmButton: false,
                    timer: this.utilsService.letItGoTimer,
                    timerProgressBar: true,
                  })
                  .then(() => {
                    this.redirectToInit();
                  });
              });
            const unavail = err.status === 400 && err.error && err?.error?.message?.startsWith('Flight-Wrapper.flightOffersSeach: "ErrorRS: KO - AVAIL_OUT') ? true : false
            this.letItGo(null, null, null, bookingQuery.ancillaries, null, unavail);
          }
        }
      ));
    })
  }

  showPaymentCardSelector(): Promise<any> {
    if (this.company.bookedWithoutCard) {
      return Promise.resolve(null);
    }

    if (!Array.isArray(this.user.paymentCards)) {
      this.user.paymentCards = this.user.paymentCards ? [this.user.paymentCards] : [];
    }

    /* if (!Array.isArray(this.user.personalPaymentCards)) {
      this.user.personalPaymentCards = this.user.personalPaymentCards ? [this.user.personalPaymentCards] : [];
    } */

    const stateData = {
      userUuid: this.userUuid,
      isUser: this.isUser,
      selectedFlights: this.selectedFlights,
      selectedUsers: this.selectedUsers,
      projectData: this.projectData,
      solutionId: this.solutionId,
      journeysAssociations: this.journeysAssociations,
      lleegoToken: this.lleegoToken,
      travelersAge: this.travelersAge,
      multiFamily: this.multiFamily,
      optimusMultiFamily: this.optimusMultiFamily,
      durations: this.durations,
      flightSearchFormData: this.stateService.flightSearchForm.getRawValue(),
      stateTotalPrice: this.stateService.totalPrice,
      stateFlightTravelers: this.stateService.flightTravelers,
      stateTransportId: this.stateService.transportId,
      isPriceWithinRange: this.isPriceWithinRange
    };
    localStorage.setItem('stateFlightRedirect', JSON.stringify(stateData));
    
    return new Promise((resolve) => {
      const modalRef = this.modalService.open(UserCardModalComponent, {
        windowClass: 'custom-modal-user-card',
        centered: true,
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.bookCards = this.user.paymentCards;
      /* modalRef.componentInstance.bookPersonalCards = this.user.personalPaymentCards; */
      modalRef.componentInstance.fromBookSelect = true;
      modalRef.componentInstance.bookType = 'flight';
      modalRef.componentInstance.user = this.user;
      modalRef.componentInstance.cardSelected.subscribe((selectedCard) => {
        resolve(selectedCard);
      });
      modalRef.result.catch(() => {
        resolve(null);
      });
    });
  }

  addToTravel(flight: any){
    if (this.utilsService.hasProcessedUuid(flight.uuid)) {
      return;
    }
    this.utilsService.addProcessedUuid(flight.uuid);

    const checkInAux = moment(flight.departDate).format("YYYY-MM-DD");
    const addBody: any = {
      item: {
        type: "flight",
        uuid: flight.uuid,
        initDate: checkInAux,
      },
    };
    if (flight.returnDate != null) {
      const checkOutAux = moment(flight.returnDate).format("YYYY-MM-DD");
      addBody.item.endDate = checkOutAux;
    }
    this.travelUtilsService.addItemToTravel(addBody, this.projectData.selectedProject.uuid);
  }

  cleanData() {
    this.stateService.flightSearchForm = null;
    this.stateService.transportRequest = null;
    this.stateService.flightSelectedState = null;
    this.stateService.didFirstSearch = false;
    this.stateService.selectedUsers = [];
  }

  emitFlight(lleegoBookingID?, locator?, paymentId?, price?, ancillaries?, available_payments?) {
    this.utilsService.loadingFlights = true;
    const data = {
      params: {
        locator: locator,
        payment: paymentId,
        userUuid: this.userUuid,
      },
      body: {
        query: {
          token: this.lleegoToken,
          available_payments,
          // expected_amount: expected_amount
        },
      },
    };
    if (!this.emitOnBookingDetail.emit_on_booking) {
      this.flightLleegoService.putFlightEmit(lleegoBookingID, data).subscribe(
        (res2) => {
          this.utilsService.loadingFlights = false;
          this.redirectToInit();
        },
        (err) => {
          this.utilsService.loadingFlights = false;
          if (err.status === 818) {
            const route = this.utilsService.getNavigationRoute(this.externalUser);
            if (route) {
              this.router.navigate([route]);
            }
          } else {
            this.utilsService.loadingFlights = false;
            this.letItGo(lleegoBookingID, locator, paymentId, ancillaries);
          }
        }
      );
    } else {
      this.redirectToInit();
    }
  }

  openSeatSelectionDialog(
    lleegoBookingID?: string,
    locator?: string,
    paymentId?: number,
    expected_amount?: number,
    ancillaries?: any
  ) {
    this.utilsService.loadingFlights = true;
    // If no booking ID, it's a mock, so LetItGo
    if (lleegoBookingID == null) {
      this.letItGo(lleegoBookingID, locator, paymentId, ancillaries);
    } else {
      const data = {
        params: {
          locator: locator,
          payment: paymentId,
          userUuid: this.userUuid,
        },
        body: {
          query: {
            token: this.lleegoToken,
            // expected_amount: expected_amount
          },
        },
      };
      if (!this.emitOnBookingDetail.emit_on_booking) {
        this.flightLleegoService.putFlightEmit(lleegoBookingID, data).subscribe(
          (res2) => {
            this.flightLleegoService
              .seatsAvailability(lleegoBookingID, this.userUuid)
              .subscribe(
                (res) => {
                  this.utilsService.loadingFlights = false;
                  if (
                    res.solutions[0].segments.some(
                      (seg) =>
                        seg.seat_map != null && seg.seat_map.avail.length > 0
                    )
                  ) {
                    this.translate
                      .get([
                        "train.user-form.selectSeat",
                        "train.taxes.extraPay",
                        "common.confirm",
                        "common.cancel",
                      ])
                      .subscribe((result) => {
                        swal
                          .fire({
                            title: result["train.user-form.selectSeat"],
                            text: result["train.taxes.extraPay"],
                            icon: "success",
                            showCancelButton: true,
                            confirmButtonColor: "#0CC27E",
                            cancelButtonColor: "#FF586B",
                            confirmButtonText: result["common.confirm"],
                            cancelButtonText: result["common.cancel"],
                            customClass: {
                              confirmButton: "btn btn-success btn-raised",
                              cancelButton: "btn btn-danger btn-raised mr-5",
                            },
                            buttonsStyling: false,
                          })
                          .then((action) => {
                            if (action.value) {
                              const navigationExtra: NavigationExtras = {
                                state: {
                                  selectedFlights: this.selectedFlights,
                                  userUuid: this.userUuid,
                                  travelersAge: this.travelersAge,
                                  isUser: this.isUser,
                                  seatAvailabilityRes: res,
                                  token: res.token,
                                  bookingID: lleegoBookingID,
                                  locator: locator,
                                  totalPrice: this.totalPrice,
                                  paymentId: paymentId,
                                  fareSelected: this.fareSelected,
                                },
                              };
                              this.router.navigateByUrl(
                                `flight/seats-selection`,
                                navigationExtra
                              );
                            } else {
                              // this.onGetPDF(res);
                              this.redirectToInit();
                            }
                          });
                      });
                  } else {
                    this.translate
                      .get("flight.form-flight.seat-unavail")
                      .subscribe((resp) => {
                        this.ngxToastrService.typeInfo(resp, "");
                        this.redirectToInit();
                      });
                    this.redirectToInit();
                  }
                },
                (err) => {
                  this.utilsService.loadingFlights = false;
                  if (err.status === 818) {
                    this.translate
                      .get("flight.form-flight.seat-unavail")
                      .subscribe((resp) => {
                        this.ngxToastrService.typeInfo(resp, "");
                        this.redirectToInit();
                      });
                  } else {
                    this.letItGo(
                      lleegoBookingID,
                      locator,
                      paymentId,
                      ancillaries
                    );
                  }
                }
              );
          },
          (err) => {
            this.utilsService.loadingFlights = false;
            if (err.status === 818) {
              this.translate
                .get("flight.form-flight.seat-unavail")
                .subscribe((resp) => {
                  this.ngxToastrService.typeInfo(resp, "");
                  this.redirectToInit();
                });
            } else {
              this.utilsService.loadingFlights = false;
              this.letItGo(lleegoBookingID, locator, paymentId, ancillaries);
            }
          }
        );
      } else {
        this.flightLleegoService
          .seatsAvailability(lleegoBookingID, this.userUuid)
          .subscribe(
            (res) => {
              this.utilsService.loadingFlights = false;
              if (
                res.solutions[0].segments.some(
                  (seg) => seg.seat_map != null && seg.seat_map.avail.length > 0
                )
              ) {
                this.translate
                  .get([
                    "train.user-form.selectSeat",
                    "train.taxes.extraPay",
                    "common.confirm",
                    "common.cancel",
                  ])
                  .subscribe((result) => {
                    swal
                      .fire({
                        title: result["train.user-form.selectSeat"],
                        text: result["train.taxes.extraPay"],
                        icon: "success",
                        showCancelButton: true,
                        confirmButtonColor: "#0CC27E",
                        cancelButtonColor: "#FF586B",
                        confirmButtonText: result["common.confirm"],
                        cancelButtonText: result["common.cancel"],
                        customClass: {
                          confirmButton: "btn btn-success btn-raised",
                          cancelButton: "btn btn-danger btn-raised mr-4",
                        },
                        buttonsStyling: false,
                      })
                      .then((action) => {
                        if (action.value) {
                          const navigationExtra: NavigationExtras = {
                            state: {
                              selectedFlights: this.selectedFlights,
                              userUuid: this.userUuid,
                              travelersAge: this.travelersAge,
                              isUser: this.isUser,
                              seatAvailabilityRes: res,
                              token: res.token,
                              bookingID: lleegoBookingID,
                              locator: locator,
                              totalPrice: this.totalPrice,
                              paymentId: paymentId,
                              fareSelected: this.fareSelected,
                            },
                          };
                          this.router.navigateByUrl(
                            `flight/seats-selection`,
                            navigationExtra
                          );
                        } else {
                          // this.onGetPDF(res);
                          this.redirectToInit();
                        }
                      });
                  });
              } else {
                this.translate
                  .get("flight.form-flight.seat-unavail")
                  .subscribe((resp) => {
                    this.ngxToastrService.typeInfo(resp, "");
                    this.redirectToInit();
                  });
                this.redirectToInit();
              }
            },
            (err) => {
              this.utilsService.loadingFlights = false;
              if (err.status === 818) {
                this.translate
                  .get("flight.form-flight.seat-unavail")
                  .subscribe((resp) => {
                    this.ngxToastrService.typeInfo(resp, "");
                    this.redirectToInit();
                  });
              } else {
                this.letItGo(lleegoBookingID, locator, paymentId, ancillaries);
              }
            }
          );
      }
    }
  }

  letItGo(
    lleegoBookingID?: string,
    locator?: string,
    paymentId?: number,
    ancillaries?: any,
    insurance?: any,
    unavail?: boolean
  ) {
    const parsedAncillaries = [];
    const travellerData: Traveller[] = this.createTravellersData();
    this.travelUtilsService.removeTravelLocalStorage();
    let letItGoTravel;
    if (
      this.stateService.flightTravelers &&
      this.stateService.flightTravelers.length > 0
    ) {
      letItGoTravel =
        this.stateService.flightTravelers.length > this.travelersAge.length
          ? this.stateService.flightTravelers.length
          : this.travelersAge.length;
    } else {
      letItGoTravel = this.travelersAge.length;
    }
    if (ancillaries != null && ancillaries.length > 0) {
      ancillaries.forEach((baggage) => {
        if (
          baggage.seg_references.every(
            (v, i) => v === this.selectedFlights[0][i].id
          )
        ) {
          parsedAncillaries.push({
            journey: `ida`,
            price: baggage.total,
            name: baggage.short_name,
          });
        } else {
          parsedAncillaries.push({
            journey: `vuelta`,
            price: baggage.total,
            name: baggage.short_name,
          });
        }
      });
    }
    const goingStops = this.selectedFlights[0].slice(
      1,
      this.selectedFlights[0].length
    );
    let goingText = "Escala Ida:";
    if (goingStops.length > 1) {
      goingText = "Escalas Ida:";
    }
    const body = {
      itinerary: `Ida: (IATA aerolinea: ${this.selectedFlights[0][0].object.marketing_company
        }) ${this.selectedFlights[0][0].object.departure}  - ${this.selectedFlights[0][this.selectedFlights[0].length - 1].object
          .arrival
        }`,
      travellers: letItGoTravel,
      dates: moment(this.selectedFlights[0][0].object.departure_date).format(
        "DD/MM/YYYY HH:mm"
      ),
      price: this.getVyooPrice(),
      stops: null,
      seats: [],
      lleegoBookingID,
      locator,
      baggages: parsedAncillaries,
      insurance: insurance,
      stopsText: null,
      travelerList: travellerData,
      unavail: unavail
    };
    if (goingStops.length > 0) {
      body.stopsText = `${goingText} ${goingStops
        .map(
          (seg) =>
            `${seg.object.departure}-${seg.object.arrival} (IATA aerolinea: ${seg.object.marketing_company})`
        )
        .join(", ")}`;
    } else {
      body.stopsText = `${goingText} ninguna`;
    }
    body.stops = goingStops.length;
    body["customCode"] = this.extraInfoForm.value.customCode;
    body["costCenter"] = this.extraInfoForm.value.costCenter;

    if (this.fareName && this.fareSelected) {
      body["fares"] = { fareName: this.fareName, fareConditions: this.fareSelected.services ?? [], price: this.fareSelected.total_price }
    }

    if (this.selectedFlights.length > 1) {
      body.dates =
        moment(this.selectedFlights[0][0].object.departure_date).format(
          "DD/MM/YYYY HH:mm"
        ) +
        " - " +
        moment(
          this.selectedFlights[this.selectedFlights.length - 1][0].object.departure_date
        ).format("DD/MM/YYYY HH:mm");
      const returnStops = this.selectedFlights[
        this.selectedFlights.length - 1
      ].slice(1, this.selectedFlights[this.selectedFlights.length - 1].length);
      let returnText = "Escala Vuelta:";
      if (returnStops.length > 1) {
        returnText = "Escalas Vuelta:";
      }
      body.itinerary += `\nVuelta: (IATA aerolinea: ${this.selectedFlights[this.selectedFlights.length - 1][0].object
          .marketing_company
        }) ${this.selectedFlights[this.selectedFlights.length - 1][0].object
          .departure
        } - ${this.selectedFlights[this.selectedFlights.length - 1][
          this.selectedFlights[this.selectedFlights.length - 1].length - 1
        ].object.arrival
        }`;
      if (returnStops.length > 0) {
        body.stopsText += `\n${returnText} ${returnStops
          .map(
            (seg) =>
              `${seg.object.departure}-${seg.object.arrival} (IATA aerolinea: ${seg.object.marketing_company})`
          )
          .join(", ")}`;
      } else {
        body.stopsText += `\n${returnText} ninguna`;
      }
      body.stops += returnStops.length;
    }

    if (this.canUseResident) {
      this.residentOptions.find((r) => {
        if (r.value === this.canUseResident) {
          body["canUseResident"] = r.text;
        }
      })
    }
    this.utilsService.sendEmailOperations(
      LetItGoType.flight,
      body,
      this.userUuid
    );
    this.translate
      .get(["common.congrat", "common.operations"])
      .subscribe((response) => {
        swal
          .fire({
            position: "center",
            icon: "success",
            title: response["common.congrat"],
            text: response["common.operations"],
            showConfirmButton: false,
            timer: this.utilsService.letItGoTimer,
            timerProgressBar: true,
          })
          .then(() => {
            this.redirectToInit();
          });
      });
  }

  requestFlight() {
    const vyooPrice = this.getVyooPrice();
    const travellerData: Traveller[] = this.createTravellersData();
    this.utilsService.loadingFlights = true;
    const airlines = [];
    const bookingQuery: BookingQuery = {
      token: this.lleegoToken,
      solutions: [
        {
          id: this.stateService.transportId ? this.stateService.transportId : (this.fareSelected ? this.fareSelected.id : this.solutionId),
          journeys: this.fareSelected
            ? this.fareSelected.journeys_ids
            : [this.journeysAssociations[0]?.journey_references[0]],
        },
      ],
      holder: {
        name: "VYOOTRIP",
        surnames: ["S.L"],
        contact: {
          type: "Emergency",
          mails: ["reservas@vyootrip.com"],
          phones: [{
            country_pref: "34",
            number: "648200516",
          }],
          address: "C. Egido, 2",
          city: "Los Villares",
          postal_code: "23160",
          country: "ES",
        }
      },
      travellers: travellerData,
      vyooPrice: vyooPrice,
    };
    if (this.journeysAssociations[1]?.journey_references[0] != null) {
      bookingQuery.solutions[0].journeys.push(
        this.journeysAssociations[1]?.journey_references[0]
      );
    }
    if (this.selectedBaggages != null && this.selectedBaggages.length > 0) {
      bookingQuery.ancillaries = this.selectedBaggages.filter(
        (selectedBaggage) => selectedBaggage.id != null
      );
    }
    const confirmDto = {
      from: this.stateService.flightSearchForm.value.origin,
      to: this.stateService.flightSearchForm.value.destination,
      departureDate: new Date(this.selectedFlights[0][0].object.departure_date),
      returnDate:
        this.selectedFlights.length > 1
          ? new Date(this.selectedFlights[1][0].object.departure_date)
          : null,
      oneWay:
        this.stateService.flightSearchForm.value.roundtrip === "false"
          ? true
          : false,
      annotations: "Solicitud de vuelo personalizado con preautorización",
      customCode: this.extraInfoForm.value.customCode,
      costCenter: this.extraInfoForm.value.costCenter,
      userUuid: this.userUuid,
      emitOnBookingDetail: this.emitOnBookingDetail,
      query: bookingQuery,
    };

    if (this.selectedFlights.length > 0) {
      const outwardAirline = this.selectedFlights[0][0].object.marketing_company;
      airlines.push(outwardAirline);
    }

    if (this.selectedFlights.length > 1) {
      const returnAirline = this.selectedFlights[1][0].object.marketing_company;
      airlines.push(returnAirline);
    }

    confirmDto["airlines"] = airlines;

    if (this.fareSelected && this.fareName) {
      bookingQuery['fares'] = { fareName: this.fareName, fareSelected: this.fareSelected };
    }

    const goingStops = this.selectedFlights[0].slice(
      1,
      this.selectedFlights[0].length
    );
    let goingText = "Escala Ida:";
    if (goingStops.length > 1) {
      goingText = "Escalas Ida:";
    }
    const body = {
      itinerary: `Ida: (IATA aerolinea: ${this.selectedFlights[0][0].object.marketing_company
        }) ${this.selectedFlights[0][0].object.departure}  - ${this.selectedFlights[0][this.selectedFlights[0].length - 1].object
          .arrival
        }`,
      travellers: this.arrayTravelers.length,
      dates: moment(this.selectedFlights[0][0].object.departure_date).format(
        "DD/MM/YYYY HH:mm"
      ),
      price: this.getVyooPrice(),
      stops: null,
      seats: [],
      baggages: this.baggagesInfo,
      stopsText: null,
    };
    if (goingStops.length > 0) {
      body.stopsText = `${goingText} ${goingStops
        .map(
          (seg) =>
            `${seg.object.departure}-${seg.object.arrival} (IATA aerolinea: ${seg.object.marketing_company})`
        )
        .join(", ")}`;
    } else {
      body.stopsText = `${goingText} ninguna`;
    }
    body.stops = goingStops.length;
    if (this.selectedFlights.length > 1) {
      body.dates =
        moment(this.selectedFlights[0][0].object.departure_date).format(
          "DD/MM/YYYY HH:mm"
        ) +
        " - " +
        moment(
          this.selectedFlights[this.selectedFlights.length - 1][
            this.selectedFlights[this.selectedFlights.length - 1].length - 1
          ].object.departure_date
        ).format("DD/MM/YYYY HH:mm");
      const returnStops = this.selectedFlights[
        this.selectedFlights.length - 1
      ].slice(1, this.selectedFlights[this.selectedFlights.length - 1].length);
      let returnText = "Escala Vuelta:";
      if (returnStops.length > 1) {
        returnText = "Escalas Vuelta:";
      }
      body.itinerary += `\nVuelta: (IATA aerolinea: ${this.selectedFlights[this.selectedFlights.length - 1][0].object
          .marketing_company
        }) ${this.selectedFlights[this.selectedFlights.length - 1][0].object
          .departure
        } - ${this.selectedFlights[this.selectedFlights.length - 1][
          this.selectedFlights[this.selectedFlights.length - 1].length - 1
        ].object.arrival
        }`;
      if (returnStops.length > 0) {
        body.stopsText += `\n${returnText} ${returnStops
          .map(
            (seg) =>
              `${seg.object.departure}-${seg.object.arrival} (IATA aerolinea: ${seg.object.marketing_company})`
          )
          .join(", ")}`;
      } else {
        body.stopsText += `\n${returnText} ninguna`;
      }
      body.stops += returnStops.length;
    }
    confirmDto["letItGoInfo"] = body;
    if(this.availablePayments){
      confirmDto["availablePayments"] = this.availablePayments;
    }
    const authorization = {
      type: "flight",
      price: this.getVyooPrice(),
      requestData: confirmDto,
      fromDate: this.selectedFlights[0][0].object.departure_date,
      toDate:
        this.selectedFlights.length > 1
          ? this.selectedFlights[1][0].object.departure_date
          : null,
      userUuid: this.userUuid,
      customCode: this.extraInfoForm.value.customCode,
      costCenter: this.extraInfoForm.value.costCenter,
    };

    if (this.fareSelected) {
      confirmDto["fareSelected"] = this.fareSelected;
    }
    if (this.selectBaggage) {
      confirmDto.query["ancillaries"] = this.selectBaggage;
    }
    this.subcriptions.push(
      this.bookedService.requestAuthorization(authorization).subscribe(
        () => {
          this.loading = false;
          this.translate
            .get(["common.congrat", "authorization.flight"])
            .subscribe((response) => {
              swal
                .fire({
                  position: "center",
                  icon: "success",
                  title: response["common.congrat"],
                  text: response["authorization.flight"],
                  showConfirmButton: false,
                  timer: 5000,
                })
                .then(() => {
                  this.utilsService.loadingFlights = false;
                  this.authService.totalAuthorizations++;
                  this.redirectToInit();
                });
            });
        },
        (err) => {
          swal.close();
          this.loading = false;
          this.utilsService.loadingFlights = false;
          this.translate.get("authorization.error").subscribe((resp) => {
            this.ngxToastrService.typeInfo(resp, err.error.message);
          });
        }
      )
    );
  }

  deleteTrav(id: string) {
    this.arrayDataTravelers.forEach((e) => {
      if (e.id === id) {
        this.arrayDataTravelers.splice(this.arrayDataTravelers.indexOf(e), 1);
      }
    });
  }

  handleTravelFlow(flight: any) {
    const checkInAux = moment(flight.departDate).format("YYYY-MM-DD");
    const bookingData: any = {
      initDate: checkInAux,
      bookingUuid: flight.uuid,
      type: "flight",
    };
    if (flight.returnDate != null) {
      const checkOutAux = moment(flight.returnDate).format("YYYY-MM-DD");
      bookingData.endDate = checkOutAux;
    }

    bookingData["userUuid"] = this.userUuid;
    const dialogAssignProject = this.dialog.open(DialogAssignProjectComponent, {
      data: { bookingData, isUser: this.isUser, isNewBooking: true },
      panelClass: "new-dialog",
    });
    return dialogAssignProject.afterClosed();
  }
  
  redirectToInit(){
    this.utilsService.loadingFlights = false;
    const route = this.utilsService.getNavigationRoute(this.externalUser);
    if (route) {
      this.router.navigate([route], {...this.navigationExtras, queryParams: { specialAction: true } });
    }
  }

  onGetPDF(booked: any) {
    let fileName;
    if (booked.voucherFile && Array.isArray(booked.voucherFile) && booked.voucherFile.length > 0) {
      const firstFile = booked.voucherFile[0];
      fileName = firstFile.fileName || 'flight_bonus.pdf';
    } 
    else if (booked.voucherFileUrl) {
      fileName = 'flight_bonus.pdf';
    }

    this.bookedService
      .getBookedsPdf(booked.voucherFileUrl)
      .pipe(take(1))
      .subscribe(
        (res) => {
          const modalRef = this.modalService.open(ViewPdfModalComponent, {
            size: "lg",
            centered: true,
          });
          modalRef.componentInstance.blobUrl = URL.createObjectURL(res);
          modalRef.componentInstance.fileName = fileName;
        },
        () =>
          this.translate
            .get(["common.error-pdf"])
            .pipe(take(1))
            .subscribe((value) =>
              this.ngxToastrService.typeInfo(null, value["common.error-pdf"])
            )
      );
  }

  updateBaggagePrice(event: any) {
    this.baggagePrice = 0;
    for (const foundBaggage of event) {
      this.baggagePrice += foundBaggage.total;
    }
    this.selectedBaggages = event;
  }

  openPoliciesDialog(multi) {
    this.flightLleegoService
      .getFlightPolicy({
        token: this.lleegoToken,
        solutionID0: multi.id,
        journey00: this.journeysAssociations[0]?.journey_references[0],
        journey01: this.journeysAssociations[1]?.journey_references[0],
        userUuid: this.isUser ? null : this.userUuid,
      })
      .subscribe(
        (res) => {
          if (res.error != null) {
            this.translate.get("flight.form-flight.error").subscribe((resp) => {
              this.ngxToastrService.typeInfo(resp, "");
              this.router.navigateByUrl("flight");
              this.utilsService.loadingFlights = false;
            });
          } else {
            if (
              res.solutions[0].penalties != null &&
              res.solutions[0].penalties.length > 0
            ) {
              this.policiesSolutionPenalties = res.solutions[0].penalties;
              this.dialog.open(DialogPoliciesComponent, {
                width: "60%",
                height: "60%",
                panelClass: "dialog-policies",
                data: this.policiesSolutionPenalties,
              });
            }
          }
        },
        (err) => {
          this.translate.get("flight.form-flight.error").subscribe((resp) => {
            this.loadingFlight = false;
            this.utilsService.loadingFlights = false;
          });
        }
      );
  }

  goBack() {
    if(this.didSearch == true){
      this.router.navigate(['/flight'], { queryParams: { didSearch: true } });
    }else{
      history.back();
    }
  }

  getOptimusMultifamily(multifamily: MultifamilyRS) {
    if (multifamily && multifamily.fares.length >= 1) {
      multifamily.fares.forEach((fare) => {
        fare.touched = false;
      });
      const optimusMultifamilyFares = [multifamily.fares[0]];
      if (multifamily.fares.length > 3) {
        multifamily.fares.shift();
        const sortFares = this.sortFaresByPrice(multifamily.fares);
        const middleFare = sortFares[Math.floor((sortFares.length - 1) / 2)];
        optimusMultifamilyFares.push(middleFare);
        optimusMultifamilyFares.push(
          multifamily.fares[multifamily.fares.length - 1]
        );
        const optimusMultiFamily: MultifamilyRS = {
          token: multifamily.token,
          fares: optimusMultifamilyFares,
        };

        optimusMultiFamily.fares.forEach((fare) => {
          if (fare.services && fare.services.length > 1) {
            fare.services.forEach((service) => {
              if (service && service.name && service.name !== "") {
                service.name = service.name?.toLowerCase();
              }
            });
          }
        });

        return optimusMultiFamily;
      } else {
        multifamily.fares.forEach((fare) => {
          if (fare.services && fare.services.length > 1) {
            fare.services.forEach((service) => {
              if (service && service.name && service.name !== "") {
                service.name = service.name?.toLowerCase();
              }
            });
          }
        });
        return multifamily;
      }
    } else {
      return { token: "", fares: [] };
    }
  }

  sortFaresByPrice(fares: Fares[]) {
    fares.sort((a, b) => {
      // return (a.services.length -1) - (b.services.length - 1);
      return a.total - b.total;
    });
    return fares;
  }

  nextStepFromFares(event) {
    this.fareSelected = event.fare;
    this.fareName = event.fareName;
    this.stepper.next();
  }
  backStepFromFares() {
    this.fareSelected = null;
    this.stepper.previous();
  }

  goNextStep(event) {
    if (
      event &&
      event.needDocApis !== null &&
      event.needAddress !== null &&
      event.emitOnBookingDetail !== null
    ) {
      this.needAddress = event.needAddress;
      this.needDocApis = event.needDocApis;
      this.emitOnBookingDetail = event.emitOnBookingDetail;
      this.availablePayments = event.availablePayments;
      this.totalPrice = event.totalPrice;
      this.selectBaggage = event.selectBaggage ?? '';
    }
    this.stepper.next();
  }

  isFareWithinRange(totalPrice: any): boolean {
    if (
      !this.groupFilters ||
      this.groupFilters?.priceMinFlight == null ||
      this.groupFilters?.priceMaxFlight == null
    ) {
      return true;
    }
    return (
      totalPrice >= this.groupFilters.priceMinFlight &&
      totalPrice <= this.groupFilters.priceMaxFlight
    );
  }

  goBackStep($event) {
    this.stepper.previous();
  }

  ngOnDestroy() {
    localStorage.removeItem('stateFlightRedirect');
    this.subcriptions.forEach((s) => s.unsubscribe());
  }
}
