import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Location } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatStepper } from "@angular/material/stepper";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { Plan } from "@core/models/plan";
import { Agency, Company, GroupRestriction } from "@models";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AgencyService,
  AuthService,
  CompanyPlanService,
  CompanyService,
  GroupService,
  LanguageService,
  MediaService,
  NgxToastrService,
  UtilsService,
} from "@services";
import { StaticsConditionsComponent } from "app/modules/components/statics/statics-conditions/statics-conditions.component";
import * as _lodash from "lodash-es";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import countryJson from "../../../../../assets/utils/amadeus/countries.json";
import { AppState } from "../company.reducer";
import swal from "sweetalert2";

declare var PAYTPV: any;

@Component({
  selector: "app-company-form",
  templateUrl: "./company-form.component.html",
  styleUrls: ["./company-form.component.scss"],
})
export class CompanyFormComponent implements OnInit, OnDestroy {
  company: Company;
  isLoading = false;
  @Input() signUp: boolean;
  isAdmin: boolean;
  payCometScript;
  plans: Plan[];
  formGeneral: UntypedFormGroup;
  formBilling: UntypedFormGroup;
  formCard: UntypedFormGroup;
  selectedCard: string = '';
  selectedCardIndex: number | null = 0;
  previewImagePath: string;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  profileImage: any;
  countriesList = countryJson;
  // lista de proveedores disponibles totales (de la API)
  suppliers: any[] = [];
  // lista de proveedores seleccionados por el usuario
  suppliersSelected: any[] = [];
  // lista de proveedores que coincide con lo que está escribiendo el usuario
  suppliersMatchedList: any[] = [];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  subscriptions: Subscription[] = [];
  iban_no: string;
  hasCreditCard: boolean;
  maxHotelPercentage;
  maxFlightPercentage;

  @ViewChild("stepper") stepper: MatStepper;

  companyPlan: Plan;
  canUploadCreditCard: boolean;
  hasAcceptConditions = false;
  canBookWithOutCard: boolean;
  isCreate;

  agencies: Agency[] = [];
  groups: any;
  agencyUuid: string;
  userType: string;
  isInvalid: boolean = false;
  companyUsers: { 
    name: string; 
    lastname: string; 
    uuid: string; 
    paymentCards: { 
      name: string; 
      token: string; 
    }[]; 
  }[] = [];
  assignmentScope: 'all' | 'departments' | 'users' = 'all';
  selectedDepartments: string[] = [];
  selectedUsers: string[] = [];
  errorMessage: string | null = null;
  assignToVisible: boolean = false;
  lastSelectedCard: any;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    private ngxToastrService: NgxToastrService,
    private mediaService: MediaService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private planService: CompanyPlanService,
    public authService: AuthService,
    private modalService: NgbModal,
    private agencyService: AgencyService,
    public utilsService: UtilsService,
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    private groupService: GroupService,
    private langService: LanguageService,
  ) {
    this.store
      .select("auth")
      .pipe(take(1))
      .subscribe((auth) => {
        if (auth && auth.user) {
          const user: any = auth.user;

          //Obtener tipo de acceso usuario. Según el tipo mostraremos o no la selección de agencia en el formulario
          if (user && user.accessType) {
            this.userType = user.accessType;
          }

          if (user && user.user && user.user.accessType) {
            this.userType = user.user.accessType;
          }
        }

        if (auth.user && auth.user.type?.toUpperCase() === "EMPLOYEE") {
          this.isAdmin = true;
        }
        if (auth && auth.user) {
          // const user: any = auth.user;
        }
      });
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation()?.extras?.state
    ) {
      this.company = this.router.getCurrentNavigation().extras.state.company;
      this.maxFlightPercentage =
        this.router.getCurrentNavigation().extras.state.maxFlightPercentage;
      this.maxHotelPercentage =
        this.router.getCurrentNavigation().extras.state.maxHotelPercentage;
      this.isLoading = this.router.getCurrentNavigation().extras.state.loading;
      this.isCreate = this.router.getCurrentNavigation().extras.state.isCreate;
      this.agencyUuid =
        this.router.getCurrentNavigation().extras.state.agencyUuid;
      this.storeParams();
    } else {
      this.getFromLocalStorage();
    }

    this.getAllAgencies();
  }

  getFromLocalStorage() {
    this.company = this.utilsService.getLocalStorageItem("companyData")?.value;
    this.maxFlightPercentage = this.utilsService.getLocalStorageItem(
      "maxFlightPercentage"
    )?.value;
    this.maxHotelPercentage =
      this.utilsService.getLocalStorageItem("maxHotelPercentage")?.value;
    this.isLoading = this.utilsService.getLocalStorageItem("isLoading")?.value;
    this.isCreate = this.utilsService.getLocalStorageItem("isCreate")?.value;
    this.signUp = this.utilsService.getLocalStorageItem("signUp")?.value;
    this.agencyUuid =
      this.utilsService.getLocalStorageItem("agencyUuid")?.value;
  }

  storeParams() {
    this.utilsService.storeParamInLocalStorage({
      title: "companyData",
      value: this.company,
    });
    this.utilsService.storeParamInLocalStorage({
      title: "maxFlightPercentage",
      value: this.maxFlightPercentage,
    });
    this.utilsService.storeParamInLocalStorage({
      title: "maxHotelPercentage",
      value: this.maxHotelPercentage,
    });
    this.utilsService.storeParamInLocalStorage({
      title: "isLoading",
      value: this.isLoading,
    });
    this.utilsService.storeParamInLocalStorage({
      title: "isCreate",
      value: this.isCreate,
    });
    this.utilsService.storeParamInLocalStorage({
      title: "signUp",
      value: this.signUp,
    });
    this.utilsService.storeParamInLocalStorage({
      title: "agencyUuid",
      value: this.agencyUuid,
    });
  }

  ngOnInit() {
    if (this.company && this.company?.cardRedsys && !Array.isArray(this.company?.cardRedsys)) {
      this.company.cardRedsys = [this.company.cardRedsys];
    }
    if (this.company && Array.isArray(this.company?.cardRedsys)) {
      const uniqueCards = this.company.cardRedsys.reduce((acc, currentCard) => {
        const existingCard = acc.find(card => card.name === currentCard.name);
        if (!existingCard && currentCard.token) {
          acc.push(currentCard);
        }
        return acc;
      }, []);
  
      this.company.cardRedsys = uniqueCards;
    }
    if (this.company && this.company?.cardRedsys?.length > 0) {
      this.selectedCardIndex = 0;
    }
    const params = { ...this.route.snapshot.queryParams };
    if (params && Object.keys(params).length > 0) {

      const cardName = localStorage.getItem('cardName');
      if (cardName) {
        params.cardName = cardName;
        localStorage.removeItem('cardName');
      }

      this.companyService
        .postCompanyNewTokenRedsys(this.company.uuid, params)
        .subscribe(
          (result) => {
            if (result && result.cardRedsys) {
              result.cardRedsys = result.cardRedsys.filter(card => card.token);
            }
            this.company = result;
            this.generateFormCard();
            this.translate.get("company.card.card-added").subscribe((resp) => {
              if(this.stepper)
                this.stepper.selectedIndex = this.isAdmin ? 3 : 2;
              this.ngxToastrService.typeSuccess(null, resp);
            });
          },
          (err) => {
            this.translate.get("company.card.error").subscribe((resp) => {
              if(this.stepper)
                this.stepper.selectedIndex = this.isAdmin ? 3 : 2;
              this.ngxToastrService.typeInfo(null, resp);
            });
          }
        );
    }
    if (!this.isCreate && !this.company) {
      this._location.back();
    }
    if (this.company && this.company.image) {
      this.subscriptions.push(
        this.mediaService.getMedia(this.company.image).subscribe((s) => {
          const reader = new FileReader();
          reader.readAsDataURL(s);
          let base64data;
          reader.onloadend = () => {
            base64data = reader.result;
            this.isImageSaved = true;
            this.cardImageBase64 = base64data;
          };
          reader.readAsArrayBuffer(s);
        })
      );
    } else {
      this.cardImageBase64 = "assets/img/gallery/image_not_available.png";
    }

    if(this.company){
      this.loadGroups(this.company.uuid);
      this.getCompanyUsers(this.company.uuid);
    }

    if (this.company) {
      this.subscriptions.push(
        this.authService.getCompanyPlan(this.company.uuid).subscribe((plan) => {
          this.companyPlan = plan;
          this.canUploadCreditCard =
            this.companyPlan &&
              this.companyPlan.permissions &&
              this.companyPlan.permissions.canUploadCreditCard === true
              ? true
              : false;
          this.canBookWithOutCard =
            this.companyPlan &&
              this.companyPlan.permissions &&
              this.companyPlan.permissions.canRequestWithoutCard === true
              ? true
              : false;

          if (
            this.isAdmin ||
            (this.company &&
              this.companyPlan &&
              this.companyPlan.permissions &&
              this.companyPlan.permissions.canUploadCreditCard)
          ) {
            this.generateFormCard();
          }
        })
      );
    }

    this.formGeneral = this.formBuilder.group({
      agencyUuid: [
        this.company
          ? this.company.agencyUuid
          : this.agencyUuid !== ""
            ? this.agencyUuid
            : "",
      ],
      image: [this.company ? this.company.image : null],
      name: [
        this.company && this.company.name ? this.company.name : "",
        [Validators.required],
      ],
      cif: [
        this.company && this.company.cif ? this.company.cif : "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9]*")],
      ],
      phone: [
        this.company ? (this.company.phone ? this.company.phone : null) : "",
        [Validators.required, Validators.pattern("[- +()0-9]+")],
      ],
      email: [
        this.company && this.company.email ? this.company.email : "",
        [Validators.required, Validators.email],
      ],
      address: [
        this.company && this.company.address ? this.company.address : "",
        [Validators.required],
      ],
      city: [
        this.company && this.company.city ? this.company.city : "",
        [Validators.required, Validators.maxLength(30)],
      ],
      province: [
        this.company && this.company.province ? this.company.province : "",
        [Validators.required, Validators.maxLength(30)],
      ],
      postalCode: [
        this.company && this.company.postalCode ? this.company.postalCode : "",
        [Validators.required, Validators.maxLength(100)],
      ],
      country: [
        this.company && this.company.country ? this.company.country : "",
        [Validators.required, Validators.maxLength(30)],
      ],
      suppliers: [],
    });

    if (this.isAdmin && this.formGeneral) {
      this.formGeneral.addControl(
        "bookedWithoutCard",
        this.formBuilder.control(
          this.company ? this.company.bookedWithoutCard : false
        )
      );
      this.formGeneral.addControl(
        "hotelComission",
        this.formBuilder.control(
          this.company && this.company.hotelComission
            ? this.company.hotelComission.toString()
            : 0,
          [Validators.min(0), Validators.required]
        )
      );
      this.formGeneral.addControl(
        "localFlightDiscount",
        this.formBuilder.control(
          this.company &&
            this.company.flightDiscount &&
            this.company.flightDiscount.local
            ? this.company.flightDiscount.local.toString()
            : 0,
          [Validators.min(0), Validators.required]
        )
      );

      this.formGeneral.addControl(
        "europeFlightDiscount",
        this.formBuilder.control(
          this.company &&
            this.company.flightDiscount &&
            this.company.flightDiscount.europe
            ? this.company.flightDiscount.europe.toString()
            : 0,
          [Validators.min(0), Validators.required]
        )
      );

      this.formGeneral.addControl(
        "internationalFlightDiscount",
        this.formBuilder.control(
          this.company &&
            this.company.flightDiscount &&
            this.company.flightDiscount.international
            ? this.company.flightDiscount.international.toString()
            : 0,
          [Validators.min(0), Validators.required]
        )
      );

      this.formGeneral.addControl(
        "trainComission",
        this.formBuilder.control(
          this.company && this.company.trainComission
            ? this.company.trainComission.toString()
            : 0,
          [
            Validators.min(0),
            Validators.max(this.maxHotelPercentage),
            Validators.required,
          ]
        )
      );
      this.formGeneral.addControl(
        "planUuid",
        this.formBuilder.control(
          this.company && this.company.planUuid ? this.company.planUuid : null
        )
      );
      this.formGeneral.addControl(
        "annotations",
        this.formBuilder.control(
          this.company && this.company.annotations
            ? this.company.annotations
            : null
        )
      );
    }
    this.formBilling = this.formBuilder.group({
      identificator: [
        this.company && this.company.billingData
          ? this.company.billingData.identificator
          : "",
        [Validators.required, Validators.maxLength(30)],
      ],
      businessName: [
        this.company && this.company.billingData
          ? this.company.billingData.businessName
          : "",
        [Validators.required, Validators.maxLength(100)],
      ],
      address: [
        this.company && this.company.billingData
          ? this.company.billingData.address
          : "",
        [Validators.required],
      ],
      town: [
        this.company && this.company.billingData
          ? this.company.billingData.town
          : "",
        [Validators.required, Validators.maxLength(30)],
      ],
      province: [
        this.company && this.company.billingData
          ? this.company.billingData.province
          : "",
        [Validators.required, Validators.maxLength(30)],
      ],
      postalCode: [
        this.company && this.company.billingData
          ? this.company.billingData.postalCode
          : "",
        [Validators.required, Validators.maxLength(100)],
      ],
      country: [
        this.company && this.company.billingData
          ? this.company.billingData.country
          : "",
        [Validators.required, Validators.maxLength(30)],
      ],
    });

    let hasValidatorsBilling = false;
    this.formBilling.valueChanges.subscribe(() => {
      if (!hasValidatorsBilling && this.isEmpty("billing")) {
        hasValidatorsBilling = true;
        this.setValidators("billing", Validators.required);
        this.updateValueAndValidity("billing");
      } else if (hasValidatorsBilling && !this.isEmpty("billing")) {
        hasValidatorsBilling = false;
        this.setValidators("billing");
        this.updateValueAndValidity("billing");
      }
    });
    this.getSuppliers();
    if (!this.signUp) {
      this.getPlans();
    }
  }

  assignToPopup(i: number): void {
    this.assignToVisible = this.lastSelectedCard !== i || !this.assignToVisible;
    this.lastSelectedCard = i;
  }

  goBackStepper(stepper: MatStepper) {
    stepper.previous();
  }

  goNextStepper(stepper: MatStepper) {
    this.isInvalid = false
    stepper.next();
  }

  goBack() {
    //En caso de acabemos de insertar la tarjeta de Redsys, evitamos hacer this._location.back();
    //Ya que nos mandará de nuevo a la pasarela de pago de Redsys.
    const queryParams = this.route.snapshot.queryParams;
    if (
      queryParams &&
      queryParams["Ds_SignatureVersion"] &&
      queryParams["Ds_MerchantParameters"]
    ) {
      // Redirige a la ruta deseada en lugar de hacer el back()
      this.router.navigate(["/companies"]);
    } else {
      // Realiza el location.back() si no se cumplen las condiciones
      this._location.back();
    }
  }

  changePhoto() {
    document.getElementById("upload-photo").click();
  }

  setValidators(type: string, validator?) {
    const v = validator ? validator : null;
    if (type === "billing") {
      this.formBilling.get("identificator").setValidators(v);
      this.formBilling.get("address").setValidators(v);
      this.formBilling.get("businessName").setValidators(v);
      this.formBilling.get("town").setValidators(v);
      this.formBilling.get("province").setValidators(v);
      this.formBilling.get("postalCode").setValidators(v);
      this.formBilling.get("country").setValidators(v);
    }
  }

  updateValueAndValidity(type: string) {
    if (type === "billing") {
      this.formBilling.get("identificator").updateValueAndValidity();
      this.formBilling.get("address").updateValueAndValidity();
      this.formBilling.get("businessName").updateValueAndValidity();
      this.formBilling.get("town").updateValueAndValidity();
      this.formBilling.get("province").updateValueAndValidity();
      this.formBilling.get("postalCode").updateValueAndValidity();
      this.formBilling.get("country").updateValueAndValidity();
    }
  }

  isEmpty(type) {
    const formValues =
      type === "billing"
        ? this.formBilling.getRawValue()
        : this.formCard.getRawValue();
    return _lodash.values(formValues).some((value) => value !== null);
  }

  move(index: number) {
    this.stepper.selectedIndex = index;
  }

  generateToken() {
    const cardData = this.formCard.get('name')?.value;
    const route = '/companies/edit-company';
    if (cardData !== null && cardData !== "") {
      this.companyService
        .postCompanyRedsys(this.company.uuid, { name: cardData, route: route})
        .subscribe(
          (result) => {
            localStorage.setItem('cardName', cardData);
            result = JSON.parse(result);
            this.translate
              .get(["card.redirect", "insurance.continue"])
              .pipe(take(1))
              .subscribe((transl) => {
                swal
                  .fire({
                    title: "",
                    html: `<html>
              <script></script>
              <div class="dialog-container">
                <h1>${transl["card.redirect"]}</h1>
                <form method="post" action=${result.url}>
                  <input type="text" name="Ds_SignatureVersion" value=${result.body.Ds_SignatureVersion} hidden />
                  <input type="text" name="Ds_MerchantParameters" value=${result.body.Ds_MerchantParameters} hidden />
                  <input type="text" name="Ds_Signature" value=${result.body.Ds_Signature} hidden />
                  <button type="submit" style="background-color: #0cc27e !important;
                  color: #fff !important;
                  border-color: #0cc27e;
                  font-weight: 400;
                  text-align: center;
                  border: 1px solid transparent;
                  padding: 0.375rem 0.75rem;
                  font-size: 1rem;
                  line-height: 1.5;
                  border-radius: 0.25rem;
                  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;">${transl["insurance.continue"]}</button>
                </form>
              </div>
              </html>`,
                    icon: "warning",
                    width: "650px",
                    customClass: {
                      htmlContainer: "card-swal",
                    },
                    showConfirmButton: false,
                  })
                  .then((action) => {
                    if (!action.dismiss) {
                    }
                  });
              });
          },
          (err) => {
            this.translate.get("company.card.error").subscribe((resp) => {
              this.ngxToastrService.typeInfo(null, resp);
            });
          }
        );
    }
  }

  onImageChange(event) {
    this.imageError = null;
    if (event.target.files && event.target.files[0]) {
      // Size Filter Bytes
      const max_size = 2000000;
      const allowed_types = ["image/png", "image/jpeg"];
      const max_height = 15200;
      const max_width = 25600;
      if (event.target.files[0].size > max_size) {
        this.imageError =
          "Maximum size allowed is " + max_size / 1000000 + "Mb";

        return false;
      }

      if (!_lodash.includes(allowed_types, event.target.files[0].type)) {
        this.imageError = "Only Images are allowed ( JPG | PNG )";
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget["height"];
          const img_width = rs.currentTarget["width"];

          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              "Maximum dimentions allowed " +
              max_height +
              "*" +
              max_width +
              "px";
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            this.profileImage = event.target.files[0];
            this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  removeImage() {
    this.isLoading = true;
    this.cardImageBase64 = null;
    this.isImageSaved = false;
    this.profileImage = null;
    this.subscriptions.push(
      this.companyService.deleteImage(this.company.uuid).subscribe(
        (res) => {
          this.isLoading = false;
          this.translate
            .get(
              "company.company-detail.company-edit"
            )
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess(null, result);
              this.utilsService.imageChanged(null);
            });
          this.company = res;
        },
        (error) => {
          this.isLoading = false;
          this.translate
            .get(
              "company.company-detail.error-company-edit"
            )
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, error.error.message);
            });
        }
      )
    )
  }

  onSubmit() {
    this.isInvalid = false;
    this.isLoading = true;
    const parameters = [];
    parameters["general"] = this.formGeneral.getRawValue();
    parameters["general"]["image"] = this.profileImage;
    parameters["billing"] = this.formBilling.getRawValue();
    const suppliersToSubmit = [];

    parameters["general"].agencyUuid =
      parameters["general"].agencyUuid !== null
        ? parameters["general"].agencyUuid
        : this.agencyUuid;

    if (this.suppliersSelected.length > 0) {
      this.suppliersSelected.forEach((s) => suppliersToSubmit.push(s.value));
      parameters["general"]["suppliers"] = suppliersToSubmit;
    }

    let attributes = new FormData();

    if (!_lodash.isEqual(parameters["billing"])) {
      attributes["billingData"] = parameters["billing"];
    }
    if (!_lodash.isEqual(parameters["general"])) {
      attributes = { ...attributes, ...parameters["general"] };
    }
    if (!_lodash.isEmpty(attributes)) {
      attributes["flightDiscount"] = {
        international: attributes["internationalFlightDiscount"]?.toString(),
        local: attributes["localFlightDiscount"]?.toString(),
        europe: attributes["europeFlightDiscount"]?.toString(),
      };
      delete attributes["internationalFlightDiscount"];
      delete attributes["localFlightDiscount"];
      delete attributes["europeFlightDiscount"];
      if (attributes["hotelComission"] >= 0) {
        attributes["hotelComission"] = attributes["hotelComission"].toString();
      }
      if (attributes["trainComission"] >= 0) {
        attributes["trainComission"] = attributes["trainComission"].toString();
      }
      // attributes['hubUuid'] = this.hub
      const image = attributes["image"];
      delete attributes["image"];
      let formData = new FormData();
      formData = this.utilsService.toFormData(attributes, formData);
      if (image && image.name) {
        formData.append("file", image, image.name);
      }
      if (this.company) {
        this.subscriptions.push(
          this.companyService
            .putCompany(formData, this.company.uuid)
            .subscribe(() => {
              this.isLoading = false;
              this.translate
                .get("company.company-homepage.card.edited-company")
                .subscribe((result) => {
                  this.ngxToastrService.typeSuccess(null, result);
                  const queryParams = this.route.snapshot.queryParams;
                  if (
                    queryParams &&
                    queryParams["Ds_SignatureVersion"] &&
                    queryParams["Ds_MerchantParameters"]
                  ) {
                    this.router.navigate(["/companies"]);
                  } else {
                    this._location.back();
                  }
                });
            },
              (err) => {
                this.isLoading = false;
                this.translate
                  .get("company.company-homepage.card.error-edit-company")
                  .subscribe((result) => {
                    this.ngxToastrService.typeInfo(result, err.error.message);
                  });
              })
        );
      } else {
        this.subscriptions.push(
          this.companyService.postCompany(formData).subscribe((company) => {
            this.isLoading = false;
            const navigationExtras: NavigationExtras = {
              state: {
                company: company,
                firstUser: true,
              },
            };
            this.router.navigate(["user/create-user"], navigationExtras);
          })
        );
      }
    }
  }

  onSubmitEdit() {
    this.isLoading = true;
    let attributes = {};
    const general = {
      name: this.company.name,
      cif: this.company.cif,
      email: this.company.email,
      address: this.company.address,
      city: this.company.city,
      province: this.company.province,
      postalCode: this.company.postalCode,
      country: this.company.country,
      agencyUuid: this.company?.agencyUuid,
    };
    const parameters = [];
    parameters["general"] = this.formGeneral.getRawValue();
    parameters["general"]["image"] = this.profileImage;
    parameters["billing"] = this.formBilling.getRawValue();
    if (!_lodash.isEqual(this.company.billingData, parameters["billing"])) {
      attributes["billingData"] = parameters["billing"];
    }
    if (!_lodash.isEqual(general, parameters["general"])) {
      attributes = { ...attributes, ...parameters["general"] };
    }
    if (!_lodash.isEmpty(attributes)) {
      attributes["flightDiscount"] = {
        international: attributes["internationalFlightDiscount"]?.toString(),
        local: attributes["localFlightDiscount"]?.toString(),
        europe: attributes["europeFlightDiscount"]?.toString(),
      };
      delete attributes["internationalFlightDiscount"];
      delete attributes["localFlightDiscount"];
      delete attributes["europeFlightDiscount"];
      if (attributes["hotelComission"] >= 0) {
        attributes["hotelComission"] = attributes["hotelComission"]?.toString();
      }
      if (attributes["trainComission"] >= 0) {
        attributes["trainComission"] = attributes["trainComission"]?.toString();
      }
      const image = attributes["image"];
      delete attributes["image"];
      let formData = new FormData();
      formData = this.utilsService.toFormData(attributes, formData);
      if (image && image.name) {
        formData.append("file", image, image.name);
      }
      this.companyService.putCompany(formData, this.company.uuid).subscribe(
        () => {
          this.isLoading = false;
          this.translate
            .get("company.company-homepage.card.edited-company")
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess(null, result);
              // Verifica si la URL tiene los parámetros específicos
              const queryParams = this.route.snapshot.queryParams;
              if (
                queryParams &&
                queryParams["Ds_SignatureVersion"] &&
                queryParams["Ds_MerchantParameters"]
              ) {
                // Redirige a la ruta deseada en lugar de hacer el back()
                this.router.navigate(["/companies"]);
              } else {
                // Realiza el location.back() si no se cumplen las condiciones
                this._location.back();
              }
            });
        },
        (err) => {
          this.isLoading = false;
          this.translate
            .get("company.company-homepage.card.error-edit-company")
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err.error.message);
            });
        }
      );
    }
  }

  responseToken(passenger) {
    const father = document.getElementById("paytpvPaymentForm");
    const child = document.getElementById("paytpvTokenGenerate");
    if (child !== null) {
      father.removeChild(child);
    }
    const newInputField = document.createElement("input");
    newInputField.type = "hidden";
    newInputField.name = "paytpvToken";
    newInputField.id = "paytpvTokenGenerate";
    newInputField.value = passenger.paytpvToken;
    const paytpvPaymentForm = document.forms["paytpvPaymentForm"];
    paytpvPaymentForm.appendChild(newInputField);
  }

  isNumber($event): boolean {
    return (
      $event.key === "0" ||
      $event.key === "1" ||
      $event.key === "2" ||
      $event.key === "3" ||
      $event.key === "4" ||
      $event.key === "5" ||
      $event.key === "6" ||
      $event.key === "7" ||
      $event.key === "8" ||
      $event.key === "9" ||
      $event.key === "Backspace" ||
      $event.key === "Tab"
    );
  }

  checkSupplierInArraySelected(supplier): boolean {
    return (
      this.suppliersSelected.findIndex(
        (selected) => selected.value == supplier.value
      ) != -1
    );
  }

  onSupplierChange(supplier): void {
    const index = this.suppliersSelected.findIndex(
      (selected) => selected.value == supplier.value
    );
    if (index != -1) {
      this.suppliersSelected.splice(index, 1);
    } else {
      this.suppliersSelected.push(supplier);
    }
  }

  generateFormCard() {
    if (this.canUploadCreditCard) {
      this.formCard = this.formBuilder.group({
        cards: this.formBuilder.array([]),
        name: ['', [Validators.required]],
      });
  
      if (this.company && this.company.cardRedsys) {
        this.company.cardRedsys.forEach((card) => {
          this.addCardToForm(card);
        });
      }
    }
  }

  get cards(): UntypedFormArray {
    return this.formCard.get('cards') as UntypedFormArray;
  }

  addCardToForm(cardData: any) {
    const cardGroup = this.formBuilder.group({
      name: [cardData.name, [Validators.required]],
      token: [cardData.token],
    });
    this.cards.push(cardGroup);
  }

  getSelectedCardText(): string {
    if (this.selectedCardIndex !== null && this.company.cardRedsys[this.selectedCardIndex]) {
      const card = this.company.cardRedsys[this.selectedCardIndex];
      return card.name || '';
    }
    return '';
  }

  removeCard(index: any) {
    const cardToRemove = this.company.cardRedsys[index];

    this.translate
      .get([
        "company.card.remove-card-success",
        "company.card.error-remove-card",
        "my-bookeds.train-flight.ff-card-delete",
        "common.confirm",
        "common.cancel",
        "my-bookeds.train-flight.deleted-ff-card",
        "my-bookeds.train-flight.deleted-ff-card-error",
      ])
      .subscribe((translate) => {
        const title = `${translate["my-bookeds.train-flight.ff-card-delete"]} "${cardToRemove.name}"?`;
        swal
          .fire({
            title: title,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0CC27E",
            cancelButtonColor: "#FF586B",
            confirmButtonText: translate["common.confirm"],
            cancelButtonText: translate["common.cancel"],
            customClass: {
              confirmButton: "btn btn-success btn-raised",
              cancelButton: "btn btn-danger btn-raised mr-5",
            },
            buttonsStyling: false,
          }).then((action) => {

            if(action.value){
              this.companyService.removeCardRedsys(this.company, cardToRemove).subscribe(
              (res) => {
                if (res && res.cardRedsys) {
                  res.cardRedsys = res.cardRedsys.filter(card => card.token);
                }
                this.company = res;
                this.generateFormCard();
                this.ngxToastrService.typeSuccess(
                  null,
                  translate["company.card.remove-card-success"]
                );
              },
              () => {
                this.ngxToastrService.typeInfo(
                  null,
                  translate["company.card.error-remove-card"]
                );
              }
            );
            this.generateFormCard();
            }
          })
        })
  }

  getSuppliers() {
    // TODO: cuando esté listo, cambiar esto por llamada al servicio getSuppliers
    this.suppliers = [
      {
        name: "OkTicket",
        value: "okticket",
      },
    ];
    if (
      this.company &&
      this.company.suppliers != null &&
      this.company.suppliers.includes("okticket")
    ) {
      this.suppliersSelected.push({
        name: "OkTicket",
        value: "okticket",
      });
    }
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our supplier
    if ((value || "").trim()) {
      this.suppliers.forEach((s) => {
        if (
          s.name.includes(value) ||
          s.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        ) {
          this.suppliersSelected.push(s);
        }
      });
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  getSuppliersList(value) {
    this.suppliersMatchedList = [];
    if (value !== "" && value.length > 2) {
      this.suppliers.forEach((s) => {
        if (
          s.name.includes(value) ||
          s.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        ) {
          this.suppliersMatchedList.push(s);
        }
      });
    } else {
      this.suppliersMatchedList = [];
    }
  }

  copyData(event) {
    if (event.checked) {
      this.formBilling.setControl(
        "identificator",
        new UntypedFormControl(this.formGeneral.get("cif")).value
      );
      this.formBilling.setControl(
        "address",
        new UntypedFormControl(this.formGeneral.get("address")).value
      );
      this.formBilling.setControl(
        "town",
        new UntypedFormControl(this.formGeneral.get("city")).value
      );
      this.formBilling.setControl(
        "province",
        new UntypedFormControl(this.formGeneral.get("province")).value
      );
      this.formBilling.setControl(
        "postalCode",
        new UntypedFormControl(this.formGeneral.get("postalCode")).value
      );
      this.formBilling.setControl(
        "country",
        new UntypedFormControl(this.formGeneral.get("country")).value
      );
    } else {
      this.formBilling.setControl("identificator", new UntypedFormControl(""));
      this.formBilling.setControl("address", new UntypedFormControl(""));
      this.formBilling.setControl("town", new UntypedFormControl(""));
      this.formBilling.setControl("province", new UntypedFormControl(""));
      this.formBilling.setControl("postalCode", new UntypedFormControl(""));
      this.formBilling.setControl("country", new UntypedFormControl(""));
    }
  }

  getPlans() {
    this.subscriptions.push(
      this.planService.getPlans().subscribe((res) => {
        this.plans = res;
      })
    );
  }

  acceptConditions(event) {
    this.hasAcceptConditions = event.checked;
  }

  openConditions() {
    const modalRef = this.modalService.open(StaticsConditionsComponent, {
      size: "lg",
      centered: true,
    });
    modalRef.componentInstance.isSignUp = true;
  }

  getAllAgencies() {
    this.subscriptions.push(
      this.agencyService.getAgencies().subscribe((agencies) => {
        this.agencies = agencies;
      })
    );
  }

  loadGroups(uuid: string) {
    this.subscriptions.push(
      this.groupService
      .getGroups(uuid)
      .pipe(take(1))
      .subscribe(
        (groups: GroupRestriction[]) => {
          this.groups = this.utilsService.sortArrayByDate(groups).reverse();

          this.groups.map((g) => {
            if (g.name === "Default") {
              if (this.langService.getLanguage() === "es") {
                g.name = "No asignado";
              }
              const index = this.groups.indexOf(g);
              this.groups.splice(index, 1);
              this.groups.push(g);
            }
          });
        },
        (err) => {
          this.translate
            .get("company.company-detail.error-get-groups")
            .pipe(take(1))
            .subscribe((result) => {
              this.ngxToastrService.typeInfo(result, err);
            });
        }
      )
    )
  }

  getCompanyUsers(uuid: string) {
    this.companyService.getCompanyUsers(uuid).subscribe(
      (res) => {
        this.companyUsers = res.map(user => ({
          name: user.name,
          lastname: user.lastname,
          uuid: user.uuid,
          paymentCards: user.paymentCards || []
        }));
      if (this.selectedCardIndex !== null && this.company?.cardRedsys) {
        this.updateSelectedUsers();
      }
      },
      (err) => {}
    );
  }

  updateSelectedUsers() {
    if (this.selectedCardIndex === null || !this.company?.cardRedsys) {
      this.selectedUsers = [];
      return;
    }
  
    const selectedCard = this.company.cardRedsys[this.selectedCardIndex];
  
    if (!selectedCard) {
      this.selectedUsers = [];
      return;
    }
  
    const { name, token } = selectedCard;
  
    this.selectedUsers = this.companyUsers
      .filter(user =>
        user.paymentCards.some(
          card => card.name === name && card.token === token
        )
      )
      .map(user => user.uuid);
  }

  assignCardToScope(cardIndex: number): void {
    if (!this.company || !this.company.uuid) {
      return;
    }
  
    const selectedCard = this.company.cardRedsys[cardIndex];
    if (!selectedCard) {
      return;
    }
  
    const { name, token } = selectedCard;
    const companyUuid = this.company.uuid;
  
    const payload: { scope: string; target: string[]; card: { name: string; token: string } } = {
      scope: '',
      target: [],
      card: { name, token }
    };
  
    if (this.assignmentScope === 'all') {
      payload.scope = 'all';
      payload.target = [];
    } else if (this.assignmentScope === 'departments') {
      payload.scope = 'departments';
      payload.target = this.selectedDepartments;
    } else if (this.assignmentScope === 'users') {
      payload.scope = 'users';
      payload.target = this.selectedUsers;
    }
  
    this.isLoading = true;
    this.companyService
      .assignCard(companyUuid, payload)
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.translate.get("company.card.card-added").subscribe((resp) => {
            this.ngxToastrService.typeSuccess(null, resp);
          });
          this.getCompanyUsers(companyUuid);
        },
        error: (err) => {
          this.isLoading = false;
          this.translate.get("company.card.error").subscribe((resp) => {
            this.ngxToastrService.typeInfo(null, resp);
          });
        }
      });
  }
  
  getSelectedCardName(): string {
    if (this.company?.cardRedsys && this.selectedCardIndex !== null && this.selectedCardIndex >= 0) {
      return this.company.cardRedsys[this.selectedCardIndex]?.name || '';
    }
    return '';
  }
  
  isAssignButtonDisabled(): boolean {
    if (this.assignmentScope === 'departments') {
      return this.selectedDepartments.length === 0;
    }
  
    if (this.assignmentScope === 'users') {
      return this.selectedUsers.length === 0;
    }
  
    if (this.assignmentScope === 'all') {
      return false;
    }
    return true;
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }

  ngOnDestroy() {
    if (this.payCometScript) {
      document.head.removeChild(this.payCometScript);
    }
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.utilsService.removeItemFromLocalStorage("companyData");
    this.utilsService.removeItemFromLocalStorage("maxFlightPercentage");
    this.utilsService.removeItemFromLocalStorage("maxHotelPercentage");
    this.utilsService.removeItemFromLocalStorage("isLoading");
    this.utilsService.removeItemFromLocalStorage("isCreate");
    this.utilsService.removeItemFromLocalStorage("signUp");
    this.utilsService.removeItemFromLocalStorage("agencyUuid");
  }
}
