import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Booked } from "@models";
import { Store } from "@ngrx/store";
import { BookService, UserService, UtilsService } from "@services";
import { AppState } from "app/modules/businessEntities/company/company.reducer";
import * as fromMyBookedsActions from "app/store/actions/my-bookeds.actions";
import { BehaviorSubject, Subscription } from 'rxjs';
import { take } from "rxjs/operators";

@Component({
  selector: "app-booked-container",
  templateUrl: "./booked-container.component.html",
  styleUrls: ["./booked-container.component.scss"],
})
export class BookedContainerComponent implements OnInit, OnDestroy {
  @Input() paginationConf;
  @Input() bookeds: Booked[];
  @Input() form: UntypedFormGroup;
  @Input() statusOptions;
  @Input() isUser: boolean;
  @Input() place: "user" | "user-company" | "company";
  @Input() uuid: string;
  @Input() companyUuid?: string;
  @Input() service: string;
  @Input() userUuid: string;
  @Input() isEmployee: boolean;
  @Input() groupRestrictionUuid: string;
  @Input() nextTravelsChecked
  @Output() changeTypeEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeComponentEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadTypeEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectChangeToGrandparent = new EventEmitter<string>()
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  fromDate: any;
  vyooName = "Vyootrip";
  toDate: any;
  loading = false;
  requestCancelBooked: boolean;
  requestCancelFlight: boolean;
  nameFilters;
  typeFilters: any[];
  subscriptions: Subscription[] = [];
  createdFromDate: any;
  createdToDate: any;
  lowValue = 0;
  highValue = 4;
  pagination: any;
  @ViewChild("paginator") paginator: MatPaginator;

  constructor(
    private store: Store<AppState>,
    private userService: UserService,
    public utilsService: UtilsService,
    private bookService: BookService
  ) {
    this.requestCancelBooked = false;
    this.requestCancelFlight = false;
    this.isLoading = this.bookService.isLoading;
  }

  ngOnInit() {
    this.bookService.loading.subscribe((loading) => {
      this.loading = loading;
    });
    this.nameFilters = this.getNameFilters();
    this.typeFilters = this.getTypeFilters();
    this.store
      .select("auth")
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res.isAuthenticated) {
          const userType = res.user.type;
          if (userType.toLowerCase() !== "employee") {
            const uuidUser = res?.user?.user?.uuid
              ? res?.user?.user?.uuid
              : res.user.uuid;

            this.userService
              .getGroupUser(uuidUser)
              .pipe(take(1))
              .subscribe((value) => {
                this.requestCancelBooked = value.filters.requestCancelBooked;
                this.requestCancelFlight = value.filters.requestCancelFlight;
                this.loading = false;
              }, (err => {}));
          } else {
            // Si no es tipo USER, significa que tiene privilegios para cancelar
            this.requestCancelFlight = true;
            this.requestCancelBooked = true;
            this.loading = false;
          }
        }
      });
    this.bookeds = this.utilsService.sortArrayByDate(this.bookeds);
  }

  getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;

    return event;
  }

  setPage() {
    this.paginator.firstPage();
  }

  get f() {
    return this.form.controls;
  }

  onPager(page: number) {
    this.paginationConf.page = page;

    const action = new fromMyBookedsActions.MyBookedsPageAction(
      this.paginationConf
    );
    this.store.dispatch(action);
  }

  nextBookings(event: any[]) {
    this.bookeds = event;
    this.paginator.firstPage();
  }

  getNameFilters() {
    return [
      "string",
      "status",
      "checkIn",
      "departDate",
      "beginDate",
      "checkOut",
      "returnDate",
      "endDate",
      "createdAt",
    ];
  }

  getFilters() {
    return [
      this.f ? this.f.modelHotel.value : '',
      this.f ? this.f.statusOptions.value : '',
      this.fromDate,
      this.toDate,
      [this.createdFromDate, this.createdToDate],
    ];
  }

  getTypeFilters(): any[] {
    return ["string", "status", "dateStart", "dateEnd", "date"];
  }

  changeType(event) {
    this.service = event.value;
    this.paginator.firstPage();
    this.changeTypeEmit.emit(event);
  }

  changeComponent(event) {
    this.changeComponentEmit.emit(event);
  }

  reloadType(event) {
    this.reloadTypeEmit.emit(event);
  }

  onSelectChange(selectedValue: string) {
    this.selectChangeToGrandparent.emit(selectedValue);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
