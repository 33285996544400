import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Booked } from "@models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TokenService } from "./token.service";

@Injectable()
export class BookedService {
  constructor(private http: HttpClient, private tokenService: TokenService) {}

  getBookedsByCompany(uuidCompany: string): Observable<Booked[]> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<Booked[]>(
        `${environment.baseUrlApi}/companies/${uuidCompany}/bookeds`,
        { headers: reqHeaders }
      )
      .pipe(map((res: any) => res));
  }

  getBookedsByAgency(uuidAgency: string): Observable<Booked[]> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<Booked[]>(
        `${environment.baseUrlApi}/agencies/${uuidAgency}/bookeds`,
        { headers: reqHeaders }
      )
      .pipe(map((res: any) => res));
  }

  getBookedsByHub(uuidHub: string): Observable<Booked[]> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<Booked[]>(`${environment.baseUrlApi}/hubs/${uuidHub}/bookeds`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  getBookedsByUser(uuidUser: string): Observable<Booked[]> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<Booked[]>(`${environment.baseUrlApi}/users/${uuidUser}/bookeds`, {
        headers: reqHeaders,
      })
      .pipe(map((res) => res));
  }

  getBookedsPdf(bonoFileUrl: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get(`${environment.baseUrlApi}${bonoFileUrl}`, {
      headers: reqHeaders,
      responseType: "blob",
    });
  }

  getFileName(bonoFileUrl: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get(`${environment.baseUrlApi}/bookeds/file-name/${bonoFileUrl}`, {
      headers: reqHeaders,
    });
  }

  getBooked(uuid: string): Observable<Booked> {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<Booked>(`${environment.baseUrlApi}/bookeds/${uuid}`, {
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  getCustomBookingPdf(bonoFileUrl: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.get(`${environment.baseUrlApi}${bonoFileUrl}`, {
      headers: reqHeaders,
      responseType: "blob",
    });
  }

  getMyBookeds() {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http
      .get<any>(`${environment.baseUrlApi}/bookeds`, { headers: reqHeaders })
      .pipe(map((res: any) => res));
  }

  createBooked(params) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(`${environment.baseUrlApi}/bookeds`, params, {
      headers: reqHeaders,
    });
  }

  createCustomBooking(params, requestUuid?: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const url = requestUuid
      ? `${environment.baseUrlApi}/bookeds/admin/custom?requestUuid=${requestUuid}`
      : `${environment.baseUrlApi}/bookeds/admin/custom`;
    return this.http.post<any>(url, params, { headers: reqHeaders });
  }

  updateBooked(uuidBooked: string, status = "cancelled") {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const body = { status };
    return this.http.put<any>(
      `${environment.baseUrlApi}/bookeds/${uuidBooked}`,
      body,
      { headers: reqHeaders }
    );
  }

  requestAuthorization(body: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/authorization`,
      body,
      { headers: reqHeaders }
    );
  }

  changeStatusAuthorization(body: any, uuid: string, type: string) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.put<any>(
      `${environment.baseUrlApi}/authorization/${uuid}/${type}`,
      body,
      { headers: reqHeaders }
    );
  }

  getAuthorization(params) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    const reqParams = new HttpParams({ fromObject: params });
    return this.http
      .get<any>(`${environment.baseUrlApi}/authorization`, {
        params: reqParams,
        headers: reqHeaders,
      })
      .pipe(map((res: any) => res));
  }

  sendCustomHotelRequest(body) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/hotels/request-hotel`,
      body,
      { headers: reqHeaders }
    );
  }

  sendEmailOperations(text: any) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/bookeds/email/operations`,
      text,
      { headers: reqHeaders }
    );
  }

  putCustomBooking(uuid: string, body: FormData) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/bookeds/update-custom/${uuid}`,
      body,
      { headers: reqHeaders }
    );
  }

  putBooking(uuid: string, body: FormData) {
    const token = this.tokenService.getToken();
    const reqHeaders = {
      Authorization: `JWT ${token}`,
    };
    return this.http.post<any>(
      `${environment.baseUrlApi}/bookeds/update/${uuid}`,
      body,
      { headers: reqHeaders }
    );
  }

   // CHECKED RATES
   // (It should go in hotels.service, but it gives an error.)
   checkRate(params: any) {
    return this.http
      .post<any>(
        `${environment.baseUrlApi}/bookeds/checkRate`,
        params
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
