import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { User } from "@core/models";
import { LetItGoType } from "@core/models/letitgo-type";
import {
  BookedService,
  LanguageService,
  NgxToastrService,
  TrainService,
  TravelUtilsService,
  UserService,
  UtilsService,
} from "@core/services";
import { NgbDateParserFormatter, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DialogAssignProjectComponent } from "@shared/component/dialog-assign-project/dialog-assign-project.component";
import { ViewPdfModalComponent } from "app/modules/businessEntities/user/user-detail/view-pdf-modal/view-pdf-modal.component";
import * as _lodash from "lodash-es";
import moment from "moment";
import "moment/min/locales.min";
import { Observable, Subject, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import swal from "sweetalert2";
import { AppState } from "../../search/search.reducer";

@Component({
  selector: "app-train-confirm-stepper-container",
  templateUrl: "./train-confirm-stepper-container.component.html",
  styleUrls: ["./train-confirm-stepper-container.component.scss"],
})
export class TrainConfirmStepperContainerComponent
  implements OnInit, OnDestroy
{
  navigationExtras: any;
  searchConfig: any;
  trainsComplements: any;
  graphRes: any;
  canChooseSeat = false;
  stepperTabs = [];
  trainStationList = [];
  trainsList: any;
  outwardWagonList: any[];
  returnWagonList: any[];
  outwardSeats: any[] = [];
  returnSeats: any[] = [];
  firstTrain = true;
  eventsSubject: Subject<void> = new Subject<void>();
  passengerForm: UntypedFormGroup;
  isAdmin: boolean = false;
  canUseTRM: boolean;
  userToBook: string;
  subscriptions: Subscription[] = [];
  uuidUser: string;
  isUser: boolean;
  authSubscription: Subscription;
  userType = "USER";
  user: User;
  canRequest = true;
  userSimulated: boolean;
  documentTypeOptions = [
    { value: "PASSPORT", text: "Pasaporte" },
    { value: "IDENTITY_CARD", text: "DNI/NIF" },
    { value: "NIE", text: "NIE" },
  ];
  farePriceMap = [
    { fare: "B", pricePerSeat: 8 },
    { fare: "A", pricePerSeat: 8 },
    { fare: "X", pricePerSeat: 5 },
    { fare: "Z", pricePerSeat: 5 },
    { fare: "P", pricePerSeat: 0 },
    { fare: "I", pricePerSeat: 0 },
    { fare: "Y", pricePerSeat: 0 },
  ];
  amount: string;
  confirmedSelection: any[];
  apiSession: string;
  isMocked = false;
  travelData: any;
  paxType: string;
  params: any;
  bookFailed: any = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private utilsService: UtilsService,
    private route: ActivatedRoute,
    private userService: UserService,
    private trainService: TrainService,
    public translate: TranslateService,
    private ngbDateParser: NgbDateParserFormatter,
    private ngxToastrService: NgxToastrService,
    private travelUtilsService: TravelUtilsService,
    private bookingService: BookedService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private langService: LanguageService
  ) {
    this.subscriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() !== "USER" && (s.user.type?.toUpperCase() === "COMPANY" || s.user.type?.toUpperCase() === "EMPLOYEE" ||
            s.user.type?.toUpperCase() === "AGENCY" || s.user.type?.toUpperCase() === "HUB")) {
          this.isAdmin = true;
          this.userToBook = localStorage.getItem("userToBook")
            ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
            : null;
          this.canUseTRM = s.user.type
            ? s.user.plan && s.user.plan.permissions.canUseTRM
            : s.user.user.plan && s.user.user.plan.permissions.canUseTRM;
        }
      })
    );
    moment.locale(this.langService.getLanguage());

    this.uuidUser = this.userToBook;

    this.navigationExtras = this.router.getCurrentNavigation().extras.state;
    if (this.navigationExtras != null) {
      this.searchConfig = this.navigationExtras.searchConfig;
      this.trainsComplements = this.navigationExtras.trainsComplements;
      this.graphRes = this.navigationExtras.graphRes;
      this.canChooseSeat = this.navigationExtras.seatSelection;
      this.trainStationList = this.navigationExtras.trainStationList;
      this.trainsList = this.navigationExtras.trainsList;
      this.confirmedSelection = this.navigationExtras.confirmedSelection;
      this.apiSession = this.navigationExtras.apiSession;
      this.amount = this.navigationExtras.amount;
      this.uuidUser = this.navigationExtras.uuidUser;
      this.bookFailed = this.navigationExtras.bookFailed;
      if (this.canChooseSeat) {
        this.stepperTabs.push({
          title: "train.confirm-stepper-container.stepper.seat-selection",
          selected: true,
          id: "seatSelection",
          enabled: true,
        });
      }
      this.stepperTabs.push({
        title:
          this.searchConfig.travelers.length > 1
            ? "train.confirm-stepper-container.stepper.passengers-info"
            : "train.confirm-stepper-container.stepper.passenger-info",
        selected: this.stepperTabs.length > 0 ? false : true,
        id: "passengerForm",
        enabled: this.stepperTabs.length > 0 ? false : true,
      });
      this.stepperTabs.push({
        title: "train.confirm-stepper-container.stepper.confirmation",
        selected: false,
        id: "confirmBooking",
        enabled: false,
      });
    } else {
      this.router.navigate(["/train"]);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(
      (this.authSubscription = this.store
        .select("auth")
        .pipe(take(1))
        .subscribe((auth: any) => {
          this.userType = auth.user.type;
          if (auth.isAuthenticated) {
            if (this.uuidUser == null) {
              this.uuidUser = this.route.snapshot.paramMap.has("id")
                ? this.route.snapshot.paramMap.get("id")
                : auth.user.uuid;
            }
            if (auth.user.type?.toUpperCase() === "USER") {
              this.user = auth.user;
              this.userService
                .getGroupUser(this.user.uuid)
                .pipe(take(1))
                .subscribe((value) => {
                  if (value) {
                    this.canRequest = !value.filters.onlyTrainAuthorization;
                  }
                  this.createForm();
                });
            } else {
              if (
                !this.route.snapshot.paramMap.get("id") &&
                this.uuidUser == null
              ) {
                this.router.navigate([""]);
                return false;
              }
              this.subscriptions.push(
                this.userService.getUser(this.uuidUser).subscribe((s) => {
                  this.user = s;
                  this.createForm();
                })
              );
            }
          }
        }))
    );

    this.store
      .select("auth")
      .pipe(take(1))
      .subscribe((auth) => {
        if (auth.isAuthenticated) {
          this.store
            .select("search")
            .pipe(take(1))
            .subscribe((search) => {
              if (this.uuidUser == null) {
                this.uuidUser = this.route.snapshot.paramMap.has("id")
                  ? this.route.snapshot.paramMap.get("id")
                  : auth.user.uuid;
              }
              this.userSimulated = !_lodash.isEmpty(this.uuidUser);
            });
        }
        const st: any = auth;
        this.isUser = st.user.type?.toUpperCase() === "USER" ? true : false;
      });

    if (this.canChooseSeat) {
      this.handleSeats();
    }
  }

  changeStepperTab(tab: any, isClicked: boolean = false) {
    if (tab.enabled === true && isClicked) {
      this.stepperTabs.forEach((t) => {
        t.selected = false;
      });
      tab.selected = true;
    } else if (isClicked === false) {
      this.stepperTabs.forEach((t) => {
        t.selected = false;
      });
      tab.enabled = true;
      tab.selected = true;
    }
  }

  isTabSelected(tabId: string) {
    const foundSeatTab = this.stepperTabs.find((t) => t.id === tabId);
    if (foundSeatTab != null) {
      return foundSeatTab.selected;
    }
  }

  parseDate(date: any) {
    const dateD = new Date(date.year, date.month - 1, date.day);
    const momentDate = moment(dateD);
    return `${momentDate.format("dddd")}, ${momentDate.format("DD/MM/yyyy")}`;
  }

  parseTrainCodeToName(code: string) {
    return this.trainStationList.find((station) => station.value === code)
      .title;
  }

  handleSeats() {
    this.outwardWagonList = [];
    this.returnWagonList = [];

    this.typeWagon(this.graphRes.ida, false);

    if (!this.searchConfig.oneWay && this.graphRes.vuelta.cars != null) {
      this.typeWagon(this.graphRes.vuelta, true);
    }

    this.returnWagonList.reverse();
  }

  typeWagon(wagonArray, isReturn) {
    if (wagonArray.Errors === null) {
      wagonArray.cars.forEach((car) => {
        const wagon: wagon = {
          carCode: car.car_code,
          classCode: car.class_code,
          groupCode: car.group_code,
          freeSeats: car.num_free_seats,
          classDesc: car.class_desc,
          carNumber: car.car_number,
          isSilentWagon: car.silent_car,
          rowSeats: this.typeWagonList(car.seats),
        };
        if (!this.searchConfig.oneWay && isReturn && !wagon.isSilentWagon) {
          this.returnWagonList.push(wagon);
        } else if (!wagon.isSilentWagon) {
          this.outwardWagonList.push(wagon);
        }
      });
    }
  }

  typeWagonList(wagonList: wagon[]) {
    const typedRowSeatList: rowSeat[] = [];
    let seats: seat[] = [];
    wagonList.forEach((wagon: any) => {
      wagon.forEach((seat: any) => {
        const seatTyped: seat = {
          seatNumber: seat.seat_code,
          seatDirection: seat.seat_direction === "true" ? true : false,
          isFreeSeat: seat.seat_state === "true" ? true : false,
          type: seat.seat_type,
          selected: false,
        };

        seats.push(seatTyped);
      });
      const rowWithSeat: rowSeat = {
        seats: seats,
      };
      typedRowSeatList.push(rowWithSeat);
      seats = [];
    });
    this.addTableObject(typedRowSeatList);
    return typedRowSeatList;
  }

  addTableObject(rowSeat: rowSeat[]) {
    let table: seat;
    let firstOfTwoTables = true;
    rowSeat.forEach((rowSeat: rowSeat) => {
      for (let index = 0; index < rowSeat.seats.length; index++) {
        const seat = rowSeat.seats[index];
        if (seat.type?.toUpperCase() === "T") {
          if (firstOfTwoTables) {
            table = {
              seatNumber: "TABLE",
              seatDirection: null,
              isFreeSeat: false,
              type: "TABLE",
              selected: false,
            };
            if (
              index < rowSeat.seats.length &&
              index + 1 < rowSeat.seats.length
            ) {
              rowSeat.seats.splice(index + 1, 0, table);
            } else {
              rowSeat.seats.splice(index, 0, table);
            }
          }
          firstOfTwoTables = !firstOfTwoTables;
        }
      }
    });
  }

  createForm() {
    this.passengerForm = this.fb.group({
      users: this.fb.array([
        this.fb.group({
          name: [this.user.name ? this.user.name : "", Validators.required],
          surname: [
            this.user.lastname ? this.user.lastname.split(" ")[0] : "",
            Validators.required,
          ],
          lastSurname: [
            this.user.lastname ? this.user.lastname.split(" ")[1] : "",
          ],
          email: [
            this.user.email ? this.user.email : "",
            [Validators.required, Validators.email],
          ],
          documentType: [
            this.user.dni
              ? this.documentTypeOptions[1].text
              : this.user.passport
              ? this.documentTypeOptions[0].text
              : "",
            Validators.required,
          ],
          documentNumber: [
            this.user.dni
              ? this.user.dni
              : this.user.passport
              ? this.user.passport
              : "",
            Validators.required,
          ],
          age: [
            this.user.birthdate
              ? moment().diff(this.user.birthdate, "years", false)
              : "",
            Validators.required,
          ],
          phone: [this.user.phone ? this.user.phone : "", Validators.required],
          plus50: [null],
          goldenCard: [null],
          passengerRenfeCard: [this.user.renfeCard ? this.user.renfeCard : ""],
        }),
      ]),
    });

    if (this.searchConfig.travelers.length > 1) {
      for (let index = 1; index < this.searchConfig.travelers.length; index++) {
        // const element = this.searchConfig.travelers[index];
        const formArr = this.passengerForm.get("users") as UntypedFormArray;
        formArr.push(
          this.fb.group({
            name: [null, Validators.required],
            surname: [null, Validators.required],
            lastSurname: [null],
            email: [null, [Validators.required, Validators.email]],
            documentType: [null, Validators.required],
            documentNumber: [null, Validators.required],
            age: [this.searchConfig.travelers[index].age, Validators.required],
            phone: [null, Validators.required],
            plus50: [null],
            goldenCard: [null],
          })
        );
      }
    }
  }

  selectSeat(event: any) {
    if (this.firstTrain) {
      this.outwardSeats = event.outwardSeats;
    } else {
      if (this.searchConfig.oneWay === false) {
        this.returnSeats = event.returnSeats;
      }
    }
  }

  changeToReturnTrain() {
    this.firstTrain = false;
    this.eventsSubject.next();
  }

  changeToFirstTrain() {
    this.firstTrain = true;
  }

  confirmSeats() {
    if (this.outwardSeats.length > 0) {
      const bookBody = {
        apiSession: this.apiSession,
        outwardId: this.confirmedSelection[0].id,
        classCodeOutward: this.confirmedSelection[0].class,
        classSalesGroupCodeOutward: this.confirmedSelection[0].code,
        retId:
          this.confirmedSelection[1] != null
            ? this.confirmedSelection[1].id
            : "",
        classCodeReturn:
          this.confirmedSelection[1] != null
            ? this.confirmedSelection[1].class
            : "",
        classSalesGroupCodeReturn:
          this.confirmedSelection[1] != null
            ? this.confirmedSelection[1].code
            : "",
        outwardSeats: [],
        returnSeats: [],
      };
      this.outwardSeats.forEach((seat) => {
        bookBody.outwardSeats.push({
          seat_code: seat.seat_code,
          car_code: seat.car_code,
        });
      });
      this.returnSeats.forEach((seat) => {
        bookBody.returnSeats.push({
          seat_code: seat.seat_code,
          car_code: seat.car_code,
        });
      });
      if (this.searchConfig.oneWay === true) {
        delete bookBody.retId;
        delete bookBody.classCodeReturn;
        delete bookBody.classSalesGroupCodeReturn;
        delete bookBody.returnSeats;
      }
      bookBody['userUuid'] = this.uuidUser;
      this.utilsService.loadingTrains = true;
      this.subscriptions.push(
        this.trainService.bookTrainsGraph(bookBody).subscribe(
          (res) => {
            this.utilsService.loadingTrains = false;
            this.changeStepperTab(this.stepperTabs[1]);
          },
          (err) => {
            this.bookFailed = true;
            this.letItGo(false);
            this.changeStepperTab(this.stepperTabs[1]);
          }
        )
      );
    }
  }

  confirmPassengers() {
    const pasArray = [];
    this.passengerForm.value.users.forEach((p) => {
      p.documentType === "DNI/NIF"
        ? (p.documentType = "0021")
        : (p.documentType = "0022");
      let paxType = this.paxType;
      if (paxType == null) {
        switch (p.age) {
          case p.age >= 13:
            paxType = "ADT";
            break;
          case p.age >= 4:
            paxType = "CHD";
            break;
          case p.age < 4:
            paxType = "INF";
            break;
          default:
            paxType = "ADT";
        }
      }

      let passengerRenfeCard = p.passengerRenfeCard;
      // if (p.plus50) {
      //   passengerRenfeCard = p.plus50;
      // }
      // if (p.goldenCard) {
      //   passengerRenfeCard = p.goldenCard;
      // }
      p = {
        age: p.age,
        email: p.email,
        name: p.name,
        firstSurname: p.surname,
        secondSurname: p.lastSurname ?? "",
        phone: p.phone.toString().trim(),
        docType: p.documentType,
        docNumber: p.documentNumber.trim(),
        paxType: paxType,
        passengerRenfeCard:
          passengerRenfeCard != null
            ? passengerRenfeCard.toString().trim()
            : passengerRenfeCard,
      };

      if (this.outwardSeats && this.outwardSeats.length > 0) {
        p.complementsOutward = [];
        this.outwardSeats.forEach((seat) => {
          p.complementsOutward.push({
            complement_code: "SEAT",
            complement_num: 1,
          });
        });
        if (this.returnSeats && this.returnSeats.length > 0) {
          p.complementsReturn = [];
          this.returnSeats.forEach((seat) => {
            p.complementsReturn.push({
              complement_code: "SEAT",
              complement_num: 1,
            });
          });
        }
      }

      pasArray.push(p);
    });
    let params;
    pasArray.forEach((passenger) => {
      passenger.phone = passenger.phone.replace(/\s+/g, "");
      if (passenger.docType != "0022") {
        const re = /^[0-9]{8,8}[A-Za-z]$/g;
        passenger.docType = re.test(passenger.docNumber) ? "0021" : "0023";
      }
    });
    if (this.amount == null) {
      params = {
        apiSession: this.apiSession,
        passengers: pasArray,
        rates: this.confirmedSelection,
        seats: {
          outwardSeats: this.outwardSeats ? this.outwardSeats : null,
          returnSeats: this.returnSeats ? this.returnSeats : null
        },
        train: {
          amount: this.calcTotal(),
          depart: {
            departDate: this.confirmedSelection[0].travelDate,
            departTime: this.confirmedSelection[0].departTime,
          },
        },
      };
    } else {
      params = {
        apiSession: this.apiSession,
        passengers: pasArray,
        rates: this.confirmedSelection,
        seats: {
          outwardSeats: this.outwardSeats ? this.outwardSeats : null,
          returnSeats: this.returnSeats ? this.returnSeats : null
        },
        train: {
          amount: this.amount,
          depart: {
            departDate: this.confirmedSelection[0].travelDate,
            departTime: this.confirmedSelection[0].departTime,
          },
        },
      };
    }

    if (this.searchConfig.oneWay === false) {
      params["train"]["return"] = {
        returnDate: this.confirmedSelection[1].travelDate,
        returnTime: this.confirmedSelection[1].departTime,
      };
    }
    if (this.uuidUser) {
      params["userUuid"] = this.uuidUser;
    }

    this.params = params;

    this.changeStepperTab(this.stepperTabs[this.stepperTabs.length - 1]);
  }

  onGetPDF(booked: any) {
    let fileName;
    if (booked.voucherFile && Array.isArray(booked.voucherFile) && booked.voucherFile.length > 0) {
      const firstFile = booked.voucherFile[0];
      fileName = firstFile.fileName || 'train_bonus.pdf';
    } 
    else if (booked.voucherFileUrl) {
      fileName = 'train_bonus.pdf';
    }

    this.bookingService
      .getBookedsPdf(booked.voucherFileUrl)
      .pipe(take(1))
      .subscribe(
        (res) => {
          const modalRef = this.modalService.open(ViewPdfModalComponent, {
            size: "lg",
            centered: true,
          });
          modalRef.componentInstance.blobUrl = URL.createObjectURL(res);
          modalRef.componentInstance.fileName = fileName;
        },
        () =>
          this.translate
            .get(["common.error-pdf"])
            .pipe(take(1))
            .subscribe((value) =>
              this.ngxToastrService.typeInfo(null, value["common.error-pdf"])
            )
      );
  }

  handleTravelFlow(bookingUuid: string): Observable<any> {
    const checkInAux = moment(
      this.ngbDateParser.format(this.searchConfig.departDate),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const checkOutAux =
      this.searchConfig.returnDate != null
        ? moment(
            this.ngbDateParser.format(this.searchConfig.returnDate),
            "DD/MM/YYYY"
          ).format("YYYY-MM-DD")
        : null;

    const bookingData = {
      initDate: checkInAux,
      bookingUuid: bookingUuid,
      type: "train",
    };

    if (checkOutAux !== null) {
      bookingData["endDate"] = checkOutAux;
    }

    bookingData["userUuid"] = this.uuidUser;
    const dialogAssignProject = this.dialog.open(DialogAssignProjectComponent, {
      data: { bookingData, isUser: this.isUser, isNewBooking: true },
      panelClass: "new-dialog",
    });
    return dialogAssignProject.afterClosed();
  }

  goBack() {
    history.back();
  }

  letItGo(isFinal: boolean = true) {
    if (isFinal) {
      const parseStringDate = (stringDate) => {
        const date = moment(stringDate, "YYYYMMDD");

        return date.format("DD-MM-YYYY");
      };
      const departDate = parseStringDate(this.confirmedSelection[0].travelDate);
      const body = {
        itinerary: `${this.confirmedSelection[0].departureStationName} - ${this.confirmedSelection[0].arrivalStationName}`,
        travellers: this.passengerForm.value.users.map(
          (u) => `${u.name} ${u.surname}, `
        ),
        dates: `${departDate} ${this.confirmedSelection[0].departTime}`,
        price: this.calcTotal(),
        seats:
          this.outwardSeats != null || this.returnSeats != null
            ? { outwardSeats: this.outwardSeats, returnSeats: this.returnSeats }
            : "",
      };
      if (this.confirmedSelection.length > 1) {
        const returnDate = parseStringDate(
          this.confirmedSelection[1].travelDate
        );
        body.dates =
          `${departDate} ${this.confirmedSelection[0].departTime}` +
          " - " +
          `${returnDate} ${this.confirmedSelection[1].departTime}`;
      }
      this.travelUtilsService.removeTravelLocalStorage();
      this.utilsService.sendEmailOperations(
        LetItGoType.train,
        body,
        this.uuidUser
      );
      this.translate
        .get(["common.congrat", "common.operations"])
        .subscribe((response) => {
          swal
            .fire({
              position: "center",
              icon: "success",
              title: response["common.congrat"],
              text: response["common.operations"],
              showConfirmButton: false,
              timer: this.utilsService.letItGoTimer,
              timerProgressBar: true,
            })
            .then(() => {
              this.utilsService.loadingTrains = false;
              this.goToUserBookings();
            });
        });
    } else {
      this.isMocked = true;
      this.utilsService.loadingTrains = false;
    }
  }

  goToUserBookings() {
    const navigationExtras: NavigationExtras = {
      state: {
        module: "trains",
      },
    };
    if (this.isUser) {
      this.router.navigate([`/my-bookeds`], navigationExtras);
    } else {
      this.router.navigate([`/user/booked/${this.uuidUser}`], navigationExtras);
    }
  }

  checkSeatsAreCorrect() {
    if (this.searchConfig.oneway) {
      return this.outwardSeats.length === this.searchConfig.travelers.length;
    } else {
      if (this.firstTrain) {
        return this.outwardSeats.length === this.searchConfig.travelers.length;
      } else {
        return (
          this.outwardSeats.length === this.searchConfig.travelers.length &&
          this.returnSeats.length === this.searchConfig.travelers.length
        );
      }
    }
  }

  calcTotal() {
    const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;
    let total = parseFloat(this.amount);
    if (total == null || isNaN(total)) {
      const t1 = this.confirmedSelection[0].price * (this.searchConfig.adults.length + (this.searchConfig.children ? this.searchConfig.children.length : 0))
      total =
        parseFloat(t1.toFixed(2)) + 
        this.calcSeatsPrice(
          this.outwardSeats.length,
          this.confirmedSelection[0]
        );
      if (this.confirmedSelection[1] != null) {
        const t2 = this.confirmedSelection[1].price * (this.searchConfig.adults.length + (this.searchConfig.children ? this.searchConfig.children.length : 0))
        total +=
          parseFloat(t2.toFixed(2)) +
          this.calcSeatsPrice(
            this.returnSeats.length,
            this.confirmedSelection[1]
          );
      }
    }
    return round(total);
  }

  calcSeatsPrice(selectedSeats: number, selectedTrain: any) {
    let seatPrice;
    if (!this.trainsComplements) {
      seatPrice =
        selectedSeats *
        this.farePriceMap.find(
          (farePrice) => farePrice.fare === selectedTrain.code
        ).pricePerSeat;
    } else if (this.firstTrain) {
      seatPrice =
        selectedSeats *
        this.trainsComplements.ComplementosIda[0].complements.find(
          (complement) => complement.code === "SEAT"
        ).price;
    } else {
      seatPrice =
        selectedSeats *
        this.trainsComplements.ComplementosVuelta[0].complements.find(
          (complement) => complement.code === "SEAT"
        ).price;
    }
    return seatPrice;
  }
}

export interface promo {
  name: string;
  price: string;
  selected: boolean;
  code: string;
}

export interface wagon {
  carCode: string;
  classCode: string;
  groupCode: string;
  freeSeats: number;
  classDesc: string;
  carNumber: string;
  isSilentWagon: boolean;
  rowSeats: rowSeat[];
}

export interface rowSeat {
  seats: seat[];
}
export interface seat {
  seatNumber: string;
  seatDirection: boolean;
  isFreeSeat: boolean; // equivalente al seat_state: string
  type: string;
  selected: boolean;
}
