export class Flight {
  id: string;
  status: string;
  deleted: boolean;
  companyUuid: string;
  userUuid: string;
  from: string;
  to: string;
  price: string;
  departDate: string;
  returnDate: string;
  observations: string;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  voucherFile?: {
    uuid?: string;
    fileName?: string;
  }[];
  voucherFileUrl: string;
  oneWay?: boolean;
  user?: any;
  travelTitle?: string;
}
