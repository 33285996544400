import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms'
import { Company, User } from '@core/models'
import { NgbActiveModal, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { CompanyService, NgxToastrService, TravelUtilsService, UserService, UtilsService } from '@services'
import { UserCardModalComponent } from '@shared/component/user-card-modal/user-card-modal.component'
import { AppState } from 'app/app.reducer'
import moment from 'moment'
import { Subscription, take } from 'rxjs'

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit, OnDestroy {
  @Input() companyUuid: string
  @Input() userUuid: string
  @Input() groupRestrictionUuid: string
  @Input() expense
  @Output() sendInfo = new EventEmitter<any>()
  editing = false;
  loading = false
  subcriptions: Subscription[] = []
  form: UntypedFormGroup
  bodyTravel: any = {}
  file: File
  minDateNgStruct: any
  maxDateNgStruct: any
  departDataDate: any
  returnDataDate: any
  isInvalid: boolean = false
  userType: any;
  companyUsers: { name: string; lastname: string; uuid: string }[] = [];
  @Input() blobUrl?: any;
  @Input() fileBack?: any;
  files: File[] = [];
  filesToRemove: any[] = [];
  existingFiles: any[] = [];
  company: Company;
  user: User;

  constructor (
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    private travelUtilsService: TravelUtilsService,
    private utilsService: UtilsService,
    private store: Store<AppState>,
    private ngbDateParser: NgbDateParserFormatter,
    private companyService: CompanyService,
    private userService: UserService,
    private modalService: NgbModal,
  ) {}

  ngOnInit () {
    if (this.fileBack) {
      this.existingFiles = Array.isArray(this.fileBack) ? this.fileBack : [this.fileBack];
    }
    if (Array.isArray(this.files[0])) {
      this.files = this.files.reduce((acc, val) => acc.concat(val), []);
    }
    this.expense && this.expense !== null
      ? (this.editing = true)
      : (this.editing = false);
    if (this.expense) {
      const departDate = this.expense.beginDate.split('T')
      this.departDataDate = {
        year: parseInt(departDate[0].split('-')[0], 10),
        month: parseInt(departDate[0].split('-')[1], 10),
        day: parseInt(departDate[0].split('-')[2], 10)
      }

      const returnDate = this.expense.endDate.split('T')
      this.returnDataDate = {
        year: parseInt(returnDate[0].split('-')[0], 10),
        month: parseInt(returnDate[0].split('-')[1], 10),
        day: parseInt(returnDate[0].split('-')[2], 10)
      }
    }

    this.store
    .select("auth")
    .pipe(take(1))
    .subscribe((res) => {
      if (res.isAuthenticated) {
        this.userType = res.user.type;
      }
      if (this.userUuid) {
        this.userService
          .getUser(this.userUuid)
          .pipe(take(1))
          .subscribe((user: User) => {
            this.user = user;
            if (this.user?.companyUuid) {
              this.companyService.getCompany(this.user.companyUuid).subscribe(
                (company: Company) => {
                  this.company = company;
                },
                (error) => {}
              );
            }
          });
      }
    });

    this.form = this.formBuilder.group({
      file: [this.expense && this.expense !== null ? this.expense.file : null],
      type: [
        { value: this.expense && this.expense !== null ? this.expense.type : '', disabled: this.userType != "EMPLOYEE"},
        [Validators.required]
      ],
      price: [
        { value: 
          this.expense && this.expense !== null
          ? this.expense.price.toFixed(2) || this.expense.amount.toFixed(2)
          : '',
          disabled: this.expense?.payment || this.expense?.refund || this.userType != "EMPLOYEE"
        },
        [Validators.required],
      ],
      freeTaxes: [
        { value: this.expense && this.expense !== null && this.expense.freeTaxes
          ? this.expense.freeTaxes
          : false, disabled: this.userType != "EMPLOYEE"},
        [Validators.required]
      ],
      beginDate: [
        this.editing ? this.ngbDateParser.parse(
          moment(this.expense.beginDate).toString()
        ) : "",
        [Validators.required]
      ],
      endDate: [
        this.editing ? this.ngbDateParser.parse(
          moment(this.expense.endDate).toString()
        ) : "",
        [Validators.required],
      ],
      customCode: [
        this.expense && this.expense !== null && this.expense.customCode
          ? this.expense.customCode
          : ''
      ],
      costCenter: [
        this.expense && this.expense !== null && this.expense.costCenter
          ? this.expense.costCenter
          : ''
      ],
    });

    if (this.editing) {
      this.form.addControl('passenger', new FormControl({ value: this.expense ? this.expense.userUuid : '', disabled: !(this.userType?.toLowerCase() === 'employee' || this.userType?.toLowerCase() === 'company') }));
    }

    if (this.form.value.beginDate) {
      this.form.patchValue({ beginDate: { day: this.form.value.beginDate.day, month: this.form.value.beginDate.month + 1, year: this.form.value.beginDate.year } })
    }
    if (this.form.value.endDate) {
      this.form.patchValue({ endDate: { day: this.form.value.endDate.day, month: this.form.value.endDate.month + 1, year: this.form.value.endDate.year } })
    }

    if(this.editing && this.utilsService.isAdmin(this.userType?.toLowerCase())){
      this.getCompanyUsers();
    }
  }

  get f () {
    return this.form.controls
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0 && this.userType === 'EMPLOYEE') {
      for (let i = 0; i < event.target.files.length; i++) {
        this.files.push(event.target.files[i]);
      }
    }
  }

  removeFile(index: number) {
    const fileToRemove = this.existingFiles[index] || this.files[index];
    if (fileToRemove.url) {
      this.filesToRemove.push(fileToRemove.url);
      this.existingFiles.splice(index, 1);
    } else {
      this.files.splice(index, 1);
    }
  }

  handleTravel (dataTravel: any) {
    this.bodyTravel = dataTravel
    this.travelUtilsService.setTravelLocalStorage(this.bodyTravel)
    this.translate.get(['project.form.saved']).subscribe(resp => {
      this.ngxToastrService.typeSuccess(resp['project.form.saved'], '')
    })
  }

  onSubmit() {
    if (!this.editing) {
      this.showPaymentCardSelector().then((selectedCard) => {
        if (!selectedCard && !this.company.bookedWithoutCard) {
          this.loading = false;
          return;
        }
  
        this.processBookingForm(selectedCard);
      });
    } else {
      this.processBookingForm();
    }
  }
  
  processBookingForm(selectedCard?: any) {
    const travel = this.travelUtilsService.getTravelLocalStorage()
    
    this.isInvalid = false
    this.loading = true
    const body = {
      groupRestrictionUuid: this.groupRestrictionUuid,
      userUuid: this.userUuid,
      companyUuid: this.companyUuid,
      file: this.files.length > 0 ? this.files : [],
      filesToRemove: this.filesToRemove,
      price: this.form.get('price').value.toString().replace(',', '.'),
      type: this.form.get('type').value,
      freeTaxes: this.form.get('freeTaxes').value,
      customCode: this.form.get('customCode').value,
      costCenter: this.form.get('costCenter').value,
      beginDate:
        this.form.get('beginDate').value.year +
        '-' +
        this.form.get('beginDate').value.month +
        '-' +
        this.form.get('beginDate').value.day,
      endDate:
        this.form.get('endDate').value.year +
        '-' +
        this.form.get('endDate').value.month +
        '-' +
        this.form.get('endDate').value.day
    }

    if(this.editing){
      body["passenger"] = this.f.passenger.value;
    }

    if (travel && travel.data.title) {
      body['travelName'] = travel.data.title
    }
    if (selectedCard) {
      body["paymentCard"] = {
        name: selectedCard.name,
        token: selectedCard.token,
      };
    }
    this.sendInfo.emit(body);
  }

  closeModal () {
    this.activeModal.dismiss('Cross click')
  }

  getOutMinDate () {
    return this.utilsService.getOutDateSame(
      this.minDateNgStruct,
      this.form.controls['beginDate'].value
    )
  }

  getOutMaxDate () {
    return this.utilsService.getOutDateSame(
      this.maxDateNgStruct,
      this.form.controls['endDate'].value
    )
  }

  getCompanyUsers() {
    this.companyService.getCompanyUsers(this.companyUuid).subscribe(
      (res) => {
        this.companyUsers = res.map(user => ({
          name: user.name,
          lastname: user.lastname,
          uuid: user.uuid
        }));
        if (this.expense) {
          this.form.patchValue({
            passenger: this.expense.userUuid
          });
        }
      },
      (err) => {
        console.error('Error fetching users:', err);
      }
    );
  }

  showPaymentCardSelector(): Promise<any> {
    if (this.company.bookedWithoutCard) {
      return Promise.resolve(null);
    }

    if (!Array.isArray(this.user.paymentCards)) {
      this.user.paymentCards = this.user.paymentCards ? [this.user.paymentCards] : [];
    }

    /* if (!Array.isArray(this.user.personalPaymentCards)) {
      this.user.personalPaymentCards = this.user.personalPaymentCards ? [this.user.personalPaymentCards] : [];
    } */

    if ((!this.user.paymentCards || this.user.paymentCards.length === 0) /* && (!this.user.personalPaymentCards || this.user.personalPaymentCards.length === 0) */) {
      this.translate
        .get(["common.no-card-user-error"])
        .pipe(take(1))
        .subscribe((value) =>
          this.ngxToastrService.typeInfo(
            null,
            value["common.no-card-user-error"]
          )
        );
      return Promise.resolve(null);
    }

    return new Promise((resolve) => {
      const modalRef = this.modalService.open(UserCardModalComponent, {
        windowClass: 'custom-modal-user-card',
        centered: true,
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.bookCards = this.user.paymentCards;
      /* modalRef.componentInstance.bookPersonalCards = this.user.personalPaymentCards; */
      modalRef.componentInstance.fromBookSelect = true;
      modalRef.componentInstance.cardSelected.subscribe((selectedCard) => {
        resolve(selectedCard);
      });
      modalRef.result.catch(() => {
        resolve(null);
      });
    });
  }

  ngOnDestroy () {
    this.subcriptions.forEach(s => s.unsubscribe())
  }
}
