import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatStepper } from "@angular/material/stepper";
import { GroupRestriction } from "@core/models";
import {
  AuthService,
  FlightLleegoService,
  StateService,
  UserService,
} from "@core/services";
import { TranslateService } from "@ngx-translate/core";
import countries from "assets/utils/amadeus/countries.json";
import moment from "moment";
import { Subscription } from "rxjs";
import municipalitiesJson from "../../../../../../assets/utils/resident-municipalities.json";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserCardModalComponent } from "@shared/component/user-card-modal/user-card-modal.component";

@Component({
  selector: "app-flight-step-travelerform",
  templateUrl: "./flight-step-travelerform.component.html",
  styleUrls: ["./flight-step-travelerform.component.scss"],
})
export class FlightStepTravelerformComponent implements OnInit, OnDestroy {
  @Input() totalPrice: number;
  @Input() travelersAge: any[];
  @Input() isUser: boolean;
  @Input() userUuid: string;
  @Input() needAddress: boolean;
  @Input() needDocApis: boolean;
  @Input() stepper: MatStepper;
  @Input() selectedUsers: any;
  @Output() travelerFormSubmit: EventEmitter<UntypedFormGroup> =
    new EventEmitter<UntypedFormGroup>();
  user: any;
  private subcriptions: Subscription[] = [];
  groupRestriction: GroupRestriction;
  birthCountry = "";
  nationality = "";
  date = new Date();
  todayMoment = moment();
  minDateNgStruct = {
    year: this.date.getFullYear() - 120,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  countries = [];
  munipalitiesList = municipalitiesJson;
  canUseResident: boolean;

  constructor(
    public translate: TranslateService,
    private _formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UserService,
    public flightLleegoService: FlightLleegoService,
    private dateAdapter: DateAdapter<Date>,
    private stateService: StateService,
    private modalService: NgbModal
  ) {
    this.dateAdapter.setLocale("en-GB");
  }

  ngOnInit(): void {
    if (this.isUser) {
      this.subcriptions.push(
        this.authService.getProfile().subscribe((u) => {
          this.user = u;
          this.subcriptions.push(
            this.userService
              .getGroupUser(this.user["uuid"])
              .subscribe((group) => {
                this.groupRestriction = group;
                this.createFormAndPreFill();
                this.flightLleegoService.travelerForm.controls.forEach((travelerFormGroup, index) => {
                  travelerFormGroup.get('documentType').valueChanges.subscribe(value => {
                    this.updateDocumentNumber(index, value);
                  });
                });
                this.f.valueChanges.subscribe((value) => {
                  this.getInvalidString();
                });
              })
          );
        })
      );
    } else {
      this.subcriptions.push(
        this.userService.getUser(this.userUuid).subscribe((u) => {
          this.user = u;
          this.subcriptions.push(
            this.userService
              .getGroupUser(this.user["uuid"])
              .subscribe((group) => {
                this.groupRestriction = group;
                this.createFormAndPreFill();
                this.flightLleegoService.travelerForm.controls.forEach((travelerFormGroup, index) => {
                  travelerFormGroup.get('documentType').valueChanges.subscribe(value => {
                    this.updateDocumentNumber(index, value);
                  });
                });
                this.f.valueChanges.subscribe((value) => {
                  this.getInvalidString();
                });
              })
          );
        })
      );
    }
  }

  createFormAndPreFill() {
    this.countries = countries;
    this.flightLleegoService.travelerForm = this._formBuilder.array([]);
    const birthdate = moment(this.user.birthdate.split("T")[0]).format(
      "YYYY-MM-DD"
    );
    let expiryDate;
    if (this.user.dniExpirationDate) {
      expiryDate = moment(this.user.dniExpirationDate.split("T")[0]).format(
        "YYYY-MM-DD"
      );
    }
    let issuanceDate;
    if (this.user.dniIssuanceDate) {
      issuanceDate = moment(this.user.dniIssuanceDate.split("T")[0]).format(
        "YYYY-MM-DD"
      );
    }
    this.canUseResident = this.user.resident && this.user.resident.toString() !== "none" && this.stateService.residentCandidates && this.stateService.residentCandidates.find(r => r === this.user.resident);
    this.flightLleegoService.travelerForm.push(
      this._formBuilder.group({
        name: [
          this.user.name ? this.user.name : "",
          [
            Validators.required,
            Validators.maxLength(90),
            Validators.minLength(2),
          ],
        ],
        surname: [
          this.user.lastname ? this.user.lastname.split(" ")[0] : "",
          [Validators.required, Validators.maxLength(90)],
        ],
        lastSurname: [
          this.user.lastname ? this.user.lastname.split(" ")[1] : "",
          [Validators.maxLength(90)],
        ],
        email: [
          this.user.email ? this.user.email : "",
          [Validators.required, Validators.email],
        ],
        birthDate: [
          this.user.birthdate
            ? {
              year: parseInt(birthdate.split("-")[0], 10),
              month: parseInt(birthdate.split("-")[1], 10),
              day: parseInt(birthdate.split("-")[2], 10),
            }
            : "",
          [Validators.required],
        ],
        expiryDate: [
          this.user.dni ?
            this.user.dniExpirationDate
              ? {
                year: parseInt(expiryDate.split("-")[0], 10),
                month: parseInt(expiryDate.split("-")[1], 10),
                day: parseInt(expiryDate.split("-")[2], 10),
              }
              : ""
            : this.user.passport ?
              this.user.passportExpires
                ? {
                  year: parseInt(expiryDate.split("-")[0], 10),
                  month: parseInt(expiryDate.split("-")[1], 10),
                  day: parseInt(expiryDate.split("-")[2], 10),
                } : ''
              : '',
          [Validators.required],
        ],
        issuanceDate: [
          this.user.dni ?
            this.user.dniIssuanceDate
              ? {
                year: parseInt(issuanceDate.split("-")[0], 10),
                month: parseInt(issuanceDate.split("-")[1], 10),
                day: parseInt(issuanceDate.split("-")[2], 10),
              }
              : ""
            : this.user.passport ?
              this.user.passportIssuanceDate
                ? {
                  year: parseInt(issuanceDate.split("-")[0], 10),
                  month: parseInt(issuanceDate.split("-")[1], 10),
                  day: parseInt(issuanceDate.split("-")[2], 10),
                }
                : ""
              : "",
          [Validators.required],
        ],
        phoneNumber: [
          this.user.phone ? this.user.phone : "",
          [Validators.required],
        ],
        documentType: [
          this.user.dni ? "IDENTITY_CARD" : "",
          [Validators.required],
        ],
        documentNumber: [
          this.user.dni
            ? this.user.dni
            : this.user.passport
              ? this.user.passport
              : "",
          [Validators.required],
        ],
        expeCountry: [
          this.user.dni ?
            this.user.country
              ? this.countries.find((c) => c.name === this.user.country)
              : ""
            : this.user.passport ?
              this.user.passportCountryEmitter
                ? this.countries.find((c) => c.name === this.user.passportCountryEmitter)
                : ""
              : "",
          [Validators.required],
        ],
        nationality: [
          this.user.nationality
            ? this.countries.find((c) => c.name === this.user.nationality)
            : "",
          [Validators.required],
        ],
        city: [
          this.canUseResident
            ? this.munipalitiesList.find((c) => c.municipality === this.user.city)?.municipality
            : "",
          [this.checkResidentCity()],
        ],
        gender: [
          this.user.gender
            ? this.user.gender.charAt(0)?.toUpperCase() +
            this.user.gender.slice(1)
            : "",
          [Validators.required],
        ],
        documents: this._formBuilder.group({
          documentType: ["FF"],
          ffCard: this._formBuilder.array(this.initFfCards(this.user)),
          selectedFfCard: [this.user.ffCard && this.user.ffCard.length > 0 ? 0 : null]
        }),
        uuid: [this.user && this.user.uuid ? this.user.uuid : ""],
      })
    );

    this.selectedUsers.forEach((user, index) => {
      if (index === 0) return;

      const birthdate = moment(user.birthdate.split("T")[0]).format("YYYY-MM-DD");
      let expiryDate;
      if (user.dniExpirationDate) {
        expiryDate = moment(user.dniExpirationDate.split("T")[0]).format(
          "YYYY-MM-DD"
        );
      }
      let issuanceDate;
      if (user.dniIssuanceDate) {
        issuanceDate = moment(user.dniIssuanceDate.split("T")[0]).format(
          "YYYY-MM-DD"
        );
      }
      const canUseResident = user.resident && user.resident.toString() !== "none" && this.stateService.residentCandidates && this.stateService.residentCandidates.find(r => r === user.resident);

      this.flightLleegoService.travelerForm.push(
        this._formBuilder.group({
          name: [
            user.name ? user.name : "",
            [
              Validators.required,
              Validators.maxLength(90),
              Validators.minLength(2),
            ],
          ],
          surname: [
            user.lastname ? user.lastname.split(" ")[0] : "",
            [Validators.required, Validators.maxLength(90)],
          ],
          lastSurname: [
            user.lastname ? user.lastname.split(" ")[1] : "",
            [Validators.maxLength(90)],
          ],
          email: [
            user.email ? user.email : "",
            [Validators.required, Validators.email],
          ],
          birthDate: [
            user.birthdate
              ? {
                year: parseInt(birthdate.split("-")[0], 10),
                month: parseInt(birthdate.split("-")[1], 10),
                day: parseInt(birthdate.split("-")[2], 10),
              }
              : "",
            [Validators.required],
          ],
          expiryDate: [
            user.dni ?
              user.dniExpirationDate
                ? {
                  year: parseInt(expiryDate.split("-")[0], 10),
                  month: parseInt(expiryDate.split("-")[1], 10),
                  day: parseInt(expiryDate.split("-")[2], 10),
                }
                : ""
              : user.passport ?
                user.passportExpires
                  ? {
                    year: parseInt(expiryDate.split("-")[0], 10),
                    month: parseInt(expiryDate.split("-")[1], 10),
                    day: parseInt(expiryDate.split("-")[2], 10),
                  } : ''
                : '',
            [Validators.required],
          ],
          issuanceDate: [
            user.dni ?
              user.dniIssuanceDate
                ? {
                  year: parseInt(issuanceDate.split("-")[0], 10),
                  month: parseInt(issuanceDate.split("-")[1], 10),
                  day: parseInt(issuanceDate.split("-")[2], 10),
                }
                : ""
              : user.passport ?
                user.passportIssuanceDate
                  ? {
                    year: parseInt(issuanceDate.split("-")[0], 10),
                    month: parseInt(issuanceDate.split("-")[1], 10),
                    day: parseInt(issuanceDate.split("-")[2], 10),
                  }
                  : ""
                : "",
            [Validators.required],
          ],
          phoneNumber: [
            user.phone ? user.phone : "",
            [Validators.required],
          ],
          documentType: [
            user.dni ? "IDENTITY_CARD" : "",
            [Validators.required],
          ],
          documentNumber: [
            user.dni
              ? user.dni
              : user.passport
                ? user.passport
                : "",
            [Validators.required],
          ],
          expeCountry: [
            user.dni ?
              user.country
                ? this.countries.find((c) => c.name === user.country)
                : ""
              : user.passport ?
                user.passportCountryEmitter
                  ? this.countries.find((c) => c.name === user.passportCountryEmitter)
                  : ""
                : "",
            [Validators.required],
          ],
          nationality: [
            user.nationality
              ? this.countries.find((c) => c.name === user.nationality)
              : "",
            [Validators.required],
          ],
          city: [
            canUseResident
              ? this.munipalitiesList.find((c) => c.municipality === user.city)?.municipality
              : "",
            [this.checkResidentCity()],
          ],
          gender: [
            user.gender
              ? user.gender.charAt(0)?.toUpperCase() +
              user.gender.slice(1)
              : "",
            [Validators.required],
          ],
          documents: this._formBuilder.group({
            documentType: ["FF"],
            ffCard: this._formBuilder.array(this.initFfCards(user)),
            selectedFfCard: [user.ffCard && user.ffCard.length > 0 ? 0 : null]
          }),
          uuid: [user && user.uuid ? user.uuid : ""],
        })
      );
    });
    this.getInvalidString();
  }

  initFfCards(user: any): FormGroup[] {
    if (user && user.ffCard && Array.isArray(user.ffCard)) {
      return user.ffCard
        .filter(card => card && card.cardName && card.cardNumber)
        .map(card =>
          this._formBuilder.group({
            cardName: [card.cardName, Validators.required],
            cardNumber: [card.cardNumber, Validators.required]
          })
        );
    } else {
      return [];
    }
  }

  getFfCardsArray(index: number): FormArray {
    const travelerFormGroup = this.flightLleegoService.travelerForm.at(index) as FormGroup;
    if (!travelerFormGroup) {
      return null;
    }
    const documentsGroup = travelerFormGroup.get('documents') as FormGroup;
    if (!documentsGroup) {
      return null;
    }
    return documentsGroup.get('ffCard') as FormArray;
  }
  
  getSelectedFfCardControl(index: number): FormControl {
    const travelerFormGroup = this.flightLleegoService.travelerForm.at(index) as FormGroup;
    if (!travelerFormGroup) {
      return null;
    }
  
    const documentsGroup = travelerFormGroup.get('documents') as FormGroup;
    if (!documentsGroup) {
      return null;
    }
    return documentsGroup.get('selectedFfCard') as FormControl;
  }

  openFfCardModal(index) {
    const traveller = this.flightLleegoService.travelerForm.at(index).value.uuid;

    const modalRef = this.modalService.open(UserCardModalComponent, {
      windowClass: 'custom-modal-user-card',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
  
    modalRef.componentInstance.ffCards = this.flightLleegoService.travelerForm.at(index).value.documents.ffCard;
    modalRef.componentInstance.user = traveller;
    modalRef.componentInstance.cardType = 'ffCard';
  
    modalRef.result.then((result) => {
      if (result) {
        let { ffCards, selectedFfCard } = result;

        ffCards = ffCards.filter(card => card !== undefined && card !== null);
        if (selectedFfCard >= ffCards.length) {
          selectedFfCard = 0;
        }
  
        const ffCardArray = this.flightLleegoService.travelerForm.at(index).get('documents').get('ffCard') as FormArray;
        const selectedCard = ffCards[selectedFfCard];
        ffCards.splice(selectedFfCard, 1);
        ffCards.unshift(selectedCard);
        ffCardArray.clear();
        ffCards.forEach(card => {
          ffCardArray.push(this._formBuilder.group({
            cardName: [card.cardName, Validators.required],
            cardNumber: [card.cardNumber, Validators.required]
          }));
        });
  
        const selectedFfCardControl = this.flightLleegoService.travelerForm.at(index).get('documents').get('selectedFfCard') as FormControl;
        selectedFfCardControl.setValue(0);
      }
    }).catch((error) => {});
  }
  
  get f() {
    return this.flightLleegoService.travelerForm;
  }

  getInvalidString(): string {
    let response: string = ``;

    const keys = [
      "flight.result-flight.form.name",
      "flight.result-flight.form.surname",
      "flight.result-flight.form.lastSurname",
      "flight.result-flight.form.email",
      "flight.result-flight.form.birthDate",
      "flight.result-flight.form.phoneNumber",
      "flight.result-flight.form.documentNumber",
      "flight.result-flight.form.expeCountry",
      "flight.result-flight.form.nationality",
      "flight.result-flight.form.documentType",
      "flight.result-flight.form.fillForContinue",
      "flight.form-flight.passenger",
      "user.form.expiryDate",
      "user.form.issuanceDate",
      "user.form.invalid-birthdate",
      "common.city",
    ];
    this.translate.get(keys).subscribe((result) => {
      this.f.controls.forEach((formControl: UntypedFormGroup) => {
        if (formControl.invalid) {
          response += ` ${result["flight.form-flight.passenger"]} Nº ${this.f.controls.indexOf(formControl) + 1
            }  ${result["flight.result-flight.form.fillForContinue"]} `;
        }
        let invalids = Object.keys(formControl.controls).filter(
          (key) => formControl.controls[key].invalid
        );
        invalids.forEach((key, index) => {
          switch (key) {
            case "name":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["flight.result-flight.form.name"]}.`;
              } else {
                response += `${result["flight.result-flight.form.name"]},`;
              }
              break;
            case "surname":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["flight.result-flight.form.surname"]}.`;
              } else {
                response += `${result["flight.result-flight.form.surname"]}, `;
              }
              break;
            case "lastSurname":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["flight.result-flight.form.lastSurname"]}. `;
              } else {
                response += `${result["flight.result-flight.form.lastSurname"]}, `;
              }
              break;
            case "email":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["flight.result-flight.form.email"]}. `;
              } else {
                response += `${result["flight.result-flight.form.email"]}, `;
              }
              break;
            case "birthDate":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["flight.result-flight.form.birthDate"]}. `;
              } else {
                response += `${result["flight.result-flight.form.birthDate"]}, `;
              }
              break;
            case "phoneNumber":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["flight.result-flight.form.phoneNumber"]}. `;
              } else {
                response += `${result["flight.result-flight.form.phoneNumber"]}, `;
              }
              break;
            case "documentNumber":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["flight.result-flight.form.documentNumber"]}. `;
              } else {
                response += `${result["flight.result-flight.form.documentNumber"]}, `;
              }
              break;
            case "expeCountry":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["flight.result-flight.form.expeCountry"]}. `;
              } else {
                response += `${result["flight.result-flight.form.expeCountry"]}, `;
              }
              break;
            case "nationality":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["flight.result-flight.form.nationality"]}. `;
              } else {
                response += `${result["flight.result-flight.form.nationality"]}, `;
              }
              break;
            case "documentType":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["flight.result-flight.form.documentType"]}. `;
              } else {
                response += `${result["flight.result-flight.form.documentType"]}, `;
              }
              break;
            case "expiryDate":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["user.form.expiryDate"]}. `;
              } else {
                response += `${result["user.form.expiryDate"]}, `;
              }
              break;
            case "issuanceDate":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["user.form.issuanceDate"]}. `;
              } else {
                response += `${result["user.form.issuanceDate"]}, `;
              }
              break;
            case "city":
              if (invalids[index] === invalids[invalids.length - 1]) {
                response += `${result["common.city"]}. `;
              } else {
                response += `${result["common.city"]}, `;
              }
              break;
            default:
              break;
          }
        });
      });
    });
    return response;
  }

  goNextStep() {
      this.stepper.next();
  }

  // checkAgesCorrect() {
  //   let correctAges = 0;
  //   this.travelersAge.forEach((travelerAge) => {
  //     if (
  //       this.flightLleegoService.travelerForm.value.some((traveler) => {
  //         const travelerRealAge = travelerAge.age ?? travelerAge;
  //         const birthDate = moment({
  //           year: traveler.birthDate.year,
  //           month: traveler.birthDate.month - 1,
  //           day: traveler.birthDate.day,
  //         });
  //         const age = this.todayMoment.diff(birthDate, "years");
  //         return age === travelerRealAge;
  //       })
  //     ) {
  //       correctAges++;
  //     }
  //   });
  //   return correctAges === this.travelersAge.length;
  // }

  ngOnDestroy() {
    this.subcriptions.forEach((s) => s.unsubscribe());
  }

  checkResidentCity() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.canUseResident) {
        return null;
      } else if (this.munipalitiesList.find((c) => c.municipality === control.value) && this.munipalitiesList.find((c) => c.municipality === control.value).community === this.user.resident) {
        return null;
      } else {
        return { city: true };
      }
    }
  }

  private updateDocumentNumber(index: number, documentType: string) {
    const traveler = this.flightLleegoService.travelerForm.at(index);
    if (index === 0) {
      switch (documentType?.toUpperCase()) {
        case 'IDENTITY_CARD':
          traveler.get('documentNumber').setValue(this.user.dni || '');
          traveler.get('issuanceDate').setValue(this.parseDate(this.user.dniIssuanceDate) || '');
          traveler.get('expiryDate').setValue(this.parseDate(this.user.dniExpirationDate) || '');
          traveler.get('expeCountry').setValue(this.parseCountry(this.user.country) || '');
          break;
        case 'NIE':
          traveler.get('documentNumber').setValue(this.user.nie || '');
          traveler.get('issuanceDate').setValue(this.parseDate(this.user.nieIssuanceDate) || '');
          traveler.get('expiryDate').setValue(this.parseDate(this.user.nieExpirationDate) || '');
          traveler.get('expeCountry').setValue(this.parseCountry(this.user.country) || '');
          break;
        case 'PASSPORT':
          traveler.get('documentNumber').setValue(this.user.passport || '');
          traveler.get('issuanceDate').setValue(this.parseDate(this.user.passportIssuanceDate) || '');
          traveler.get('expiryDate').setValue(this.parseDate(this.user.passportExpires) || '');
          traveler.get('expeCountry').setValue(this.parseCountry(this.user.passportCountryEmitter) || '');
          break;
        default:
          break;
      }
    }
  }

  parseCountry(countrySelected: string) {
    if (!countrySelected) {
      return null;
    }
    return this.countries.find((c) => c.name === countrySelected)
  }

  parseDate(dateString: string) {
    if (!dateString) {
      return null;
    }
    const parts = dateString.split("-");
    return {
      year: parseInt(parts[0], 10),
      month: parseInt(parts[1], 10),
      day: parseInt(parts[2], 10)
    };
  }

}
