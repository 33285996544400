<div *ngIf="isLoading" style="display: block; margin: auto; z-index: 9999">
  <app-loading [userType]="'employee'"></app-loading>
</div>

<div class="header">
  <h4 *ngIf="company" class="title heading-5" id="modal-basic-title">
    {{ "company.company-form.edit-company" | translate }}
  </h4>
  <h4 *ngIf="!company" class="title heading-5" id="modal-basic-title">
    {{ "company.company-form.create-company" | translate }}
  </h4>
</div>

<div class="content">
  <button
    mat-icon-button
    aria-label="Back button"
    class="back-button"
    (click)="goBack()"
  >
    <img src="/assets/img/svg/arrow-back.svg"/>
  </button>

  <mat-horizontal-stepper
    #stepper
    [ngClass]="{ 'header-desactivado': this.company == null }"
  >
    <ng-template matStepperIcon="edit" let-index="index">
      {{ index + 1 }}
    </ng-template>
    <!--General data-->
    <mat-step label="{{ 'common.general-data' | translate }}" #stepper *ngIf="formGeneral">
      <form [formGroup]="formGeneral" class="step-form">
        <!-- Upload photo -->
        <div class="image-row">
          <p>{{ imageError }}</p>
          <div
            class="img-space"
            [ngClass]="{
              'invalid-image  ':
                formGeneral.controls['image'].hasError('required')
            }"
          >
            <input
              type="file"
              (change)="onImageChange($event)"
              id="upload-photo"
              class="fileInputProfile"
            />
            <ng-container *ngIf="isImageSaved; else elseTemplate">
              <img [src]="cardImageBase64" class="upload-photo" />
            </ng-container>
            <ng-template #elseTemplate>
              <img src="../../../../../assets/img/svg/upload-photo.svg" />
            </ng-template>
            <span class="heading-6 my-2 mx-0" *ngIf="company != null">{{
              company.name
            }}</span>
          </div>
          <div class="modify-image-wrapper" (click)="modifySelect.open()">
            <div class="modify-image">
              <label class="labels mr-1">{{
                "company.company-form.modify-image" | translate
              }}</label>
              <mat-select class="modify-image-select" #modifySelect>
                <mat-option (click)="changePhoto()">{{
                  "company.company-form.change-image" | translate
                }}</mat-option>
                <mat-option (click)="removeImage()">{{
                  "company.company-form.remove-image" | translate
                }}</mat-option>
              </mat-select>
            </div>
          </div>
        </div>
        <div class="form-row-step">
          <div
            class="input-stepper"
            *ngIf="
              !signUp && (isAdmin || userType === 'hub' || userType === 'HUB')
            "
          >
            <label for="agencyUuid">{{
              "common.agency" | translate
            }}</label>
            <mat-select
              formControlName="agencyUuid"
              type="text"
              id="agencyUuid"
              class="select-input form-control form-control--no-border"
            >
              <mat-option class="option" value="">
                {{ "agency.select" | translate }}
              </mat-option>
              <mat-option
                class="option"
                *ngFor="
                  let agency of agencies;
                  trackBy: utilsService.getUuidTracking
                "
                value="{{ agency.uuid }}"
              >
                {{ agency.name }}
              </mat-option>
            </mat-select>
          </div>
          <div class="input-stepper" *ngIf="!signUp && isAdmin">
            <label for="planUuid">{{
              "common.contract" | translate
            }}</label>
            <mat-select
              formControlName="planUuid"
              type="text"
              id="planUuid"
              class="select-input form-control form-control--no-border"
            >
              <mat-option
                class="option"
                *ngFor="
                  let plan of plans;
                  trackBy: utilsService.getUuidTracking
                "
                value="{{ plan.uuid }}"
              >
                {{ plan.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="form-row-step" *ngIf="!signUp">
          <div class="input-stepper margin1">
            <label class="labels" for="name">{{
              "common.name" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              class="text-input form-control form-control--no-border"
              formControlName="name"
              [ngClass]="{
                'input-error': !formGeneral.controls.name.valid && isInvalid
              }"
              type="text"
              id="name"
            />
          </div>
          <div class="input-stepper margin2">
            <label class="labels" for="email">{{
              "common.email" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              class="text-input form-control form-control--no-border"
              [ngClass]="{
                'input-error': !formGeneral.controls.email.valid && isInvalid
              }"
              formControlName="email"
              type="text"
              id="email"
            />
            <div
              *ngIf="
                formGeneral.get('email').touched &&
                !formGeneral.controls.email.valid &&
                isInvalid
              "
            >
              {{ "user.form.error.email" | translate }}
            </div>
          </div>
          <div class="input-stepper margin3">
            <label class="labels" for="phone">{{
              "common.phone" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error': !formGeneral.controls.phone.valid && isInvalid
              }"
              class="text-input form-control form-control--no-border"
              formControlName="phone"
              type="text"
              id="phone"
            />
            <div
              *ngIf="
                formGeneral.get('phone').touched &&
                !formGeneral.controls.phone.valid &&
                isInvalid
              "
            >
              {{ "user.form.error.phone" | translate }}
            </div>
          </div>
        
          <div class="input-stepper margin4">
            <label class="labels" for="cif">{{
              "common.cif" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              class="text-input form-control form-control--no-border"
              [ngClass]="{
                'input-error': !formGeneral.controls.cif.valid && isInvalid
              }"
              formControlName="cif"
              type="text"
              id="cif"
            />
          </div>

          <div class="input-stepper margin5">
            <label class="labels" for="address">{{
              "common.address" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error': !formGeneral.controls.address.valid && isInvalid
              }"
              class="text-input form-control form-control--no-border"
              formControlName="address"
              type="text"
              id="address"
            />
          </div>

          <div class="input-stepper margin6">
            <label class="labels" for="city">{{
              "common.city" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error': !formGeneral.controls.city.valid && isInvalid
              }"
              class="text-input form-control form-control--no-border"
              formControlName="city"
              type="text"
              id="city"
            />
          </div>
      
          <div class="input-stepper margin7">
            <label class="labels" for="province">{{
              "common.province" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error': !formGeneral.controls.province.valid && isInvalid
              }"
              class="text-input form-control form-control--no-border"
              formControlName="province"
              type="text"
              id="province"
            />
          </div>

          <div class="input-stepper margin8">
            <label class="labels" for="postalCode">{{
              "common.postal-code" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error':
                  !formGeneral.controls.postalCode.valid && isInvalid
              }"
              class="text-input form-control form-control--no-border"
              formControlName="postalCode"
              type="text"
              id="postalCode"
            />
          </div>

          <div class="input-stepper margin9">
            <label class="labels" for="postalCode">{{
              "common.country" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error': !formGeneral.controls.country.valid && isInvalid
              }"
              class="text-input form-control form-control--no-border"
              formControlName="country"
              type="text"
              id="country"
            />
          </div>
        </div>

        <div class="form-row-step" *ngIf="!signUp && isAdmin">
          <div class="input-stepper">
            <label for="localFlightDiscount">{{
              "common.local-flight-discount" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              class="text-input form-control form-control--no-border"
              formControlName="localFlightDiscount"
              type="number"
              id="localFlightDiscount"
            />
          </div>

          <div class="input-stepper">
            <label for="europeFlightDiscount">{{
              "common.europe-flight-discount" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              class="text-input form-control form-control--no-border"
              formControlName="europeFlightDiscount"
              type="number"
              id="europeFlightDiscount"
            />
          </div>

          <div class="input-stepper">
            <label for="internationalFlightDiscount">{{
              "common.international-flight-discount" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              class="text-input form-control form-control--no-border"
              formControlName="internationalFlightDiscount"
              type="number"
              id="internationalFlightDiscount"
            />
          </div>
        </div>

        <div class="form-row-step" *ngIf="!signUp && isAdmin">
          <div class="input-stepper">
            <label for="hotelComission">{{
              "common.hotelComission" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              class="text-input form-control form-control--no-border"
              formControlName="hotelComission"
              type="number"
              id="hotelComission"
            />
          </div>

          <div class="input-stepper">
            <label for="trainComission">{{
              "common.trainComission" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              class="text-input form-control form-control--no-border"
              formControlName="trainComission"
              type="number"
              id="trainComission"
            />
          </div>
        </div>
        <div class="form-row-step" *ngIf="isAdmin">
          <div class="input-stepper w100">
            <label for="trainComission">{{
              "hotel.custom.annotations" | translate
            }}</label>
            <textarea
              [readOnly]="isLoading"
              class="text-input form-control form-control--no-border"
              formControlName="annotations"
              type="number"
              id="trainComission"
            ></textarea>
          </div>
        </div>
      </form>
      <div class="button-row">
        <button
          *ngIf="this.company"
          class="custom-btn"
          (click)="
            isCreate && formGeneral.valid && !loading
              ? onSubmit()
              : !isCreate && formGeneral.valid && !loading
              ? onSubmitEdit()
              : (isInvalid = true)
          "
          [disabled]="signUp && !hasAcceptConditions"
          [ngStyle]="{
            cursor: !formGeneral.valid || loading ? 'not-allowed' : 'pointer'
          }"
        >
          {{ "common.save" | translate }}
        </button>
        <button
          *ngIf="!this.company"
          class="custom-btn"
          (click)="
            formGeneral.valid && !loading
              ? goNextStepper(stepper)
              : (isInvalid = true)
          "
          [disabled]="signUp && !hasAcceptConditions"
          [ngStyle]="{
            cursor: !formGeneral.valid || loading ? 'not-allowed' : 'pointer'
          }"
        >
          {{ "company.company-form.next" | translate }}
        </button>
        <button
          *ngIf="stepper.selectedIndex !== 0"
          class="custom-btn custom-btn--close"
          (click)="goBackStepper(stepper)"
          [disabled]="isLoading"
        >
          {{ "common.prev-step" | translate }}
        </button>
      </div>
    </mat-step>
    <!--Billing data-->
    <mat-step label="{{ 'common.billing-title' | translate }}" *ngIf="formBilling">
      <form [formGroup]="formBilling" class="formBilling">
        <div class="form-row-step" *ngIf="!signUp">
          <div class="input-stepper tamayo_check" *ngIf="!signUp">
            <mat-checkbox
              class="section"
              (change)="copyData($event)"
              [checked]="false"
            >
              {{ "company.company-form.copy-data" | translate }}
            </mat-checkbox>
          </div>
        </div>
        <div class="form-row-step" *ngIf="!signUp">
          <div class="input-stepper margin1-2">
            <label class="labels" for="identificator">{{
              "common.identificator" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error':
                  !formBilling.controls.identificator.valid && isInvalid
              }"
              formControlName="identificator"
              type="text"
              id="identificator"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="input-stepper margin2-2">
            <label class="labels" for="businessName">{{
              "common.business" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error':
                  !formBilling.controls.businessName.valid && isInvalid
              }"
              formControlName="businessName"
              type="text"
              id="businessName"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="input-stepper margin3-2">
            <label class="labels" for="addressBilling">{{
              "common.address" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error': !formBilling.controls.address.valid && isInvalid
              }"
              formControlName="address"
              type="text"
              id="addressBilling"
              class="text-input form-control form-control--no-border"
            />
          </div>
    
          <div class="input-stepper margin4-2">
            <label class="labels" for="cityBilling">{{
              "common.city" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error': !formBilling.controls.town.valid && isInvalid
              }"
              formControlName="town"
              type="text"
              id="cityBilling"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="input-stepper margin5-2">
            <label class="labels" for="provinceBilling">{{
              "common.province" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error': !formBilling.controls.province.valid && isInvalid
              }"
              formControlName="province"
              type="text"
              id="provinceBilling"
              class="text-input form-control form-control--no-border"
            />
          </div>
          <div class="input-stepper margin6-2">
            <label class="labels" for="postalCodeBilling">{{
              "common.postal-code" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error':
                  !formBilling.controls.postalCode.valid && isInvalid
              }"
              formControlName="postalCode"
              type="text"
              id="postalCodeBilling"
              class="text-input form-control form-control--no-border"
            />
          </div>
      
          <div class="input-stepper margin7-2">
            <label class="labels" for="countryBilling">{{
              "common.country" | translate
            }}</label>
            <input
              [readOnly]="isLoading"
              [ngClass]="{
                'input-error': !formBilling.controls.country.valid && isInvalid
              }"
              formControlName="country"
              type="text"
              id="countryBilling"
              class="text-input form-control form-control--no-border"
            />
          </div>
        </div>
          <form *ngIf="isAdmin" [formGroup]="formGeneral" class="form-row-step" style="margin-top: 2rem;">
            <mat-radio-group
              formControlName="bookedWithoutCard"
              attr.aria-label="{{
                'company.company-form.allow_book_without_card' | translate
              }}"
            >
              <label>{{
                "company.company-form.allow_book_without_card" | translate
              }}</label>
              <div class="options">
                <mat-radio-button [value]="true">{{
                  "company.company-form.allow" | translate
                }}</mat-radio-button>
                <mat-radio-button [value]="false">{{
                  "company.company-form.no-allow" | translate
                }}</mat-radio-button>
              </div>
            </mat-radio-group>
          </form>
      </form>
      <div class="button-row">
        <button
          *ngIf="!isAdmin && !this.company"
          class="custom-btn"
          (click)="onSubmit()"
          [disabled]="
            !formGeneral.valid || isLoading || (signUp && !hasAcceptConditions)
          "
        >
          {{ "common.save" | translate }}
        </button>
        <button
          *ngIf="isAdmin || this.company"
          class="custom-btn"
          (click)="
            formBilling.valid && !loading
              ? goNextStepper(stepper)
              : (isInvalid = true)
          "
          [disabled]="signUp && !hasAcceptConditions"
          [ngStyle]="{
            cursor: !formGeneral.valid || loading ? 'not-allowed' : 'pointer'
          }"
        >
          {{ "company.company-form.next" | translate }}
        </button>
        <button
          *ngIf="stepper.selectedIndex !== 0"
          class="custom-btn custom-btn--close"
          (click)="goBackStepper(stepper)"
          [disabled]="isLoading"
        >
          {{ "common.prev-step" | translate }}
        </button>
      </div>
    </mat-step>
    <!--Providers-->
    <mat-step
      *ngIf="isAdmin"
      label="{{ 'common.providers-selection' | translate }}"
    >
      <div class="providers-form">
        <h5 class="suppliers-title">
          {{ "company.suppliers.title" | translate }}
        </h5>
        <label>{{
          "company.suppliers.title-label" | translate
        }}</label>
        <div class="options">
          <mat-checkbox
            *ngFor="let option of suppliers"
            (change)="onSupplierChange(option)"
            [checked]="checkSupplierInArraySelected(option)"
            class="providers-name"
            >{{ option.name }}</mat-checkbox
          >
        </div>
      </div>
      <div class="button-row">
        <button
          class="custom-btn"
          (click)="onSubmit()"
          [disabled]="
            !formGeneral.valid || isLoading || (signUp && !hasAcceptConditions)
          "
        >
          {{ "common.save" | translate }}
        </button>
        <button
          *ngIf="stepper.selectedIndex !== 0"
          class="custom-btn custom-btn--close"
          (click)="goBackStepper(stepper)"
          [disabled]="isLoading"
        >
          {{ "common.prev-step" | translate }}
        </button>
      </div>
    </mat-step>
    <!--Card data-->
    <mat-step
    label="{{ 'common.card-title' | translate }}"
    *ngIf="
      (company &&
        companyPlan &&
        companyPlan.permissions.canUploadCreditCard) ||
      (isAdmin &&
        company &&
        companyPlan &&
        companyPlan.permissions.canUploadCreditCard) && formCard
    " 
  >
    <form [formGroup]="formCard" id="paytpvPaymentForm" class="new-card">
      <label for="name">{{ "company.card.add-credit-card-question" | translate }}</label>
      <div class="new-card-container">
        <input
          type="text"
          id="name"
          class="form-control form-control--no-border mb-0"
          data-paytpv="name"
          formControlName="name"
          placeholder="{{ 'company.card.card-name' | translate }}"
        />
        <button
          class="custom-btn"
          type="button"
          (click)="generateToken()"
          [disabled]="isLoading"
          style="padding: 0.2rem;"
        >
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </form>
    <div class="available-cards">
      <label>{{ "company.card.available-cards" | translate }}</label>
      <mat-list role="list" *ngIf="company?.cardRedsys && company?.cardRedsys?.length > 0">
        <mat-list-item role="listitem" *ngFor="let card of company.cardRedsys; let i = index" [value]="i">
          <span>{{ card.name }}</span>
          <button mat-button color="warn" class="card-action-btn" (click)="removeCard(i); $event.stopPropagation();">
            <p>{{ "common.delete" | translate }}</p>
          </button>
          <button mat-button color="success" class="card-action-btn" (click)="assignToPopup(i); selectedCardIndex = i">
            <p>{{ "company.card.assign-card-to" | translate }}</p>
          </button>
        </mat-list-item>
      </mat-list>
      <mat-list role="list" class="form-control form-control--no-border select-cards" placeholder="{{ 'my-bookeds.train-flight.no-cards-available' | translate }}" *ngIf="(company?.cardRedsys && company?.cardRedsys?.length === 0) || !company.cardRedsys" disabled>
        <mat-list-item role="listitem" disabled>
          {{ "my-bookeds.train-flight.no-cards-available" | translate }}
        </mat-list-item>
      </mat-list>
    </div>

    <div class="available-cards" *ngIf="company?.cardRedsys && company?.cardRedsys.length > 0 && assignToVisible">
      <label>
        {{ "company.card.assign-to" | translate }} 
        <strong style="margin: 0 6px; color: black;">{{ getSelectedCardName() }}</strong> 
        {{ "common.to" | translate }}
      </label>
      <mat-select
      [(value)]="assignmentScope"
      class="form-control form-control--no-border select-cards show-arrow"
      style="width: 100% !important; margin-bottom: 0 !important;"
      placeholder="{{ 'company.card.select-scope' | translate }}"
      >
        <mat-option value="all">
          {{ "company.card.all-users" | translate }}
        </mat-option>
        <mat-option value="departments">
          {{ "company.card.departments" | translate }}
        </mat-option>
        <mat-option value="users">
          {{ "company.card.individual-users" | translate }}
        </mat-option>
      </mat-select>
  
      <div *ngIf="assignmentScope === 'departments'" class="departments-select">
        <label>{{ "company.card.select-departments" | translate }}</label>
        <mat-select
          style="margin-bottom: 0 !important;"
          [(value)]="selectedDepartments"
          [multiple]="true"
          class="form-control show-arrow"
          placeholder="{{ 'company.card.select-departments' | translate }}"
        >
          <mat-option
            *ngFor="let department of groups"
            [value]="department.uuid"
          >
            {{ department.name }}
          </mat-option>
        </mat-select>
      </div>
      <div *ngIf="assignmentScope === 'users'" class="users-select">
        <label>{{ "company.card.select-users" | translate }}</label>
        <mat-select
          style="margin-bottom: 0 !important;"
          [(value)]="selectedUsers"
          [multiple]="true"
          class="form-control show-arrow"
          placeholder="{{ 'company.card.select-users' | translate }}"
        >
          <mat-option
            *ngFor="let user of companyUsers"
            [value]="user.uuid"
          >
            {{ user.name }} {{ user.lastname }}
          </mat-option>
        </mat-select>
      </div>
      <button
        class="custom-btn"
        style="font-size: smaller;"
        type="button"
        [disabled]="isLoading || isAssignButtonDisabled()"
        (click)="assignCardToScope(selectedCardIndex)"
      >
      {{ "company.card.assign-card" | translate }}
      </button>
    </div>
    
    <div class="button-row-end">
      <button
        *ngIf="stepper.selectedIndex !== 0"
        class="custom-btn custom-btn--close"
        (click)="goBackStepper(stepper)"
        [disabled]="isLoading"
      >
        {{ "common.prev-step" | translate }}
      </button>
    </div>
  </mat-step>
  </mat-horizontal-stepper>
</div>
