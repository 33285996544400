import { Options } from "@angular-slider/ngx-slider";
import { Location } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  NgModel,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { NavigationExtras, Router } from "@angular/router";
import { environment } from "@env/environment";
import {
  FlightAvailReq,
  FlightAvailRes,
  FlightDto,
  FlightRouteDto,
  JourneysReferences,
  TransportRequest,
  User,
} from "@models";
import {
  NgbActiveModal,
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  FlightService,
  GroupService,
  NgxToastrService,
  StateService,
  TravelUtilsService,
  UserService,
  UtilsService,
} from "@services";
import { CustomDateParserFormatter } from "@shared/component/date-parser";
import { PaginationConfig } from "@shared/interfaces";
import { Flight } from "@shared/interfaces/amadeus/search/flight";
import { AppState } from "app/app.reducer";
import { CreateTravelModalComponent } from "app/modules/components/travels/create-travel-modal/create-travel-modal.component";
import airports from "assets/utils/amadeus/airports.json";
import cities from "assets/utils/cities.json";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { TrainService } from "../../../../core/services/train.service";

@Component({
  selector: "app-custom-flight-train",
  templateUrl: "./custom-flight-train.component.html",
  styleUrls: ["./custom-flight-train.component.scss"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class CustomFlightTrainComponent implements OnInit, OnDestroy {
  @Input() isModal?;
  @Input() type?: "flight" | "train";
  @Input() userUuid?;
  @Input() companyUuid?;
  @Input() isManual?;
  @Output() closeEvent? = new EventEmitter();
  @Output() fromDateEmit = new EventEmitter();
  @Output() toDateEmit = new EventEmitter();
  @Output() fromCreationDateEmit = new EventEmitter();
  @Output() toCreationDateEmit = new EventEmitter();
  @Output() nextBookingsEmit = new EventEmitter();
  fromDate: any;
  toDate: any;
  hoveredDate: NgbDateStruct;
  @ViewChild("d1") bookedInput: NgbInputDatepicker;
  @ViewChild("d2") createdInput: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild("bookedRange") bookedRange: ElementRef;
  @ViewChild("createdAtRange") createdAtRange: ElementRef;

  isManualFlight = false;
  private subscriptions: Subscription[] = [];
  canBookHotel: boolean;
  canRequestCustomFlight: boolean;
  canRequestCustomTrain: boolean;
  canRequestCustomCar: boolean;
  canRequestAirbnb: boolean;
  isAmadeusEnabled = environment.AMADEUS_ENABLED === 0 ? false : true;
  lowValue = 0;
  highValue = 10;
  requestId = "";
  authUserUuid = "";
  form: UntypedFormGroup;
  flightDto: FlightDto;
  flightRouteDtoOrigin: FlightRouteDto;
  flightRouteDtoDestination: FlightRouteDto;
  flightListResult;
  flightsToView: Flight[] = [];
  loading = false;
  prefOptions = [];
  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  checkedOneWay = false;
  checkedReturn = true;
  users = [];
  airportList: any[] = [];
  airportSelected = "";
  checked = false;
  disabled = true;
  groupFilters: any = {};
  seats = [
    {
      value: "Y",
      viewValue: "Economy",
    },
    {
      value: "W",
      viewValue: "Economic Premium",
    },
    {
      value: "M",
      viewValue: "Economic Standard",
    },
    {
      value: "C",
      viewValue: "Business",
    },
    {
      value: "F",
      viewValue: "First",
    },
  ];
  isOnlyDirectChecked = false;
  isAllowBaggageChecked = false;
  isPermitCancellationChecked = false;
  isArrivalSameDayChecked = false;
  loadingFlights = false;
  paginationConfig: PaginationConfig = {
    page: 1,
    pageSize: 10,
    collectionSize: 0,
  };
  state: any;
  isUser = true;
  allowAllFlights = false;
  airportsJson: any = {};
  bodyTravelModal: any = {};
  citiesList = [];
  userAge: number;
  travelersAge: number[] = [];
  flightAvailReq: FlightAvailReq;
  minTimeDeparture = 0;
  maxTimeDeparture = 24;
  optionsTimeDeparture: Options = {
    floor: 0,
    ceil: 24,
    step: 1,
    showTicks: true,
    translate: (value: number): string => {
      return value + "h";
    },
  };
  user: User;
  minTimeReturnDeparture = 0;
  maxTimeReturnDeparture = 24;
  optionsTimeArrival: Options = {
    floor: 0,
    ceil: 24,
    step: 1,
    showTicks: true,
    translate: (value: number): string => {
      return value + "h";
    },
  };
  distanceDeparture = 0;
  // kmValues: string[] = this.initKmValues();
  value = 0; // this.distanceToIndex('0 km');
  optionsDistanceDeparture: Options = {
    floor: 0,
    ceil: 1000,
    step: 50,
    showTicks: true,
    showSelectionBar: true,
    translate: (value: number): string => {
      return value + " Km";
    },
  };
  distanceArrival = 0;
  optionsDistanceArrival: Options = {
    floor: 0,
    ceil: 1000,
    step: 50,
    showTicks: true,
    showSelectionBar: true,
    translate: (value: number): string => {
      return value + " Km";
    },
  };
  expandableFilters = false;
  originRadius: number;
  destinationRadius: number;
  departureSchedules: {
    minTimeDeparture: string;
    maxTimeDeparture: string;
  };
  arrivalSchedules: {
    minTimeDeparture: string;
    maxTimeDeparture: string;
  };
  showHoursDeparture = false;
  showHoursArrival = false;
  flightAvailRes: FlightAvailRes;
  outwardList: JourneysReferences[] = [];
  returnList: JourneysReferences[] = [];
  transportRequest: TransportRequest;
  userImageBase64: string;
  isAdmin = false;
  isEmployee = false;
  canUseTRM: boolean;
  userToBook: string;
  bookedUserAccessType: string;
  searchFailData: any;
  isInvalid: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private ngxToastrService: NgxToastrService,
    private flightService: FlightService,
    private router: Router,
    private groupService: GroupService,
    public utilsService: UtilsService,
    public activeModal: NgbActiveModal,
    public stateService: StateService,
    private userService: UserService,
    private store: Store<AppState>,
    private travelUtilsService: TravelUtilsService,
    private modalService: NgbModal,
    private ngbDateParser: NgbDateParserFormatter,
    private trainService: TrainService,
    public formatter: NgbDateParserFormatter,
    private location: Location
  ) {
    this.subscriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() !== "USER") {
          this.isAdmin = true;
          if (s.user.type?.toUpperCase() === "EMPLOYEE") {
            this.isEmployee = true;
          }
          this.userToBook = localStorage.getItem("userToBook")
            ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
            : null;
          if (this.userToBook != null) {
            this.userUuid = this.userToBook;
          }
          this.canUseTRM = s.user.type
            ? s.user.plan && s.user.plan.permissions.canUseTRM
            : s.user.user.plan && s.user.user.plan.permissions.canUseTRM;
        } else {
          this.companyUuid = s.user.companyUuid;
        }
      })
    );

    this.isManualFlight = this.router.url.includes("custom") ? true : false;
    this.type = this.router.url.includes("train") ? "train" : "flight";

    if (window.history.state.dataFromCompany) {
      const dataFromCompany = window.history.state.dataFromCompany;
      this.userUuid = dataFromCompany.userUuid;
      this.type = dataFromCompany.type;
      this.companyUuid = dataFromCompany.companyUuid;
      this.isModal = false;
      this.isUser = false;
      this.stateService.setDataFromCompany(dataFromCompany);
    } else {
      const dataFromState = this.stateService.dataFromCompany$.getValue();
      if (dataFromState && dataFromState.userUuid) {
        this.userUuid = dataFromState.userUuid;
        this.companyUuid = dataFromState.companyUuid;
        this.isModal = false;
        this.isUser = false;
      }
      if (!this.userUuid || this.userUuid === "" || this.userUuid === null) {
        this.subscriptions.push(
          this.store.select("auth").subscribe((s) => {
            if (s.user && s.user.type === "USER") {
              this.userAge = moment().diff(s.user.birthdate, "years");
              // this.userUuid = s.user.uuid;
              this.travelersAge = [this.userAge];
            }
          })
        );
      }
    }
  }

  ngOnInit() {
    if (
      window.history.state.canBookHotel !== null &&
      window.history.state.canRequestCustomCar !== null &&
      window.history.state.canRequestCustomFlight !== null &&
      window.history.state.canRequestCustomTrain !== null &&
      window.history.state.canRequestAirbnb !== null
    ) {
      this.canBookHotel = window.history.state.canBookHotel;
      this.canRequestCustomCar = window.history.state.canRequestCustomCar;
      this.canRequestCustomFlight = window.history.state.canRequestCustomFlight;
      this.canRequestCustomTrain = window.history.state.canRequestCustomTrain;
      this.canRequestAirbnb = window.history.state.canRequestAirbnb;
      this.userUuid = window.history.state.userUuid;
      this.companyUuid = window.history.state.companyUuid;
      this.userImageBase64 = window.history.state.userImageBase64;
      if (
        window.history.state.isFromSearchFail &&
        window.history.state.isFromSearchFail === true
      ) {
        this.searchFailData = window.history.state.searchFailData;
      }
    } else {
      this.router.navigate(["/custom-services"]);
    }

    if (
      this.userUuid &&
      (this.userUuid !== null || this.userUuid !== undefined)
    ) {
      this.userService
        .getUser(this.userUuid)
        .pipe(take(1))
        .subscribe((user: User) => {
          this.bookedUserAccessType = user.type;
          this.user = user;
        });
    }

    this.state = this.stateService.state$.getValue() || {};
    if (
      window.history.state &&
      window.history.state.isOutdated != null &&
      window.history.state.isOutdated === true
    ) {
      this.stateService.flightSelectedState = null;
    }
    if (this.state.flights) {
      this.flightListResult = this.state.flights;
      this.requestId = this.state.flights.requestId;
    }
    this.stateService.state$.next({});
    this.subscriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() === "USER") {
          this.user = s.user;
          this.authUserUuid = s.user.uuid;
        } else {
          this.isUser = false;
        }
      })
    );
    this.airportsJson = airports;
    this.translate
      .get([
        "train.form-train.anyone",
        "train.form-train.morning",
        "train.form-train.afternoon",
        "train.form-train.night",
      ])
      .subscribe((result) => {
        this.prefOptions = [
          { value: "anyone", text: result["train.form-train.anyone"] },
          { value: "morning", text: result["train.form-train.morning"] },
          { value: "evening", text: result["train.form-train.afternoon"] },
          { value: "noon", text: result["train.form-train.night"] },
        ];
      });
    this.expandableFilters = this.stateService.searchOptionsToggled
      ? this.stateService.searchOptionsToggled
      : false;
    if (this.stateService.flightSelectedState != null) {
      this.flightListResult = this.stateService.flightSelectedState;
    }
    if (this.stateService.transportRequest != null) {
      this.transportRequest = this.stateService.transportRequest;
    }

    this.form = this.formBuilder.group({
      origin: [
        this.searchFailData
          ? this.searchFailData.originText ?? this.searchFailData.origin
          : null,
        [Validators.required],
      ],
      destination: [
        this.searchFailData
          ? this.searchFailData.destinationText ??
            this.searchFailData.destination
          : null,
        [Validators.required],
      ],
      dateDeparture: [
        this.searchFailData
          ? this.searchFailData.departDate ?? this.searchFailData.dateIn
          : null,
        [Validators.required],
      ],
      dateReturn: [
        this.searchFailData
          ? this.searchFailData.returnDate ?? this.searchFailData.dateOut
          : null,
      ],
      timeDeparture: [null, [Validators.required]],
      timeReturn: [
        null,
        this.searchFailData?.oneWay ? [] : [Validators.required],
      ],
      notes: [""],
      userUuid: this.userUuid,
      companyUuid: this.companyUuid,
    });
    const validator = this.checkedOneWay ? null : [Validators.required];
    this.form.get("dateReturn").setValidators(validator);
    this.form.get("dateReturn").updateValueAndValidity();
    // Cargo los empleados de la compañia
    if (this.isModal && !this.userUuid) {
      this.form.addControl(
        "userUuid",
        new UntypedFormControl(null, [Validators.required])
      );
      this.loadGroups(this.companyUuid);
    }
    this.flightDto = {};
    this.flightRouteDtoOrigin = {};
    this.flightRouteDtoDestination = {};
    this.flightListResult = {};
    if (this.searchFailData?.oneWay) {
      this.checkedOneWay = true;
      this.checkedReturn = false;
    }
  }

  ngAfterViewInit() {
    if (
      this.searchFailData?.oneWay === false &&
      this.form.controls["dateDeparture"].value
    ) {
      this.bookedRange.nativeElement.value =
        this.ngbDateParser.format(this.form.controls["dateDeparture"].value) +
        " - " +
        this.ngbDateParser.format(this.form.controls["dateReturn"].value);
    }
  }

  public getPaginatorData(event: PageEvent): PageEvent {
    this.lowValue = event.pageIndex * event.pageSize;
    this.highValue = this.lowValue + event.pageSize;
    return event;
  }

  navigate(route: string) {
    const navigationExtra: NavigationExtras = {
      state: {
        canBookHotel: this.canBookHotel,
        canRequestCustomFlight: this.canRequestCustomFlight,
        canRequestCustomTrain: this.canRequestCustomTrain,
        canRequestCustomCar: this.canRequestCustomCar,
        canRequestAirbnb: this.canRequestAirbnb,
        userUuid: this.userToBook ? this.userToBook : this.authUserUuid,
        companyUuid: this.companyUuid,
        userImageBase64: this.userImageBase64,
      },
      queryParams: {
        userId: this.userToBook ? this.userToBook : this.authUserUuid,
        companyUuid: this.companyUuid,
      },
      replaceUrl: true
    };

    this.router.navigate(["custom-services/" + route], navigationExtra);
  }

  exchangeUbications() {
    const origin = this.form.get("origin").value;
    const destination = this.form.get("destination").value;
    this.form.get("origin").setValue(destination);
    this.form.get("destination").setValue(origin);
  }

  goBack() {
    this.location.back();
  }

  loadGroups(uuid) {
    this.loading = true;
    this.groupService.getGroups(uuid).subscribe(
      (result: any[]) => {
        this.loading = false;
        result
          .filter((group) => group.filters.requestFlight)
          .forEach((group) => (this.users = this.users.concat(group.users)));
      },
      (err) => {
        this.loading = false;
        this.translate
        .get(`${this.type?.toLowerCase()}.create-${this.type?.toLowerCase()}.error-load-employees`)
          .subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.error);
          });
      }
    );
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.isInvalid = false;
    if (this.form.valid) {
      let req = this.form.getRawValue();
      req.departDate = moment(
        this.ngbDateParser.format(req.dateDeparture),
        "DD/MM/YYYY"
      ).format("DD-MM-YYYY");
      req.departDate = `${req.departDate} ${req.timeDeparture.hour}:${req.timeDeparture.minute}:00`;
      delete req.dateDeparture;
      delete req.timeDeparture;
      if (this.isModal) {
        req.userUuid = this.userUuid
          ? this.userUuid
          : this.f.userUuid.value.uuid;
      }
      if (!this.checkedOneWay) {
        // req.returnDate = moment(dateReturnAux + ' ' + ('0' + (this.f.timeReturn.value ?
        //  this.f.timeReturn.value.hour : '00')).slice(-2) + ':' + ('0'
        //    + (this.f.timeReturn.value ? this.f.timeReturn.value.minute : '00')).slice(-2));
        req.returnDate = moment(
          this.ngbDateParser.format(req.dateReturn),
          "DD/MM/YYYY"
        ).format("DD-MM-YYYY");
        req.returnDate = `${req.returnDate} ${req.timeReturn.hour}:${req.timeReturn.minute}:00`;
      }
      delete req.dateReturn;
      delete req.timeReturn;
      req.annotations = req.notes;
      req.oneWay = this.checkedOneWay;
      delete req.notes;
      let traveller = [];

      if (this.type?.toLowerCase() === "train") {
        traveller.push({
          name: this.user.name + " " + this.user.lastname,
          renfeCard: this.user.renfeCard ? this.user.renfeCard : null,
        });
        req["traveller"] = traveller;
      } else {
        traveller.push({ name: this.user.name + " " + this.user.lastname });
        req["traveller"] = traveller;
      }

      this.loading = true;
      const post =
        this.type?.toLowerCase() === "flight"
          ? this.flightService.postFlightRequest(req)
          : this.trainService.postTrainRequest(req);
      post.subscribe(
        () => {
          this.loading = false;
          this.translate
            .get([
              "common.congrat",
              `flight.form-flight.request-flight-send`,
              "train.form-train.request-train-send",
            ])
            .subscribe((result) => {
              this.ngxToastrService.typeSuccess(
                result["common.congrat"],
                this.type?.toLowerCase() === "flight"
                  ? result[`flight.form-flight.request-flight-send`]
                  : result["train.form-train.request-train-send"]
              );
              if (this.isModal) {
                this.closeModalCross();
              } else {
                if(this.isUser) {
                  this.router.navigateByUrl("my-bookeds");
                } else { 
                  this.router.navigateByUrl(`/user/booked/${this.user.uuid}`)
                }
              }
            });
        },
        (err) => {
          this.loading = false;
          this.translate.get("common.error").subscribe((result) => {
            this.ngxToastrService.typeInfo(result, err.error.message);
          });
        }
      );
    } else {
      this.translate
        .get(["common.error", "train.form-train.error-complete-form"])
        .subscribe((result) => {
          this.ngxToastrService.typeInfo(
            result["common.error"],
            result["train.form-train.error-complete-form"]
          );
        });
    }
  }

  closeModalCross() {
    this.activeModal.dismiss("Cross click");
  }
  getOutMinDateManual() {
    return this.utilsService.getOutDateSame(
      this.minDateNgStruct,
      this.form.controls["dateDeparture"].value
    );
  }

  returnTrip(type: string) {
    this.checkedOneWay = type === "oneWay";
    this.checkedReturn = type === "return";

    if (this.checkedOneWay) {
      this.form.controls["dateDeparture"].setValue(null);
      this.form.controls["timeDeparture"].setValue(null);
      this.form.controls["dateReturn"].setValue(null);
      this.form.controls["timeReturn"].setValue(null);
      this.form.controls["dateReturn"].disable();
      this.form.controls["timeReturn"].disable();
    } else if (this.checkedReturn) {
      this.form.controls["dateDeparture"].setValue(null);
      this.form.controls["timeDeparture"].setValue(null);
      this.form.controls["dateReturn"].setValue(null);
      this.form.controls["timeReturn"].setValue(null);
      this.form.controls["dateReturn"].enable();
      this.form.controls["timeReturn"].enable();
    }
    const validator = this.checkedOneWay ? null : [Validators.required];
    this.form.get("dateReturn").setValidators(validator);
    this.form.get("dateReturn").updateValueAndValidity();
  }

  closeModal() {
    this.closeEvent.emit();
  }
  // Buscador de vuelos
  getAirports(value: string) {
    this.airportList = [];
    if (value !== "" && value.length > 2) {
      airports.Table1.forEach((e) => {
        if (
          e.AirportName.includes(value) ||
          e.AirportName?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              value
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) ||
          e.IATA.includes(value) ||
          e.IATA?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              value
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            ) ||
          e.Country.includes(value) ||
          e.Country?.toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              value
                ?.toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
        ) {
          this.airportList.push({
            title: e.AirportName + " " + "(" + e.IATA + ")",
            coordinates: {
              latitude: e.Latitude,
              longitude: e.Longitude,
            },
          });
        }
      });
    } else {
      this.airportList = [];
    }
  }

  // Buscador de ciudades
  getCities(value: string) {
    this.citiesList = [];
    if (value !== "" && value.length > 2) {
      cities.Table1.forEach((v) => {
        if (
          v["name"]?.toLowerCase().includes(value?.toLowerCase()) &&
          !this.citiesList.includes(v["name"])
        ) {
          this.citiesList.push(v["name"]);
        }
      });
    } else {
      this.citiesList = [];
    }
  }

  optionSelected(field: string, value: string) {
    if (field === "origin") {
      this.airportList = [];
      this.disabled = false;
    }
    if (field === "destination") {
      this.airportList = [];
    }
  }

  cleanCitiesList() {
    this.citiesList = [];
  }

  checkValue(event: any) {
    return null;
  }

  openTravelModal() {
    if (
      !localStorage.getItem("travel") ||
      localStorage.getItem("travel") === null
    ) {
      const modalRef = this.modalService.open(CreateTravelModalComponent, {
        size: "lg",
        centered: true,
      });
      modalRef.componentInstance.loading = false;
      modalRef.componentInstance.previousBooking = false;
      if (this.companyUuid) {
        modalRef.componentInstance.companyUuid = this.companyUuid;
      }
      if (this.userUuid) {
        modalRef.componentInstance.userUuid = this.userUuid;
      }
      this.subscriptions.push(
        modalRef.componentInstance.sendInfo.subscribe((dataTravel) => {
          this.bodyTravelModal = dataTravel;
          this.travelUtilsService.setTravelLocalStorage(this.bodyTravelModal);
        })
      );
    }
  }

  toggleFilter() {
    this.stateService.searchOptionsToggled =
      !this.stateService.searchOptionsToggled;
  }

  async presentToast(header: string, msg: string) {
    this.ngxToastrService.typeInfo(header, msg);
  }

  emitDates() {
    this.fromDateEmit.emit(this.form.controls["dateDeparture"].value);
    this.toDateEmit.emit(this.form.controls["dateReturn"].value);
  }

  onDateSelection(date: NgbDate) {
    const dateDeparture = this.form.controls["dateDeparture"].value;
    const dateReturn = this.form.controls["dateReturn"].value;
  
    if (!dateDeparture) {
      this.form.controls["dateDeparture"].setValue(date);
    } else if (date && !dateReturn && date.after(dateDeparture)) {
      this.form.controls["dateReturn"].setValue(date);
    } else {
      this.form.controls["dateReturn"].setValue(null);
      this.form.controls["dateDeparture"].setValue(date);
    }
    this.updateDateInput();
  }

  onDatepickerClosed() {
    const dateDeparture = this.form.controls["dateDeparture"].value;
    const dateReturn = this.form.controls["dateReturn"].value;
  
    if (dateDeparture && !dateReturn) {
      this.form.controls["dateReturn"].setValue(dateDeparture);
    }
    this.updateDateInput();
  }
  
  updateDateInput() {
    this.bookedRange.nativeElement.value =
      this.ngbDateParser.format(this.form.controls["dateDeparture"].value) +
      " - " +
      this.ngbDateParser.format(this.form.controls["dateReturn"].value);

    this.emitDates();
  }

  isHovered(date: NgbDate) {
    return (
      this.form.controls["dateDeparture"].value &&
      !this.form.controls["dateReturn"].value &&
      this.hoveredDate &&
      date.after(this.form.controls["dateDeparture"].value) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return (
      date.after(this.form.controls["dateDeparture"].value) &&
      date.before(this.form.controls["dateReturn"].value)
    );
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.form.controls["dateDeparture"].value) ||
      date.equals(this.form.controls["dateReturn"].value)
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
