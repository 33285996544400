<div class="row" [ngClass]="!firstSearch && openModal ? 'full-container scroll' : ''">
  <div class="col-12">
    <div class="card m-0">
      <div class="card-body">
        <form [ngClass]="!firstSearch && !openModal ? 'is-collapsed' : ''">
          <div class="row d-flex first-row">
            <div class="input-style mr-3 destiny">
              <div class="form-group mb-1">
                <label>{{
                  "search.search-homepage.destiny" | translate
                }}</label>
                <app-google-autocomplete
                  [readOnly]="!firstSearch && !openModal"
                  (setAddress)="getAddress($event)"
                  [locationTxt]="searchConfig.locationText"
                  [openModal]="openModal"
                  adressType="geocode"
                  class="google"
                ></app-google-autocomplete>
              </div>
            </div>
            <div class="input-style mr-3 date">
              <div class="form-group input-long mb-1">
                <label for="date_form">{{
                  "search.search-homepage.date-entry" | translate
                }}</label>
                <div class="input-group">
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="dInput.toggle()"
                      type="button"
                    >
                      <img src="/assets/img/svg/calendar-orange.svg" />
                    </button>
                  </div>
                  <input
                    id="date_form"
                    class="form-control"
                    type="text"
                    placeholder="{{
                      'search.search-homepage.checkin' | translate
                    }}"
                    name="dpInput"
                    [(ngModel)]="searchConfig.checkIn"
                    ngbDatepicker
                    #dInput="ngbDatepicker"
                    (click)="dInput.toggle()"
                    (ngModelChange)="onDateChange()"
                    [minDate]="getInMinDate()"
                    [maxDate]="getInMaxDate()"
                  />
                </div>
              </div>
            </div>
            <div class="input-style date">
              <div class="form-group input-long mb-1">
                <label for="date_to">{{
                  "search.search-homepage.date-departure" | translate
                }}</label>
                <div
                  class="input-group"
                  [ngClass]="{ 'disabled-input': !searchConfig.checkIn }"
                >
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary calendar"
                      (click)="dOutput.toggle()"
                      mat-button
                      [disabled]="!searchConfig.checkIn"
                      type="button"
                    >
                      <img src="/assets/img/svg/calendar-orange.svg" />
                    </button>
                  </div>
                  <input
                    id="date_to"
                    class="form-control"
                    type="text"
                    placeholder="{{
                      'search.search-homepage.checkout' | translate
                    }}"
                    name="dpOutput"
                    [(ngModel)]="searchConfig.checkOut"
                    ngbDatepicker
                    #dOutput="ngbDatepicker"
                    (click)="dOutput.toggle()"
                    mat-button
                    (ngModelChange)="onDateChange()"
                    [disabled]="!searchConfig.checkIn"
                    [minDate]="getOutMinDate()"
                    [maxDate]="maxDateNgStruct"
                  />
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="firstSearch || openModal" class="row" style="margin: 0 auto;">
            <div class="container-room">
              <div class="rooms-container">
                <div class="first-search">
                  <div
                    class="input-style mr-3 room-selector"
                    style="display: flex; align-items: end"
                  >
                    <div class="marg-t top-text form-group input-long mb-1">
                      <div class="flex">
                        {{ "search.filters.num_rooms" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <form [formGroup]="formRoom" id="formFilters">
                  <div
                    id="rooms"
                    class="container-room"
                    *ngIf="formRoom && formRoom.getRawValue().room"
                  >
                    <div
                      class="flex first-search"
                      r
                      i
                      *ngFor="let r of formRoom.getRawValue().room; let i = index"
                      [ngStyle]="{
                        'margin-top':
                          opened[0][0] && rooms.length > 1 && i !== 0
                            ? -505 + 64 * i + 'px'
                            : '0px',
                        display: i !== 0 ? 'none' : ''
                      }"
                    >
                      <div
                        ngbDropdown
                        class="room-div mr-3 room-selector"
                        (openChange)="checkDropdown($event, 0, 0)"
                        *ngIf="i === 0; else elseBlock"
                      >
                        <div ngbDropdownToggle class="dropdown-toggle">
                          <div
                            class="bg-room-l"
                            [ngClass]="opened[0][0] ? 'opened' : ''"
                          >
                            <img src="assets/img/svg/bed-orange.svg"/>
                            <span class="span-bed"
                              >{{ formRoom.getRawValue().room.length }}
                              {{ "search.filters.rooms" | translate }}</span
                            >
                            <img
                              class="dropdown-arrow"
                              src="assets/img/svg/ionic-ios-arrow-down.svg"
                            />
                          </div>
                        </div>
                        <div ngbDropdownMenu class="rooms-dropdown-menu">
                          <div
                            class="dropdown-item"
                            *ngFor="let item of [].constructor(10); let j = index"
                            (click)="addRoom(j + 1); opened[0][0] = false"
                          >
                            {{ j + 1 }}
                          </div>
                        </div>
                      </div>
                      <ng-template #elseBlock>
                        <div class="room-div mr-3 room-selector"></div>
                      </ng-template>
                    </div>
                  </div>
                </form>
              </div>
              <div *ngIf="defaultUser">
                <div class="first-search">
                  <div
                    class="input-style mr-3 room-selector"
                    style="display: flex; align-items: end"
                  >
                    <div class="marg-t top-text form-group input-long mb-1">
                      <div class="flex">{{'my-bookeds.flight.travellers' | translate}}</div>
                    </div>
                  </div>
                </div>
                <div class="travellers-container">
                  <div>
                    <mat-form-field appearance="fill" class="full-width">
                      <mat-label>{{ ("common.traveller" | translate) + ' ' + 1 }}</mat-label>
                      <input class="custom-input" matInput [value]="defaultUser?.name + ' ' + defaultUser?.lastname" readonly>
                    </mat-form-field>
                  </div>
                  <div *ngFor="let traveler of travelers; let i = index; trackBy: trackByIndex" class="traveler-dropdown">
                    <mat-form-field appearance="fill" class="full-width">
                      <mat-label>
                        {{ travelers[i] ? ('common.traveller' | translate) + ' ' + (i + 2) : ('common.add-passenger' | translate) }}
                      </mat-label>
                      <mat-select #select id="traveler-{{ i }}" [(value)]="travelers[i]" (closed)="onPanelClose()" (openedChange)="onOpenedChange($event, input)">
                        <mat-option>
                          <input class="custom-mat-input" #input matInput (input)="applyFilter($event.target.value)" (click)="$event.stopPropagation()" placeholder="{{ 'common.search-user' | translate }}" />
                        </mat-option>
                        <mat-option *ngFor="let user of filteredUsers" [value]="user.uuid">
                          {{ user.name }} {{ user.lastname }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <label class="project-label">{{'project.project-assign' | translate}}</label>
              <div class="d-flex align-items-center">
                <div class="input-group" [ngClass]="{ 'disabled-input': loadingProjects || !searchConfig.checkIn || !searchConfig.checkOut }">
                  <select
                    name=""
                    id=""
                    class="form-control form-control--squared select-input custom-select"
                    [(ngModel)]="selectedProject"
                    [disabled]="loadingProjects || !searchConfig.checkIn || !searchConfig.checkOut"
                  >
                    <option [ngValue]="null" disabled selected>{{'project.project-select' | translate}}</option>
            
                    <option *ngFor="let project of projects" [ngValue]="project">
                      {{ project.title }}
                    </option>
                  </select>
                </div>

                <button *ngIf="!loadingProjects" class="btn no-focus-border" (click)="openTravelDialog()"
                  [disabled]="loadingProjects || !searchConfig.checkIn || !searchConfig.checkOut">
                  <img src="assets/img/svg/add-booking-circle.svg" alt="Add booking" />
                </button>
                <i style="margin-left: 0.5rem; font-size: 1.5rem;" class="ft-loader" *ngIf="loadingProjects"></i>
              </div>
            </div>

          </div>
          <div *ngIf="firstSearch || openModal" class="row">
            <div class="col-md-12 col-sm-12">
              <div>
                <div>
                  <button
                    id="submit-button"
                    [disabled]="!searchConfig.checkIn || !searchConfig.checkOut || !searchConfig.latitude || ! searchConfig.longitude || !allTravelersSelected()"
                    type="submit"
                    class="btn-search custom-btn m-0"
                    (click)="sendData()"
                  >
                    {{ "search.search-homepage.search" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
