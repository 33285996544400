import { Options } from "@angular-slider/ngx-slider";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ApplyFiltersConfig,
  PaginationConfig,
  SearchConfig,
} from "@interfaces";
import { Facility, Hotel } from "@models";
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbDropdown,
} from "@ng-bootstrap/ng-bootstrap";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AuthService,
  GoogleMaps,
  NgxToastrService,
  SearchService,
  UserService,
  UtilsService,
} from "@services";
import { CustomDateParserFormatter } from "@shared/component/date-parser";
import { AppState } from "app/modules/components/search/search.reducer";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { debounceTime, startWith, take } from "rxjs/operators";
import { RoomsComponent } from "../../hotel/rooms/rooms.component";
import { SearchFormComponent } from "../search-form/search-form.component";
import { ViewportScroller } from "@angular/common";

// @ts-ignore
@Component({
  selector: "app-search-homepage",
  templateUrl: "./search-homepage.component.html",
  styleUrls: ["./search-homepage.component.scss"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class SearchHomepageComponent
  implements OnInit, OnDestroy, AfterViewInit {
  value = 0;
  highValue = 0;
  highValueApplicated;
  options: Options;
  optionsApplicated: Options;
  order: {
    order: "distance" | "price";
    type: "asc" | "desc";
  };
  hotelsList: Hotel[] = [];
  hotelListCopy: Hotel[] = [];
  hotelMaps: Hotel[] = [];
  hotelMapsCopy: Hotel[] = [];
  searchConfig: SearchConfig = {
    uuid: "",
    locationText: "",
    latitude: "",
    longitude: "",
    checkIn: null,
    minElements: "0",
    checkOut: null,
    occupancies: [
      {
        rooms: 1,
        adults: 1,
        paxes: [],
      },
    ],
  };
  applyFilterConfig: ApplyFiltersConfig = {
    uuid: "",
    locationText: "",
    latitude: "",
    longitude: "",
    checkIn: null,
    checkOut: null,
    facilities: [],
    occupancies: [],
  };
  firstSearch: boolean;
  hotelsSubscription: Subscription[] = [];
  STARS_FILTERS: Boolean[] = [false, false, false, false, false];
  filters: Facility[] = [];
  filtersSelected: string[] = [];
  showPrice = false;
  nextMinElements = 0;
  mapHasBeenDragged = false;
  date = new Date();
  minDateNgStruct = {
    year: this.date.getFullYear(),
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  maxDateNgStruct = {
    year: this.date.getFullYear() + 20,
    month: this.date.getMonth() + 1,
    day: this.date.getDate(),
  };
  @ViewChild(RoomsComponent) roomChildren: RoomsComponent;
  searchSubscription: Subscription;
  translateSubscription: Subscription;
  formRoom: any;
  rooms = [];
  filterNumRooms = 1;
  filterNumRoomsAux = 1;
  countAdults = 1;
  isUser: boolean;
  uuidUser: string;
  authSubscription: Subscription;
  moreHotelsButtonDisabled = false;
  canRequest = true;
  isAdmin = false;
  isEmployee = false;
  private subcriptions: Subscription[] = [];
  canUseTRM: boolean;
  userToBook: string;
  companyUuid: string;
  opened: boolean[][] = [[false], [false], [false]];
  @ViewChildren(NgbDropdown) dropdown: NgbDropdown;
  navActive = 1;
  module = "";
  groupFilters: any;
  showHotels: boolean = true;
  loadingMoreHotels: boolean = false;
  loading: boolean[] = [false, false];
  paginationConf: PaginationConfig = {
    page: 1,
    pageSize: 20,
    collectionSize: 120,
  };
  length = 0;
  isLastPage = false;
  @ViewChild("paginator") paginator: MatPaginator;
  minPrice: number;
  maxPrice: number;
  filtersApplicated: any = {};
  isMoreHotels: boolean = false;
  temporaryHotelsList = [];
  selectedUsers: any;
  projectData: any;

  constructor(
    private searchService: SearchService,
    private ngxToastrService: NgxToastrService,
    private store: Store<AppState>,
    public translate: TranslateService,
    private googleMapService: GoogleMaps,
    private formBuilder: UntypedFormBuilder,
    private ngbDateParser: NgbDateParserFormatter,
    private userService: UserService,
    public utilsService: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: MatDialog,
    private viewportScroller: ViewportScroller,
    private cdr: ChangeDetectorRef
  ) {
    this.utilsService.socket.on("hotels-by-coordinates", (res) => {
      if (this.utilsService.correlationId.includes(res.correlationId)) {
        this.utilsService.loadingHotels = false;
        if (res.data.type && res.data.type?.toLowerCase().includes("error")) {
          this.utilsService.loadingHotels = false;
          this.translate
            .get(["search.search-homepage.error-get-hotels"])
            .subscribe((result) =>
              this.ngxToastrService.typeInfo(
                null,
                result["search.search-homepage.error-get-hotels"]
              )
            );
        }
        if (res.data.count <= 0) {
          this.nextMinElements = res.data.nextMinElements;
          this.loadMoreHotelsPage();
        } else {
          const hotelExists = (hotel, hotelList) => {
            return hotelList.some(existingHotel => existingHotel.uuid === hotel.uuid);
          };

          let filteredHotels = this.filterHotelsByFacilities(res.data.hotels, this.filtersSelected);
          filteredHotels = this.filterByStars(filteredHotels);
          filteredHotels = this.filterByPrice(filteredHotels);

          this.temporaryHotelsList = this.temporaryHotelsList.concat(filteredHotels);

          if (this.temporaryHotelsList.length >= 20) {

            this.hotelListCopy = this.hotelListCopy ? this.hotelListCopy.concat(this.temporaryHotelsList.filter(hotel => !hotelExists(hotel, this.hotelListCopy))) : this.temporaryHotelsList;
            this.hotelListCopy = this.filterHotelsByFacilities(this.hotelListCopy, this.filtersSelected)
            this.hotelListCopy = this.filterByStars(this.hotelListCopy);
            this.hotelListCopy = this.filterByPrice(this.hotelListCopy);
            if (this.order.order === "price") {
              this.orderByPrice(this.hotelListCopy);
            } else {
              this.orderByDistance(this.hotelListCopy);
            }
  
            this.hotelsList = this.hotelListCopy;
            this.searchService.hotelsList = this.hotelListCopy;
            this.searchService.searchConfig = this.searchConfig;
            this.searchService.nextMinElements = res.data.nextMinElements;
            this.nextMinElements = this.searchService.nextMinElements;
            this.length = this.hotelListCopy ? this.hotelListCopy.length : 0;
  
            this.hotelMaps = this.hotelListCopy;
            if (this.order.order === "price") {
              this.orderByPrice(this.hotelMaps);
            } else {
              this.orderByDistance(this.hotelMaps);
            }
  
            this.hotelMapsCopy = this.hotelMaps;
            if (this.order.order === "price") {
              this.orderByPrice(this.hotelMapsCopy);
            } else {
              this.orderByDistance(this.hotelMapsCopy);
            }
  
            this.temporaryHotelsList = []
  
            this.searchService.hotelsMap = this.hotelMapsCopy;
            this.searchService.searchConfig = this.searchConfig;

            this.hotelsList = this.hotelListCopy.slice(
              0,
              this.paginationConf.pageSize
            );
  
            if (this.hotelsList?.length < 20 || this.hotelListCopy?.length <= 20) {
              this.isLastPage = true;
            }else{
              this.isLastPage = false;
            }
            this.getGroupFilters();
            this.getMaxPrice(this.hotelListCopy);
            this.utilsService.loadingHotels = false;

            this.cdr.detectChanges();

            this.loading[1] = false;
            if (this.hotelMapsCopy.length > 0 && !this.loading[0])
              this.utilsService.loadingHotels = false;
    
            localStorage.setItem("nextMinElements", res.data.nextMinElements);
            this.loading[0] = false;
            if (this.hotelListCopy.length > 0 && !this.loading[1])
              this.utilsService.loadingHotels = false;

            this.firstSearch = false;
            if (this.paginator != null && this.paginator.pageIndex !== 0) {
              this.paginator.pageIndex = 0;
            }
          } else {
            this.nextMinElements = res.data.nextMinElements;
            this.loadMoreHotelsPage();
          }
        }
      }
    });

    this.subcriptions.push(
      this.store.select("auth").subscribe((s: any) => {
        if (s.user && s.user.type?.toUpperCase() !== "USER") {
          this.isAdmin = true;
          if (s.user.type?.toUpperCase() === "EMPLOYEE") {
            this.isEmployee = true;
          }
          this.userToBook = localStorage.getItem("userToBook")
            ? this.utilsService.decrypt(localStorage.getItem("userToBook"))
            : null;
          this.canUseTRM = s.user.type
            ? s.user.plan && s.user.plan.permissions.canUseTRM
            : s.user.user.plan && s.user.user.plan.permissions.canUseTRM;
          if(!this.canUseTRM){
            this.canUseTRM = this.authService.userCompanyPlan?.permissions.canUseTRM;
          }
          if (this.userToBook !== null) {
            this.subcriptions.push(
              this.userService.getUser(this.userToBook).subscribe(
                (user) => {
                  this.companyUuid = user.companyUuid;
                },
                (err) => {
                  this.subcriptions.push(
                    this.translate
                      .get("common.oops-error")
                      .pipe(take(1))
                      .subscribe((result) => {
                        this.ngxToastrService.typeInfo(result, "");
                      })
                  );
                }
              )
            );
          }
        } else {
          this.companyUuid = s.user.companyUuid;
        }
      })
    );

    this.firstSearch = true;
    this.order = {
      type: "asc",
      order: "distance",
    };
    this.authSubscription = this.store
      .select("auth")
      .pipe(take(1))
      .subscribe((auth: any) => {
        if (auth.isAuthenticated) {
          this.uuidUser = this.route.snapshot.paramMap.has("id")
            ? this.route.snapshot.paramMap.get("id")
            : auth.user.type
              ? auth.user.uuid
              : auth.user.user.uuid;
          // do nothing this.userService
          this.userService
            .getGroupUser(this.uuidUser)
            .pipe(take(1))
            .subscribe((value) => {
              if (value) {
                this.canRequest = !value.filters.onlyAuthorization;
              }
            });
        }
      });

    this.subcriptions.push(
      this.translate.onLangChange
        .pipe(startWith(this.translate.currentLang), debounceTime(100))
        .subscribe(() => {
          this.searchService.getFacilities().subscribe((facilities: any) => {
            this.filters = facilities;
          });
        })
    );
    this.viewSelectRoom();

    if (
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state
    ) {
      this.searchConfig =
        this.router.getCurrentNavigation().extras.state.searchConfig;
      this.hotelMapsCopy =
        this.router.getCurrentNavigation().extras.state.hotelsMapCopy;
      this.hotelListCopy =
        this.router.getCurrentNavigation().extras.state.hotelsListCopy;
      this.hotelMaps =
        this.router.getCurrentNavigation().extras.state.hotelsMapCopy;
      this.hotelsList =
        this.router.getCurrentNavigation().extras.state.hotelsListCopy;
      this.selectedUsers = this.router.getCurrentNavigation().extras?.state?.selectedUsers
      this.projectData = this.router.getCurrentNavigation().extras?.state?.projectData;
      if (this.router.getCurrentNavigation().extras.state.newSearch) {
        this.newSearch(this.searchConfig);
      }

      if (this.router.getCurrentNavigation().extras?.state?.filters) {
        const filter = this.router.getCurrentNavigation().extras?.state?.filters;
        this.filtersApplicated = filter;
        if (filter.stars) {
          this.STARS_FILTERS = filter?.stars;
        }

        if (filter.facilities) {
          this.searchService.facilities = filter?.facilities;
        }

        if (filter.maxPrice) {
          this.updateOptions(filter.options);
          this.highValueApplicated = filter.maxPrice;
          this.maxPrice = filter.maxPrice;
        }

        if (filter.minPrice) {
          this.value = filter.minPrice;
          this.minPrice = filter.minPrice;
        }

      }
    } else {
      this.hotelListCopy = this.searchService.hotelsList;
      this.hotelMapsCopy = this.searchService.hotelsMap;
      this.searchConfig = this.searchService.searchConfig;
      this.hotelsList = this.searchService.hotelsList;
      this.hotelMaps = this.searchService.hotelsMap;
    }
    if (this.searchConfig && this.searchConfig.userUuid && this.searchConfig.userUuid !== this.userToBook) {
      this.searchConfig.userUuid = this.userToBook;
    }
    this.nextMinElements = this.searchService.nextMinElements;
    this.filtersSelected = this.searchService.facilities;
    if (this.hotelListCopy?.length > 0) {
      this.firstSearch = false;
    }
    if (this.hotelListCopy.length > 0) {
      this.length = this.hotelListCopy ? this.hotelListCopy.length : 0;
      this.setFilter(this.order.order);
    }

    this.options = {
      floor: 0,
      ceil: 0,
      step: 0.1,
    };
    this.options = {
      translate: (value: number, label: any): string => {
        if (value === this.options.ceil) {
          return `+ ${value}`;
        } else {
          return `${value}`
        }
      }
    }
    if(this.hotelListCopy.length > 0){
      this.getMaxPrice(this.hotelListCopy)
      this.options = {
        ...this.options,
        floor: this.groupFilters?.priceMin || 1
      };
    }
  }

  ngOnInit() {
    if(this.firstSearch == true){
      /* this.searchConfig = {
        uuid: "",
        locationText: "",
        latitude: "",
        longitude: "",
        checkIn: null,
        minElements: "0",
        checkOut: null,
        occupancies: [
          {
            rooms: 1,
            adults: 1,
            children: 0,
            paxes: [],
          },
        ],
      }; */
    }
    this.currentTab();
    this.module = "list";
    this.store
      .select("auth")
      .pipe(take(1))
      .subscribe((auth: any) => {
        this.canUseTRM = auth.user.type
          ? auth.user.plan && auth.user.plan.permissions.canUseTRM
          : auth.user.user.plan && auth.user.user.plan.permissions.canUseTRM;
        if(!this.canUseTRM){
          this.canUseTRM = this.authService.userCompanyPlan?.permissions.canUseTRM;
        }
        if (auth.isAuthenticated) {
          this.isUser =
            auth.user.type?.toUpperCase() === "USER" ||
            (!this.canUseTRM && auth.user.type?.toUpperCase() !== "EMPLOYEE");
          if (!this.isUser && this.route.snapshot.paramMap.get("id")) {
            this.userService
              .getUser(this.uuidUser)
              .pipe(take(1))
              .subscribe(
                (user: any) => {
                  this.userService
                    .getGroupUser(this.uuidUser)
                    .pipe(take(1))
                    .subscribe((value) => {
                      if (value) {
                        this.groupFilters = value.filters;
                        this.getGroupFilters();
                        this.getMaxPrice(this.hotelListCopy);
                      }
                    });
                },
                () => {
                  this.router.navigate([""]);
                  return false;
                }
              );
          } else if (this.isUser) {
            this.userService
              .getGroupUser(this.uuidUser)
              .pipe(take(1))
              .subscribe((value) => {
                if (value) {
                  this.groupFilters = value.filters;
                  this.getGroupFilters();
                  if (!this.router.getCurrentNavigation()?.extras?.state?.filters) {
                    this.getMaxPrice(this.hotelListCopy);
                  }
                }
              });
          } else {
            this.router.navigate(["/"]);
            return false;
          }
        }
      });
    this.route.data.subscribe((res) => {
      if (
        this.authService.userCompanyPlan &&
        !this.authService.userCompanyPlan.permissions
          .canRequestAutomatizedBookings
      ) {
        this.router.navigate(["/my-bookeds"]);
      }
    });

    let countRoom = 0;
    this.countAdults = 0;
    this.searchConfig.occupancies.forEach((occupancy) => {
      countRoom++;
      this.countAdults += occupancy.adults;
    });
    this.filterNumRooms = countRoom;
  }

  ngAfterViewInit() { }

  currentTab() {
    switch (this.module) {
      case "list":
        this.navActive = 1;
        break;
      case "map":
        this.navActive = 2;
        break;
      default:
        break;
    }
  }

  getOutMinDate() {
    return this.utilsService.getOutDate(
      this.minDateNgStruct,
      this.searchConfig.checkIn
    );
  }

  updateOptions(newOptions: any) {
    this.optionsApplicated = { ...this.options, ...newOptions };

    if (!this.optionsApplicated?.floor) {
      this.optionsApplicated.floor = 1;
    }
  }

  setFilter(order: any) {
    this.order.order = order === "distance" ? "distance" : "price";
    this.searchConfig.order = this.order;
    this.searchConfig.userUuid = this.userToBook ? this.userToBook : this.uuidUser;

    if (this.order.order === "price") {
      if (this.order.type === "asc") {
        this.hotelListCopy.sort((a, b) => a.totalBooked - b.totalBooked);
      } else {
        this.hotelListCopy.sort((a, b) => b.totalBooked - a.totalBooked);
      }
    } else {
      if (this.order.type === "asc") {
        this.hotelListCopy.sort(
          (a, b) =>
            parseFloat(a.distanceToDestination) -
            parseFloat(b.distanceToDestination)
        );
      } else {
        this.hotelListCopy.sort(
          (a, b) =>
            parseFloat(b.distanceToDestination) -
            parseFloat(a.distanceToDestination)
        );
      }
    }

    this.hotelsList = this.hotelListCopy.slice(
      0,
      this.paginationConf.pageSize
    );

    if (this.length <= 20) {
      this.isLastPage = true;
    } else {
      this.isLastPage = false;
    }
  }

  filter() {
  }

  filterByStars(hotels: Hotel[]): Hotel[] {
    if (!this.STARS_FILTERS.includes(true)) {

      return hotels;
    }
  
    return hotels.filter(hotel => {
      const stars = parseInt(hotel.stars, 10);
      return this.STARS_FILTERS[stars - 1];
    });
  }
  

  starsFilter(index?: number) {
    if (index >= 0 && index < 5)
      this.STARS_FILTERS[index!!] = !this.STARS_FILTERS[index!!];
    this.moreHotelsButtonDisabled = false;
  }

  after(date1: NgbDateStruct, date2: NgbDateStruct) {
    const d1 = new NgbDate(date1.year, date1.month, date1.day);
    const d2 = new NgbDate(date2.year, date2.month, date2.day);
    return d2.after(d1);
  }

  equal(date1: NgbDateStruct, date2: NgbDateStruct) {
    const d1 = new NgbDate(date1.year, date1.month, date1.day);
    const d2 = new NgbDate(date2.year, date2.month, date2.day);
    return d2.equals(d1);
  }

  priceFilter($event) {
    this.value = $event.value;
    this.highValue = $event.highValue;
    this.moreHotelsButtonDisabled = false;
    this.minPrice = $event.value;
    this.maxPrice = this.options.ceil !== $event.highValue ? $event.highValue : null;
  }

  onSearch() {
    localStorage.removeItem("nextMinElements");
    this.nextMinElements = 0;
    this.isLastPage = false;
    this.moreHotelsButtonDisabled = false;
    if (
      this.searchConfig.checkIn === null ||
      this.searchConfig.checkOut === null ||
      this.searchConfig.latitude === "" ||
      this.searchConfig.longitude === ""
    ) {
      this.translate
        .get([
          "search.search-homepage.check-data",
          "search.search-homepage.check-locate-dates",
        ])
        .subscribe((result) => {
          this.ngxToastrService.typeInfo(
            result["search.search-homepage.check-data"],
            result["search.search-homepage.check-locate-dates"]
          );
        });
      this.googleMapService.setCoordinates(
        this.searchConfig.latitude,
        this.searchConfig.longitude
      );
    } else {
      if (this.after(this.searchConfig.checkIn, this.searchConfig.checkOut)) {
        const now = new Date();
        const format = {
          day: now.getUTCDate(),
          month: now.getUTCMonth() + 1,
          year: now.getUTCFullYear(),
        };
        if (
          (this.equal(format, this.searchConfig.checkIn) ||
            this.after(format, this.searchConfig.checkIn)) &&
          this.after(format, this.searchConfig.checkOut)
        ) {
          this.searchConfig.order = this.order;
          this.searchConfig.userUuid = this.userToBook ? this.userToBook : this.uuidUser;
          this.loadHotelList();
          this.getFacilities();
        } else {
          this.translate
            .get(["search.search-homepage.checkout-before-checkin"])
            .subscribe((result) =>
              this.ngxToastrService.typeInfo(
                null,
                result["search.search-homepage.checkout-before-checkin"]
              )
            );
        }
      } else {
        this.translate
          .get(["search.search-homepage.checkout-before-checkin"])
          .subscribe((result) =>
            this.ngxToastrService.typeInfo(
              null,
              result["search.search-homepage.checkout-before-checkin"]
            )
          );
      }
    }
  }

  getFacilities() {
    this.searchService.getFacilities().subscribe(
      (res) => {
        this.filters = res;
      },
      (err) => { }
    );
  }

  loadHotelList() {
    this.hotelListCopy = [];
    this.hotelsList = [];
    this.hotelMapsCopy = [];
    this.hotelMaps = [];
    const checkInAux = moment(
      this.ngbDateParser.format(this.searchConfig.checkIn),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const checkOutAux = moment(
      this.ngbDateParser.format(this.searchConfig.checkOut),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const params = {
      latitude: this.searchConfig.latitude,
      longitude: this.searchConfig.longitude,
      checkIn: checkInAux,
      checkOut: checkOutAux,
      occupancies: this.searchConfig.occupancies,
      order: this.searchConfig.order,
      userUuid: this.searchConfig.userUuid,
    };
    const param: any = this.searchConfig;
    if (
      (param.facilities && param.facilities.length > 0) ||
      this.filtersSelected.length > 0
    ) {
      params["facilities"] = param.facilities || this.filtersSelected;
    }
    const correlation = crypto.randomUUID()
    this.utilsService.correlationId.push(correlation);
    params['correlationId'] = correlation;
    if (!this.firstSearch) {
      delete params["facilities"];
    }
    this.filtersSelected = [];
    this.filtersApplicated = {};
    this.STARS_FILTERS = [false, false, false, false, false];
    this.options.floor = this.groupFilters.priceMin;
    this.options.ceil = this.groupFilters.priceMax;
    this.options = Object.assign({}, this.options);
    this.value = 1;
    this.searchService.getListHotelsByCoordinate(params).subscribe((res) => {
      this.isMoreHotels = false;
    });

  }

  newSearch(event) {
    this.searchConfig = event;
    this.utilsService.loadingHotels = true;
    this.loading[0] = true;
    this.loading[1] = true;
    this.onSearch();
  }
  
  unFocusInput() {
    if (this.searchConfig.checkIn && this.searchConfig.checkOut) {
      document.getElementById("date_form").focus();
      document.getElementById("submit-button").click();
    } else {
      document.getElementById("date_form").focus();
    }
  }

  getAddress(place: object) {
    if (place["geometry"]) {
      this.searchConfig.locationText = place["formatted_address"];
      this.searchConfig.latitude = place["geometry"]["location"]
        .lat()
        .toString();
      this.searchConfig.longitude = place["geometry"]["location"]
        .lng()
        .toString();
    } else {
      this.searchConfig.latitude = "";
      this.searchConfig.longitude = "";
      this.translate
        .get(["common.attention", "search.search-homepage.pick-valid-location"])
        .subscribe((result) => {
          this.ngxToastrService.typeInfo(
            result["common.attention"],
            result["search.search-homepage.pick-valid-location"]
          );
        });
    }
    this.unFocusInput();
  }

  getMaxDistance() {
    return 100000;
  }

  getMoreHotels(hasCenterChanged?: boolean) {
    if (this.module === "map") {
      if (this.nextMinElements === 0) {
        this.nextMinElements = parseFloat(
          localStorage.getItem("nextMinElements")
        );
      }
      if (!this.moreHotelsButtonDisabled) {
        this.loadingMoreHotels = true;
        this.moreHotelsButtonDisabled = true;
        const checkInAux = moment(
          this.ngbDateParser.format(this.searchConfig.checkIn),
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
        const checkOutAux = moment(
          this.ngbDateParser.format(this.searchConfig.checkOut),
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
        // const maxDistance = this.getMaxDistance();
        const params = {
          latitude: this.searchConfig.latitude,
          longitude: this.searchConfig.longitude,
          checkIn: checkInAux,
          checkOut: checkOutAux,
          occupancies: this.searchConfig.occupancies,
          order: this.searchConfig.order,
          userUuid: this.searchConfig.userUuid,
        };
        if (this.filtersSelected.length > 0)
          params["facilities"] = this.filtersSelected;
        if (hasCenterChanged) {
          this.mapHasBeenDragged = true;
          this.nextMinElements = 0;
        } else {
          this.mapHasBeenDragged = false;
        }
        const elementToSearch = this.nextMinElements.toString();
        const correlation = crypto.randomUUID()
        this.utilsService.correlationId.push(correlation);
        params['correlationId'] = correlation;
        this.hotelsSubscription.push(
          this.searchService
            .getListHotelsScroll(params, elementToSearch)
            .subscribe((result) => {
              this.isMoreHotels = true;
            })
        );
      }
    } else {
      if (this.hotelListCopy.length === 0 || this.paginationConf.pageSize === 0) {
        this.utilsService.loadingHotels = false;
      } else {
        let nextPageStart = (this.paginationConf.page - 1) * this.paginationConf.pageSize;
        let nextPageEnd = nextPageStart + this.paginationConf.pageSize;
        
        if (nextPageEnd > this.hotelListCopy.length) {
          nextPageEnd = this.hotelListCopy.length;
        }
    
        if (nextPageEnd >= this.length) {
          this.isLastPage = true;
        } else {
          this.isLastPage = false;
        }
        
        this.hotelsList = this.hotelListCopy.slice(nextPageStart, nextPageEnd);
      }
    }
  }

  getRooms() {
    return this.formRoom.get("room") as UntypedFormArray;
  }

  applyFilters(event: any) {
    this.filterNumRooms = this.filterNumRoomsAux;
    this.countAdults = 0;
    const occupancies = [];
    this.moreHotelsButtonDisabled = false;
    if (this.firstSearch && localStorage.getItem("travel") == null) {
      this.firstSearch = false;
    }

    if (this.formRoom) {
      this.formRoom.value.room.forEach((r) => {
        occupancies.push({
          rooms: 1,
          adults: r.numAdults,
          paxes: [],
        });
        this.countAdults += r.numAdults;
      });
    } else {
      occupancies.push({
        rooms: 1,
        adults: 1,
        paxes: [],
      });
      this.countAdults = 1;
    }

    const idFilter = event.target.id;
    const checkedFilter = event.target.checked;

    const index = this.filtersSelected.indexOf(idFilter);

    if (checkedFilter && index === -1) {
      this.filtersSelected.push(idFilter);
    } else if (index !== -1) {
      this.filtersSelected.splice(index, 1);
    }
    this.searchService.facilities = this.filtersSelected;
    this.applyFilterConfig.locationText = this.searchConfig.locationText;
    this.applyFilterConfig.latitude = this.searchConfig.latitude;
    this.applyFilterConfig.longitude = this.searchConfig.longitude;
    this.applyFilterConfig.checkIn = this.searchConfig.checkIn;
    this.applyFilterConfig.checkOut = this.searchConfig.checkOut;
    this.applyFilterConfig.facilities = this.filtersSelected;
    this.applyFilterConfig.occupancies = occupancies;
    this.applyFilterConfig.order = this.order;
    this.applyFilterConfig.userUuid = this.uuidUser;
  }

  applyFiltersAction() {
    this.utilsService.loadingHotels = true;
    const checkInAux = moment(
      this.ngbDateParser.format(this.applyFilterConfig?.checkIn ?? this.searchConfig?.checkIn),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const checkOutAux = moment(
      this.ngbDateParser.format(this.applyFilterConfig?.checkOut ?? this.searchConfig?.checkOut),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const params = {
      latitude: this.applyFilterConfig.latitude || this.searchConfig.latitude,
      longitude: this.applyFilterConfig.longitude || this.searchConfig.longitude,
      checkIn: checkInAux,
      checkOut: checkOutAux,
      facilities: this.applyFilterConfig?.facilities?.length > 0 ? this.applyFilterConfig?.facilities : this.searchService.facilities,
      occupancies: this.applyFilterConfig?.occupancies?.length > 0 ? this.applyFilterConfig.occupancies : this.searchConfig.occupancies,
      order: this.applyFilterConfig.order ?? this.order,
      userUuid: this.applyFilterConfig.userUuid ?? this.uuidUser,
    };

    if (this.STARS_FILTERS.some(star => star === true)) {
      params['stars'] = this.STARS_FILTERS;
    }

    if (this.minPrice) {
      params["minPrice"] = this.minPrice;
      this.filtersApplicated['minPrice'] = this.minPrice;
    }

    if (this.maxPrice) {
      params["maxPrice"] = this.maxPrice;
      this.filtersApplicated['maxPrice'] = this.maxPrice;
    }

    this.filtersApplicated['stars'] = this.STARS_FILTERS;
    this.filtersApplicated['facilities'] = this.applyFilterConfig?.facilities?.length > 0 ? this.applyFilterConfig?.facilities : this.searchService.facilities;
    this.filtersApplicated['options'] = { ceil: this.options?.ceil, floor: this.options?.floor };
    const correlation = crypto.randomUUID()
    this.utilsService.correlationId.push(correlation);
    params['correlationId'] = correlation;
    return this.searchService
      .getListHotelsByCoordinateAndFilters(params)
      .subscribe(
        (res) => {
          this.isMoreHotels = false;
        },
        (err) => {
          this.utilsService.loadingHotels = false;
          this.translate
            .get(["search.search-homepage.error-get-hotels"])
            .subscribe((result) =>
              this.ngxToastrService.typeInfo(
                null,
                result["search.search-homepage.error-get-hotels"]
              )
            );
        }
      );
  }

  isChecked(idFilter) {
    const index = this.filtersSelected.indexOf(idFilter);
    return index !== -1;
  }

  addRoom(number: number) {
    const arrayRooms = [];
    for (let i = 0; i < number; i++) {
      arrayRooms.push({
        idHab: i + 1,
        numAdults: 1,
      });
      this.formRoom = this.formBuilder.group({
        room: this.formBuilder.array(arrayRooms),
      });
      this.rooms = arrayRooms;
    }
  }


  add(elementId: string, index: number) {
    (<HTMLInputElement>document.getElementById(elementId)).stepUp();

    if (
      elementId.includes("adult") &&
      this.formRoom.getRawValue().room[index].numAdults < 4
    ) {
      this.formRoom.controls["room"].controls[index].setValue({
        idHab: this.formRoom.getRawValue().room[index].idHab,
        numAdults: this.formRoom.getRawValue().room[index].numAdults + 1
      });
      this.rooms = this.formRoom.getRawValue().room;
    }
  }

  substract(elementId: string, index: number) {
    const element: any = <HTMLInputElement>document.getElementById(elementId);
    element.stepDown();
    if (
      elementId.includes("adult") &&
      this.formRoom.getRawValue().room[index].numAdults > 1
    ) {
      this.formRoom.controls["room"].controls[index].setValue({
        idHab: this.formRoom.getRawValue().room[index].idHab,
        numAdults: this.formRoom.getRawValue().room[index].numAdults - 1
      });
      this.rooms = this.formRoom.getRawValue().room;
    }
  }

  viewSelectRoom() {
    const arrayLoadRoom = [];
    let countRoom = 0;
    this.countAdults = 0;

    this.searchConfig.occupancies.forEach((occupancy) => {
      countRoom++;
      this.countAdults += occupancy.adults;
      const loadRoom = {
        idHab: countRoom,
        numAdults: occupancy.adults,
      };
      arrayLoadRoom.push(loadRoom);
    });
    this.filterNumRooms = this.searchConfig.occupancies.length;
    this.filterNumRoomsAux = this.filterNumRooms;
    this.formRoom = this.formBuilder.group({
      room: this.formBuilder.array(arrayLoadRoom),
    });
    this.rooms = new Array(this.formRoom.getRawValue().room[0]);
  }

  hotelHoveredIn(hotel: Hotel) {
    const index = this.hotelsList.indexOf(hotel);
    if (!hotel.hovered) {
      hotel.hovered = true;
      this.hotelMaps[index] = hotel;
    }
  }

  hotelHoveredOut(hotel: Hotel) {
    const index = this.hotelsList.indexOf(hotel);

    if (hotel.hovered) {
      hotel.hovered = false;
      this.hotelMaps[index] = hotel;
    }
  }

  checkDropdown(dropdown: any, number: number, item: number) {
    this.opened[number][item] = dropdown;
  }

  openSearchModal() {
    const modalRef = this.modalService.open(SearchFormComponent, {
      panelClass: "hotel-form-modal",
    });
    modalRef.componentInstance.openModal = true;
    modalRef.componentInstance.searchConfig = this.searchConfig;
    modalRef.componentInstance.userToBook = this.userToBook;
    modalRef.componentInstance.companyUuid = this.companyUuid;
    modalRef.componentInstance.uuidUser= this.uuidUser;
    modalRef.componentInstance.isAdmin= this.isAdmin;
    modalRef.componentInstance.groupFilters= this.groupFilters;
    if(this.selectedUsers){
      modalRef.componentInstance.travelersSelected= this.selectedUsers;
    }
    if(this.projectData){
      modalRef.componentInstance.projectDataSaved= this.projectData;
    }
    modalRef.componentInstance.emitData.subscribe((data: any) => {
      this.newSearch(data);
      modalRef.close();
      modalRef.afterClosed().subscribe(() => {
        const elements = document.getElementsByClassName('pac-container')
        if (elements.length > 0) {
          const firstElement = elements[0] as HTMLElement
          firstElement.style.opacity = '0'
        }
      });
    });
    modalRef.componentInstance.emitSelectedUsers.subscribe((selectedUsers: any) => {
      this.handleSelectedUsers(selectedUsers);
    });
    modalRef.componentInstance.emitProject.subscribe((projectData: any) => {
      this.handleSelectedProjects(projectData);
    });
  }

  handleSelectedUsers(selectedUsers: any) {
    this.selectedUsers = selectedUsers;
  }

  handleSelectedProjects(projectData: any){
    this.projectData = projectData;
  }

  totalNights(checkIn, checkOut) {
    const diff = checkOut - checkIn;
    return diff / (1000 * 60 * 60 * 24);
  }

  getGroupFilters() {
    if (this.groupFilters && !this.groupFilters.breakfast) {
      this.filters = this.filters.filter((f) => {
        f.code !== "BREAKFAST";
      });
    }
    if (this.searchConfig.checkIn && this.searchConfig.checkOut) {
      const totalNights = this.totalNights(
        new Date(
          this.searchConfig.checkIn.year,
          this.searchConfig.checkIn.month,
          this.searchConfig.checkIn.day
        ),
        new Date(
          this.searchConfig.checkOut.year,
          this.searchConfig.checkOut.month,
          this.searchConfig.checkOut.day
        )
      );
      if (this.options.ceil === 0) {
        this.groupFilters.priceMax = this.groupFilters.priceMax * totalNights;
        this.options.floor = this.groupFilters.priceMin;
        this.options.ceil = this.groupFilters.priceMax;
        this.options = Object.assign({}, this.options);
        this.highValue = this.options.ceil;
      }
    }
  }

  getMaxPrice(listHotels) {
    let maxPrice = 0;
    listHotels.forEach((hotel) => {
      if (maxPrice < hotel.totalBooked)
        maxPrice = hotel.totalBooked;
    })

    this.highValue = maxPrice;
    this.options = {
      ...this.options,
      ceil: Math.floor(this.highValue / 100) * 100
    };
  }

  onPageChange(event: any) {
    this.paginationConf.page = event.pageIndex + 1;
    this.paginationConf.pageSize = event.pageSize;
    this.getMoreHotels();
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  getPaginatorData(event: PageEvent): PageEvent {
    return event;
  }

  loadMoreHotelsPage() {
    this.utilsService.loadingHotels = true;
    this.loadingMoreHotels = true;
    this.moreHotelsButtonDisabled = true;
    const checkInAux = moment(
      this.ngbDateParser.format(this.searchConfig.checkIn),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const checkOutAux = moment(
      this.ngbDateParser.format(this.searchConfig.checkOut),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const params = {
      latitude: this.searchConfig.latitude,
      longitude: this.searchConfig.longitude,
      checkIn: checkInAux,
      checkOut: checkOutAux,
      occupancies: this.searchConfig.occupancies,
      order: this.searchConfig.order,
      userUuid: this.searchConfig.userUuid,
    };
    if (this.STARS_FILTERS.some(star => star === true)) {
      params['stars'] = this.STARS_FILTERS;
    }
    if (this.filtersSelected.length > 0) {
      params["facilities"] = this.filtersSelected;
    }

    if (this.minPrice) {
      params["minPrice"] = this.minPrice;
    }

    if (this.maxPrice || this.highValueApplicated) {
      params["maxPrice"] = this.maxPrice ?? this.highValueApplicated;
    }
    const elementsToSearch = this.nextMinElements.toString();
    const correlation = crypto.randomUUID()
    this.utilsService.correlationId.push(correlation);
    params['correlationId'] = correlation;
    this.hotelsSubscription.push(
      this.searchService
        .getListHotelsScroll(params, elementsToSearch)
        .subscribe(
          (result) => {
            this.isMoreHotels = true;
          },
          (err) => {
            this.loadingMoreHotels = false;
            this.utilsService.loadingHotels = false;
          }
        )
    );
  }


  private filterHotelsByFacilities(hotels: any[], facilities: string[]): any[] {
    if (!facilities || facilities.length === 0) {
      return hotels;
    }

    return hotels.filter(hotel => 
      facilities.every(facility => hotel.facility.includes(facility))
    );
  }

  orderByPrice(list: Hotel[]) {
    return list.sort(function (h1, h2) {
      return h1.totalBooked - h2.totalBooked;
    });
  }

  orderByDistance(list: Hotel[]) {
    return list.sort((h1, h2) => {
      return parseFloat(h1.distanceToDestination) - parseFloat(h2.distanceToDestination);
    });
  }

  filterByPrice(hotels: any[]): any[] {
  const minPrice = this.filtersApplicated?.minPrice;
  const maxPrice = this.filtersApplicated?.maxPrice;

  return hotels.filter(hotel => {
    const totalBooked = hotel.totalBooked;

    if (minPrice != null && totalBooked < minPrice) {
      return false;
    }
    if (maxPrice != null && totalBooked > maxPrice) {
      return false;
    }
    return true;
  });
}



  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    if (this.translateSubscription) {
      this.translateSubscription.unsubscribe();
    }

    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    this.hotelsSubscription.forEach((s) => s.unsubscribe());
    this.nextMinElements = 0;
    this.searchService.hotelsMap = [];
    this.searchService.hotelsList = [];
    this.filtersSelected = [];
    this.searchService.facilities = [];
  }
}