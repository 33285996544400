<div class="modal-header">
  <h1 class="title-custom mat-dialog-title">{{travelTitle}}</h1>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div mat-dialog-content class="data-loaded-container">
  <div class="table-container">
    <table class="table">
      <thead>
        <tr>
          <th>{{'excel.name' | translate}}</th>
          <th>{{'excel.employee-number' | translate}}</th>
          <th>{{'excel.employee-dpt' | translate}}</th>
          <th>{{'excel.booking-date' | translate}}</th>
          <th>{{'excel.service' | translate}}</th>
          <th>{{'excel.destination' | translate}}</th>
          <th>{{'excel.initDate' | translate}}</th>
          <th>{{'excel.endDate' | translate}}</th>
          <th>{{'excel.iva' | translate}}</th>
          <th>{{'excel.price-not-iva' | translate}}</th>
          <th>{{'excel.total' | translate}}</th>
          <th>{{'common.custom-code' | translate}}</th>
          <th>{{'common.cost-center' | translate}}</th>
          <th>{{'company.transaction.filters.payment-card' | translate}}</th>
          <th>{{'common.status' | translate}}</th>
          <th>{{'agency.bills.bills' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let reservation of allReservations">
          <td>{{ getValueOrDefault(reservation.author) }}</td>
          <td>{{ getValueOrDefault(reservation.employeeNumber) }}</td>
          <td>{{ getValueOrDefault(reservation.groupRestrictionName) }}</td>
          <td>{{ formatDate(reservation.createdAt) }}</td>
          <td>
            <ng-container *ngIf="isExpense(reservation.type); else noTranslate">
              {{ getReservationProperty(reservation, 'type') }}
            </ng-container>
            <ng-template #noTranslate>
              {{ getReservationProperty(reservation, 'type') | translate }}
            </ng-template>
          </td>
          <td>{{ getValueOrDefault(getReservationProperty(reservation, 'destination')) }}</td>
          <td>{{ formatDate(getReservationProperty(reservation, 'initDate')) }}</td>
          <td>{{ formatDate(getReservationProperty(reservation, 'endDate')) }}</td>
          <td>{{ getIvaPrice(reservation.price) | number:'1.2-2' }} €</td>
          <td>{{ getRawPrice(reservation.price) | number:'1.2-2' }} €</td>
          <td>{{ getPrice(reservation.price) | number:'1.2-2' }} €</td>
          <td>{{ getValueOrDefault(reservation.customCode) }}</td>
          <td>{{ getValueOrDefault(reservation.costCenter) }}</td>
          <td>{{ getValueOrDefault(reservation?.paymentCard?.name) }}</td>
          <td>{{ getReservationProperty(reservation, 'status') | translate }}</td>
          <td>{{ transactionStatus(reservation) | translate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
